<template>
<ComponentsLayoutPage1
  header
  footer
>

</ComponentsLayoutPage1>
</template>

<script>
import ComponentsLayoutPage1 from '@/components/layout/Page1'

export default {
  name: 'IndexPage',

  data: () => ({
  }),

  components: {
    ComponentsLayoutPage1
  }
}
</script>
