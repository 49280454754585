<template>
<v-bottom-sheet persistent scrollable v-model="model" v-if="model">
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutHeading1 text="体重情報" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="center" justify="center" :style="{ maxWidth: '840px' }"  no-gutters>
        <v-col cols=12 class="pt-3">
          <v-btn-toggle color="primary" v-model="componentsIndex" borderless mandatory>
            <v-btn :value="index" v-for="(components, index) of components" :key="index">
              <v-icon :color="(componentsIndex == index) ? 'primary' : 'grey lighten-1'">{{ components.icon }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols=12>
          <component v-bind:is="components[componentsIndex].component" :personal-id="personalId"></component>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            text-btn
            text="閉じる"
            :loading="isProcessLoading"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>

</v-bottom-sheet>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsItemsCalendar from '@/components/items/Calendar'
import ComponentsItemsCalculator from '@/components/items/Calculator'
import ComponentsItemsButton from '@/components/items/Button'

import ComponentsFormsWeightDays from '@/components/forms/WeightDays'
import ComponentsFormsWeightMonths from '@/components/forms/WeightMonths'

export default {
  name: 'ComponentsBottomSheetWeightHistory',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    componentsIndex: 0,
    components: [
      {
        icon: 'mdi-chart-line',
        component: ComponentsFormsWeightMonths
      },
      {
        icon: 'mdi-format-list-bulleted',
        component: ComponentsFormsWeightDays
      }
    ]
  }),

  mounted () {
    this.$store.dispatch('modStorage/LoadWeight', [this.personalId, new Date().toISOString().substr(0, 10)])
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsItemsCalendar,
    ComponentsItemsCalculator,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    },
    personalId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
