<template>
<v-menu
  ref="calendarDialog"
  v-model="modal"
  :close-on-content-click="false"
  transition="scale-transition"
  offset-y
  max-width="290px"
  min-width="auto"
  :value="value"
  @input="updateValue"
>
  <template v-slot:activator="{ on, attrs }">
    <v-btn block depressed dense v-on="on" v-bind="attrs" @blur="calendarDate = value" color="white">
      <span class="ec-font-size12" :style="{ color: buttonColor }" v-text="dateTitle()"></span><v-icon :color="buttonColor" right v-text="(modal) ? 'mdi-menu-up' : 'mdi-menu-down'"></v-icon>
    </v-btn>

  </template>
  <v-date-picker
    v-model="calendarDate"
    locale="ja-jp"
    no-title
    :day-format="date => new Date(date).getDate()"
    :type="calendarType"
  >
    <v-btn text color="primary" @click="modal = false"><span class="ec-font-size10">Cancel</span></v-btn>
    <v-spacer></v-spacer>
    <v-btn text color="primary" @click="$refs.calendarDialog.save(calendarDate)"><span class="ec-font-size10">OK</span></v-btn>
  </v-date-picker>
</v-menu>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsItemsCalendar',

  mixins: [MixinLib],

  data: () => ({
    modal: false,
    calendarDate: ''
  }),

  mounted () {
    // if (this.isUndefined(this.value)) {
    //   this.updateValue(this.getDate(null, 'YYYY-MM-DD'))
    // }
    console.log(this.value)
  },

  methods: {
    updateValue: function (e) {
      this.$emit('update:value', this.calendarDate)
    },

    dateTitle: function () {
      if (this.calendarType === 'date') {
        if (this.dateType === 'day' || this.dateType === 'category') {
          return this.formatDate(this.value, 'YYYY年MM月DD日(ddd)')
        }
        if (this.dateType === '4day') {
          return this.formatDate(this.value, 'YYYY年MM月DD日') + '～' + this.formatDate(this.addDateTime(this.value, 3, 'days'), 'MM月DD日')
        }
        if (this.dateType === 'week') {
          return this.getStartWeekDate(this.value, 'YYYY年MM月DD日', this.startWeek) + '～' + this.formatDate(this.addDateTime(this.getStartWeekDate(this.value, 'YYYY-MM-DD', this.startWeek), 6, 'days'), 'MM月DD日')
        }
      }
      if (this.calendarType === 'month') {
        return this.formatDate(this.value, 'YYYY年MM月')
      }
      if (this.calendarType === 'year') {
        return this.formatDate(this.value, 'YYYY年')
      }
    }
  },

  props: {
    value: {
      type: String,
      required: true,
      default: () => ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    dateType: {
      type: String,
      required: false,
      default: () => 'day'
    },
    calendarType: {
      type: String,
      required: false,
      default: () => 'date'
    },
    startWeek: {
      type: String,
      required: false,
      default: () => 'sun'
    }
  }
}
</script>
