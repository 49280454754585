<template>
<v-row align="center" justify="center">
  <v-col cols=12>
    <ComponentsLayoutHeading1 text="保険証情報"></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12 v-if="isUndefined(edit.insurances_id)">
    <ComponentsLayoutSection2>
      <v-row align="center" justify="center">
        <v-col cols=3>
          <v-avatar tile>
            <v-icon size=45>mdi-alert-box-outline</v-icon>
          </v-avatar>
        </v-col>
        <v-col cols=6>
          <span>設定されていません</span>
        </v-col>
        <v-col cols=3 class="text-right">
          <ComponentsItemsButton
            :loading="loading || isProcessLoading"
            icon-btn
            icon="mdi-pencil"
            @click="bottomSheetModel = true"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </ComponentsLayoutSection2>
  </v-col>
  <v-col cols=12 v-else>
    <ComponentsLayoutSection2>
      <v-row align="center" justify="center">
        <v-col cols=3>
          <v-avatar tile v-if="loading || isProcessLoading">
          </v-avatar>
          <v-avatar tile v-else>
            <v-img :loading="loading || isProcessLoading" :src="edit.personals_insurances.img_src + '?width=120&height=120'" aspect-ratio="1" class="grey lighten-2"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols=6>
          <v-row align="center" justify="center">
            <v-col cols=12 class="py-0 text-truncate" >
              <span>有効期限:</span>
            </v-col>
            <v-col cols=12 class="py-0 text-truncate" v-if="loading || isProcessLoading">
              <span>-</span>
            </v-col>
            <v-col cols=12 class="py-0 text-truncate" v-else>
              <span v-text="formatDate(edit.personals_insurances.expire_date, 'YYYY年MM月DD日')" v-if="!isUndefined(edit.personals_insurances.expire_date)"></span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols=3 class="text-right">
          <ComponentsItemsButton
            :loading="loading || isProcessLoading"
            icon-btn
            icon="mdi-pencil"
            @click="bottomSheetModel = true"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </ComponentsLayoutSection2>
  </v-col>

  <v-bottom-sheet persistent scrollable v-model="bottomSheetModel" v-if="bottomSheetModel">
    <v-card flat >
      <v-card-title :color="primaryColor" height="60">
        <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
          <v-col cols=12>
            <ComponentsLayoutHeading1 text="保険証設定" border-hidden></ComponentsLayoutHeading1>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text :style="{ backgroundColor: backGroundColor }">
        <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
          <v-col cols=12>
            <ComponentsFormsInsurances
              :personals-insurances="edit.personals_insurances"
              :loading="loading || isProcessLoading"
              ref="insurancesForms"
            >
            </ComponentsFormsInsurances>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
          <v-col cols=12 class="d-flex">
            <ComponentsItemsButton
              text-btn
              :loading="isLoading || isProcessLoading"
              text="閉じる"
              @click="bottomSheetModel = false"
            >
            </ComponentsItemsButton>
            <v-spacer></v-spacer>
            <ComponentsItemsButton
              text-btn
              :loading="isLoading || isProcessLoading"
              text="更新する"
              @click="created()"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsFormsInsurances from '@/components/forms/Insurances'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsInsurances',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    bottomSheetModel: false,
    edit: {
      insurances_id: '',
      personals_insurances: {
        action_date: '',
        expire_date: '',
        img_src: ''
      }
    }
  }),

  watch: {
    personalId () {
      this.goLoading()
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.edit = {
          insurances_id: '',
          personals_insurances: {
            action_date: '',
            expire_date: '',
            img_src: ''
          }
        }
        if (this.isUndefined(this.personalId)) {
          return false
        }
        this.isProcessLoading = true
        const resultRows = await APILib.getAnimalsInsurances(this.personalId)
        if (this.isUndefined(resultRows) === false && resultRows.length > 0) {
          this.edit = resultRows[0]
        }
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    created: function () {
      (async () => {
        let error = false
        if (this.$refs.insurancesForms) {
          await this.$refs.insurancesForms.confirm()
          if (this.$refs.insurancesForms.error) {
            error = true
          }
        }
        if (error) {
          return false
        }

        this.isProcessLoading = true
        this.edit = await APILib.potAnimalsInsurances(this.personalId, this.edit)
        this.isProcessLoading = false
        this.bottomSheetModel = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutSection2,
    ComponentsLayoutHeading1,
    ComponentsFormsInsurances,
    ComponentsItemsButton
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    personalId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
