<template>
<v-card rounded flat class="rounded-lg" :color="(isUndefined(color)) ? secondaryColor : color" @click="(onClickAction) ? $emit('click') : false" v-if="onClickAction">
  <v-card-text class="ec-font-size12" :style="{ color: tertiaryFontColor }">
    <slot></slot>
  </v-card-text>
</v-card>
<v-card rounded flat class="rounded-lg" :color="(isUndefined(color)) ? secondaryColor : color" v-else>
  <v-card-text class="ec-font-size12" :style="{ color: tertiaryFontColor }">
    <slot></slot>
  </v-card-text>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsLayoutSection1',

  mixins: [MixinLib],

  props: {
    color: {
      type: String,
      required: false,
      default: () => ''
    },
    onClickAction: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
