<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="アカウント削除"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/console/index')"
>
<v-row align="center" justify="center">

  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12 class="py-5">
          <span>消去したアカウントを元に戻すことはできませんのでご注意ください</span>
        </v-col>
        <v-col cols=12 class="py-5">
          <ComponentsItemsButton
            block
            :loading="isLoading || isProcessLoading"
            text="アカウントを削除する"
            @click="confirmDelete()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

</v-row>
</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleRemovePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  mounted: function () {
    this.isProcessLoading = false
  },

  methods: {
    confirmDelete: function () {
      this.$confirm({ title: 'Confirm', message: 'アカウントを削除します。本当に宜しいですか？', button: { yes: 'OK', no: 'Cancel' }, callback: this.commitDelete })
    },

    commitDelete: function (evt) {
      (async () => {
        if (evt) {
          await APILib.deleteUsers()
          this.SignOut(true)

          this.$confirm({ title: 'SUCCESS', message: 'アカウントを削除しました', button: { yes: 'OK' } })
          this.pageTransition('/')
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutPage2,
    ComponentsItemsButton
  }
}
</script>
