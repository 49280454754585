<template>
<v-row align="center" justify="center">
  <v-col cols=12>
    <ComponentsLayoutHeading1 text="プロフィール"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection2>
      <v-row align="center" justify="center" v-if="!isUndefined(edit)">
        <v-col cols=9>
          <v-row align="center" justify="center">
            <v-col cols=12 class="py-0 text-left text-truncate">
              <span class="ec-font-size10" v-text="getValue(edit.personals, 'name_kana1')"></span>
            </v-col>
            <v-col cols=12 class="py-0 ec-font-size16 text-left text-truncate">
              <span class="font-weight-bold" v-text="getValue(edit.personals, 'name1')"></span>
            </v-col>
            <v-col cols=12 class="py-0 text-left text-truncate">
              <span class="pr-3" v-text="getValue(edit.personals, 'animal')"></span><span v-text="getValue(edit.personals, 'species')"></span>
            </v-col>
            <v-col cols=12 class="py-0 text-left text-truncate">
              <span class="pr-3" v-text="formatDate(getValue(edit.personals, 'birth_date'), 'YYYY/MM/DD')"></span>
              <span class="pr-3" v-text="getAge(getValue(edit.personals, 'birth_date')) + '歳'"></span>
              <span class="pr-3" v-text="getAnimalSexName(getValue(edit.personals, 'sex'))"></span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols=3 class="text-right">
          <ComponentsItemsButton
            :loading="loading || isProcessLoading"
            icon-btn
            icon="mdi-pencil"
            @click="profileModal = true"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </ComponentsLayoutSection2>
  </v-col>

  <v-bottom-sheet persistent scrollable v-model="profileModal" v-if="profileModal">
    <v-card flat >
      <v-card-title :color="primaryColor" height="60">
        <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
          <v-col cols=12>
            <ComponentsLayoutHeading1 text="プロフィール設定" border-hidden></ComponentsLayoutHeading1>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text :style="{ backgroundColor: backGroundColor }">
        <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
          <v-col cols=12 v-if="!isUndefined(edit)">
            <ComponentsFormsProfileAnimal
              :loading="isLoading || isProcessLoading"
              :personals.sync="edit.personals"
              :animals_attribute.sync="edit.animals_attribute"
              ref="animals"
            ></ComponentsFormsProfileAnimal>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
          <v-col cols=12 class="d-flex">
            <ComponentsItemsButton
              text-btn
              :loading="isLoading || isProcessLoading"
              text="閉じる"
              @click="profileModal = false"
            >
            </ComponentsItemsButton>
            <v-spacer></v-spacer>
            <ComponentsItemsButton
              text-btn
              :loading="isLoading || isProcessLoading"
              text="更新する"
              @click="created()"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </v-card-actions>

    </v-card>
  </v-bottom-sheet>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsFormsProfileAnimal from '@/components/forms/ProfileAnimal'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsAnimalProfile',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    profileModal: false,
    edit: {}
  }),

  watch: {
    personalId () {
      this.goLoading()
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.vaccines = []
        if (this.isUndefined(this.personalId)) {
          return false
        }
        this.isProcessLoading = true
        this.edit = await APILib.getAnimalsContents(this.personalId)
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    created () {
      (async () => {
        this.error = false
        if (this.$refs.animals) {
          await this.$refs.animals.confirm()
          if (this.$refs.animals.error) {
            this.error = true
          }
        }
        if (this.error) {
          return false
        }
        this.isProcessLoading = true
        this.edit = await APILib.putAnimals(this.personalId, this.edit)
        await this.$store.dispatch('modStorage/LoadAnimals')
        this.$confirm({ title: 'Success', message: '情報を更新しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutSection2,
    ComponentsLayoutHeading1,
    ComponentsFormsProfileAnimal,
    ComponentsItemsButton
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    personalId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
