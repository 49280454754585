<template>
<ComponentsLayoutPage4
  header
>
  <v-row align="start" justify="start">
    <v-col cols=12 md=6 lg=6 xl=6 v-for="item in list" :key="item.stores.store_id">
      <v-card flat @click="openStore(item.stores.store_id)">
        <v-img :src="item.stores_profile.store_img_src + '?width=400&height=400'" aspect-ratio="1" height="120px" class="grey lighten-2"></v-img>
        <v-row align="center" justify="center" class="px-3 py-3" no-gutter>
          <v-col cols=12 class="py-2 primary--text subtitle-2 text-truncate">
            <span v-text="item.stores.store_name"></span>
          </v-col>
          <v-col cols=12 class="py-1 text-truncate" style="font-size:.7rem!important">
            <span v-text="'〒' + item.stores_profile.store_post_no"></span>&nbsp;<span v-text="getPrefectureName(item.stores_profile.store_prefecture_id)"></span><span v-text="item.stores_profile.store_address"></span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>

  <!-- BottomSheet --->
  <ComponentsBottomSheetStore
    :model="bottomSheets"
    :store-id="storeID"
    @close-click="bottomSheets = false"
    v-if="bottomSheets"
  >
  </ComponentsBottomSheetStore>
</ComponentsLayoutPage4>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage4 from '@/components/layout/Page4'
import ComponentsBottomSheetStore from '@/components/bottom-sheet/Store'

export default {
  name: 'ConsoleShopPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    bottomSheets: false,
    storeID: '',
    list: []
  }),

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        this.list = await APILib.getStoresList()
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    getPrefectureName: function (code) {
      const index = this.StoragePrefecture.findIndex(v => v.code === code)
      return (index >= 0) ? this.StoragePrefecture[index].name : ''
    },

    openStore: function (storeID) {
      this.storeID = storeID
      this.bottomSheets = true
    }
  },

  components: {
    ComponentsLayoutPage4,
    ComponentsBottomSheetStore
  }
}
</script>
