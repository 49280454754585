<template>
<v-row align="center" justify="center">
  <v-col cols=12 class="pt-5">
    <ComponentsLayoutHeading1 text="基本情報" border-hidden></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="名前" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsTextField
            name="animals_name"
            :value.sync="personals.name1"
            :loading="loading"
            ref="name"
          ></ComponentsItemsTextField>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="名前（カナ）" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsTextField
            name="animals_name_kana"
            :value.sync="personals.name_kana1"
            :loading="loading"
            ref="name_kana"
          ></ComponentsItemsTextField>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="動物" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsComboBox
            name="animal"
            :value.sync="personals.animal"
            :items="StorageAnimalType"
            :loading="loading"
            ref="animal"
          >
          </ComponentsItemsComboBox>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 :text="personals.animal + '種'" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsComboBox
            name="species"
            :value.sync="personals.species"
            :items="StorageSpecies"
            :loading="isLoadingSpecies || loading"
            ref="species"
          >
          </ComponentsItemsComboBox>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="性別" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsButtonToggle
            name="sex"
            :value.sync="personals.sex"
            :loading="loading"
            :items="StorageSexAnimal"
            item-text="name"
            item-value="code"
            ref="sex"
          ></ComponentsItemsButtonToggle>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="生年月日" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsDatePicker
            name="birth_date"
            :value.sync="personals.birth_date"
            :loading="loading"
            :start-date="'2020-01-01'"
            ref="birth_date"
          >
          </ComponentsItemsDatePicker>
        </v-col>

        <!--
        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="迎えた日" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsDatePicker
            name="adoptaversary_date"
            :value.sync="animalsAttribute.adoptaversary_date"
            :loading="loading"
            :start-date="'2020-01-01'"
            ref="adoptaversary_date"
          >
          </ComponentsItemsDatePicker>
        </v-col>
        --->

        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="アレルギーの有無" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsButtonToggle
            name="allergy_status"
            :value.sync="animalsAttribute.allergy_status"
            :loading="loading"
            :items="allergy_status"
            item-text="name"
            item-value="code"
            ref="allergy_status"
          ></ComponentsItemsButtonToggle>
        </v-col>
        <v-col cols=12 v-show="animalsAttribute.allergy_status === '1'">
          <ComponentsLayoutHeading3 text="お持ちのアレルギーを記載下さい（複数のアレルギーをお持ちの場合は、改行で区切って下さい）" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 v-show="animalsAttribute.allergy_status === '1'">
          <ComponentsItemsTextArea
            name="allergy"
            :value.sync="allergy"
            :loading="loading"
            rows="5"
            ref="allergy"
          >
          </ComponentsItemsTextArea>
        </v-col>
        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="お預かり中に「おやつ」をあげて良いか" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsButtonToggle
            name="snack_status"
            :value.sync="animalsAttribute.snack_status"
            :loading="loading"
            :items="snack_status"
            item-text="name"
            item-value="code"
            ref="snack_status"
          ></ComponentsItemsButtonToggle>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="避妊去勢" border-hidden></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="避妊去勢" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsButtonToggle
            name="sterilization_status"
            :value.sync="animalsAttribute.sterilization_status"
            :loading="loading"
            :items="sterilization_status"
            item-text="name"
            item-value="code"
            ref="sterilization_status"
          ></ComponentsItemsButtonToggle>
        </v-col>

        <!--
        <v-col cols=12 v-show="animalsAttribute.sterilization_status === '1'">
          <ComponentsLayoutHeading3 text="避妊去勢日" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 v-show="animalsAttribute.sterilization_status === '1'">
          <span>正確な年月日が不明な場合は、避妊去勢年の1月1日をご指定下さい。</span>
        </v-col>
        <v-col cols=12 v-show="animalsAttribute.sterilization_status === '1'">
          <ComponentsItemsDatePicker
            name="sterilization_date"
            :value.sync="animalsAttribute.sterilization_date"
            :loading="loading"
            :start-date="'2020-01-01'"
            ref="sterilization_date"
          >
          </ComponentsItemsDatePicker>
        </v-col>
        --->
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <!--
  <v-col cols=12>
    <ComponentsLayoutHeading1 text="飼い犬の登録" border-hidden></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="鑑札交付の手続き" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsButtonToggle
            name="license_status"
            :items="license_status"
            itemText="name"
            itemValue="code"
            :value.sync="animalsAttribute.license_status"
            :loading="loading"
            ref="license_status"
          >
          </ComponentsItemsButtonToggle>
        </v-col>

        <v-col cols=12 v-show="animalsAttribute.license_status === '1'">
          <ComponentsLayoutHeading3 text="鑑札番号" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=5 v-show="animalsAttribute.license_status === '1'">
          <ComponentsItemsTextField
            name="license_area"
            :value.sync="animalsAttribute.license_area"
            :loading="loading"
            ref="license_area"
          ></ComponentsItemsTextField>
        </v-col>
        <v-col cols=7 v-show="animalsAttribute.license_status === '1'">
          <ComponentsItemsTextField
            name="license_id"
            :value.sync="animalsAttribute.license_id"
            :loading="loading"
            ref="license_id"
          ></ComponentsItemsTextField>
        </v-col>

        <v-col cols=12 v-show="animalsAttribute.license_status === '1'">
          <ComponentsLayoutHeading3 text="狂犬病注射済票" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=5 v-show="animalsAttribute.license_status === '1'">
          <ComponentsItemsTextField
            name="rabies_area"
            :value.sync="animalsAttribute.rabies_area"
            :loading="loading"
            ref="rabies_area"
          ></ComponentsItemsTextField>
        </v-col>
        <v-col cols=7 v-show="animalsAttribute.license_status === '1'">
          <ComponentsItemsTextField
            name="rabies_id"
            :value.sync="animalsAttribute.rabies_id"
            :loading="loading"
            ref="rabies_id"
          ></ComponentsItemsTextField>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="マイクロチップの装着" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsButtonToggle
            name="microchip_status"
            :items="microchip_status"
            itemText="name"
            itemValue="code"
            :value.sync="animalsAttribute.microchip_status"
            :loading="loading"
            ref="microchip_status"
          >
          </ComponentsItemsButtonToggle>
        </v-col>

        <v-col cols=12 v-show="animalsAttribute.microchip_status === '1'">
          <ComponentsLayoutHeading3 text="マイクロチップID" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 v-show="animalsAttribute.microchip_status === '1'">
          <ComponentsItemsTextField
            name="microchip"
            :value.sync="animalsAttribute.microchip"
            :loading="loading"
            ref="microchip"
          ></ComponentsItemsTextField>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>
        --->

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="動物病院" border-hidden></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="かかりつけの動物病院はありますか？" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsButtonToggle
            name="doctor_status"
            :items="family_doctor_status"
            itemText="name"
            itemValue="code"
            :value.sync="animalsAttribute.doctor_status"
            :loading="loading"
            ref="doctor_status"
          >
          </ComponentsItemsButtonToggle>
        </v-col>
        <v-col cols=12 v-show="animalsAttribute.doctor_status === '1'">
          <ComponentsLayoutHeading3 text="動物病院名" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 v-show="animalsAttribute.doctor_status === '1'">
          <ComponentsItemsTextField
            name="doctor_name"
            :value.sync="animalsAttribute.doctor_name"
            :loading="loading"
            ref="doctor_name"
          >
          </ComponentsItemsTextField>
        </v-col>
        <v-col cols=12 v-show="animalsAttribute.doctor_status === '1'">
          <ComponentsLayoutHeading3 text="動物病院 連絡先" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 v-show="animalsAttribute.doctor_status === '1'">
          <ComponentsItemsTextField
            name="doctor_tel"
            inputmode="numeric"
            :value.sync="animalsAttribute.doctor_tel"
            :loading="loading"
            ref="doctor_tel"
          >
          </ComponentsItemsTextField>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="ワクチン接種情報" border-hidden></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12 v-for="item in vaccines" :key="item.personals_vaccine.vaccine_type_id">
    <v-row>
      <v-col cols=12>
        <ComponentsFormsVaccine
          :vaccine.sync="item.personals_vaccine"
          :loading="loading"
          ref="vaccine_form"
        >
        </ComponentsFormsVaccine>
      </v-col>
    </v-row>
  </v-col>

  <!--- ご希望のカットイメージ --->
  <v-col cols=12>
    <ComponentsViewFormsTrimmingImage
      :images.sync="images"
      :loading="loading"
      border-hidden
    >
    </ComponentsViewFormsTrimmingImage>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="その他" border-hidden></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="今まで病気、ケガ、手術をしたことがあればご記入ください" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsTextArea
            name="communication_memo"
            :value.sync="animalsAttribute.clinical_history"
            :loading="loading"
            rows="5"
            ref="communication_memo"
          >
          </ComponentsItemsTextArea>
        </v-col>
        <!-- 問診オプション内容 -->
        <v-col cols=12 v-if="storeInterviewSheet.length > 0">
          <ComponentsItemsInterviewSheet
            :values.sync="InterviewSheet"
            :schema.sync="storeInterviewSheet"
            :loading="loading"
          >
          </ComponentsItemsInterviewSheet>
        </v-col>
        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="パートナーに関してサロン側へ伝えたいこと（共有しておきたいこと）があればご記入ください" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsTextArea
            name="communication_memo"
            :value.sync="animalsAttribute.memo"
            :loading="loading"
            rows="5"
            ref="communication_memo"
          >
          </ComponentsItemsTextArea>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsLayoutSection1 from '@/components/layout/Section1'

import ComponentsViewFormsTrimmingImage from '@/components/view-forms/TrimmingImage'

import ComponentsItemsButtonToggle from '@/components/items/ButtonToggle'
// import ComponentsItemsTextFieldCustomType from '@/components/items/TextFieldCustomType'
import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsTextArea from '@/components/items/TextArea'
import ComponentsItemsComboBox from '@/components/items/ComboBox'
import ComponentsFormsVaccine from '@/components/forms/Vaccine'
import ComponentsItemsInterviewSheet from '@/components/items/InterviewSheet'
import ComponentsItemsDatePicker from '@/components/items/DatePicker'

// import ComponentsViewFormsVaccine from '@/components/view-forms/Vaccine'

export default {
  name: 'ComponentsFormsTrimmingProfileAnimal',

  mixins: [MixinLib],

  data: () => ({
    isLoadingSpecies: false,
    error: false,
    schema: []
  }),

  watch: {
    'personals.animal' () {
      this.LoadingSpecies()
    }
  },

  computed: {
    sterilization_status: {
      get () {
        return this.getSterilizationStatus()
      }
    },
    license_status: {
      get () {
        return this.getLicenseStatus()
      }
    },
    microchip_status: {
      get () {
        return this.getMicrochipStatus()
      }
    },
    family_doctor_status: {
      get () {
        return this.getFamilyDoctorStatus()
      }
    },
    allergy_status: {
      get () {
        return this.getAllergyStatus()
      }
    },
    snack_status: {
      get () {
        return this.getSnackStatus()
      }
    },
    allergy: {
      get () {
        return this.personalsAllergies.join('\n')
      },
      set (v) {
        this.$emit('update:personalsAllergies', v.split('\n'))
      }
    },
    vaccines: {
      get () {
        return this.personalsVaccine
      },
      set (v) {
        this.$emit('update:personalsVaccine', v)
      }
    },
    images: {
      get () {
        return this.personalsTrimmingCutImage
      },
      set (v) {
        this.$emit('update:personalsTrimmingCutImage', v)
      }
    },
    InterviewSheet: {
      get () {
        return this.personalsInterviewSheet
      },
      set (v) {
        this.$emit('update:personalsInterviewSheet', v)
      }
    }
  },

  created: function () {
  },

  mounted () {
    this.LoadingSpecies()
  },

  methods: {
    LoadingSpecies () {
      (async () => {
        this.isLoadingSpecies = true
        await this.$store.dispatch('modStorage/LoadSpecies', this.getValue(this.personals, 'animal'))
        this.isLoadingSpecies = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isLoadingSpecies = false
      })
      // this.pageTransition('/sign-in')
    },

    isError: function () {
      return this.error
    },

    confirm: async function () {
      this.error = false
      const forms = [
        'name',
        'name_kana',
        'animal',
        'species',
        'sex',
        'blood',
        'birth_date',
        'snack_status',
        'allergy_status',
        'sterilization_status',
        'sterilization_date',
        'adoptaversary_date',
        'death_date',
        'license_status',
        'license_area',
        'license_id',
        'rabies_area',
        'rabies_id',
        'microchip_status',
        'microchip',
        'animal_code',
        'doctor_status',
        'doctor_name',
        'doctor_tel'
      ]
      for (const name of forms) {
        if (this.$refs[name]) {
          await this.$refs[name].confirm()
        }
        if (this.$refs[name] && this.$refs[name].error) {
          this.error = true
        }
      }
      if (this.$refs.vaccine_form) {
        for (const form of this.$refs.vaccine_form) {
          await form.confirm()
          if (form.error) {
            this.error = true
          }
        }
      }

      if (this.error) {
        this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
        return false
      }
      return true
    }
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading3,
    ComponentsLayoutSection1,
    ComponentsViewFormsTrimmingImage,
    ComponentsItemsButtonToggle,
    // ComponentsItemsTextFieldCustomType,
    ComponentsItemsTextField,
    ComponentsItemsTextArea,
    ComponentsItemsComboBox,
    ComponentsFormsVaccine,
    ComponentsItemsInterviewSheet,
    ComponentsItemsDatePicker
    // ComponentsViewFormsVaccine
  },

  props: {
    personals: {
      type: Object,
      required: false,
      default: () => {
        return {
          img_src: '',
          name1: '',
          name2: '',
          name_kana1: '',
          name_kana2: '',
          animal: '犬',
          species: '',
          sex: '1',
          birth_date: ''
        }
      }
    },
    animalsAttribute: {
      type: Object,
      required: false,
      default: () => {
        return {
          blood: '',
          snack_status: '2',
          allergy_status: '2',
          sterilization_status: '2',
          sterilization_date: '',
          adoptaversary_date: '',
          death_date: '',
          license_status: '2',
          license_area: '',
          license_id: '',
          rabies_area: '',
          rabies_id: '',
          microchip_status: '2',
          microchip: '',
          doctor_status: '2',
          doctor_name: '',
          doctor_tel: '',
          memo: '',
          clinical_history: ''
        }
      }
    },
    personalsAllergies: {
      type: Array,
      required: false,
      default: () => []
    },
    personalsVaccine: {
      type: Array,
      required: false,
      default: () => []
    },
    personalsTrimmingCutImage: {
      type: Array,
      required: false,
      default: () => []
    },
    personalsInterviewSheet: {
      type: Object,
      required: false,
      default: () => {}
    },
    storeInterviewSheet: {
      type: Array,
      required: false,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
