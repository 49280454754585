<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">
    <v-col cols=12>
      <ComponentsLayoutHeading1 text="(本人確認) 電話番号の入力"></ComponentsLayoutHeading1>
    </v-col>
    <v-col cols=12>
      <ComponentsLayoutHeading2 text="本人確認のため、ご入力された電話番号宛に認証コードをご連絡致します。事前にご予約を行っているお客様は、ご予約時にお伝えした電話番号をご入力ください。"></ComponentsLayoutHeading2>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection2>
        <v-row align="center" justify="center" class="mx-auto py-3">
          <v-col cols=12>
            <ComponentsLayoutHeading3 text="電話番号" required></ComponentsLayoutHeading3>
          </v-col>
          <!--
          <v-col cols=12 md=4 class="py-1">
            <ComponentsItemsSelectField
              name="country"
              :value.sync="country"
              :items="StorageCountry"
              :loading="isLoading || isProcessLoading"
              itemText="name"
              itemValue="code"
              ref="prefecture_id"
            ></ComponentsItemsSelectField>
          </v-col>
          --->
          <v-col cols=12>
            <ComponentsItemsTextFieldCustomType
              name="tel"
              :value.sync="tel"
              type="numeric"
              :loading="isProcessLoading"
              ref="tel"
            ></ComponentsItemsTextFieldCustomType>
          </v-col>

          <v-col cols=12>
            <ComponentsItemsButton
              block
              :loading="isProcessLoading"
              :disabled="isUndefined(tel)"
              text="ショートメール（SMS）で受け取る"
              @click="goVerify('sms')"
            >
            </ComponentsItemsButton>
          </v-col>

          <v-col cols=12>
            <ComponentsItemsButton
              block
              outlined
              :loading="isProcessLoading"
              :disabled="isUndefined(tel)"
              text="自動音声通話で受け取る"
              @click="goVerify('call')"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </ComponentsLayoutSection2>
    </v-col>

    <v-col cols=12 class="pb-5">
      <ComponentsLayoutHeading2 text="※SMSが届かない場合は、海外SMSを受信する設定になっているかご確認ください。"></ComponentsLayoutHeading2>
    </v-col>
  </v-row>
</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'
// import Twilio from '@/libs/Twilio'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'

import ComponentsLayoutSection2 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsItemsTextFieldCustomType from '@/components/items/TextFieldCustomType'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingProfileAuthenticationPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    channel: {
      get () {
        return this.$store.getters['modVerify/channel']
      },
      set (v) {
        this.$store.dispatch('modVerify/channel', v)
      }
    },
    tel: {
      get () {
        return this.$store.getters['modVerify/tel']
      },
      set (v) {
        this.$store.dispatch('modVerify/tel', v)
      }
    }
  },

  mounted () {
    this.isProcessLoading = false
  },
  methods: {
    goVerify: function (Channel) {
      (async () => {
        if (this.$refs.tel) {
          await this.$refs.tel.confirm()
        }
        if (this.$refs.tel && this.$refs.tel.error) {
          return false
        }
        this.isProcessLoading = true
        this.channel = Channel
        // カルテチェック
        await APILib.getStoresConfirmTel(this.storeID, this.tel)
        // 認証コード発行
        await APILib.postStoresVerifications(this.storeID, this.channel, this.tel)
        //
        return this.pageTransition('/gr-shs/' + this.storeID + '/authentication-verify')
      })().catch((e) => {
        this.isProcessLoading = false
        console.log(e)
        if (e.code === 'NotAuthorizedException') {
          return this.$confirm({ title: 'Error', message: '電話番号をご確認ください', button: { yes: 'OK' } })
        }
        this.$confirm({ title: 'Error[1]', message: e, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsLayoutSection2,
    ComponentsLayoutHeading3,
    ComponentsItemsTextFieldCustomType,
    ComponentsItemsButton
  }
}
</script>
