import liff from '@line/liff'

const utilsAuthLineObj = {}

// utilsAuthLineObj.init = async (SuccessCallBack, ErrorCallBack) => {
utilsAuthLineObj.init = async () => {
  return await liff.init({ liffId: process.env.VUE_APP_LINE_LIFF_ID })
}

utilsAuthLineObj.isInClient = () => {
  return (liff.isInClient())
}

utilsAuthLineObj.getProfile = async () => {
  return await liff.getProfile()
}

utilsAuthLineObj.getAccessToken = () => {
  return liff.getIDToken()
}

utilsAuthLineObj.signIn = () => {
  liff.login()
}

utilsAuthLineObj.isLogin = () => {
  return (liff.isLoggedIn())
}

export default utilsAuthLineObj
