<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP1. 予約申請希望条件" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/new-reserve/1')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsViewFormsTrimmingNewerRequest
        :loading="isLoading || isProcessLoading"
        :newer-request="commonRequest"
        @on-change-click="pageTransition('/gr-rsv/' + storeID + '/new-reserve/1')"
      >
      </ComponentsViewFormsTrimmingNewerRequest>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP2. ご予約希望日時の設定" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/new-reserve/2')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsViewFormsTrimmingApplyCalendar
        :loading="isLoading || isProcessLoading"
        :trimming-timezone="timezone"
      >
      </ComponentsViewFormsTrimmingApplyCalendar>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP3. お客様情報の入力" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/new-reserve/3')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection1>
        <ComponentsViewFormsShortPersonalsProfile
          :personals="personals"
          :users_attribute="usersAttribute"
          :address_book="addressBook"
        >
        </ComponentsViewFormsShortPersonalsProfile>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12 ref="TOP">
      <ComponentsLayoutHeading1 text="STEP4. カルテ情報の入力"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 class="pb-5" v-for="(item, index) in animals" :key="index">
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsLayoutHeading1 :text="(index + 1) + '頭目のカルテ情報'"></ComponentsLayoutHeading1>
        </v-col>

        <v-col cols=12>
          <ComponentsViewFormsTrimmingApplyConditionsNew
            :loading="isProcessLoading"
            :contents="commonRequest[index]"
          ></ComponentsViewFormsTrimmingApplyConditionsNew>
        </v-col>

        <v-col cols=12>
          <ComponentsFormsTrimmingProfileAnimal
            :loading="isProcessLoading"
            :store-id="storeID"
            :personals.sync="item.personals"
            :personals-allergies.sync="item.personals_allergies"
            :animals-attribute.sync="item.animals_attribute"
            :personals-vaccine.sync="item.personals_vaccine"
            :personals-trimming-cut-image.sync="item.personals_trimming_cut_image"
            :personals-interview-sheet.sync="item.personals_interview_sheet"
            :store-interview-sheet="StorageStoresInterviewSheet"
            ref="animalProfile"
          ></ComponentsFormsTrimmingProfileAnimal>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12>
      <ComponentsItemsButton
        block
        :loading="isProcessLoading"
        text="次へ"
        @click="onNext()"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=12 ref="Step5">
      <ComponentsLayoutHeading1 text="STEP5. 申請内容の最終確認"></ComponentsLayoutHeading1>
    </v-col>
  </v-row>

</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
// import AuthLineLib from '@/libs/AuthLineLib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'

import ComponentsViewFormsTrimmingNewerRequest from '@/components/view-forms/TrimmingNewerRequest'
import ComponentsViewFormsTrimmingApplyCalendar from '@/components/view-forms/TrimmingApplyCalendar'
import ComponentsViewFormsShortPersonalsProfile from '@/components/view-forms/ShortPersonalsProfile'
import ComponentsViewFormsTrimmingApplyConditionsNew from '@/components/view-forms/TrimmingApplyConditionsNew'
import ComponentsFormsTrimmingProfileAnimal from '@/components/forms/TrimmingProfileAnimal'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingReserveTrimmingNewerStep3Page',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    commonRequest: {
      get () {
        return this.$store.getters['modTrimming/commonRequest']
      }
    },
    timezone: {
      get () {
        return this.$store.getters['modTrimming/trimmingTimezone']
      }
    },
    fbnPersonals: {
      get () {
        return this.$store.getters['modTrimming/fbnPersonals']
      }
    },
    personals: {
      get () {
        return this.$store.getters['modTrimming/personals']
      }
    },
    usersAttribute: {
      get () {
        return this.$store.getters['modTrimming/usersAttribute']
      }
    },
    addressBook: {
      get () {
        return this.$store.getters['modTrimming/addressBook']
      }
    },
    usersAuthentication: {
      get () {
        return this.$store.getters['modTrimming/usersAuthentication']
      }
    },
    animals: {
      get () {
        return this.$store.getters['modTrimming/animals']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changeAnimals', v)
      }
    },
    StorageStoresInterviewSheet: {
      get () {
        return this.$store.getters['modStorage/storesInterviewSheet']
      }
    }
  },

  created () {
    setTimeout(() => (
      this.$refs.TOP.scrollIntoView({ behavior: 'smooth' })
    ))
  },

  mounted () {
    this.onLoading()
  },

  methods: {
    onLoading: function () {
      this.isProcessLoading = false
    },
    onNext: function () {
      (async () => {
        if (this.$refs.animalProfile) {
          if (this.isArray(this.$refs.animalProfile)) {
            for (const forms of this.$refs.animalProfile) {
              await forms.confirm()
              if (forms.error) {
                this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
                return false
              }
            }
          } else {
            await this.$refs.animalProfile.confirm()
            if (this.$refs.animalProfile.error) {
              this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
              return false
            }
          }
        }
        this.pageTransition('/gr-rsv/' + this.storeID + '/new-reserve/5')
        return true
      })().catch((e) => {
        console.log(e)
        this.$confirm({ title: 'Error', message: '入力内容をご確認ください [' + e.message + ']', button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsLayoutSection1,
    ComponentsViewFormsTrimmingNewerRequest,
    ComponentsViewFormsTrimmingApplyCalendar,
    ComponentsViewFormsShortPersonalsProfile,
    ComponentsViewFormsTrimmingApplyConditionsNew,
    ComponentsFormsTrimmingProfileAnimal,
    ComponentsItemsButton
  }
}
</script>
