<template>
<v-app :style="{ backgroundColor: backGroundColor }">

  <div id="scrollTop"></div>

  <v-app-bar fixed flat app :color="primaryColor" height="60" v-show="header" class="mx-auto" max-width=840>
    <v-toolbar-title >
      <router-link to="/">
        <v-img :src="logoSrc" style="max-width: 100px; height: auto;"></v-img>
      </router-link>
    </v-toolbar-title>
  </v-app-bar>

  <v-main>
    <v-container fluid class="ec-font-size12" style="max-width: 840px;">
      <slot></slot>
    </v-container>
  </v-main>

  <v-footer absolute app :color="primaryColor" padless height="40" v-show="footer">
    <v-row no-gutters>
      <v-col cols="12" class="py-2 text-center">
        <span class="'mx-auto ec-font-size10" >copyright (c) ColorZoo.All rights reserved..</span>
      </v-col>
    </v-row>
  </v-footer>
</v-app>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsLayoutPage4',

  mixins: [MixinLib],

  components: {
  },

  props: {
    header: { type: Boolean, required: false, default: () => false },
    footer: { type: Boolean, required: false, default: () => false },
    headerTitle: { type: String, require: false, default: () => '' },
    headerLeft: { type: Boolean, required: false, default: () => false },
    headerLeftIcon: { type: String, require: false, default: () => '' },
    headerLeftText: { type: String, require: false, default: () => '' },
    headerRight: { type: Boolean, required: false, default: () => false },
    headerRightIcon: { type: String, require: false, default: () => '' },
    headerRightText: { type: String, require: false, default: () => '' },
    loading: { type: Boolean, required: false, default: () => false }
  }
}
</script>
