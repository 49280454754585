<template>
<div>

  <v-app-bar style="max-width: 840px; margin: auto;" color="white" app flat fixed >
    <v-row align="center" justify="center">
      <v-col cols=4 class="text-left">
        <v-btn icon @click="drawer = !drawer">
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
        </v-btn>
      </v-col>
      <v-col cols=4 class="text-center">
        <v-img style="max-width:100px; max-height: 40px; margin: auto; cursor: pointer;" :src="logoSrc" @click="goHome()" ></v-img>
      </v-col>
      <v-col cols=4 class="text-right">
      </v-col>
    </v-row>
  </v-app-bar>

  <v-navigation-drawer fixed clipped touchless style="top:0;" v-model="drawer" >
      <v-list dense nav shaped>
        <v-subheader>MENU</v-subheader>

        <v-list-item-group color="primary">
          <v-list-item v-for="(menu, menuIndex) in list" :key="menuIndex" @click="pageTransition(menu.route)" v-show="menu.isShow">
            <v-list-item-icon>
              <v-icon v-text="menu.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title><span v-text="menu.text"></span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
  </v-navigation-drawer>

  <v-main>
    <v-container fluid class="ec-font-size12" style="max-width: 840px; margin: auto;">
      <v-row align="start" justify="center">
        <v-col cols=12 class="pt-0">

          <v-row align="center" justify="center" style="height:50vh;">
            <v-col cols=12 :class="($vuetify.breakpoint.xs) ? 'Page--top--main-xs' :'Page--top--main'">
              <v-parallax
                dark
                height="50vh"
                :src="(isUndefined(users.personals.img_src)) ? noImagew : users.personals.img_src + '?width=980&height=460'"
                class="Page--top--main--image"
              >
                  <v-row align="start" justify="center" :style="($vuetify.breakpoint.xs) ? 'padding-top: 32vh; height:8vh;' : 'padding-top: 35vh; height:8vh;'" @click="openUploadImageLibrary()">
                    <v-col cols=12 class="pa-0 ma-0 text-center">
                      <v-img style="margin: auto; width:40vw; max-width:150px; height: 8vh; max-height: 60px;" :src="logoSrc"></v-img>
                    </v-col>
                  </v-row>

                  <div :style="($vuetify.breakpoint.xs) ? 'position: absolute; top: 37vh; width:100%; left: 0;' : 'position: absolute; top: 40vh; width:100%; left: 0;'">
                    <v-row align="center" justify="center">
                      <!--
                      <v-col cols=3 class="text-center">

                        <v-row align="center" justify="center" class="pa-0 ma-0">
                          <v-col cols=12 class="pa-0 ma-0">
                            <v-btn depressed fab small color="white" @click="pageTransition('/console/news')">
                              <v-badge v-model="showBadgeNews" color="cyan" class="align-self-center">
                                <template v-slot:badge>
                                  <span v-text="newsCount"></span>
                                </template>
                                <v-icon color="#666">mdi-bell-ring</v-icon>
                              </v-badge>
                            </v-btn>
                          </v-col>
                          <v-col cols=12 class="pa-0 ma-0">
                            <span class="grey--text text--darken-3" style="font-size:8pt!important;">お知らせ</span>
                          </v-col>
                        </v-row>
                      </v-col>
                      -->
                      <v-col cols=3 class="text-center">
                        <v-row align="center" justify="center" class="pa-0 ma-0">
                          <v-col cols=12 class="pa-0 ma-0">
                            <v-btn depressed fab small color="white" @click="pageTransition('/console/reserve')">
                              <v-badge v-model="showBadgeReserve" color="cyan" class="align-self-center">
                                <template v-slot:badge>
                                  <span v-text="reserveCount"></span>
                                </template>
                                <v-icon color="#666">mdi-open-in-app</v-icon>
                              </v-badge>
                            </v-btn>
                          </v-col>
                          <v-col cols=12 class="pa-0 ma-0">
                            <span class="grey--text text--darken-3" style="font-size:8pt!important;">予約一覧</span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>

              </v-parallax>
            </v-col>
          </v-row>

          <v-row align="center" justify="center">
            <v-col cols=12 class="caption py-1 text-left">
              <span class="grey--text text--lighten-1">うちの子</span>
            </v-col>
            <v-col cols=12 class="text-left">
              <v-slide-group :show-arrows="!$vuetify.breakpoint.xs">
                <v-slide-item v-for="item in animals" :key="item.personal_id" >
                  <v-card class="text-center" flat color="transparent" @click="pageTransition('/console/animals/' + item.personal_id)">
                    <v-row align="center" justify="center" class="mr-3" :style="($vuetify.breakpoint.xs) ? 'max-width:100px;' : 'max-width:150px;'" @click="() => false">
                      <v-col cols=12 class="pb-1 text-center">
                        <v-avatar :size="($vuetify.breakpoint.xs) ? 75 : 110">
                          <v-img :src="item.personals.img_src + '?width=110&height=110'" aspect-ratio="1" class="grey lighten-2"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols=12 class="text-center text-omitted " style="color: rgba(0,0,0,.87);">
                        <span v-text="item.personals.name1"></span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-slide-item>

                <v-slide-item >
                  <v-card class="text-center" flat color="transparent" @click="pageTransition('/console/create-animals')">
                    <v-row align="center" justify="center" class="mr-3" :style="($vuetify.breakpoint.xs) ? 'max-width:100px;' : 'max-width:150px;'">
                      <v-col cols=12 class="pb-1 text-center">
                        <v-avatar :size="($vuetify.breakpoint.xs) ? 75 : 110" style="background-color: rgb(243, 143, 61, 0.2);">
                          <v-icon large dark :color="buttonColor">mdi-plus</v-icon>
                        </v-avatar>
                      </v-col>
                      <v-col cols=12 class="text-center text-omitted " style="color: rgba(0,0,0,.87);">
                        <span>新規追加</span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-slide-item>

              </v-slide-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
  <v-dialog v-model="bottomSheet.selectLibrary" width="90%" max-width="420">
    <v-card>
      <v-card-text class="pt-5">
        <v-row align="center" justify="center" >
          <v-col cols=12 class="text-left ec-font-size14 py-1">
            <span>アップロードする画像を選択します</span>
          </v-col>
          <v-col cols=12 class="text-left ec-font-size10 py-1">
            <span>最大アップロードサイズ: 20MB</span>
          </v-col>
          <v-col cols=12 class="text-center py-1">
            <croppa
              v-model="imgSrc"
              :width="280"
              :height="280"
              placeholder="タップして画像を選択してください"
              :prevent-white-space="true"
              :disabled="false"
              :disable-click-to-choose="false"
              :disable-drag-and-drop="false"
              :disable-drag-to-move="false"
              :disable-scroll-to-zoom="false"
              :disable-pinch-to-zoom="false"
              :disable-rotation="false"
              initial-size="natural"
              initial-position="center"
              :show-remove-button="true"
              remove-button-color="black"
              :show-loading="true"
              :file-size-limit="20 * 1024 * 1024"
              accept=".jpeg,.png,.jpg"
              style="line-height: normal!important;"
              @file-type-mismatch="onFileTypeMismatch"
              @file-size-exceed="onFileSizeExceed"
            >
            </croppa>
          </v-col>
          <v-col cols=12 class="text-center py-1">
            <v-btn-toggle dense dark>
              <v-btn @click="imgSrc.zoomIn()">
                <v-icon>mdi-magnify-minus-outline</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.zoomOut()">
                <v-icon>mdi-magnify-plus-outline</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.rotate()">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.rotate(-1)">
                <v-icon>mdi-restore</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.flipX()">
                <v-icon>mdi-compare-horizontal</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.flipY()">
                <v-icon>mdi-compare-vertical</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols=12 class="text-left ec-font-size10 py-1">
            <span>アップロード後の画像を変更する場合は <v-icon small>mdi-close-circle</v-icon> アイコンで一度画像をクリアして下さい。</span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <ComponentsItemsButton
          text-btn
          text="閉じる"
          @click="bottomSheet.selectLibrary = false"
        >
        </ComponentsItemsButton>
        <v-spacer></v-spacer>
        <ComponentsItemsButton
          text-btn
          text="変更する"
          @click="changeUploadImageLibrary()"
        >
        </ComponentsItemsButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'
import AuthLineLib from '@/libs/AuthLineLib'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleHomePage',

  mixins: [MixinLib],

  data: () => ({
    baseImgSrc: require('@/assets/home.jpg'),
    drawer: false,
    imgSrc: {},
    bottomSheet: {
      selectMenus: false,
      selectLibrary: false
    }
  }),

  computed: {
    noImagew: {
      get () {
        return this.baseImgSrc
      }
    },
    newsCount: {
      get () {
        return 0
      }
    },
    reserveCount: {
      get () {
        return (this.isUndefined(this.reserves)) ? 0 : this.reserves.length
      }
    },
    showBadgeNews: {
      get () {
        return (this.newsCount > 0)
      }
    },
    showBadgeReserve: {
      get () {
        return (this.reserveCount > 0)
      }
    },
    animals: {
      get () {
        return this.$store.getters['modStorage/animals']
      }
    },
    users: {
      get () {
        return this.$store.getters['modStorage/users']
      }
    },
    list: {
      get () {
        return [
          {
            icon: 'mdi-account',
            route: '/console/user-profile',
            text: 'プロフィール設定',
            isShow: true
          },
          {
            icon: 'mdi-lock',
            route: '/console/rest-password',
            text: 'パスワード変更',
            isShow: true
          },
          {
            icon: 'mdi-eye-off',
            route: '/console/hide-animals',
            text: 'うちの子 非表示一覧',
            isShow: true
          },
          {
            icon: 'mdi-account-off',
            route: '/console/remove',
            text: 'アカウント削除',
            isShow: (!AuthLineLib.isInClient())
          },
          /*
          {
            icon: 'mdi-account-off',
            route: '/console/version',
            text: 'バージョン情報',
            isShow: true
          },
          */
          {
            icon: 'mdi-alpha-l-circle-outline',
            route: process.env.VUE_APP_SUPPORT_LINE,
            text: 'お問合せ',
            isShow: true
          },
          {
            icon: 'mdi-help',
            route: '/console/help',
            text: 'HELP',
            isShow: true
          },
          {
            icon: 'mdi-logout',
            route: '/console/sign-out',
            text: 'サインアウト',
            isShow: true
          }
        ]
      }
    }
  },

  mounted () {
  },

  methods: {
    getCircleClass: function (index) {
      if (this.animals[index].owner === this.userID) {
        return 'AnimalCircleStyle'
      }
      return 'FamilyCircleStyle'
    },
    openUploadImageLibrary: function () {
      if (this.exists(this.imgSrc, 'remove')) {
        this.imgSrc.remove()
      }
      this.bottomSheet.selectLibrary = true
    },

    changeUploadImageLibrary: function () {
      this.imgSrc.generateBlob((blob) => this.uploadImageLibrary(blob), 'image/png')
      this.bottomSheet.selectMenus = false
      this.bottomSheet.selectLibrary = false
    },

    uploadImageLibrary: function (blob) {
      (async () => {
        const resultRows = await APILib.postMediaImage(blob, 'images.png')
        this.users.personals.img_src = resultRows
        await APILib.putUsers(this.users)
        await this.$store.dispatch('modStorage/LoadUsers')
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    onFileTypeMismatch: function (file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },

    onFileSizeExceed: function (file) {
      alert('File size exceeds. Please choose a file smaller than 100kb.')
    }
  },

  beforeDestroy () {
    this.drawer = false
    this.imgSrc = {}
    this.bottomSheet = {
      selectMenus: false,
      selectLibrary: false
    }
  },

  components: {
    ComponentsItemsButton
  }
}
</script>
<style>
.Page--top--main .v-parallax__image-container {
  height: 43vh;
  margin: 0 auto;
}

.Page--top--main .v-parallax__image {
  height: 100% !important;
  object-fit:cover !important;
}

.Page--top--main {
  height: 50vh;
  overflow:hidden;
}

.Page--top--main .Page--top--main--image {
  margin:0 -10%;
  height: 100%;
}

.Page--top--main .Page--top--main--badge {
  padding:6px;
  border-radius: 50%;
  background-color:#FFF;
  width:41px;
  height:41px;
}

.Page--top--main-xs .v-parallax__image-container {
  height: 43vh;
  margin: 0 auto;
}

.Page--top--main-xs .v-parallax__image {
  height: 100% !important;
  object-fit:cover !important;
  border-radius: 0 0 30vh 30vh;
  -moz-border-radius: 0 0 30vh 30vh;
  -webkit-border-radius: 0 0 30vh 30vh;
}

.Page--top--main-xs {
  height: 50vh;
  overflow:hidden;
}

.Page--top--main-xs .Page--top--main--image {
  margin:0 -10%;
  height: 100%;
}

.Page--top--main-xs .Page--top--main--badge {
  padding:6px;
  border-radius: 50%;
  background-color:#FFF;
  width:41px;
  height:41px;
}

/*
.CreateCircleStyle {
  border: 3px solid #FF8300;
  width: 100% !important;
  height: 100% !important;
  object-fit:cover !important;
}

.SelfCircleStyle {
  border: 3px dashed #EA5514;
  width: 100% !important;
  height: 100% !important;
  object-fit:cover !important;
}

.AnimalCircleStyle {
  cursor: pointer;
  border: 3px dashed #15ba40;
  width: 100% !important;
  height: 100% !important;
  object-fit:cover !important;
}

.FamilyCircleStyle {
  border: 2px dashed #FF8300;
  width: 100% !important;
  height: 100% !important;
  object-fit:cover !important;
}

.ActiveCircleStyle {
  border: 3px solid #337ab7;
  width: 100% !important;
  height: 100% !important;
  object-fit:cover !important;
}

.v-application .caption {
  font-family: 'Helvetica Neue', Arial,'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'BIZ UDPGothic', Meiryo, sans-serif !important;
}
*/

a:-webkit-any-link {
  text-decoration: none !important;
}
</style>
