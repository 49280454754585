import UtilLib from '@/libs/UtilLib'
import AuthLib from '@/libs/AuthLib'
import AuthLineLib from '@/libs/AuthLineLib'

export default {
  data: () => ({
    logoSrc: require('@/assets/logo.png'),

    backGroundColor: 'rgba(var(--b3f,250,250,250),1)',
    primaryColor: 'white', // Page1 Background
    secondaryColor: 'rgba(239, 235, 233, 0.6)', // Page2 Background
    tertiaryColor: 'rgba(var(--b3f,250,250,250),1)',

    // buttonColor: 'rgba(0, 124, 255, 1)',
    // secondaryFontColor: 'rgba(188, 170, 164, 1)',
    // tertiaryFontColor: 'rgba(158, 132, 123, 1)',
    buttonColor: 'rgba(255 , 178 , 102, 1)',

    primaryFontColor: 'rgba(105, 185, 246, 1)', // Heading1
    // secondaryFontColor: 'rgba(105, 185, 246, 1)', // Heading2
    secondaryFontColor: 'rgba(155, 128, 120, 1)', // Heading2
    tertiaryFontColor: 'rgba(155, 128, 120, 1)', // Section

    activeColor: 'white',
    secondaryActiveColor: 'rgba(255 , 178 , 102, 0.3)',

    // subTitleClass: 'text-left pt-5 pb-1 font-weight-bold ec-font-size14',
    // normalTitleClass: 'ec-font-size12',
    // columnClass: 'ec-font-size12 font-weight-bold text-left',
    storageDataRefreshInterval: null,
    storageDataRefreshTime: 3600000, // 60分
    checkSessionInterval: null,
    checkSessionTime: 600000 // 10分
  }),

  watch: {
    '$route' (routeInstance) {
      this.createTitleDesc(routeInstance)
    }
  },

  computed: {
    displaySpSize: {
      get () {
        return this.$vuetify.breakpoint.mdAndDown
      }
    },
    isInLineClient: {
      get () {
        return (AuthLineLib.isInClient())
      }
    },
    isInitialLoading: {
      get () {
        return this.$store.getters['modPage/isInitialLoading']
      },
      set (v) {
        this.$store.dispatch('modPage/isInitialLoading', v)
      }
    },
    isLoading: {
      get () {
        return this.$store.getters['modPage/isLoading']
      },
      set (v) {
        this.$store.dispatch('modPage/isLoading', v)
      }
    },
    isDataLoading: {
      get () {
        return this.$store.getters['modPage/isDataLoading']
      },
      set (v) {
        this.$store.dispatch('modPage/isDataLoading', v)
      }
    },
    routeURI: {
      get () {
        return this.$route.path
      }
    },
    windowWidthSize: {
      get () {
        return this.$store.getters['modPage/windowWidthSize']
      },
      set (v) {
        this.$store.dispatch('modPage/setWindowWidthSize', v)
      }
    },
    windowHeightSize: {
      get () {
        return this.$store.getters['modPage/windowHeightSize']
      },
      set (v) {
        this.$store.dispatch('modPage/setWindowHeightSize', v)
      }
    },
    /*
    CommonHeaderTitle: {
      get () {
        return this.$store.getters['modPage/CommonHeaderTitle']
      }
    },
    CommonHeaderLeft: {
      get () {
        return this.$store.getters['modPage/CommonHeaderLeft']
      }
    },
    CommonHeaderRight: {
      get () {
        return this.$store.getters['modPage/CommonHeaderRight']
      }
    },
    */
    StorageUsers: {
      get () {
        return this.$store.getters['modStorage/users']
      }
    },
    userAnimals: {
      get () {
        return this.$store.getters['modStorage/animals']
      }
    },
    reserves: {
      get () {
        return this.$store.getters['modStorage/reserve']
      }
    },
    StorageShopProfile: {
      get () {
        return this.$store.getters['modStorage/shop']
      }
    },
    StorageCountry: {
      get () {
        return this.$store.getters['modStorage/country']
      }
    },
    StoragePrefecture: {
      get () {
        return this.$store.getters['modStorage/prefecture']
      }
    },
    StorageSexPerson: {
      get () {
        return this.$store.getters['modStorage/sexPerson']
      }
    },
    StorageAnimalType: {
      get () {
        return this.$store.getters['modStorage/animalType']
      }
    },
    StorageSpecies: {
      get () {
        return this.$store.getters['modStorage/species']
      }
    },
    StorageSexAnimal: {
      get () {
        return this.$store.getters['modStorage/sexAnimal']
      }
    },
    StorageAllergyType: {
      get () {
        return this.$store.getters['modStorage/allergy']
      }
    },
    StorageVaccineTypes: {
      get () {
        return this.$store.getters['modStorage/vaccineType']
      }
    },
    StorageStoreContents: {
      get () {
        return this.$store.getters['modStorage/storeContents']
      }
    },
    StorageStoreServiceContents: {
      get () {
        return this.$store.getters['modStorage/storeServiceContents']
      }
    },
    StorageStoreServicesType: {
      get () {
        return this.$store.getters['modStorage/storeServices']
      }
    },
    StorageStoresGroomer: {
      get () {
        return this.$store.getters['modStorage/storesGroomer']
      }
    },
    StorageStoresGroomingCategories: {
      get () {
        return this.$store.getters['modStorage/storesGroomingCategories']
      }
    },
    StorageStoresMemberShip: {
      get () {
        return this.$store.getters['modStorage/storesMemberShip']
      }
    },
    pageBodyBackgroundColor: {
      get () {
        return {
          // backgroundColor: 'rgba(var(--b3f,244,247,252),1)'
          backgroundColor: this.$store.getters['modPage/pageBackgroundColor']
        }
      }
    },
    CommonContentsHeight: {
      get () {
        return this.windowHeightSize
      }
    }
  },

  methods: {
    ...UtilLib,

    getPrefectureName: function (code) {
      const index = this.StoragePrefecture.findIndex(v => v.code === code)
      return (index >= 0) ? this.StoragePrefecture[index].name : ''
    },

    getAnimalSexName: function (code) {
      const index = this.StorageSexAnimal.findIndex(v => v.code === code)
      return (index >= 0) ? this.StorageSexAnimal[index].name : ''
    },

    getVaccineName: function (code) {
      const index = this.StorageVaccineTypes.findIndex(v => v.code === code)
      return (index >= 0) ? this.StorageVaccineTypes[index].name : ''
    },

    getVaccineExpireMonths: function (code) {
      const index = this.StorageVaccineTypes.findIndex(v => v.code === code)
      return (index >= 0) ? this.StorageVaccineTypes[index].incrementExpireMonths : ''
    },

    requiredVaccineImage: function (code) {
      const index = this.StorageVaccineTypes.findIndex(v => v.code === code)
      return (index >= 0) ? (this.StorageVaccineTypes[index].requiredImage) : false
    },

    requiredVaccineDate: function (code) {
      const index = this.StorageVaccineTypes.findIndex(v => v.code === code)
      return (index >= 0) ? (this.StorageVaccineTypes[index].requiredExpireDate) : false
    },

    // Windowサイズのwatch
    refreshWindowSize: function () {
      if (this.windowWidthSize !== window.innerWidth) {
        this.windowWidthSize = window.innerWidth
      }
      if (this.windowHeightSize !== window.innerHeight) {
        this.windowHeightSize = window.innerHeight
      }
      // this.showFloatingAction = (window.pageYOffset >= 32)
    },

    // タイトルデータ設定
    createTitleDesc: function (routeInstance) {
      if (routeInstance.meta.title) {
        document.title = routeInstance.meta.title
      }
      if (routeInstance.meta.desc) {
        document.querySelector("meta[name='description']").setAttribute('content', routeInstance.meta.desc)
      }
    },

    // Page遷移
    pageTransition: function (url, basePath = null, query = {}) {
      if (this.isUndefined(url)) {
        this.isLoading = false
        return false
      }
      if (this.routeURI === url) {
        if (url === '/') {
          this.$router.replace({ path: '/login' }, () => {})
        } else {
          this.isLoading = false
          return true
        }
      }
      if (url.startsWith('https://')) {
        window.open(url, '_blank')
      } else {
        if (url.startsWith('/')) {
          this.$router.replace({ path: (url === '/') ? '/login' : url, query: query }, () => {})
        } else {
          this.$router.replace({ path: basePath + '/' + url, query: query }, () => {})
        }
      }
    },

    // AWS Amplify サインイン正常終了時に呼び出し
    // SignInSuccess: function (uri = '/console') {
    //   this.SignInLoading(uri)
    // },
    SignInSuccess: function () {
      this.SignInLoading('/console/index')
    },

    // AWS Amplify サインイン失敗時
    SignInError: function (error) {
      this.$confirm({ title: 'Error', message: 'サインイン失敗 [' + error + ']', button: { yes: 'OK' } })
    },

    // サインイン正常終了後に呼び出し: Call SignInSuccess
    SignInLoading: async function (uri, transition = false) {
      try {
        console.log('SignInLoading')

        if (this.isUndefined(uri)) {
          uri = (this.routeURI.endsWith('/')) ? this.routeURI.slice(0, -1) : this.routeURI
        }

        if (this.isDebug()) {
          // デバッグ用ログイン処理を記載

        } else {
          // メンテナンスチェック処理を記載

          // ログイン状態の確認
          const status = (AuthLineLib.isInClient()) ? AuthLineLib.isLogin() : await AuthLib.isLogin()

          // サインアウト状態
          if (!status) {
            // コンソールへのアクセスがあった場合、TOPに遷移
            if (uri.startsWith('/console')) {
              return this.goTransition('/login')
            }
            // コンソール以外へのアクセスがあった場合は、そのまま
            return this.goTransition(uri)
            // return this.goTransition(uri)
          }

          // 初回データ読み込み
          if (this.isDataLoading === false) {
            try {
              // ログインユーザの情報を取得
              await this.$store.dispatch('modStorage/LoadUsers')
              await this.$store.dispatch('modStorage/LoadAnimals')
              this.isDataLoading = true
            } catch (e) {
              // LINE LIFF経由
              if (AuthLineLib.isInClient()) {
                // this.isDataLoading = true
                // トリミング予約関連ページ以外へのアクセスがあった場合
                if (this.routeURI.startsWith('/gr-') === false) {
                  // アカウント登録に遷移
                  if (this.exists(this.$route.query, 'store')) {
                    return this.goTransition('/sign-up/confirm', (this.exists(this.$route.query, 'page')) ? { store: this.$route.query.store, page: this.$route.query.page } : { store: this.$route.query.store })
                  } else {
                    return this.goTransition('/sign-up/confirm', (this.exists(this.$route.query, 'page')) ? { page: this.$route.query.page } : {})
                  }
                  // return this.goTransition('/?referer=' + this.routeURI)
                }
              } else {
                this.$confirm({ title: 'Error', message: '一部コンテンツの読み込みに失敗しました。自動でログアウトします。何度も発生する場合には、お手数ですがシステム管理者にお問合せ下さい。', button: { yes: 'OK' } })
                // return this.SignOut(false, this.routeURI)
                return false
              }
            }
          }
          // サインイン状態でコンソール以外へのアクセスがあった場合
          if (uri.startsWith('/design')) {
            return this.goTransition(uri)
          }
          if (uri.startsWith('/faq')) {
            return this.goTransition(uri)
          }
        }

        if (this.storageDataRefreshInterval === null) {
          // バックグラウンド処理の実行
          this.StorageDataRefresh()
          this.storageDataRefreshInterval = setInterval(this.StorageDataRefresh, this.storageDataRefreshTime)
          // this.checkSessionInterval = setInterval(this.checkSession, this.checkSessionTime)
        }

        // 強制的に画面遷移
        if (transition) {
          return this.goTransition(uri)
        }

        // 現在位置がトリミング予約関連ページの場合
        if (this.routeURI.startsWith('/gr-')) {
          this.isLoading = false
          return true
        }
        // LINE経由は、自動でHOME画面表示
        if (AuthLineLib.isInClient()) {
          // if (this.routeURI.startsWith('/menu')) {
          //   return this.goTransition(this.routeURI)
          // } else {
          //   return this.goTransition('/console/index')
          // }
          // アカウント登録に遷移
          return this.goTransition((this.exists(this.$route.query, 'page')) ? this.$route.query.page : '/console/index', (this.exists(this.$route.query, 'page')) ? this.$route.query : {})
        }

        const suffix = (this.routeURI.endsWith('/')) ? '/' : ''
        // if (this.routeURI !== uri + suffix) {
        //   return this.goTransition(uri)
        // }
        // ログイン済みの場合は、コンソール画面に遷移させる
        if (this.routeURI.startsWith('/console') === false) {
          if ((uri + suffix).startsWith('/console')) {
            return this.goTransition(uri + suffix)
          } else {
            return this.goTransition('/console/index')
          }
        }

        this.isLoading = false
        return true
      } catch (e) {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.SignOut(true)
      }
    },

    // バックエンドデータ更新: Call SignInLoading
    StorageDataRefresh: function () {
      (async () => {
        console.log('StorageDataRefresh')
        // セッションチェック処理
        await this.checkSession()
        await this.$store.dispatch('modStorage/LoadMaster')
        if (UtilLib.isUndefined(this.StorageUsers) === false) {
          await this.$store.dispatch('modStorage/LoadReserve')
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '一部コンテンツの読み込みに失敗しました。自動でログアウトします。', button: { yes: 'OK' } })
        this.SignOut(false)
      })
    },

    // セッションチェック: Call StorageDataRefresh
    checkSession: function () {
      (async () => {
        console.log('checkSession')
        if (AuthLineLib.isInClient()) {
          return true
        }
        const status = await AuthLib.isLogin()
        if (!status) {
          this.SignOut()
        }
      })().catch((e) => {
        this.SignOut(false)
      })
    },

    // サインアウト
    SignOut: function (reset = false, uri = '/') {
      (async () => {
        console.log('SignOut')
        clearInterval(this.storageDataRefreshInterval)
        clearInterval(this.checkSessionInterval)
        await AuthLib.SignOut()
        this.isDataLoading = false
        this.storageDataRefreshInterval = null
        this.checkSessionInterval = null
        this.goTransition(uri)
      })().catch((e) => {
        this.goTransition(uri)
      })
    },

    // Loadingをtrueにしてページ遷移
    goTransition: function (uri, query = {}) {
      this.isLoading = true
      this.pageTransition(uri, null, query)
    }
  }
}
