<template>
<ComponentsLayoutSection1>
  <v-row align="center" justify="center" class="py-3">
    <v-col cols=10>
      <ComponentsViewFormsShortPersonalsProfile
        :personals="personals"
        :users_attribute="users_attribute"
        :address_book="address_book"
      >
      </ComponentsViewFormsShortPersonalsProfile>
    </v-col>
    <v-col cols=2>
      <ComponentsItemsButton
        icon-btn
        icon="mdi-pencil"
        :loading="loading"
        @click="$emit('edit-click')"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>
</ComponentsLayoutSection1>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'

import ComponentsViewFormsShortPersonalsProfile from '@/components/view-forms/ShortPersonalsProfile'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsPersonalsCardProfile',

  mixins: [MixinLib],

  components: {
    ComponentsLayoutSection1,
    ComponentsViewFormsShortPersonalsProfile,
    ComponentsItemsButton
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    personals: {
      type: Object,
      required: false,
      default: () => {}
    },
    users_attribute: {
      type: Object,
      required: false,
      default: () => {}
    },
    address_book: {
      type: Array,
      required: false,
      default: () => []
    }
  }

}
</script>
