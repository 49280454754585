<template>
<ComponentsLayoutSection1>
  <v-row align="center" justify="center">
    <v-col cols=12>
      <ComponentsViewFormsShortAnimalProfile
        :personals="personals"
      >
      </ComponentsViewFormsShortAnimalProfile>
    </v-col>
    <!---
    <v-col cols=2>
      <ComponentsItemsButton
        icon-btn
        icon="mdi-pencil"
        :loading="loading"
        @click="$emit('edit-click')"
      >
      </ComponentsItemsButton>
    </v-col>
    --->
    <v-col cols=12>
      <v-divider></v-divider>
    </v-col>
    <v-col cols=12>
      <v-row align="center" justify="center">
        <v-col cols=4 class="py-1">
          <ComponentsLayoutHeading3 text="希望コース" class="pb-3"></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=8 class="py-1 text-truncate text-left">
          <span v-text="getCategoriesName(courses)"></span>
        </v-col>
        <v-col cols=4 class="py-1">
          <ComponentsLayoutHeading3 text="指名" class="pb-3"></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=8 class="py-1 text-truncate text-left">
          <span v-text="getGroomerName(assign)"></span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</ComponentsLayoutSection1>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsViewFormsShortAnimalProfile from '@/components/view-forms/ShortAnimalProfile'

// import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsTrimmingApplyConditions',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    StorageGroomer: {
      get () {
        return this.$store.getters['modStorage/storesGroomer']
      }
    },
    StorageCategories: {
      get () {
        return this.$store.getters['modStorage/storesGroomingCategories']
      }
    }
  },

  created () {
  },

  mounted () {
    this.isProcessLoading = false
  },

  methods: {
    getGroomerName: function (code) {
      const index = this.StorageGroomer.findIndex(v => v.business_users_id === code)
      return (index >= 0) ? this.StorageGroomer[index].business_users.name : 'おまかせ'
    },
    getCategoriesName: function (code) {
      const index = this.StorageCategories.findIndex(v => v.item_category_id === code)
      return (index >= 0) ? this.StorageCategories[index].item_categories.item_category_name : '選択してください'
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading3,
    ComponentsViewFormsShortAnimalProfile
    // ComponentsItemsButton
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    courses: {
      type: String,
      require: true,
      default: () => ''
    },
    assign: {
      type: String,
      require: true,
      default: () => ''
    },
    personals: {
      type: Object,
      require: true,
      default: () => {
        return {
          img_src: '',
          name: '',
          name_kana: '',
          animal: '犬',
          species: '',
          sex: '9',
          birth_date: ''
        }
      }
    }
  }
}
</script>
