<template>
<v-btn
  text
  depressed
  :loading="loading"
  :color="(isUndefined(color)) ? buttonColor : color"
  :style="{ height: 'inherit' }"
  @click="$emit('click')"
>
  <span class="v-btn__content" :style="{ flexDirection: 'column-reverse' }">
    <span :style="{
        lineHeight: '1.2',
        fontSize: '0.6rem',
        fontFamily: '\'Helvetica Neue\', Tabular,\'Hiragino Kaku Gothic ProN\', \'Hiragino Sans\', \'BIZ UDPGothic\', Meiryo, sans-serif !important'
      }"
      v-text="text"
    ></span>
    <v-icon :color="(isUndefined(color)) ? undefined : color">{{ icon }}</v-icon>
  </span>
</v-btn>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsItemsTextIconButton',

  mixins: [MixinLib],

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    color: {
      type: String,
      required: false,
      default: () => ''
    },
    outlined: {
      type: Boolean,
      required: false,
      default: () => false
    },
    icon: {
      type: String,
      required: false,
      default: () => ''
    },
    text: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>
<style scoped>
.btn-text-style {
  line-height: 1.2;
  font-size: 0.75rem;
  font-family: 'Helvetica Neue', Tabular,'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'BIZ UDPGothic', Meiryo, sans-serif !important;
}
</style>
