<template>
<v-bottom-sheet persistent scrollable v-model="model" v-if="model">
  <v-card>
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutHeading1 :text="title" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="text-left ec-font-size10 py-1">
          <span>最大アップロードサイズ: 20MB</span>
        </v-col>
        <v-col cols=12 class="text-left ec-font-size10 py-1">
          <span class="ec-font-size10">※アップロード可能なファイル形式は、JPEG/PNGのみです</span>
        </v-col>
        <v-col cols=12>
          <v-file-input
            v-model="imageFiles"
            accept="image/png, image/jpeg, image/jpg"
            label="画像を選択してください"
            show-size
            counter
            multiple
          ></v-file-input>
        </v-col>
        <v-col cols=12 v-if="imageFiles.length > 0">
          <ComponentsLayoutHeading2 text="選択されている画像"></ComponentsLayoutHeading2>
        </v-col>
        <v-col cols=12>
          <v-row align="start" justify="start">
            <v-col cols=4 v-for="(item, idx) in imageFiles" :key="idx">
              <v-img :src="getImageObjectURL(item)" aspect-ratio="1" class="grey lighten-2"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <ComponentsItemsButton
            :loading="loading || isProcessLoading"
            text-btn
            text="閉じる"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            :loading="loading || isProcessLoading"
            text-btn
            text="設定する"
            @click="actionAddImages()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-bottom-sheet>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsBottomSheetUploadImages',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    imageFiles: [],
    imageConfig: {
      imagePath: process.env.VUE_APP_IMAGE_ENDPOINT + process.env.VUE_APP_IMAGE_FACE_PATH,
      fileMaxSize: 20971520,
      fileMaxCount: 1,
      fileExtensions: ['jpg', 'jpeg', 'png']
    },
    rules: [
      value => !value[0] || value[0].size < 20971520 || '20MB以下の画像を選択して下さい '
    ]
  }),

  watch: {
    model () {
      if (this.model) {
        this.goLoading()
      }
    }
  },

  mounted () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      this.imageFiles = []
      this.isProcessLoading = false
    },

    // ------------------------------------------
    // 画像の取得
    // ------------------------------------------
    getImageObjectURL: function (file) {
      if (file && file.type.match(/^image\/(png|jpeg)$/)) {
        return URL.createObjectURL(file)
      }
    },

    actionAddImages: function () {
      (async () => {
        this.isProcessLoading = true
        const imgSrc = []
        for (const item of this.imageFiles) {
          const imageType = item.type
          const imageSize = item.size
          if (!this.imageConfig.fileExtensions.some((v) => { return imageType.indexOf(v.fileExtension) })) {
            return this.$confirm({ title: 'Error', message: 'ファイルの拡張子をご確認ください。（jpg, jpeg, png の拡張子のみアップロード可能です）', button: { yes: 'OK' } })
          }
          if (imageSize > this.imageConfig.fileMaxSize) {
            return this.$confirm({ title: 'Error', message: 'アップロード可能な画像サイズは 1ファイル 20MB までです', button: { yes: 'OK' } })
          }
          const extension = imageType.split('/')
          const filename = 'images.' + extension.slice(-1)[0]

          const resultRows = await APILib.postMediaImage(item, filename)
          imgSrc.push(resultRows)
        }
        this.$emit('commit-click', imgSrc)
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  beforeDestroy () {
    this.isProcessLoading = true
    this.imageFiles = []
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsItemsButton
  },

  props: {
    title: {
      type: String,
      require: true,
      default: () => '画像のアップロード'
    },
    model: {
      type: Boolean,
      require: true,
      default: () => false
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
