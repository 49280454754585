<template>
<v-row align="center" justify="center">
  <v-col cols=12>
    <ComponentsLayoutSection2>
      <v-row align="center" justify="center">
        <v-col cols=12>
          <v-row align="center" justify="center" v-for="(item, index) of timezone" :key="index">
            <v-col cols=12>
              <ComponentsLayoutHeading3 :text="'第' + (index + 1) + '希望'" class="pb-3"></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=12 class="pl-5 text-left">
              <v-row align="center" justify="center">
                <v-col cols=4>
                  <ComponentsLayoutHeading3 text="希望日：" class="pb-3"></ComponentsLayoutHeading3>
                </v-col>
                <v-col cols=8>
                  <span v-if="!isUndefined(item.trimming_str_time)">
                    <span v-text="formatDate(item.trimming_date, 'YYYY年MM月DD日 (ddd)')"></span>
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols=12 class="pl-5 text-left">
              <v-row align="center" justify="center">
                <v-col cols=4>
                  <ComponentsLayoutHeading3 text="希望時間：" class="pb-3"></ComponentsLayoutHeading3>
                </v-col>
                <v-col cols=8>
                  <span v-if="!isUndefined(item.trimming_str_time)">
                    <span v-text="(isUndefined(item.times)) ? item.trimming_str_time + '-' + item.trimming_end_time : item.times"></span>
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols=12 class="py-1" v-if="index < timezone.length - 1">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </ComponentsLayoutSection2>
  </v-col>

</v-row>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection2 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

export default {
  name: 'ComponentsViewFormsTrimmingApplyCalendar',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    timezone: {
      get () {
        return this.trimmingTimezone
      },
      set (v) {
        this.$emit('update:trimmingTimezone', v)
      }
    }
  },

  created () {
  },

  mounted () {
    this.isProcessLoading = false
  },

  methods: {
  },

  components: {
    ComponentsLayoutSection2,
    ComponentsLayoutHeading3
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    trimmingTimezone: {
      type: Array,
      require: false,
      default: () => {
        return [
          {
            priority: 1,
            trimming_date: '',
            times: '',
            trimming_str_time: '',
            trimming_end_time: ''
          },
          {
            priority: 1,
            trimming_date: '',
            times: '',
            trimming_str_time: '',
            trimming_end_time: ''
          },
          {
            priority: 1,
            trimming_date: '',
            times: '',
            trimming_str_time: '',
            trimming_end_time: ''
          }
        ]
      }
    }
  }
}
</script>
