<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP1. 予約申請希望選択" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/reserve/1')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP2. コース＆指名条件" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/reserve/2')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 class="pb-5" v-for="(item, index) in animals" :key="index">
      <ComponentsViewFormsTrimmingApplyConditions
        :loading="isProcessLoading"
        :courses="commonRequest[index].courses"
        :assign="commonRequest[index].assign"
        :personals="item.personals"
        :contents="item"
      >
      </ComponentsViewFormsTrimmingApplyConditions>
    </v-col>

    <v-col cols=12 ref="TOP">
      <ComponentsLayoutHeading1 text="STEP3. ご予約希望日時の設定"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading2 text="※混雑状況などにより、ご希望に添えない場合がございますのでご了承ください"></ComponentsLayoutHeading2>
    </v-col>
    <v-col cols=12>
      <ComponentsLayoutHeading2 text="第三希望までご入力いただく事を推奨いたします"></ComponentsLayoutHeading2>
    </v-col>

    <v-col cols=12>
      <ComponentsFormsTrimmingApplyCalendar
        :loading="isLoading || isProcessLoading"
        :trimming-timezone.sync="timezone"
      >
      </ComponentsFormsTrimmingApplyCalendar>
    </v-col>

    <v-col cols=12>
      <ComponentsFormsTrimmingApplyAdditional
        :loading="isLoading || isProcessLoading"
        :trimming_request.sync="trimmingRequest"
        :trimming.sync="trimming"
        ref="applyAdditionalForm"
      ></ComponentsFormsTrimmingApplyAdditional>
    </v-col>

    <v-col cols=12>
      <ComponentsItemsButton
        block
        :loading="isLoading || isProcessLoading"
        text="次へ"
        @click="onNext()"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP4. お客様情報の確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP5. カルテ情報の確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP6. 申請内容の最終確認"></ComponentsLayoutHeading1>
    </v-col>

  </v-row>

</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'

import ComponentsViewFormsTrimmingApplyConditions from '@/components/view-forms/TrimmingApplyConditions'
import ComponentsFormsTrimmingApplyCalendar from '@/components/forms/TrimmingApplyCalendar'
import ComponentsFormsTrimmingApplyAdditional from '@/components/forms/TrimmingApplyAdditional'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingReserveTrimmingMemberStep3Page',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    commonRequest: {
      get () {
        return this.$store.getters['modTrimming/commonRequest']
      }
    },
    animals: {
      get () {
        return this.$store.getters['modTrimming/animals']
      }
    },
    trimming: {
      get () {
        return this.$store.getters['modTrimming/trimming']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changeTrimming', v)
      }
    },
    trimmingRequest: {
      get () {
        return this.$store.getters['modTrimming/trimmingRequest']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changeTrimmingRequest', v)
      }
    },
    timezone: {
      get () {
        return this.$store.getters['modTrimming/trimmingTimezone']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changeTrimmingTimezone', v)
      }
    }
  },

  created () {
    setTimeout(() => (
      this.$refs.TOP.scrollIntoView({ behavior: 'smooth' })
    ))
  },

  mounted () {
    this.onLoading()
  },

  methods: {
    onLoading: function () {
      this.isProcessLoading = false
    },
    onNext: function () {
      (async () => {
        if (this.$refs.applyAdditionalForm) {
          await this.$refs.applyAdditionalForm.confirm()
          if (this.$refs.applyAdditionalForm.error) {
            this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
            return false
          }
        }
        this.$store.dispatch('modTrimming/setupRequestAnimals')
        this.pageTransition('/gr-rsv/' + this.storeID + '/reserve/4')
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '予約申請でエラーが発生しました。お手数ですがシステムにお問合せ下さい [' + e.message + ']', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsViewFormsTrimmingApplyConditions,
    ComponentsFormsTrimmingApplyCalendar,
    ComponentsFormsTrimmingApplyAdditional,
    ComponentsItemsButton
  }
}
</script>
