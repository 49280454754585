<template>
<v-app :style="{ backgroundColor: primaryColor }">
  <v-main>
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col cols=6 class="mx-auto text-center">
          <v-img :src="splash" :lazy-src="splash" contain aspect-ratio="1" class="mx-auto">
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="warning"
              ></v-progress-circular>
            </v-row>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</v-app>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsLayoutLoadingScreen',

  mixins: [MixinLib],

  data: () => ({
    splash: require('@/assets/logo.png')
  }),

  props: {
  }
}
</script>
