<template>
<div>
  <transition name="fade">
    <ComponentsLayoutLoadingScreen v-if="isProcessLoading"></ComponentsLayoutLoadingScreen>
  </transition>
  <transition name="fade">
    <router-view transition="fade-transition" v-if="!isProcessLoading" />
  </transition>
  <vue-confirm-dialog></vue-confirm-dialog>

  <v-dialog
    v-model="versionUp"
    persistent
    max-width="290"
  >
    <v-card>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols=12 class="pt-5 text-center">
            <span class="ec-font-size16 blue-grey--text text--lighten-1">UPDATE</span>
          </v-col>
          <v-col cols=12>
            <v-progress-linear
              v-model="progress"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import AuthLib from '@/libs/AuthLib'
// import AuthLineLib from '@/libs/AuthLineLib'
import axios from 'axios'

import ComponentsLayoutLoadingScreen from '@/components/layout/LoadingScreen'

export default {
  name: 'App',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    versionUp: false,
    interval: 0,
    progress: 0,
    splash: require('@/assets/logo.png')
  }),

  created: function () {
    (async () => {
      await this.checkVersion()
      // 初期設定
      // history.pushState(null, null, location.href)
      // window.addEventListener('popstate', (e) => {
      //   history.go(1)
      // })
      this.$store.dispatch('modPage/reset')
      this.refreshWindowSize()
      window.addEventListener('resize', this.refreshWindowSize)
      window.addEventListener('scroll', this.refreshWindowSize)
      window.addEventListener('load', this.refreshWindowSize)
    })().catch((e) => {
      this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
    })
  },

  mounted: function () {
    (async () => {
      // LINEブラウザのアクセスチェック
      // await AuthLineLib.init()
      if (this.isInLineClient) {
        if (this.isInitialLoading === false) {
          this.isInitialLoading = true
          const uri = (this.routeURI.endsWith('/')) ? this.routeURI.slice(0, -1) : this.routeURI
          await this.SignInLoading((this.routeURI === '/') ? '/login' : uri)
        }
      } else {
        // WEB アプリ
        await AuthLib.Configure()
        await AuthLib.onListen(this.SignInSuccess, this.SignInError)
        if (this.isInitialLoading === false) {
          this.isInitialLoading = true
          const uri = (this.routeURI.endsWith('/')) ? this.routeURI.slice(0, -1) : this.routeURI
          await this.SignInLoading((this.routeURI === '/') ? '/login' : uri)
        }
      }
      this.isProcessLoading = false
    })().catch((e) => {
      this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
    })
  },

  methods: {
    checkVersion: async function () {
      axios({
        method: 'GET',
        url: '/version.json?' + parseInt(new Date() / 1000),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (process.env.VUE_APP_VERSION !== response.data.version) {
          // if (this.isInLineClient) {
          //   window.navigator.serviceWorker.getRegistrations()
          //     .then(registrations => {
          //       for (const registration of registrations) {
          //         registration.unregister()
          //       }
          //     })
          //   window.location.reload(true)
          // } else {
          this.$confirm({ title: 'Confirm', message: '最新のバージョンにアップデートを行います', button: { yes: 'OK' }, callback: this.updateVersion })
          // }
        }
      })
    },
    updateVersion: async function () {
      this.versionUp = true
      window.navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          for (const registration of registrations) {
            registration.unregister()
          }
        })
      setTimeout(() => {
        this.interval = setInterval(() => {
          if (this.progress === 100) {
            clearInterval(this.interval)
            return window.location.reload(true)
          }
          this.progress += 20
        }, 1000)
      }, 5000)
    }
  },

  components: {
    ComponentsLayoutLoadingScreen
  }
}
</script>
<style>
/**
 * css
 */
.right-input-select .v-select__selections {
  direction: rtl;
}

.right-input input {
  text-align: right;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.v-application .caption {
  font-family: 'Helvetica Neue', Tabular,'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'BIZ UDPGothic', Meiryo, sans-serif !important;
}

h1, h2, h3, h4, p, span, div, input {
  font-family: 'Helvetica Neue', Tabular,'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'BIZ UDPGothic', Meiryo, sans-serif !important;
}

.text-omitted {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

a {
  text-decoration: none;
}

.ec-font-size8 {
  font-size: 10px!important;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

.ec-font-size10 {
  font-size: 10px!important;
}

.ec-font-size12 {
  font-size: 12px!important;
}

.ec-font-size14 {
  font-size: 14px!important;
}

.ec-font-size16 {
  font-size: 16px!important;
}

.ec-font-size18 {
  font-size: 18px!important;
}

.ec-font-size20 {
  font-size: 20px!important;
}

.ec-font-size22 {
  font-size: 22px!important;
}

.ec-font-size32 {
  font-size: 32px!important;
}

.ec-font-size40 {
  font-size: 40px!important;
}

.ec-row-h25 {
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ec-row-h40 {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ec-row-h80 {
  height: 80px;
  overflow: hidden;
  text-overflow: clip;
}

/*
.ec-secHeading {
  margin-bottom: 15px;
  color: black;
}

.ec-secHeading .ec-secHeading__en {
  font-size: clamp(14px, 1.2vw, 18px);
  font-weight: bold;
  letter-spacing: .2em;
}

.ec-secHeading .ec-secHeading__line {
  display: inline-block;
  margin: 0 20px;
  width: 1px;
  height: 14px;
  background: black;
}

.ec-secHeading .ec-secHeading__ja {
  font-size: clamp(6px, 1vw, 12px);
  font-weight: normal;
  letter-spacing: .15em;
  vertical-align: 2px;
}
*/

.app-secHeading {
    font-size: 1.8rem;
    position: relative;
    padding: 0.8rem;
    text-align: center;
    margin-bottom: 0.3em;
    font-weight: 700;
}

.app-secHeading:before {
    position: absolute;
    bottom: 0px;
    left: calc(50% - 30px);
    width: 60px;
    height: 5px;
    content: '';
    border-radius: 3px;
    background: #FF8300;
}

.app-secHeading span {
    font-size: 0.9rem;
    display: block;
    margin-top: 4px;
}

pre {
  font-family: 'Helvetica Neue', Tabular,'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'BIZ UDPGothic', Meiryo, sans-serif !important;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
}
</style>
