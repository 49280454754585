import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import AuthLib from '@/libs/AuthLib'
import AuthLineLib from '@/libs/AuthLineLib'

// import AppPage from '@/App.vue'
import IndexPage from '@/views/IndexPage.vue'

// デザインチェック用
import DesignPage from '@/views/DesignPage.vue'

// ログイン
import LoginPage from '@/views/LoginPage.vue'

// FAQ
import FAQPage from '@/views/FAQPage.vue'

// アカウント登録
import SignUpPage from '@/views/SignUpPage.vue'
import SignUpConfirmPage from '@/views/SignUp/ConfirmPage.vue'
import SignUpRegisterPage from '@/views/SignUp/RegisterPage.vue'

// パスワード再発行
import ForgetPasswordPage from '@/views/ForgetPasswordPage.vue'
import ForgetPasswordAuthenticationPage from '@/views/ForgetPassword/AuthenticationPage.vue'
import ForgetPasswordAuthenticationVerifyPage from '@/views/ForgetPassword/AuthenticationVerifyPage.vue'
import ForgetPasswordResetPage from '@/views/ForgetPassword/ResetPage.vue'

//
import ConsolePage from '@/views/ConsolePage.vue'
import ConsoleHomePage from '@/views/Console/HomePage.vue'
import ConsoleAlbumPage from '@/views/Console/AlbumPage.vue'
import ConsoleMembersCardPage from '@/views/Console/MembersCardPage.vue'
import ConsoleShopPage from '@/views/Console/ShopPage.vue'

//
import ConsoleNewsPage from '@/views/Console/NewsPage.vue'
import ConsoleReservePage from '@/views/Console/ReservePage.vue'
import ConsoleCreateAnimalsPage from '@/views/Console/CreateAnimalsPage.vue'

//
import ConsoleUserProfilePage from '@/views/Console/UserProfilePage.vue'
import ConsoleResetPasswordPage from '@/views/Console/ResetPasswordPage.vue'
import ConsoleHideAnimalsPage from '@/views/Console/HideAnimalsPage.vue'
import ConsoleRemovePage from '@/views/Console/RemovePage.vue'
import ConsoleVersionPage from '@/views/Console/VersionPage.vue'
import ConsoleSignOutPage from '@/views/Console/SignOutPage.vue'
import ConsoleHelpPage from '@/views/Console/HelpPage.vue'

//
import ConsoleAnimalsContentsPage from '@/views/Console/Animals/ContentsPage.vue'

// LINE経由認証
import Stores404Page from '@/views/Stores/404Page.vue'
import StoresPage from '@/views/StoresPage.vue'
import StoresCommonPage from '@/views/Stores/CommonPage.vue'
import StoresSignInIndexPage from '@/views/Stores/SignIn/IndexPage.vue'

// 会員証
import StoresMemberShipIndexPage from '@/views/Stores/MemberShip/IndexPage.vue'

// 予約申請
/// import GroomingPage from '@/views/GroomingPage.vue'
import StoresTrimmingReservePage from '@/views/Stores/TrimmingReservePage.vue'

import StoresTrimmingReserveReservePage from '@/views/Stores/TrimmingReserve/ReservePage.vue'
import StoresTrimmingReserveTrimmingMemberStep1Page from '@/views/Stores/TrimmingReserve/Member/Step1Page.vue'
import StoresTrimmingReserveTrimmingMemberStep2Page from '@/views/Stores/TrimmingReserve/Member/Step2Page.vue'
import StoresTrimmingReserveTrimmingMemberStep3Page from '@/views/Stores/TrimmingReserve/Member/Step3Page.vue'
import StoresTrimmingReserveTrimmingMemberStep4Page from '@/views/Stores/TrimmingReserve/Member/Step4Page.vue'
import StoresTrimmingReserveTrimmingMemberStep5Page from '@/views/Stores/TrimmingReserve/Member/Step5Page.vue'
import StoresTrimmingReserveTrimmingMemberStep6Page from '@/views/Stores/TrimmingReserve/Member/Step6Page.vue'

import StoresTrimmingReserveNewReservePage from '@/views/Stores/TrimmingReserve/NewReservePage.vue'
import StoresTrimmingReserveTrimmingNewerStep1Page from '@/views/Stores/TrimmingReserve/Newer/Step1Page.vue'
import StoresTrimmingReserveTrimmingNewerStep2Page from '@/views/Stores/TrimmingReserve/Newer/Step2Page.vue'
import StoresTrimmingReserveTrimmingNewerStep3Page from '@/views/Stores/TrimmingReserve/Newer/Step3Page.vue'
import StoresTrimmingReserveTrimmingNewerStep4Page from '@/views/Stores/TrimmingReserve/Newer/Step4Page.vue'
import StoresTrimmingReserveTrimmingNewerStep5Page from '@/views/Stores/TrimmingReserve/Newer/Step5Page.vue'

import StoresTrimmingReserveMemberShipPage from '@/views/Stores/TrimmingReserve/MemberShipPage.vue'
import StoresTrimmingReserveCompletePage from '@/views/Stores/TrimmingReserve/CompletePage.vue'

// カルテ入力
import StoresTrimmingProfilePage from '@/views/Stores/TrimmingProfilePage.vue'
import StoresTrimmingProfileMemberShipPage from '@/views/Stores/TrimmingProfile/MemberShipPage.vue'
import StoresTrimmingProfileAuthenticationPage from '@/views/Stores/TrimmingProfile/AuthenticationPage.vue'
import StoresTrimmingProfileAuthenticationVerifyPage from '@/views/Stores/TrimmingProfile/AuthenticationVerifyPage.vue'
import StoresTrimmingProfileSheetsPage from '@/views/Stores/TrimmingProfile/SheetsPage.vue'
import StoresTrimmingProfileCompletePage from '@/views/Stores/TrimmingProfile/CompletePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'IndexPage',
    component: IndexPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  {
    path: '/index',
    name: 'TopPage',
    component: LoginPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  {
    path: '/faq',
    name: 'FAQPage',
    component: FAQPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  {
    path: '/design',
    name: 'DesignPage',
    component: DesignPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  // --------------------------------------------------------
  // アカウント登録
  // --------------------------------------------------------
  {
    path: '/sign-up',
    name: 'SignUpPage',
    component: SignUpPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    },
    children: [
      {
        path: 'confirm',
        name: 'SignUpConfirmPage',
        component: SignUpConfirmPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        }
      },
      {
        path: 'register',
        name: 'SignUpRegisterPage',
        component: SignUpRegisterPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        }
      }
    ]
  },
  // --------------------------------------------------------
  // パスワード再発行
  // --------------------------------------------------------
  {
    path: '/forget-password',
    name: 'ForgetPasswordPage',
    component: ForgetPasswordPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    },
    children: [
      {
        path: 'authentication',
        name: 'ForgetPasswordAuthenticationPage',
        component: ForgetPasswordAuthenticationPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        }
      },
      {
        path: 'authentication-verify',
        name: 'ForgetPasswordAuthenticationVerifyPage',
        component: ForgetPasswordAuthenticationVerifyPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        }
      },
      {
        path: 'reset',
        name: 'ForgetPasswordResetPage',
        component: ForgetPasswordResetPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        }
      }
    ]
  },
  // --------------------------------------------------------
  // メインメニュー
  // --------------------------------------------------------
  {
    path: '/console',
    name: 'ConsolePage',
    component: ConsolePage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    },
    children: [
      {
        path: 'index',
        name: 'ConsoleHomePage',
        component: ConsoleHomePage,
        meta: {
          requiresAuth: true,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        }
      },
      {
        path: 'album',
        name: 'ConsoleAlbumPage',
        component: ConsoleAlbumPage,
        meta: {
          requiresAuth: true,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        }
      },
      {
        path: 'members-card',
        name: 'ConsoleMembersCardPage',
        component: ConsoleMembersCardPage,
        meta: {
          requiresAuth: true,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        }
      },
      {
        path: 'shops',
        name: 'ConsoleShopPage',
        component: ConsoleShopPage,
        meta: {
          requiresAuth: true,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        }
      }
    ]
  },
  // --------------------------------------------------------
  // メインメニュー > お知らせ
  // --------------------------------------------------------
  {
    path: '/console/news',
    name: 'ConsoleNewsPage',
    component: ConsoleNewsPage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  // --------------------------------------------------------
  // メインメニュー > 予約一覧
  // --------------------------------------------------------
  {
    path: '/console/reserve',
    name: 'ConsoleReservePage',
    component: ConsoleReservePage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  // --------------------------------------------------------
  // メインメニュー > 動物登録
  // --------------------------------------------------------
  {
    path: '/console/create-animals',
    name: 'ConsoleCreateAnimalsPage',
    component: ConsoleCreateAnimalsPage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  // --------------------------------------------------------
  // メインメニュー > 左上メニュー
  // --------------------------------------------------------
  {
    path: '/console/user-profile',
    name: 'ConsoleUserProfilePage',
    component: ConsoleUserProfilePage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  {
    path: '/console/rest-password',
    name: 'ConsoleResetPasswordPage',
    component: ConsoleResetPasswordPage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  {
    path: '/console/hide-animals',
    name: 'ConsoleHideAnimalsPage',
    component: ConsoleHideAnimalsPage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  {
    path: '/console/remove',
    name: 'ConsoleRemovePage',
    component: ConsoleRemovePage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  {
    path: '/console/version',
    name: 'ConsoleVersionPage',
    component: ConsoleVersionPage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  {
    path: '/console/sign-out',
    name: 'ConsoleSignOutPage',
    component: ConsoleSignOutPage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  {
    path: '/console/help',
    name: 'ConsoleHelpPage',
    component: ConsoleHelpPage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  // --------------------------------------------------------
  // メインメニュー > うちの子
  // --------------------------------------------------------
  {
    path: '/console/animals/:PersonalsID',
    name: 'ConsoleAnimalsContentsPage',
    component: ConsoleAnimalsContentsPage,
    meta: {
      requiresAuth: true,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    }
  },
  // --------------------------------------------------------
  // 仮予約
  // --------------------------------------------------------
  {
    path: '/gr-rsv',
    name: 'StoresTrimmingReserveConsolePage',
    component: StoresPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    },
    children: [
      {
        path: ':shopID(\\d+)',
        name: 'StoresTrimmingReservePage',
        component: StoresTrimmingReservePage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        },
        children: [
          {
            path: '404',
            name: 'StoresTrimmingReserve404Page',
            component: Stores404Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'member-ship',
            name: 'StoresTrimmingReserveMemberShipPage',
            component: StoresTrimmingReserveMemberShipPage,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'reserve',
            name: 'StoresTrimmingReserveReservePage',
            component: StoresTrimmingReserveReservePage,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'reserve/1',
            name: 'StoresTrimmingReserveTrimmingMemberStep1Page',
            component: StoresTrimmingReserveTrimmingMemberStep1Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'reserve/2',
            name: 'StoresTrimmingReserveTrimmingMemberStep2Page',
            component: StoresTrimmingReserveTrimmingMemberStep2Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'reserve/3',
            name: 'StoresTrimmingReserveTrimmingMemberStep3Page',
            component: StoresTrimmingReserveTrimmingMemberStep3Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'reserve/4',
            name: 'StoresTrimmingReserveTrimmingMemberStep4Page',
            component: StoresTrimmingReserveTrimmingMemberStep4Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'reserve/5',
            name: 'StoresTrimmingReserveTrimmingMemberStep5Page',
            component: StoresTrimmingReserveTrimmingMemberStep5Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'reserve/6',
            name: 'StoresTrimmingReserveTrimmingMemberStep6Page',
            component: StoresTrimmingReserveTrimmingMemberStep6Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'new-reserve',
            name: 'StoresTrimmingReserveNewReservePage',
            component: StoresTrimmingReserveNewReservePage,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'new-reserve/1',
            name: 'StoresTrimmingReserveTrimmingNewerStep1Page',
            component: StoresTrimmingReserveTrimmingNewerStep1Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'new-reserve/2',
            name: 'StoresTrimmingReserveTrimmingNewerStep2Page',
            component: StoresTrimmingReserveTrimmingNewerStep2Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'new-reserve/3',
            name: 'StoresTrimmingReserveTrimmingNewerStep3Page',
            component: StoresTrimmingReserveTrimmingNewerStep3Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'new-reserve/4',
            name: 'StoresTrimmingReserveTrimmingNewerStep4Page',
            component: StoresTrimmingReserveTrimmingNewerStep4Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'new-reserve/5',
            name: 'StoresTrimmingReserveTrimmingNewerStep5Page',
            component: StoresTrimmingReserveTrimmingNewerStep5Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'complete',
            name: 'StoresTrimmingReserveCompletePage',
            component: StoresTrimmingReserveCompletePage,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          }
        ]
      }
    ]
  },
  // --------------------------------------------------------
  // 問診入力
  // --------------------------------------------------------
  {
    path: '/gr-shs',
    name: 'StoresTrimmingProfileConsolePage',
    component: StoresPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    },
    children: [
      {
        path: ':shopID(\\d+)',
        name: 'StoresTrimmingProfilePage',
        component: StoresTrimmingProfilePage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        },
        children: [
          {
            path: '404',
            name: 'StoresTrimmingProfile404Page',
            component: Stores404Page,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'member-ship',
            name: 'StoresTrimmingProfileMemberShipPage',
            component: StoresTrimmingProfileMemberShipPage,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'authentication',
            name: 'StoresTrimmingProfileAuthenticationPage',
            component: StoresTrimmingProfileAuthenticationPage,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'authentication-verify',
            name: 'StoresTrimmingProfileAuthenticationVerifyPage',
            component: StoresTrimmingProfileAuthenticationVerifyPage,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'sheets',
            name: 'StoresTrimmingProfileSheetsPage',
            component: StoresTrimmingProfileSheetsPage,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'complete',
            name: 'StoresTrimmingProfileCompletePage',
            component: StoresTrimmingProfileCompletePage,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          }
        ]
      }
    ]
  },
  // --------------------------------------------------------
  // 店舗別ページ
  // --------------------------------------------------------
  {
    path: '/stores',
    name: 'StoresConsolePage',
    component: StoresPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
        desc: ''
      }
    },
    children: [
      {
        path: ':shopID(\\d+)',
        name: 'StoresCommonPage',
        component: StoresCommonPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
            desc: ''
          }
        },
        children: [
          {
            path: 'sign-in',
            name: 'StoresSignInIndexPage',
            component: StoresSignInIndexPage,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          },
          {
            path: 'member-ship',
            name: 'StoresMemberShipIndexPage',
            component: StoresMemberShipIndexPage,
            meta: {
              requiresAuth: false,
              page: {
                title: 'Petcone(ペトコネ) / ペットサロンや動物病院など動物に関する人々とつながり日々の暮らしを便利にする事で、動物との暮らしを豊かにする',
                desc: ''
              }
            }
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savePosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeResolve((to, from, next) => {
  (async () => {
    // store.dispatch('modPage/isLoading', true)
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // ログイン状態の確認
      const status = (AuthLineLib.isInClient()) ? AuthLineLib.isLogin() : await AuthLib.isLogin()
      // const status = await AuthLib.isLogin()
      if (status === false) {
        return next({ path: '/index', query: {} })
      }
    }
    return next()
  })().catch((e) => {
    return next({ path: '/index', query: {} })
  })
})

router.afterEach((to, from) => {
  // if (to.meta && to.meta.requiresAuth) {
  //   API.putUsersPageAccessLog(to.fullPath)
  // }
  if (to.meta && to.meta.page) {
    store.dispatch('modPage/setPageContents', to.meta.page)
  }
  store.dispatch('modPage/isLoading', false)
})

export default router
