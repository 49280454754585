<template>
  <router-view v-if="!isProcessLoading" />
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import AuthLineLib from '@/libs/AuthLineLib'

export default {
  name: 'StoresTrimmingProfilePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    users: {
      get () {
        return this.$store.getters['modStorage/users']
      }
    },
    usersStores: {
      get () {
        return this.$store.getters['modStorage/usersStores']
      }
    },
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    StorageCategories: {
      get () {
        return this.$store.getters['modStorage/storesGroomingCategories']
      }
    }
  },

  mounted: function () {
    (async () => {
      this.goLoading()
      this.isProcessLoading = false
    })().catch((e) => {
      this.isProcessLoading = false
    })
  },

  methods: {
    goLoading: function () {
      (async () => {
        // 仮注文情報の初期化
        // this.$store.dispatch('modSession/initialTrimming')
        // this.$set(this.sessionTrimming, 'shop_id', this.shopID)

        // マスター情報の読み込み（バックエンド）
        this.$store.dispatch('modStorage/LoadMaster')
        // 店舗情報の読み込み
        await this.$store.dispatch('modStorage/LoadStoresContents', this.storeID)
        //
        await this.$store.dispatch('modStorage/LoadUsers').catch((e) => false)
        //
        await this.$store.dispatch('modStorage/LoadUsersStores', this.storeID).catch((e) => false)
        //
        this.$store.dispatch('modSession/SetMemberShip', this.usersStores)

        /*
        // トリミングサービスの有効/無効
        if (this.StorageStoreServiceContents.stores_service.status === '2') {
          return this.goTransition('/gr-shs/' + this.storeID + '/404')
        }
        // ウェブ予約の有効/無効
        if (this.StorageStoreServiceContents.stores_service_web_reserve.status === '2') {
          return this.goTransition('/gr-shs/' + this.storeID + '/404')
        }
        // コース未設定
        if (this.isUndefined(this.StorageCategories)) {
          return this.goTransition('/gr-shs/' + this.storeID + '/404')
        }
        */

        // カルテ登録済み: リピーター
        if (this.isMember()) {
          return this.pageTransition('/gr-shs/' + this.storeID + '/sheets')
        }
        // アプリ登録あり & カルテ登録無し: 新規
        if (this.isRegister()) {
          return this.pageTransition('/gr-shs/' + this.storeID + '/sheets')
        }
        // 別ページからの直リクエスト
        if (this.exists(this.$route.query, 'direct')) {
          return
        }
        // 会員制度あり
        if (this.StorageStoresMemberShip.membership_status === process.env.VUE_APP_MEMBERSHIP_STATUS) {
          return this.pageTransition('/gr-shs/' + this.storeID + '/member-ship')
        }
        // LINE LIFF アプリ
        if (AuthLineLib.isInClient()) {
          return this.pageTransition('/gr-shs/' + this.storeID + '/authentication')
        }
        // WEBアプリ
        return this.pageTransition('/gr-shs/' + this.storeID + '/authentication')
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    isRegister: function () {
      if (this.isUndefined(this.users)) {
        return false
      }
      return true
    },
    isMember: function () {
      if (this.isUndefined(this.usersStores)) {
        return false
      }
      return true
    }
  },

  components: {
    // ComponentsLayoutPage2,
    // ComponentsItemsProgressCircular
  }
}
</script>
