<template>
<v-row justify="end" class="pt-2 pr-5">
  <v-switch
    inset
    dense
    :true-value="trueValue"
    :false-value="falseValue"
    class="mt-0 mr-0"
    :color="buttonColor"
    :input-value="value"
    :loading="loading"
    :hide-details="!error"
    :rules="validate"
    :error-messages="errors"
    :placeholder="placeholder"
    @change="updateValue"
  >
    <template v-slot:label>
      <span class="ec-font-size12" :style="{ color: buttonColor }" v-text="(value == 1) ? trueLabel : falseLabel"></span>
    </template>
  </v-switch>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import ValidationLib from '@/libs/ValidationLib'

export default {
  name: 'ComponentsItemsSwitch',

  mixins: [MixinLib],

  data () {
    return {
      validate: [],
      placeholder: '',
      error: false,
      errors: ''
    }
  },

  computed: {
  },

  created: function () {
    this.validate = ValidationLib.rules(this.name)
    this.placeholder = ValidationLib.placeholder(this.name)
  },

  methods: {
    updateValue: function (e) {
      this.$emit('update:value', e)
    },

    isError: function () {
      return (this.errors !== '')
    },

    confirm: function () {
      return new Promise((resolve, reject) => {
        try {
          this.errors = ''
          if (this.isUndefined(this.validate)) {
            this.validate = []
          }
          if (!this.isArray(this.validate)) {
            this.validate = []
          }
          for (var y = 0; y < this.validate.length; y++) {
            const err = this.validate[y](this.value)
            if (err !== true) {
              this.errors = err
              break
            }
          }
          if (this.isError()) {
            this.error = true
            return resolve(false)
          }
          this.error = false
          return resolve(true)
        } catch (e) {
          return reject(e)
        }
      })
    }
  },

  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    falseValue: {
      type: String,
      required: false,
      default: () => '2'
    },
    falseLabel: {
      type: String,
      required: false,
      default: () => '無効'
    },
    trueValue: {
      type: String,
      required: false,
      default: () => '1'
    },
    trueLabel: {
      type: String,
      required: false,
      default: () => '有効'
    }
  }
}
</script>
