<template>
<v-bottom-sheet persistent scrollable v-model="model" v-if="model">
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutHeading1 text="ご予約内容" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }" v-if="!isUndefined(contents)">
        <v-col cols=12>
          <ComponentsLayoutSection1>
            <v-row align="center" justify="center">
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="予約店舗"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(stores)) ? '' : stores.store_name"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="パートナー名"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="contents.personals.name1"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="予約日"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="formatDate(contents.trimming.trimming_date, 'YYYY年MM月DD日(ddd)')"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="受付時間"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="formatDate(contents.trimming.trimming_date + ' ' + contents.trimming.trimming_str_time, 'HH:mm') + '～'"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="指名"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12 v-for="item of contents.trimming_assign.filter(v => v.assign_status === '2')" :key="item.business_users_id">
                      <span v-text="item.name"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="コース"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12 v-for="item of contents.trimming_courses" :key="item.item_category_id">
                      <span v-text="item.item_category_name"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
            </v-row>
          </ComponentsLayoutSection1>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            text-btn
            text="閉じる"
            :loading="isProcessLoading"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-bottom-sheet>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsBottomSheetTrimmingReserveSheet',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    contents: {}
  }),

  watch: {
    model () {
      if (this.model) {
        this.goLoading()
      }
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.contents = {}
        if (this.isUndefined(this.personalId)) {
          return false
        }
        if (this.isUndefined(this.trimmingId)) {
          return false
        }
        this.isProcessLoading = true
        // this.personals = await APILib.getAnimalsTrimmingOrder(this.personalId, this.trimmingId)
        this.contents = await APILib.getAnimalsTrimmingOrder(this.personalId, this.trimmingId)
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },

  beforeDestroy () {
    this.isProcessLoading = false
    this.contents = {}
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutSection2,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading3,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    },
    personalId: {
      type: String,
      require: true,
      default: () => ''
    },
    trimmingId: {
      type: String,
      require: true,
      default: () => ''
    },
    stores: {
      type: Object,
      require: true,
      default: () => {}
    }
  }
}
</script>
