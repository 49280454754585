// import UtilLib from '@/libs/UtilLib'

const state = {
  channel: '',
  tel: ''
}

const getters = {
  channel: (state) => {
    return state.channel
  },
  tel: (state) => {
    return state.tel
  }
}

const mutations = {
  channel (state, contents) {
    state.channel = contents
  },
  tel (state, contents) {
    state.tel = contents
  }
}

const actions = {
  channel ({ commit, dispatch, state }, contents) {
    commit('channel', contents)
  },
  tel ({ commit, dispatch, state }, contents) {
    commit('tel', contents)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
