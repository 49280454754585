<template>
<v-progress-circular
  :size="(xSmall) ? 30 : (small) ? 50 : 70"
  :color="buttonColor"
  :width="(xSmall) ? 3 : (small) ? 5 : 7"
  indeterminate
></v-progress-circular>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsItemsProgressCircular',

  mixins: [MixinLib],

  props: {
    xSmall: {
      type: Boolean,
      required: false,
      default: () => false
    },
    small: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
