<template>
<ComponentsLayoutSection1 :color="(value === contents.item_category_id) ? secondaryActiveColor : ''" on-click-action @click="onClick()">
  <v-row align="center" justify="center">
    <!--
    <v-col cols=12 class="text-center">
      <v-img :src="contents.item_categories.item_category_img_src" aspect-ratio="1" class="grey lighten-2" height="125"></v-img>
    </v-col>
    -->
    <v-col cols=8 class="ec-font-size14 font-weight-bold">
      <span v-text="contents.item_categories.item_category_name"></span>
    </v-col>
    <v-col cols=4 class="text-right">
      <ComponentsItemsButton
        :outlined="!(value === contents.item_category_id)"
        :loading="loading"
        small
        :text="(value === contents.item_category_id) ? '選択中' : '選択する'"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>
  <v-row align="center" justify="center" v-if="!isUndefined(contents.item_categories.item_category_description)">
    <v-col cols=12 class="py-1">
      <v-divider></v-divider>
    </v-col>
    <v-col cols=12>
      <span v-text="contents.item_categories.item_category_description"></span>
    </v-col>
  </v-row>
</ComponentsLayoutSection1>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsCoursesProfile',

  mixins: [MixinLib],

  components: {
    ComponentsLayoutSection1,
    ComponentsItemsButton
  },

  methods: {
    onClick () {
      this.$emit('click')
    }
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    value: {
      type: String,
      required: false,
      default: () => ''
    },
    contents: {
      type: Object,
      required: false,
      default: () => {
        return {
          item_category_id: '',
          item_categories: {
            item_category_name: '',
            item_category_color: '',
            item_category_img_src: '',
            item_category_description: '',
            item_category_attr1: '',
            item_category_attr2: ''
          },
          stores_item_categories: {
            sales_status: '',
            sort_order: ''
          }
        }
      }
    }
  }

}
</script>
