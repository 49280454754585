<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="バージョン情報"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/console/index')"
>
<v-row align="center" justify="center" class="px-3">

  <v-col cols=12 class="py-1">
    <v-row align="center" justify="center">
      <v-col cols=12 class="py-1" v-if="isVersion.isError">
        <v-alert dense outlined type="error" class="my-5" style="font-size:.8rem!important;">
          <span>最新バージョンの確認に失敗しました</span><br />
          <span v-text="isVersion.isErrorMessage"></span>
        </v-alert>
      </v-col>
      <v-col cols=12 class="py-1" v-if="!isVersion.isError && isVersion.isNew">
        <span>最新のバージョンです</span>
      </v-col>
      <v-col cols=12 class="py-1" v-if="!isVersion.isError && !isVersion.isNew">
        <v-row align="center" justify="center">
          <v-col cols=12>
              <span>最新のバージョン </span><span v-text="isVersion.version"></span><span> にアップデートしてください</span>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsButton
              block
              :loading="isLoading || isProcessLoading"
              text="最新バージョンに更新する"
              @click="confirmVersionUP()"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="バージョン情報"></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="バージョン"></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <v-row align="center" justify="center">
              <v-col cols=12 class="py-1">
                <span v-text="version"></span><br/>
                <span class="grey--text text--lighten-1" style="font-size:.6rem!important;" v-text="mode"></span>
              </v-col>
            </v-row>
          </ComponentsLayoutSection2>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="端末情報"></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="Device"></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <v-row align="center" justify="center">
              <v-col cols=12 class="py-1">
                <span v-text="device.model" v-if="!isUndefined(device)"></span>
                &nbsp;<span v-text="device.type" v-if="!isUndefined(device)"></span>
                &nbsp;<span v-text="device.vendor" v-if="!isUndefined(device)"></span>
              </v-col>
            </v-row>
          </ComponentsLayoutSection2>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="OS"></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <v-row align="center" justify="center">
              <v-col cols=12 class="py-1">
                <span v-text="os.name" v-if="!isUndefined(os)"></span>
                &nbsp;<span v-text="os.version" v-if="!isUndefined(os)"></span>
              </v-col>
            </v-row>
          </ComponentsLayoutSection2>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="Browser"></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <v-row align="center" justify="center">
              <v-col cols=12 class="py-1">
                <span v-text="browser.name" v-if="!isUndefined(browser)"></span>
                &nbsp;<span v-text="browser.version" v-if="!isUndefined(browser)"></span>
              </v-col>
            </v-row>
          </ComponentsLayoutSection2>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="UsertAgent"></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <v-row align="center" justify="center">
              <v-col cols=12 class="py-1">
                <span v-text="usertAgent" v-if="!isUndefined(usertAgent)"></span>
              </v-col>
            </v-row>
          </ComponentsLayoutSection2>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

</v-row>
</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import axios from 'axios'

import Revision from '@/libs/Revision'
import UAParser from 'ua-parser-js'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsLayoutPage2 from '@/components/layout/Page2'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleVersionPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    isVersion: {
      isNew: true,
      isError: false,
      isErrorMessage: '',
      version: ''
    }
  }),

  computed: {
    mode: {
      get () {
        return process.env.VUE_APP_MODE
      }
    },
    version: {
      get () {
        return Revision.getLocalStorageRevision() || '-'
      }
    },
    browser: {
      get () {
        return new UAParser().getBrowser()
      }
    },
    device: {
      get () {
        return new UAParser().getDevice()
      }
    },
    engine: {
      get () {
        return new UAParser().getEngine()
      }
    },
    os: {
      get () {
        return new UAParser().getOS()
      }
    },
    usertAgent: {
      get () {
        return new UAParser().getUA()
      }
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      try {
        const revision = Revision.getLocalStorageRevision()
        axios({
          method: 'GET',
          url: process.env.VUE_APP_SITE_URL + '/version.json?' + parseInt(new Date() / 1000),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => {
          if (revision !== response.data.version) {
            this.isVersion.isNew = false
          } else {
            this.isVersion.isNew = true
            this.isVersion.version = response.data.version
          }
        }).catch((e) => {
          this.isVersion.isError = true
          this.isVersion.isErrorMessage = e.message
        })
      } catch (e) {
        this.isVersion.isError = true
        this.isVersion.isErrorMessage = e.message
      }
    },

    confirmVersionUP: function () {
      this.$confirm({ title: 'Confirm', message: '最新のバージョンに更新します', button: { yes: 'OK', no: 'CANCEL' }, callback: this.goVersionUP })
    },

    goVersionUP: function (ask) {
      try {
        if (!ask) {
          return
        }
        // if (this.isDebug()) {
        //   return
        // }
        window.navigator.serviceWorker.getRegistrations()
          .then(registrations => {
            for (const registration of registrations) {
              registration.unregister()
            }
          })
        // this.showInformation = true
        Revision.setShowInformation(true)
        window.location.reload(true)
        // Revision.setLocalStorageRevision(this.isVersion.version)
        this.$confirm({ title: 'Success', message: 'バージョンアップが完了しました', button: { yes: 'OK' } })
      } catch (e) {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      }
    }
  },

  components: {
    ComponentsLayoutPage2,
    ComponentsLayoutSection1,
    ComponentsLayoutSection2,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading3,
    ComponentsItemsButton
  }
}
</script>
