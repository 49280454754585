<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">

    <v-col cols=12 ref="Step1">
      <ComponentsLayoutHeading1 text="STEP1. お客様情報の確認"></ComponentsLayoutHeading1>
    </v-col>
    <v-col cols=12 v-show="step === 1">
      <v-row align="center" justify="center">

        <v-col cols=12>
          <ComponentsFormsTrimmingProfilePersonals
            :loading="isProcessLoading"
            :store-id="storeID"
            :personals.sync="personals.personals"
            :users_attribute.sync="personals.users_attribute"
            :address_book.sync="personals.address_book"
            :fbn_personals.sync="personals.fbn_personals"
            :users_authentication.sync="personals.users_authentication"
            :show-member-ship="isCreateMemberShip"
            :show-app-user="isCreateAppUser"
            ref="userProfile"
          ></ComponentsFormsTrimmingProfilePersonals>
        </v-col>

        <v-col cols=12 v-show="step === 1">
          <ComponentsItemsButton
            block
            :loading="isProcessLoading"
            text="次へ"
            @click="nextPersonals()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols=12 v-show="step > 1">
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsViewFormsPersonalsCardProfile
            :loading="isProcessLoading"
            :personals="personals.personals"
            :users_attribute="personals.users_attribute"
            :address_book="personals.address_book"
            @edit-click="step = 1"
          ></ComponentsViewFormsPersonalsCardProfile>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12 ref="Step2">
      <ComponentsLayoutHeading1 text="STEP2. カルテ情報の確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 v-show="step === 2">
      <v-row align="center" justify="center">
        <v-col cols=12 class="pb-5" v-for="(item, index) in animals" :key="index">
          <v-row align="center" justify="center">
            <v-col cols=12>
              <ComponentsLayoutHeading1 :text="(index + 1) + '頭目のカルテ情報'"></ComponentsLayoutHeading1>
            </v-col>

            <!--
            <v-col cols=12>
              <ComponentsViewFormsTrimmingApplyConditions
                :loading="isProcessLoading"
                :courses="item.trimming_courses"
                :assign="item.trimming_assign"
                :personals="item.personals"
              ></ComponentsViewFormsTrimmingApplyConditions>
            </v-col>
            ---->

            <v-col cols=12>
              <ComponentsFormsTrimmingProfileAnimal
                :loading="isProcessLoading"
                :store-id="storeID"
                :personals.sync="item.personals"
                :personals-allergies.sync="item.personals_allergies"
                :animals-attribute.sync="item.animals_attribute"
                :personals-vaccine.sync="item.personals_vaccine"
                :personals-trimming-cut-image.sync="item.personals_trimming_cut_image"
                :personals-interview-sheet.sync="item.personals_interview_sheet"
                :store-interview-sheet.sync="StorageStoresInterviewSheet"
                ref="animalProfile"
              ></ComponentsFormsTrimmingProfileAnimal>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsButton
            block
            :loading="isProcessLoading"
            text="次へ"
            @click="nextAnimals()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols=12 v-show="step > 2">
      <v-row align="center" justify="center">
        <v-col cols=12 class="pb-5" v-for="(item, index) in animals" :key="index">
          <ComponentsViewFormsTrimmingApplyConditions
            :loading="isProcessLoading"
            :courses="item.trimming_courses"
            :assign="item.trimming_assign"
            :personals="item.personals"
            @edit-click="step = step = 2"
          ></ComponentsViewFormsTrimmingApplyConditions>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12 ref="Step3">
      <ComponentsLayoutHeading1 text="STEP3. 申請内容の最終確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 v-show="step === 3">
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsFormsTrimmingApplyConfirm
            :loading="isProcessLoading"
            :store-id="storeID"
            ref="applyConfirmForm"
          ></ComponentsFormsTrimmingApplyConfirm>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsButton
            block
            :loading="isProcessLoading"
            text="入力した内容でカルテを更新する"
            @click="confirm()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-col>

  </v-row>
</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'
import AuthLineLib from '@/libs/AuthLineLib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsFormsTrimmingProfileAnimal from '@/components/forms/TrimmingProfileAnimal'
import ComponentsFormsTrimmingProfilePersonals from '@/components/forms/TrimmingProfilePersonals'
import ComponentsFormsTrimmingApplyConfirm from '@/components/forms/TrimmingApplyConfirm'

import ComponentsViewFormsPersonalsCardProfile from '@/components/view-forms/PersonalsCardProfile'
import ComponentsViewFormsTrimmingApplyConditions from '@/components/view-forms/TrimmingApplyConditions'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingProfileSheetsPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    step: 1,
    personals: {
      personals: {
        img_src: '',
        name: '',
        name_kana: '',
        sex: '9',
        birth_date: ''
      },
      users_attribute: {
        country: '+81',
        post_no: '',
        prefecture_id: '',
        address: '',
        building: '',
        mail_address: ''
      },
      address_book: [
        {
          tel: '',
          memo: ''
        },
        {
          tel: '',
          memo: ''
        },
        {
          tel: '',
          memo: ''
        }
      ],
      fbn_personals: {
        members_account: '',
        members_password: '',
        mail_magazine_status: ''
      }
    },
    animals: []
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    SessionMemberShip: {
      get () {
        return this.$store.getters['modSession/MemberShip']
      }
    },
    doNextTimeZone: {
      get () {
        return this.trimmingTimezone.some(v => (v.dates.length > 0 && v.times.length > 0))
      }
    },
    users: {
      get () {
        return this.$store.getters['modStorage/users']
      }
    },
    isCreateMemberShip: {
      get () {
        if (this.StorageStoresMemberShip.membership_status !== process.env.VUE_APP_MEMBERSHIP_STATUS) {
          return false
        }
        if (this.exists(this.SessionMemberShip, 'fbn_personals')) {
          return (this.isUndefined(this.SessionMemberShip.fbn_personals.members_account))
        }
        return true
      }
    },
    isCreateAppUser: {
      get () {
        if (AuthLineLib.isInClient()) {
          return false
        }
        if (this.exists(this.SessionMemberShip, 'users_authentication')) {
          return (this.isUndefined(this.SessionMemberShip.users_authentication.personal_account))
        }
        return true
      }
    },
    StorageStoresInterviewSheet: {
      get () {
        return this.$store.getters['modStorage/storesInterviewSheet']
      }
    }
  },

  created () {
  },

  mounted () {
    this.goLoading()
  },

  methods: {
    // -------------------------------------------
    //
    // -------------------------------------------
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        // 飼い主カルテ情報のコピー
        this.personals = Object.keys(this.SessionMemberShip)
          .filter(v => v !== 'animals')
          .reduce((previous, current, index) => {
            return { ...previous, [current]: this.clone(this.SessionMemberShip[current]) }
          }, {})
        // 動物カルテ情報のコピー
        if (this.exists(this.SessionMemberShip, 'animals')) {
          this.animals = this.clone(this.SessionMemberShip.animals)
        }
        //
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },
    // -------------------------------------------
    //
    // -------------------------------------------
    CountStep: function () {
      this.step = this.step + 1
      if (this.$refs['Step' + this.step]) {
        setTimeout(() => (
          this.$refs['Step' + this.step].scrollIntoView({ behavior: 'smooth' })
        ))
      }
    },
    // -------------------------------------------
    //
    // -------------------------------------------
    nextPersonals: function () {
      (async () => {
        if (this.$refs.userProfile) {
          await this.$refs.userProfile.confirm()
          if (this.$refs.userProfile.error) {
            this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
            return false
          }
        }
        this.CountStep()
        return true
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '入力内容をご確認ください [' + e.message + ']', button: { yes: 'OK' } })
      })
    },
    // -------------------------------------------
    //
    // -------------------------------------------
    nextAnimals: function () {
      (async () => {
        if (this.$refs.animalProfile) {
          if (this.isArray(this.$refs.animalProfile)) {
            for (const forms of this.$refs.animalProfile) {
              await forms.confirm()
              if (forms.error) {
                this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
                return false
              }
            }
          } else {
            await this.$refs.animalProfile.confirm()
            if (this.$refs.animalProfile.error) {
              this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
              return false
            }
          }
        }
        this.CountStep()
        return true
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '入力内容をご確認ください [' + e.message + ']', button: { yes: 'OK' } })
      })
    },
    // -------------------------------------------
    //
    // -------------------------------------------
    confirm: function () {
      (async () => {
        if (this.$refs.applyConfirmForm) {
          await this.$refs.applyConfirmForm.confirm()
          if (this.$refs.applyConfirmForm.error) {
            // this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
            return false
          }
        }
        this.isProcessLoading = true

        const contents = {
          personals: this.personals.personals,
          users_attribute: this.personals.users_attribute,
          address_book: this.personals.address_book,
          contents: this.animals.map((v, idx) => {
            return {
              karte_id: v.fbn_personals.karte_id,
              personals: v.personals,
              animals_attribute: v.animals_attribute,
              personals_vaccine: v.personals_vaccine.map(v => {
                return {
                  personals_vaccine: {
                    vaccine_type_id: v.personals_vaccine.vaccine_type_id,
                    action_date: v.personals_vaccine.action_date,
                    expire_date: v.personals_vaccine.expire_date,
                    img_src: v.personals_vaccine.img_src,
                    action_type: v.personals_vaccine.action_type
                  }
                }
              }),
              personals_allergies: v.personals_allergies,
              personals_trimming_cut_image: v.personals_trimming_cut_image,
              personals_interview_sheet: { contents: v.personals_interview_sheet }
            }
          })
        }
        if (this.isCreateMemberShip) {
          contents.fbn_personals = this.personals.fbn_personals
        }
        if (this.isCreateAppUser) {
          contents.users_authentication = this.personals.users_authentication
        }

        await APILib.putStoresPersonals(this.storeID, this.personals.fbn_personals.karte_id, contents)
        this.isProcessLoading = false

        // ペトコネアカウントの保存
        if (this.isCreateAppUser) {
          this.$store.dispatch('modSession/setAuthorization', [contents.users_authentication.personal_account, contents.users_authentication.personal_password])
        }
        return this.pageTransition('/gr-shs/' + this.storeID + '/complete')
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: 'お客様情報の更新でエラーが発生しました。お手数ですがシステムにお問合せ下さい [' + e.message + ']', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsFormsTrimmingProfilePersonals,
    ComponentsFormsTrimmingProfileAnimal,
    ComponentsViewFormsPersonalsCardProfile,
    ComponentsViewFormsTrimmingApplyConditions,
    ComponentsFormsTrimmingApplyConfirm,
    ComponentsItemsButton
  }
}
</script>
