import axios from 'axios'
import UtilLib from '@/libs/UtilLib'
// import CookieLib from '@/libs/CookieLib'
/// import UAParser from 'ua-parser-js'

import AuthLib from '@/libs/AuthLib'
import AuthLineLib from '@/libs/AuthLineLib'

/*
axios.interceptors.request.use(request => {
  console.log('Starting Request: ', request)
  return request
})

axios.interceptors.response.use(response => {
  console.log('Response: ', response)
  return response
})
*/

const ApiLibs = {

  __apiEndPoint: function () {
    return (AuthLineLib.isInClient()) ? process.env.VUE_APP_LINE_API_ENDPOINT : process.env.VUE_APP_WEB_API_ENDPOINT
  },

  __apiKey: function () {
    return (AuthLineLib.isInClient()) ? process.env.VUE_APP_LINE_API_KEY : process.env.VUE_APP_WEB_API_KEY
  },

  __apiToken: async function () {
    return (AuthLineLib.isInClient()) ? await AuthLineLib.getAccessToken() : await AuthLib.getToken()
  },

  __getApiLibsCall: async function (uri, httpHeaders) {
    return axios({
      method: 'GET',
      url: ApiLibs.__apiEndPoint() + uri,
      ...httpHeaders
    }).then(function (response) {
      return ApiLibs.getResponse(response, true)
    }).catch(err => {
      throw err
    })
  },

  __postApiLibsCall: async function (method, uri, httpHeaders, data, isRes = false) {
    return axios({
      method: method,
      url: ApiLibs.__apiEndPoint() + uri,
      ...httpHeaders,
      data: data,
      validateStatus: function (status) {
        return status === 200 || status === 400 || status === 401 || status === 403 || status === 404 || status === 421 || status === 422 || status === 429
      }
    }).then(function (response) {
      return ApiLibs.getResponse(response, isRes)
    }).catch(err => {
      throw err
    })
  },

  __deleteApiLibsCall: async function (uri, httpHeaders, isRes = false) {
    return axios({
      method: 'DELETE',
      url: ApiLibs.__apiEndPoint() + uri,
      ...httpHeaders
    }).then(function (response) {
      return ApiLibs.getResponse(response, isRes)
    }).catch(err => {
      throw err
    })
  },

  __imageUploadApiLibsCall: async function (uri, httpHeaders, formData) {
    return axios({
      method: 'POST',
      url: ApiLibs.__apiEndPoint() + uri,
      ...httpHeaders,
      processData: false,
      contentType: false,
      data: formData,
      validateStatus: function (status) {
        return status === 200 || status === 400 || status === 401 || status === 403 || status === 404 || status === 421 || status === 422 || status === 429
      }
    }).then(function (response) {
      return ApiLibs.getResponse(response)
    }).catch(err => {
      throw err
    })
  },

  getRequestHeader: async function (additional = {}, isToken = true, ContentType = 'application/json') {
    const httpHeaders = {
      'x-api-key': ApiLibs.__apiKey(),
      'Content-Type': ContentType
    }
    // if (isToken) {
    if (AuthLineLib.isInClient() || isToken) {
      httpHeaders.authorization = await ApiLibs.__apiToken()
      if (UtilLib.isUndefined(httpHeaders.authorization)) {
        throw new Error('NoSessionException')
      }
    }
    // }
    return {
      headers: {
        ...httpHeaders,
        ...additional
      }
    }
  },

  getResponse: function (response, isRes = true) {
    if (parseInt(response.status) !== 200) {
      throw new Error(response.status)
    }
    // if (!response.data[status]) {
    //   return (isRes) ? response.data : true
    // }
    if (parseInt(response.data.status) !== 200) {
      if (UtilLib.getValue(response.data, 'message')) {
        throw new Error(response.data.message)
      } else {
        throw new Error(response.data.status)
      }
    }
    return (isRes) ? response.data.response : true
  },

  /************************************************************************
   * 認証
   ***********************************************************************/

  /**
   * ユーザ登録
   */
  postOAuthSignUp: async function (contents) {
    const uri = '/oauth/sign-up'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, contents, true)
  },

  /**
   * ユーザ登録
   */
  postOAuthReset: async function (account, password) {
    const uri = '/oauth/reset'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, { account: account, password: password }, true)
  },

  /**
   * アカウントの登録確認
   */
  getOAuthSignInCheck: async function (account) {
    const uri = '/oauth/sign-in/check?account=' + account
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * パスワード - 再発行申請
   */
  getOAuthPasswordReset: async function (account) {
    const uri = '/oauth/password/reset?account=' + account
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * パスワード - 本人確認
   */
  putOAuthPasswordReset: async function (requestCode, authenticationCode) {
    const uri = '/oauth/password/reset'
    const httpHeaders = await ApiLibs.getRequestHeader({ 'x-code': requestCode }, false)
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, { authentication_code: authenticationCode }, true)
  },

  /**
   * パスワード - 再設定
   */
  postOAuthPasswordReset: async function (requestCode, authenticationCode, password) {
    const uri = '/oauth/password/reset'
    const httpHeaders = await ApiLibs.getRequestHeader({ 'x-code': requestCode }, false)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, { authentication_code: authenticationCode, password: password }, true)
  },

  /************************************************************************
   * ユーザ
   ***********************************************************************/

  /**
   * 飼い主詳細返却
   */
  getUsers: async function () {
    const uri = '/users'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 飼い主更新
   */
  putUsers: async function (contents) {
    const uri = '/users'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, contents, true)
  },

  /**
   * 飼い主更新
   */
  deleteUsers: async function () {
    const uri = '/users'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('DELETE', uri, httpHeaders, {}, true)
  },

  /**
   * 飼い主動物一覧返却（表示のみ）
   */
  getUsersAnimalsShow: async function () {
    const uri = '/users/animals?screen=1'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 飼い主動物一覧返却（非表示のみ）
   */
  getUsersAnimalsHide: async function () {
    const uri = '/users/animals?screen=2'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * カルテ情報返却
   */
  getUsersStores: async function (storesID) {
    const uri = '/users/stores/' + storesID
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * トリミング予約一覧返却
   */
  getUsersTrimmingOrder: async function () {
    const uri = '/users/trimming/order'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * サインアウト処理 | LINEアプリ用
   */
  postUsersSignOut: async function () {
    const uri = '/users/sign-out'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, {}, true)
  },

  /************************************************************************
   * 動物
   ***********************************************************************/

  /**
   * 動物返却
   */
  getAnimals: async function () {
    const uri = '/animals'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 動物登録
   */
  postAnimals: async function (contents) {
    const uri = '/animals'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, contents, true)
  },

  /**
   * 動物詳細返却
   */
  getAnimalsContents: async function (personalID) {
    const uri = '/animals/' + personalID
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 動物更新
   */
  putAnimals: async function (personalID, contents) {
    const uri = '/animals/' + personalID
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, contents, true)
  },

  /**
   * 動物削除
   */
  deleteAnimals: async function (personalID) {
    const uri = '/animals/' + personalID
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('DELETE', uri, httpHeaders, {}, true)
  },

  /**
   * 表示設定
   */
  putAnimalsScreen: async function (personalID) {
    const uri = '/animals/' + personalID + '/screen'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, {}, true)
  },

  /**
   * 非表示設定
   */
  deleteAnimalsScreen: async function (personalID) {
    const uri = '/animals/' + personalID + '/screen'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('DELETE', uri, httpHeaders, {}, true)
  },

  /**
   * 動物アレルギー返却
   */
  getAnimalsAllergy: async function (personalID) {
    const uri = '/animals/' + personalID + '/allergy'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 動物アレルギー登録
   */
  putAnimalsAllergy: async function (personalID, contents) {
    const uri = '/animals/' + personalID + '/allergy'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, contents, true)
  },

  /**
   * 体重返却（グラフ）
   */
  getAnimalsWeightGraph: async function (personalID, weightYear) {
    const uri = '/animals/' + personalID + '/weight-graph/' + weightYear
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 体重返却（月間）
   */
  getAnimalsWeight: async function (personalID, weightMonth) {
    const uri = '/animals/' + personalID + '/weight/' + weightMonth
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 体重返却（年間）
   */
  getAnimalsWeightYears: async function (personalID, weightYears) {
    const uri = '/animals/' + personalID + '/weight/' + weightYears
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 体重登録
   */
  postAnimalsWeight: async function (personalID, weightDate, contents) {
    const uri = '/animals/' + personalID + '/weight/' + weightDate
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, contents, true)
  },

  /**
   * 体重更新
   */
  putAnimalsWeight: async function (personalID, weightDate, contents) {
    const uri = '/animals/' + personalID + '/weight/' + weightDate
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, contents, true)
  },

  /**
   * 体重削除
   */
  deleteAnimalsWeight: async function (personalID, weightDate) {
    const uri = '/animals/' + personalID + '/weight/' + weightDate
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('DELETE', uri, httpHeaders, {}, true)
  },

  /**
   * ワクチン接種情報返却
   */
  getAnimalsVaccine: async function (personalID, VaccineTypeID) {
    const uri = '/animals/' + personalID + '/vaccine-' + VaccineTypeID
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * ワクチン接種情報登録
   */
  postAnimalsVaccine: async function (personalID, contents) {
    const uri = '/animals/' + personalID + '/vaccine'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, contents, true)
  },

  /**
   * 保険証情報返却
   */
  getAnimalsInsurances: async function (personalID) {
    const uri = '/animals/' + personalID + '/insurances'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 保険証情報登録
   */
  potAnimalsInsurances: async function (personalID, contents) {
    const uri = '/animals/' + personalID + '/insurances'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, contents, true)
  },

  /**
   * 保険証情報更新
   */
  putAnimalsInsurances: async function (personalID, insurancesID, contents) {
    const uri = '/animals/' + personalID + '/insurances-' + insurancesID
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, contents, true)
  },

  /**
   * トリミング予約詳細返却
   */
  getAnimalsTrimmingOrder: async function (personalID, trimmingID) {
    const uri = '/animals/' + personalID + '/trimming/order/' + trimmingID
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * トリミング履歴一覧返却
   */
  getAnimalsTrimmingHistory: async function (personalID) {
    const uri = '/animals/' + personalID + '/trimming/history'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * トリミング履歴詳細返却
   */
  getAnimalsTrimmingHistoryContents: async function (personalID, trimmingID) {
    const uri = '/animals/' + personalID + '/trimming/history/' + trimmingID
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * トリミングカットイメージ返却
   */
  getStoresAnimalsTrimmingCutImages: async function (personalID) {
    const uri = '/animals/' + personalID + '/trimming/cut-image'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * トリミングカットイメージ更新
   */
  putStoresAnimalsTrimmingCutImages: async function (personalID, contents) {
    const uri = '/animals/' + personalID + '/trimming/cut-image'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, contents, true)
  },

  /**
   * 問診票返却
   */
  getStoresAnimalsTrimmingInterviewSheet: async function (personalID, storeID) {
    const uri = '/animals/' + personalID + '/trimming/' + storeID + '/interview-sheet'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 問診票登録
   */
  putStoresAnimalsTrimmingInterviewSheet: async function (personalID, storeID, contents) {
    const uri = '/animals/' + personalID + '/trimming/' + storeID + '/interview-sheet'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, contents, true)
  },

  /**
   * アルバム一覧返却
   */
  getStoresAnimalsMedia: async function (personalID) {
    const uri = '/animals/' + personalID + '/media'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * 店舗
   ***********************************************************************/

  /**
   * 認証処理
   */
  postStoresVerifications: async function (storeID, Channel, PhoneNumber) {
    const uri = '/stores/verifications'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, { Channel: Channel, PhoneNumber: PhoneNumber }, true)
  },

  /**
   * 認証処理
   */
  postStoresVerificationChecks: async function (storeID, PhoneNumber, verificationCode) {
    const uri = '/stores/verificationChecks'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, { PhoneNumber: PhoneNumber, verificationCode: verificationCode }, true)
  },

  /**
   * 店舗一覧返却
   */
  getStoresList: async function () {
    const uri = '/stores'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 詳細返却
   */
  getStores: async function (storeID) {
    const uri = '/stores/' + storeID
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 店舗サービス別情報返却
   */
  getStoresService: async function (storeID, servicesType = process.env.VUE_APP_TRIMMING_SERVICE_ID) {
    const uri = '/stores/' + storeID + '/srv-' + servicesType
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 指名可能グルーマー返却
   */
  getStoresGroomingGroomer: async function (storeID) {
    const uri = '/stores/' + storeID + '/srv-' + process.env.VUE_APP_TRIMMING_SERVICE_ID + '/groomer'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 商品分類一覧返却
   */
  getStoresGroomingCategories: async function (storeID) {
    const uri = '/stores/' + storeID + '/srv-' + process.env.VUE_APP_TRIMMING_SERVICE_ID + '/cat'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 会員制度情報返却
   */
  getStoresMemberShip: async function (storeID) {
    const uri = '/stores/' + storeID + '/member-ship'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 問診票返却
   */
  getStoresInterviewSheet: async function (storeID) {
    const uri = '/stores/' + storeID + '/interview-sheet'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 文書返却
   */
  getStoresDocuments: async function (storeID, docID) {
    const uri = '/stores/' + storeID + '/doc-' + docID
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * アカウント情報検索
   */
  getStoresConfirmMembers: async function (storeID, membersAccount) {
    const encode1 = membersAccount.replace(/\+/g, '%2b')
    const encode2 = encode1.replace(/#/g, '%23')
    const uri = '/stores/' + storeID + '/confirm-members?members_account=' + encode2
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * アカウント情報検索
   */
  getStoresConfirmTel: async function (storeID, Tel) {
    const uri = '/stores/' + storeID + '/confirm-tel?tel=' + Tel
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * カルテ情報検索
   */
  getStoresPersonals: async function (storeID, Query = {}) {
    const uri = '/stores/' + storeID + '/personals?' + Object.keys(Query).map(v => v + '=' + Query[v]).join('&')
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * カルテ情報更新
   */
  putStoresPersonals: async function (storeID, karteID, contents) {
    const uri = '/stores/' + storeID + '/personals/' + karteID
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, contents, true)
  },

  /**
   * 仮予約登録
   */
  postStoresTrimming: async function (storeID, contents) {
    const uri = '/stores/' + storeID + '/trimming'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, contents, true)
  },

  /**
   * 仮予約登録
   */
  postStoresTrimmingKarte: async function (storeID, karteID, contents) {
    const uri = '/stores/' + storeID + '/trimming/' + karteID
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, contents, true)
  },

  /**
   * 愛犬登録
   */
  postStoresAnimals: async function (storeID, karteID, contents) {
    const uri = '/stores/' + storeID + '/animals/' + karteID
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, contents, true)
  },

  /**
   * 予約可能日付の返却
   */
  getStoresServiceReservableDates: async function (storeID, BusinessUsersID, Month, Range) {
    const uri = '/stores/' + storeID + '/srv-' + process.env.VUE_APP_TRIMMING_SERVICE_ID + '/reservable/dates/' + BusinessUsersID + '?date=' + Month + '&date_type=months&date_range=' + Range
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * メディア
   ***********************************************************************/

  /**
   * 画像アップロード
   */
  postMediaImage: async function (blob, filename) {
    const uri = '/media/image?filename=' + encodeURI(filename)
    const formData = new FormData()
    formData.append(filename, blob)
    const httpHeaders = await ApiLibs.getRequestHeader({}, false, 'multipart/form-data')
    return await ApiLibs.__imageUploadApiLibsCall(uri, httpHeaders, formData)
  },

  /************************************************************************
   * その他
   ***********************************************************************/
  /**
   * 動物種一覧返却
   */
  getComponentAnimalType: async function () {
    const uri = '/component/animal-types'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 品種一覧返却
   */
  getComponentSpecies: async function (animalType) {
    const uri = '/component/species/' + animalType
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 性別(人間)一覧返却
   */
  getComponentSexPerson: async function () {
    const uri = '/component/sex-person'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 性別(動物)一覧返却
   */
  getComponentSexAnimal: async function () {
    const uri = '/component/sex-animal'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 国一覧返却
   */
  getComponentCountry: async function () {
    const uri = '/component/country'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * アレルギー一覧返却
   */
  getComponentAllergy: async function () {
    const uri = '/component/allergy'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * ワクチン一覧返却
   */
  getComponentVaccineType: async function () {
    const uri = '/component/vaccine-type'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 都道府県一覧返却
   */
  getComponentPrefecture: async function () {
    const uri = '/component/prefectures'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 店舗サービス分類返却
   */
  getComponentStoreServices: async function () {
    const uri = '/component/store-services'
    const httpHeaders = await ApiLibs.getRequestHeader({}, false)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  }

}

export default ApiLibs
