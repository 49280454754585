<template>
<v-text-field
  dense
  outlined
  :value="calendarTime"
  :label="label"
  v-mask="'##:##'"
  inputmode="numeric"
  :loading="loading"
  :hide-details="!error"
  :rules="validate"
  :error-messages="errors"
  :placeholder="placeholder"
  :disabled="(disabled == true)"
  background-color="rgba(var(--b3f,250,250,250),1)"
  :style="{ maxWidth: '100px' }"
  @change="changeTime($event)"
>
</v-text-field>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import ValidationLib from '@/libs/ValidationLib'

import { mask } from 'vue-the-mask'

export default {
  name: 'ComponentsItemsTextFieldTypeTime',

  mixins: [MixinLib],

  directives: { mask },

  data () {
    return {
      validate: [],
      placeholder: '',
      error: false,
      errors: ''
    }
  },

  computed: {
    calendarTime: {
      get () {
        return (this.isUndefined(this.value)) ? '' : this.value
      },
      set (v) {
        this.changeTime(v)
      }
    }
  },

  created: function () {
    this.validate = ValidationLib.rules(this.name)
    this.placeholder = ValidationLib.placeholder(this.name)
  },

  methods: {
    changeTime: function (v) {
      this.updateValue(v)
      // this.modal = false
    },

    updateValue: function (e) {
      this.$emit('update:value', e)
    },

    isError: function () {
      return (this.errors.length > 0)
    },

    setError: function (message) {
      this.errors = message
      this.error = true
    },

    confirm: function () {
      return new Promise((resolve, reject) => {
        try {
          this.errors = ''
          for (var y = 0; y < this.validate.length; y++) {
            const err = this.validate[y](this.value)
            if (err !== true) {
              this.errors = err
              break
            }
          }
          if (this.isError()) {
            this.error = true
            return resolve(false)
          }
          this.error = false
          return resolve(true)
        } catch (e) {
          return reject(e)
        }
      })
    }
  },

  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true,
      default: () => ''
    },
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
