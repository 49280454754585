<template>
<v-row no-gutters>
  <v-col cols=12>
    <v-text-field
      dense
      outlined
      :value="calendarDate"
      :label="label"
      v-mask="'####/##/##'"
      inputmode="numeric"
      :loading="loading"
      :hide-details="!error"
      :rules="validate"
      :error-messages="errors"
      :placeholder="placeholder"
      :disabled="(disabled == true)"
      background-color="rgba(var(--b3f,250,250,250),1)"
      :style="{ maxWidth: '240px' }"
      :readonly="readOnly"
      @change="changeDate(formatDate($event, 'YYYY-MM-DD'))"
    >
      <template v-slot:append-outer>
        <ComponentsItemsButton
          small
          icon-btn
          icon="mdi-calendar"
          :loading="loading"
          @click="openCalendar()"
        >
        </ComponentsItemsButton>
      </template>
    </v-text-field>
  </v-col>
  <v-dialog v-model="modal" persistent width="290px">
    <v-date-picker
      ref="picker"
      v-model="modalValue"
      locale="jp-ja"
      :day-format="date => new Date(date).getDate()"
      scrollable
      :active-picker.sync="dateType"
      :allowed-dates="(isUndefined(allowedDates)) ? null : allowedDates"
      :min="min"
      :max="max"
      :event-color="(isUndefined(eventColor)) ? 'warning' : eventColor"
      :events="events"
      :no-title="noTitle"
      @change="changeDate"
      :class="{
        'ec-font-size12': true,
        'week_start_sun': true
      }"
    >
      <v-spacer></v-spacer>
      <ComponentsItemsButton
        text-btn
        text="閉じる"
        :loading="loading"
        @click="modal = false"
      >
      </ComponentsItemsButton>
    </v-date-picker>
  </v-dialog>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import ValidationLib from '@/libs/ValidationLib'

import { mask } from 'vue-the-mask'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsItemsDatePicker',

  mixins: [MixinLib],

  directives: { mask },

  data () {
    return {
      validate: [],
      placeholder: '',
      error: false,
      errors: '',
      modal: false,
      modalValue: '',
      dateType: 'YEAR'
    }
  },

  computed: {
    calendarDate: {
      get () {
        return (this.isUndefined(this.value)) ? '' : this.formatDate(this.value, 'YYYY/MM/DD')
      }
    }
  },

  created: function () {
    this.validate = ValidationLib.rules(this.name)
    this.placeholder = ValidationLib.placeholder(this.name)
  },

  methods: {
    openCalendar () {
      this.modalValue = this.clone(this.value)
      this.modal = true
      setTimeout(() => (
        this.dateType = this.pickerType
        // this.$refs.picker.activePicker = 'YEAR'
      ))
    },

    changeDate: function (v) {
      this.updateValue(v)
      this.modal = false
    },

    updateValue: function (e) {
      this.$emit('update:value', e)
    },

    isError: function () {
      return (this.errors.length > 0)
    },

    setError: function (message) {
      this.errors = message
      this.error = true
    },

    confirm: function () {
      return new Promise((resolve, reject) => {
        try {
          this.errors = ''
          for (var y = 0; y < this.validate.length; y++) {
            const err = this.validate[y](this.value)
            if (err !== true) {
              this.errors = err
              break
            }
          }
          if (this.isError()) {
            this.error = true
            return resolve(false)
          }
          this.error = false
          return resolve(true)
        } catch (e) {
          return reject(e)
        }
      })
    }
  },

  components: {
    ComponentsItemsButton
  },

  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true,
      default: () => ''
    },
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    pickerType: {
      type: String,
      required: false,
      default: () => 'YEAR'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    allowedDates: {
      type: Function,
      required: false
    },
    min: {
      type: String,
      required: false,
      default: () => ''
    },
    max: {
      type: String,
      required: false,
      default: () => ''
    },
    eventColor: {
      type: [Array, Function, Object, String],
      required: false,
      default: () => ''
    },
    events: {
      type: Array,
      required: false,
      default: () => []
    },
    noTitle: {
      type: Boolean,
      required: false,
      default: () => false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
<style >
.week_start_sun
  .v-date-picker-table.v-date-picker-table--date
  > table
  > tbody
  tr
  td:nth-child(7)
  .theme--light.v-btn {
  color: rgba(33, 150, 243, 0.87);
  /* color: blue; */
}

.week_start_sun
  .v-date-picker-table.v-date-picker-table--date
  > table
  > tbody
  tr
  td:nth-child(1)
  .theme--light.v-btn {
  color: rgba(244, 67, 54, 0.87);
  /* color: red; */
}

</style>
