<template>
<v-row align="center" justify="center">
  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="ご連絡可能な時間帯" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <span>ご予約希望内容に関して店舗よりお電話でご相談させて頂く場合があります。</span>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsTextField
            name="time_to_contact"
            :value.sync="trimming_request.time_to_contact"
            :loading="isLoading || isProcessLoading"
            ref="time_to_contact"
          ></ComponentsItemsTextField>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="ご予約に関するご質問やご要望"></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsTextArea
            name="ask_note"
            :value.sync="trimming.ask_note"
            :loading="isLoading || isProcessLoading"
            rows="5"
            ref="ask_note"
          ></ComponentsItemsTextArea>
        </v-col>

      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

</v-row>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsTextArea from '@/components/items/TextArea'

export default {
  name: 'ComponentsFormsTrimmingApplyAdditional',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    error: false
  }),

  created () {
  },

  mounted () {
    this.isProcessLoading = false
  },

  methods: {

    isError: function () {
      return this.error
    },

    confirm: async function () {
      this.error = false
      const forms = [
        'time_to_contact',
        'ask_note'
      ]
      for (const name of forms) {
        if (this.$refs[name]) {
          await this.$refs[name].confirm()
        }
        if (this.$refs[name] && this.$refs[name].error) {
          this.error = true
        }
      }

      if (this.error) {
        return false
      }
      this.trimming_request.ask_note = this.trimming.ask_note
      return true
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading3,
    ComponentsItemsTextField,
    ComponentsItemsTextArea
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    trimming_request: {
      type: Object,
      require: true,
      default: () => {
        return {
          time_to_contact: '',
          ask_note: ''
        }
      }
    },
    trimming: {
      type: Object,
      require: true,
      default: () => {
        return {
          ask_note: '',
          trimming_date: '',
          trimming_str_time: '',
          trimming_end_time: ''
        }
      }
    }
  }
}
</script>
