<template>
<v-row align="center" justify="center">
  <v-col cols=12 class="py-0 text-left text-truncate">
    <span class="ec-font-size10" v-text="getValue(personals, 'name_kana1') + ' ' + getValue(personals, 'name_kana2')"></span>
  </v-col>
  <v-col cols=12 class="py-0 ec-font-size16 text-left text-truncate">
    <span class="font-weight-bold" v-text="getValue(personals, 'name1') + ' ' + getValue(personals, 'name2')"></span>
  </v-col>
  <v-col cols=12 class="pt-3 pb-0 text-left text-truncate">
    &#12306;<span v-text="getValue(users_attribute, 'post_no')"></span>
  </v-col>
  <v-col cols=12 class="py-0 text-left text-truncate">
    <span v-text="getPrefectureName(getValue(users_attribute, 'prefecture_id'))"></span><span v-text="getValue(users_attribute, 'address')"></span><span v-text="getValue(users_attribute, 'building')"></span>
  </v-col>
  <v-col cols=12 class="py-0 text-left text-truncate">
    <span v-text="(isArray(address_book) && address_book.length > 0) ? getValue(address_book[0], 'tel') : null"></span>
  </v-col>
</v-row>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsViewFormsShortPersonalsProfile',

  mixins: [MixinLib],

  components: {
  },

  props: {
    personals: {
      type: Object,
      required: false,
      default: () => {
        return {
          img_src: '',
          name1: '',
          name2: '',
          name_kana1: '',
          name_kana2: '',
          sex: '9',
          birth_date: ''
        }
      }
    },
    users_attribute: {
      type: Object,
      required: false,
      default: () => {
        return {
          country: '+81',
          post_no: '',
          prefecture_id: '',
          address: '',
          building: '',
          mail_address: ''
        }
      }
    },
    address_book: {
      type: Array,
      required: false,
      default: () => []
    }
  }

}
</script>
