<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">
    <v-col cols=12>
      <ComponentsLayoutHeading1 :text="(isUndefined(StorageStoresMemberShip)) ? '' : StorageStoresMemberShip.membership_name + ' アカウントをお持ちの方'"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" class="mx-auto py-3">
          <v-col cols=12>
            <ComponentsLayoutHeading3 :text="UserTypeName" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="account"
              custom-rules
              :rules="userTypeRule"
              :value.sync="account"
              :loading="isProcessLoading"
              ref="account"
            ></ComponentsItemsTextField>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="パスワード" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextFieldTypePassword
              name="password"
              custom-rules
              :rules="passwordRule"
              :value.sync="password"
              :loading="isProcessLoading"
              autocomplete="current-password"
              ref="password"
            ></ComponentsItemsTextFieldTypePassword>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsButton
              block
              :loading="isProcessLoading"
              text="ログインしてご予約に進む"
              @click="goLogin()"
            >
            </ComponentsItemsButton>
          </v-col>

          <v-col cols=12 class="pt-5 pb-1">
            <span>パスワードを忘れた方は、<a href="https://www.inugohan-madoguchi.com/password/forget" target="_blank">こちら</a></span>
          </v-col>
          <v-col cols=12 class="py-1">
            <span>店舗で会員登録し、パスワードが未設定の方は<a href="https://www.green-dog.com/shop/member" target="_blank">こちら</a></span>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12 v-show="isEnabledNewAssign">
      <ComponentsLayoutHeading1 text="アカウントをお持ちでない方"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 v-show="isEnabledNewAssign">
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" class="mx-auto py-3">
          <v-col cols=12>
            <ComponentsItemsButton
              block
              :loading="isProcessLoading"
              text="ご予約に進む"
              @click="goNext()"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12 v-show="!isEnabledNewAssign">
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" v-if="isEnabledNewContact">
          <v-col cols=12 class="pt-5">
            <ComponentsLayoutHeading3 text="はじめてのお客様へ"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <span>ご新規様のご予約は電話受付のみとさせていただいております。</span>
          </v-col>
          <v-col cols=12 class="text-center">
            <a :href="'tel:' + (isUndefined(StorageStoreContents)) ? '' : StorageStoreContents.stores_profile.store_tel" style="text-decoration: none;">
              <v-btn block :color="buttonColor" class="white--text" style="height: 55px;">
                <v-row align="center" justify="center">
                  <v-col cols=3>
                    <v-icon>mdi-phone</v-icon>
                  </v-col>
                  <v-col cols=9>
                    <v-row align="center" justify="center">
                      <v-col cols=12 class="pt-1 pb-0 px-0 ma-0 text-left" style="font-size: 1.1rem;">
                        <span v-text="(isUndefined(StorageStoreContents)) ? '' : StorageStoreContents.stores_profile.store_tel"></span>
                      </v-col>
                      <v-col cols=12 class="pt-1 pb-0 px-0 ma-0 text-left caption">
                        <span>代表番号</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-btn>
            </a>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" v-else>
          <v-col cols=12 class="pt-5">
            <ComponentsLayoutHeading3 text="はじめてのお客様へ"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <span>ご新規様のご予約は電話受付のみとさせていただいております。</span>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

  </v-row>
</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'
// import AuthLib from '@/libs/AuthLib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsTextFieldTypePassword from '@/components/items/TextFieldTypePassword'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingReserveMemberShipPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    account: '',
    password: ''
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    usersStores: {
      get () {
        return this.$store.getters['modStorage/usersStores']
      }
    },
    isRepeater: {
      get () {
        return (this.isUndefined(this.getValue(this.getValue(this.usersStores, 'stores_personals') || {}, 'last_usage_date')) === false)
      }
    },
    isEnabledNewAssign: {
      get () {
        return (this.isUndefined(this.StorageStoreServiceContents)) ? false : (this.StorageStoreServiceContents.stores_service_web_reserve.store_new_status === '1')
      }
    },
    isEnabledNewContact: {
      get () {
        return (this.isUndefined(this.StorageStoreServiceContents)) ? false : (this.StorageStoreServiceContents.stores_service_web_reserve.store_new_contact_status === '1')
      }
    },
    userTypeRule: {
      get () {
        return this.$store.getters['modStorage/userTypeRule']
      }
    },
    passwordRule: {
      get () {
        return this.$store.getters['modStorage/passwordRule']
      }
    },
    UserTypeName: {
      get () {
        if (this.StorageStoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_MAIL) {
          return 'メールアドレス'
        }
        if (this.StorageStoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_TEL) {
          return '電話番号'
        }
        if (this.StorageStoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_USER) {
          return 'アカウント'
        }
        return ''
      }
    }
  },

  mounted () {
    this.isProcessLoading = false
  },

  methods: {
    goLogin: function () {
      (async () => {
        if (this.$refs.account) {
          await this.$refs.account.confirm()
        }
        if (this.$refs.password) {
          await this.$refs.password.confirm()
        }
        if (this.$refs.account && this.$refs.account.error) {
          return false
        }
        if (this.$refs.password && this.$refs.password.error) {
          return false
        }
        this.isProcessLoading = true
        // LINE-IDをリンクさせて転送
        //
        // personal_id: String
        // fbn_personals: Object
        // personals: Object
        // users_authentication: Object
        // users_attribute: Object
        // address_book: Array
        // animals: Array

        const profiles = await APILib.getStoresPersonals(this.storeID, { authorization: Buffer.from(this.account + ':' + this.password).toString('base64') })
        this.$store.dispatch('modStorage/SetUsers', profiles)
        this.$store.dispatch('modStorage/SetUsersStores', profiles)
        this.$store.dispatch('modSession/SetMemberShip', this.$store.getters['modStorage/usersStores'])
        if (this.isRepeater) {
          return this.pageTransition('/gr-rsv/' + this.storeID + '/reserve')
        }
        if (this.isEnabledNewAssign) {
          return this.pageTransition('/gr-rsv/' + this.storeID + '/reserve')
        } else {
          return this.pageTransition('/gr-rsv/' + this.storeID + '/404')
        }
      })().catch((e) => {
        this.isProcessLoading = false
        if (e.message === 'NotAuthorizedException') {
          return this.$confirm({ title: 'Error', message: 'メールアドレス、または、パスワードをご確認ください', button: { yes: 'OK' } })
        }
        this.$confirm({ title: 'Error', message: e, button: { yes: 'OK' } })
      })
    },
    goNext: function () {
      return this.pageTransition('/gr-rsv/' + this.storeID + '/new-reserve')
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading3,
    ComponentsItemsTextField,
    ComponentsItemsTextFieldTypePassword,
    ComponentsItemsButton
  }
}
</script>
