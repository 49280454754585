<template>
  <ComponentsLayoutSection1>
    <v-row align="center" justify="center">
      <v-col cols=12 v-show="requiredVaccineImage(vaccine.vaccine_type_id)">
        <v-row align="center" justify="center">
          <v-col cols=12>
            <ComponentsLayoutHeading3 :text="getVaccineName(vaccine.vaccine_type_id) + '接種/猶予 証明書'" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <span>最新の証明書の写真を撮影してアップロード下さい。<br />猶予証明書をお持ちの方は猶予証明書をアップロードしてください</span>
          </v-col>
          <v-col cols=12 class="pb-5">
            <ComponentsItemsImageUpload
              name="vaccine_image"
              :src.sync="vaccine.img_src"
              :loading="loading"
              :required="requiredVaccineImage(vaccine.vaccine_type_id)"
              ref="vaccine_image"
            ></ComponentsItemsImageUpload>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 >
        <v-row align="center" justify="center">
          <v-col cols=12>
            <ComponentsLayoutHeading3 :text="getVaccineName(vaccine.vaccine_type_id) + '接種日/投薬日/猶予開始日'" :required="requiredVaccineDate(vaccine.vaccine_type_id)"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsDatePicker
              :name="(requiredVaccineDate(vaccine.vaccine_type_id)) ? 'action_date' : 'action_date_no_required'"
              :value.sync="vaccine.action_date"
              :loading="loading"
              ref="action_date"
            >
            </ComponentsItemsDatePicker>
            <!--
            <ComponentsItemsTextFieldCustomType
              name="action_date"
              type="date"
              :value.sync="vaccine.action_date"
              :loading="loading"
              ref="action_date"
            ></ComponentsItemsTextFieldCustomType>
            --->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 >
        <v-row align="center" justify="center">
          <v-col cols=12>
            <ComponentsLayoutHeading3 :text="getVaccineName(vaccine.vaccine_type_id) + '接種/投薬/猶予 期限日'" :required="requiredVaccineDate(vaccine.vaccine_type_id)"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsDatePicker
              :name="(requiredVaccineDate(vaccine.vaccine_type_id)) ? 'expire_date' : 'expire_date_no_required'"
              :value.sync="vaccine.expire_date"
              :loading="loading"
              ref="expire_date"
            >
            </ComponentsItemsDatePicker>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 v-show="requiredVaccineImage(vaccine.vaccine_type_id)">
        <v-row align="center" justify="center">
          <v-col cols=12 class="pb-5">
            <ComponentsItemsCheckBox
              name="action_type "
              :value.sync="vaccine.action_type"
              text="猶予証明書/抗体検査証明書"
              true-value="2"
              false-value="1"
              ref="action_type "
            ></ComponentsItemsCheckBox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </ComponentsLayoutSection1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsLayoutSection1 from '@/components/layout/Section1'

import ComponentsItemsImageUpload from '@/components/items/ImageUpload'
// import ComponentsItemsTextFieldCustomType from '@/components/items/TextFieldCustomType'
import ComponentsItemsCheckBox from '@/components/items/CheckBox'
import ComponentsItemsDatePicker from '@/components/items/DatePicker'

export default {
  name: 'ComponentsFormsVaccine',

  mixins: [MixinLib],

  data: () => ({
    isLoadingSpecies: false,
    error: false
  }),

  watch: {
    'vaccine.action_date' () {
      this.vaccine.expire_date = this.formatDate(this.addDateTime(this.vaccine.action_date, this.getVaccineExpireMonths(this.vaccine.vaccine_type_id), 'months'), 'YYYY-MM-DD')
    }
  },

  methods: {

    isError: function () {
      return this.error
    },

    confirm: async function () {
      this.error = false
      const forms = [
        'vaccine_image',
        'action_date',
        'expire_date'
      ]
      for (const name of forms) {
        if (this.$refs[name]) {
          await this.$refs[name].confirm()
        }
        if (this.$refs[name] && this.$refs[name].error) {
          this.error = true
        }
      }

      if (this.error) {
        return false
      }
      return true
    }
  },

  components: {
    ComponentsLayoutHeading3,
    ComponentsLayoutSection1,
    ComponentsItemsImageUpload,
    // ComponentsItemsTextFieldCustomType,
    ComponentsItemsDatePicker,
    ComponentsItemsCheckBox
  },

  props: {
    vaccine: {
      type: Object,
      required: false,
      default: () => {
        return {
          vaccine_type_id: '',
          img_src: '',
          action_date: '',
          expire_date: '',
          action_type: '1'
        }
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
