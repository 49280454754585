// import UtilLib from '@/libs/UtilLib'
import APILib from '@/libs/APILib'
import UtilLib from '@/libs/UtilLib'

const state = {
  weeks: {
    1: '月',
    2: '火',
    3: '水',
    4: '木',
    5: '金',
    6: '土',
    7: '日'
  },
  rules: {
    required: v => !!v || '必須項目です',
    regexNumeric: v => (/[0-9]/.test(v)) || '半角数字を1つ以上含めてください',
    regexAlphaUpper: v => (/[A-Z]/.test(v)) || '半角英字（大文字）を1つ以上含めてください',
    regexAlphaLower: v => (/[a-z]/.test(v)) || '半角英字（小文字）を1つ以上含めてください',
    regexSymbol: v => (/[-_@]/.test(v)) || '記号(-_@)を1つ以上含めてください',
    telMatch: v => (/^[0-9]+$/.test(v)) || '半角数字のみ利用可能です',
    emailMatch: v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || '形式が正しくありません'
  },
  Users: {},
  UsersStores: [],
  UsersAnimals: [],
  UsersAnimalsHide: [],
  // --------------------------------------------------------
  //
  // --------------------------------------------------------
  Reserve: [],
  WeightsDate: new Date().toISOString().substr(0, 10),
  WeightsYears: [],
  WeightsMonths: [],
  // --------------------------------------------------------
  //
  // --------------------------------------------------------
  StoreContents: {},
  StoreServiceContents: {},
  StoresGroomer: [],
  StoresGroomingCategories: [],
  StoresMemberShip: {},
  UserTypeRule: [],
  PasswordRule: [],
  StoresInterviewSheet: [],
  // --------------------------------------------------------
  //
  // --------------------------------------------------------
  AnimalType: [],
  Species: [],
  SexPerson: [],
  SexAnimal: [],
  Country: [],
  Allergy: [],
  VaccineType: [],
  Prefecture: [],
  StoreServices: []
}

const getters = {
  weeks: (state) => {
    return state.weeks
  },
  users: (state) => {
    return state.Users || {}
  },
  usersStores: (state) => {
    return state.UsersStores || []
  },
  animals: (state) => {
    return state.UsersAnimals || []
  },
  hideAnimals: (state) => {
    return state.UsersAnimalsHide || []
  },
  // --------------------------------------------------------
  //
  // --------------------------------------------------------
  reserve: (state) => {
    return state.Reserve || []
  },
  weightsDate: (state) => {
    return state.WeightsDate || new Date().toISOString().substr(0, 10)
  },
  weights: (state) => {
    return state.WeightsYears || []
  },
  weightsDaysList: (state) => {
    return state.WeightsMonths
  },
  // --------------------------------------------------------
  //
  // --------------------------------------------------------
  // stores
  // stores_outbound_links
  // stores_profile
  // stores_times
  storeContents: (state) => {
    return state.StoreContents || {}
  },
  // stores_service
  // stores_service_web_reserve
  // stores_service_web_reserve_times
  // stores_times
  storeServiceContents: (state) => {
    return state.StoreServiceContents || {}
  },
  storeServiceTimes: (state) => {
    return (state.StoreServiceContents.stores_service.store_service_time_status === '1') ? state.StoreServiceContents.stores_times : state.StoreContents.stores_times
  },
  storeReserveTimes: (state) => {
    return state.StoreServiceContents.stores_service_web_reserve_times || []
  },
  storeReserveSetting: (state) => {
    return state.StoreServiceContents.stores_service_web_reserve || {}
  },
  storesGroomer: (state) => {
    return state.StoresGroomer || []
  },
  storesGroomingCategories: (state) => {
    return state.StoresGroomingCategories || []
  },
  storesMemberShip: (state) => {
    return state.StoresMemberShip || {}
  },
  userTypeRule: (state) => {
    return state.UserTypeRule || []
  },
  passwordRule: (state) => {
    return state.PasswordRule || []
  },
  storesInterviewSheet: (state) => {
    return state.StoresInterviewSheet || []
  },
  // --------------------------------------------------------
  //
  // --------------------------------------------------------
  animalType: (state) => {
    return state.AnimalType
  },
  species: (state) => {
    return state.Species
  },
  sexPerson: (state) => {
    return state.SexPerson
  },
  sexAnimal: (state) => {
    return state.SexAnimal
  },
  country: (state) => {
    return state.Country
  },
  allergy: (state) => {
    return state.Allergy
  },
  vaccineType: (state) => {
    return state.VaccineType
  },
  prefecture: (state) => {
    return state.Prefecture
  },
  storeServices: (state) => {
    return state.StoreServices
  }
}

const mutations = {
  Users (state, contents) {
    state.Users = contents
  },
  UsersStores (state, contents) {
    state.UsersStores = contents
  },
  UsersAnimals (state, contents) {
    state.UsersAnimals = contents
  },
  UsersAnimalsHide (state, contents) {
    state.UsersAnimalsHide = contents
  },
  // --------------------------------------------------------
  //
  // --------------------------------------------------------
  Reserve (state, contents) {
    state.Reserve = contents
  },
  WeightsYears: (state, contents) => {
    state.WeightsYears = contents || []
  },
  WeightsMonths: (state, contents) => {
    const WeightsMonths = UtilLib.getValue(contents, 'personals_weight') || []
    const endDays = parseInt(UtilLib.getEndOfMonth(state.WeightsDate, 'DD'))
    state.WeightsMonths = [...Array(endDays)].map((cur, curIndex) => {
      const weightDate = UtilLib.formatDate(UtilLib.formatDate(state.WeightsDate, 'YYYY-MM') + '-' + ('00' + (curIndex + 1)).slice(-2), 'YYYY-MM-DD')
      const weightIndex = WeightsMonths.findIndex(v => v.weight_date === weightDate)
      return {
        weight_date: weightDate,
        weight: (weightIndex >= 0) ? WeightsMonths[weightIndex].weight : '',
        fluctuation: (weightIndex >= 0) ? WeightsMonths[weightIndex].fluctuation : ''
      }
    })
  },
  // --------------------------------------------------------
  //
  // --------------------------------------------------------
  StoreContents (state, contents) {
    state.StoreContents = contents
  },
  StoreServiceContents (state, contents) {
    state.StoreServiceContents = contents
  },
  StoresGroomer (state, contents) {
    state.StoresGroomer = contents
  },
  StoresGroomingCategories (state, contents) {
    state.StoresGroomingCategories = contents
  },
  StoresMemberShip (state, contents) {
    state.StoresMemberShip = contents
  },
  userTypeRule (state) {
    const minSize = (v) => {
      if (v.length >= state.StoresMemberShip.membership_username_size) {
        return true
      }
      return state.StoresMemberShip.membership_username_size + '文字以上にしてください'
    }
    // Eメール
    if (state.StoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_MAIL) {
      state.UserTypeRule = [state.rules.required, minSize, state.rules.emailMatch]
      return
    }
    // 電話番号
    if (state.StoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_TEL) {
      state.UserTypeRule = [state.rules.required, minSize, state.rules.telMatch]
      return
    }
    // 任意のユーザ名
    const arrRegExp = []
    if (state.StoresMemberShip.membership_username_policy & process.env.VUE_APP_MEMBERSHIP_USERNAME_POLICY_NUMERIC) {
      arrRegExp.push({ code: '0-9', name: '半角数字', regExp: state.rules.regexNumeric })
    }
    if (state.StoresMemberShip.membership_username_policy & process.env.VUE_APP_MEMBERSHIP_USERNAME_POLICY_SYMBOL) {
      arrRegExp.push({ code: '\\-_@', name: '記号(-_@)', regExp: state.rules.regexSymbol })
    }
    if (state.StoresMemberShip.membership_username_policy & process.env.VUE_APP_MEMBERSHIP_PASSWORD_POLICY_LOWER) {
      arrRegExp.push({ code: 'a-z', name: '半角英字（小文字）', regExp: state.rules.regexAlphaLower })
    }
    if (state.StoresMemberShip.membership_username_policy & process.env.VUE_APP_MEMBERSHIP_USERNAME_POLICY_UPPER) {
      arrRegExp.push({ code: 'A-Z', name: '半角英字（大文字）', regExp: state.rules.regexAlphaUpper })
    }
    const regExp = new RegExp('^[' + arrRegExp.map(v => v.code).join('') + ']+$')
    const regExpMsg = arrRegExp.map(v => v.name).join('/') + 'のみ利用可能です'

    const checkValue = (v) => {
      return (regExp.test(v)) ? true : regExpMsg
    }
    state.UserTypeRule = [state.rules.required, minSize, checkValue, ...arrRegExp.map(v => v.regExp)]
  },
  passwordRule (state) {
    const minSize = (v) => {
      if (v.length >= state.StoresMemberShip.membership_password_size) {
        return true
      }
      return state.StoresMemberShip.membership_password_size + '文字以上にしてください'
    }
    // パスワードポリシー
    const arrRegExp = []
    if (state.StoresMemberShip.membership_password_policy & process.env.VUE_APP_MEMBERSHIP_PASSWORD_POLICY_NUMERIC) {
      arrRegExp.push({ code: '0-9', name: '半角数字', regExp: state.rules.regexNumeric })
    }
    if (state.StoresMemberShip.membership_password_policy & process.env.VUE_APP_MEMBERSHIP_PASSWORD_POLICY_SYMBOL) {
      arrRegExp.push({ code: '\\-_@', name: '記号(-_@)', regExp: state.rules.regexSymbol })
    }
    if (state.StoresMemberShip.membership_password_policy & process.env.VUE_APP_MEMBERSHIP_PASSWORD_POLICY_LOWER) {
      arrRegExp.push({ code: 'a-z', name: '半角英字（小文字）', regExp: state.rules.regexAlphaLower })
    }
    if (state.StoresMemberShip.membership_password_policy & process.env.VUE_APP_MEMBERSHIP_PASSWORD_POLICY_UPPER) {
      arrRegExp.push({ code: 'A-Z', name: '半角英字（大文字）', regExp: state.rules.regexAlphaUpper })
    }
    if (arrRegExp.length === 0) {
      state.PasswordRule = [state.rules.required, minSize]
    } else {
      const regExp = new RegExp('^[' + arrRegExp.map(v => v.code).join('') + ']+$')
      const regExpMsg = arrRegExp.map(v => v.name).join('/') + 'のみ利用可能です'
      const checkValue = (v) => {
        return (regExp.test(v)) ? true : regExpMsg
      }
      state.PasswordRule = [state.rules.required, minSize, checkValue, ...arrRegExp.map(v => v.regExp)]
    }
  },
  StoresInterviewSheet (state, contents) {
    state.StoresInterviewSheet = contents || []
  },
  // --------------------------------------------------------
  //
  // --------------------------------------------------------

  AnimalType (state, AnimalType) {
    state.AnimalType = AnimalType
  },
  Species (state, Species) {
    state.Species = Species
  },
  SexPerson (state, SexPerson) {
    state.SexPerson = SexPerson
  },
  SexAnimal (state, SexAnimal) {
    state.SexAnimal = SexAnimal
  },
  Country (state, Country) {
    state.Country = Country
  },
  Allergy (state, Allergy) {
    state.Allergy = Allergy
  },
  VaccineType (state, VaccineType) {
    state.VaccineType = VaccineType
  },
  Prefecture (state, Prefecture) {
    state.Prefecture = Prefecture
  },
  StoreServices (state, StoreServices) {
    state.StoreServices = StoreServices
  }
}

const actions = {
  LoadUsers ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        // personal_id: String
        // personals: Object
        // users_authentication: Object
        // users_attribute: Object
        // address_book: Array
        const contents = await APILib.getUsers()
        dispatch('SetUsers', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  SetUsers ({ commit, dispatch, state }, contents) {
    commit('Users', contents)
  },
  LoadUsersStores ({ commit, dispatch, state }, storesID) {
    return new Promise((resolve, reject) => {
      (async () => {
        // personal_id: String
        // fbn_personals: Object
        // personals: Object
        // users_authentication: Object
        // users_attribute: Object
        // address_book: Array
        // animals: Array
        const contents = await APILib.getUsersStores(storesID)
        dispatch('SetUsersStores', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  SetUsersStores ({ commit, dispatch, state }, contents) {
    commit('UsersStores', contents)
  },
  LoadAnimals ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('UsersAnimals', await APILib.getUsersAnimalsShow())
        commit('UsersAnimalsHide', await APILib.getUsersAnimalsHide())
        resolve(true)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  // --------------------------------------------------------
  //
  // --------------------------------------------------------
  LoadReserve ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        const contents = await APILib.getUsersTrimmingOrder()
        commit('Reserve', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadWeight: ({ commit, dispatch, state }, [personalId, WeightsDate]) => {
    return new Promise((resolve, reject) => {
      (async () => {
        state.WeightsDate = WeightsDate
        if (UtilLib.isUndefined(personalId)) {
          commit('Weights', [])
          resolve(false)
        } else {
          commit('WeightsYears', await APILib.getAnimalsWeight(personalId, UtilLib.formatDate(WeightsDate, 'YYYY')))
          commit('WeightsMonths', await APILib.getAnimalsWeight(personalId, UtilLib.formatDate(WeightsDate, 'YYYY-MM')))
          resolve(true)
        }
      })().catch((e) => {
        reject(e)
      })
    })
  },

  // --------------------------------------------------------
  //
  // --------------------------------------------------------
  LoadStoresContents ({ commit, dispatch, state }, storeID) {
    return new Promise((resolve, reject) => {
      (async () => {
        const [
          $StoreContents,
          $StoreServiceContents,
          $StoresGroomer,
          $StoresGroomingCategories,
          $StoresMemberShip,
          $StoresInterviewSheet
        ] = await Promise.all([
          APILib.getStores(storeID),
          APILib.getStoresService(storeID),
          APILib.getStoresGroomingGroomer(storeID),
          APILib.getStoresGroomingCategories(storeID),
          APILib.getStoresMemberShip(storeID),
          APILib.getStoresInterviewSheet(storeID)
        ])
        commit('StoreContents', $StoreContents)
        commit('StoreServiceContents', $StoreServiceContents)
        commit('StoresGroomer', $StoresGroomer)
        commit('StoresGroomingCategories', $StoresGroomingCategories)
        commit('StoresMemberShip', $StoresMemberShip)
        commit('userTypeRule')
        commit('passwordRule')
        commit('StoresInterviewSheet', $StoresInterviewSheet)
        resolve(true)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  // --------------------------------------------------------
  //
  // --------------------------------------------------------
  LoadMaster ({ commit, dispatch, state }) {
    return Promise.all([
      dispatch('LoadAnimalType'),
      dispatch('LoadSexPerson'),
      dispatch('LoadSexAnimal'),
      dispatch('LoadCountry'),
      // dispatch('LoadAllergy'),
      dispatch('LoadVaccineType'),
      dispatch('LoadPrefecture'),
      dispatch('LoadStoreServices')
    ])
  },
  LoadAnimalType ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('AnimalType', await APILib.getComponentAnimalType())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadSpecies ({ commit, dispatch, state }, animal) {
    return new Promise((resolve, reject) => {
      (async () => {
        if (UtilLib.isUndefined(animal)) {
          commit('Species', [])
        } else {
          commit('Species', await APILib.getComponentSpecies(animal))
        }
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadSexPerson ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('SexPerson', await APILib.getComponentSexPerson())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadSexAnimal ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('SexAnimal', await APILib.getComponentSexAnimal())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadCountry ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('Country', await APILib.getComponentCountry())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadAllergy ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('Allergy', await APILib.getComponentAllergy())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadVaccineType ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('VaccineType', await APILib.getComponentVaccineType())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadPrefecture ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('Prefecture', await APILib.getComponentPrefecture())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadStoreServices ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('StoreServices', await APILib.getComponentStoreServices())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
