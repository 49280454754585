<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="お知らせ一覧"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/console/index')"
>
</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'

export default {
  name: 'ConsoleNewsPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  methods: {
  },

  components: {
    ComponentsLayoutPage2
  }
}
</script>
