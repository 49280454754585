<template>
<v-bottom-sheet persistent scrollable v-model="model" v-if="model">
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutHeading1 text="トリミング履歴" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }" v-if="!isProcessLoading">

        <v-col cols=12>
          <ComponentsLayoutSection1>
            <v-row align="center" justify="center" v-if="list.length > 0">
              <v-col cols=12>
                <v-list flat dense nav>
                  <template v-for="(item, i) in list">

                    <v-divider v-if="i > 0" :key="'d' + i" class="py-1"></v-divider>

                    <v-list-item :key="'i' + i" @click="openContents(item)">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-text="getDate(item.trimming.trimming_date + ' ' + item.trimming.trimming_str_time, 'YYYY年MM月DD日(ddd) HH:mm') + ' -'"></span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-text="item.store.store_name"></span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <ComponentsItemsButton
                          icon-btn
                          icon="mdi-chevron-right"
                          :loading="isLoading || isProcessLoading"
                        >
                        </ComponentsItemsButton>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-else>
              <v-col cols=12>
                <span>トリミングのご利用履歴はありません</span>
              </v-col>
            </v-row>
          </ComponentsLayoutSection1>
        </v-col>

      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            text-btn
            text="閉じる"
            :loading="isProcessLoading"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>

  <ComponentsBottomSheetTrimmingHistorySheet
    :model="bottomSheet"
    :personal-id="personalId"
    :trimming-id="edit.trimmingID"
    :stores="edit.store"
    @close-click="bottomSheet = false"
    v-if="bottomSheet"
  >
  </ComponentsBottomSheetTrimmingHistorySheet>

</v-bottom-sheet>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsBottomSheetTrimmingHistorySheet from '@/components/bottom-sheet/TrimmingHistorySheet'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsBottomSheetTrimmingHistory',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    list: [],
    bottomSheet: false,
    edit: {
      trimmingID: '',
      store: {}
    }
  }),

  watch: {
    model () {
      if (this.model) {
        this.goLoading()
      }
    }
  },

  computed: {
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.list = []
        if (this.isUndefined(this.personalId)) {
          return false
        }
        this.isProcessLoading = true
        this.list = await APILib.getAnimalsTrimmingHistory(this.personalId)
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    openContents: function (item) {
      this.edit.trimmingID = item.trimming_id
      this.edit.store = item.store
      this.bottomSheet = true
    }
  },

  beforeDestroy () {
    this.isProcessLoading = true
    this.list = []
    this.bottomSheet = false
    this.edit = {
      trimmingID: '',
      store: {}
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsBottomSheetTrimmingHistorySheet,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    },
    personalId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
