<template>
<v-bottom-sheet persistent scrollable v-model="model">
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutHeading1 text="ペトコネ 利用規約" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <pre class="ec-font-size12">
第1条 本利用規約について

  1.  「ペトコネ 利用規約」（以下「本利用規約」といいます。）は、株式会社カラーズ（以下、「当社」といいます。）が「ペトコネ」のサービス名称において提供するスマートフォン向け情報管理・情報共有サービス（以下、「本サービス」といいます。）の利用条件を定めるものです。本サービスをご利用される場合には、本利用規約に同意したものとみなします。
  2.  当社は、当社が相当の事由があると判断した場合、利用者から事前の承諾を得ることなく、変更の１か月前までに本サービス上及び当社ホームページにおいて本利用規約を変更する旨及び変更内容並びに変更時期を告知することを以って、本利用規約をいつでも変更することができるものとします。
  3.  変更後の本利用規約は、当社が別途定める場合を除いて、本サービス上に変更時期として表示した時点より効力を生じるものとします。また、本利用規約の変更の効力が生じた後、利用者が本サービスをご利用された場合、変更後の本利用規約に同意したものとみなします。
  4.  法令等により本利用規約の一部の規定の全部または一部を無効または取り消すと判断された場合であっても、当該規定の無効部分以外の部分および本利用規約のその他の規定は有効とします。
  5.  本利用規約の一部の規定の全部または一部が特定の利用者との間で無効とされ、または取り消された場合でも、本利用規約はその他の利用者との関係では有効とします。
  6.  本利用規約に示される当社の権利を行使または実施しない場合でも、当社が当該権利を放棄するものではありません。

第2条 定義

  1.  「利用者」とは、本サービスを利用する全ての個人をいいます。
  2.  「利用者情報」とは、利用者が本サービスを利用するために登録した利用者の個人情報及び利用者の飼育している動物の名前、生年月日、品種等の情報をいいます。
  3.  「個人情報」とは、個人情報の保護に関する法律第2条第1項に定める個人情報をいいます。
  4.  「利用者コンテンツ情報」とは、利用者が本サービスを利用する過程で本サービスに送信する文章・写真・情報その他コンテンツをいいます。
  5.  「ペット関連サービス提供者」とは、ペットサロンや動物病院等のペット飼育者向けのサービスを提供している事業者をいいます。
  6.  「ペット関連サービス」とは、本サービスのうち、本サービスとペット関連サービス提供者との連携を目的としたサービスをいいます。
  7.  「登録者」とは、利用者のうち、ペット関連サービスの利用を登録した個人をいいます。

第3条 利用登録

  1.  利用者は、本サービスの利用を希望する場合、本利用規約に同意のうえ、当社が定める方法により利用者情報を登録するものとします。
  2.  当社は、利用者による利用者情報の登録をもって、利用者が本利用規約の全ての条項に同意したものとみなします。
  3.  本サービスの利用が可能な状態となったことをもって、利用者の本サービス利用に対する当社の承諾の意思表示があったものとみなします。なお、次のいずれかに該当する場合には、当社の判断によって利用を承諾しないことがあります。
    ① 利用登録の申請に際して虚偽の事項を届け出た場合
    ② 本規約に違反したことがある者からの申請である場合
    ③ その他、当社が利用登録を相当でないと判断した場合

第4条 本サービスの利用終了

  1.  利用者は、いつでも当社が定める方法により本サービスの利用終了を届け出ることにより、本サービスの利用を終了することができます。
  2.  当社は、当社が相当の事由があると判断した場合、本サービス上及び当社ホームページにおいて事前に告知することを以って、いつでも本サービスの提供を終了することができます。

第5条 本サービスの提供の停止等

  1.  当社は、以下のいずれかの事由があると判断した場合、利用者に事前に通知することなく本サービスの全部または一部の提供を停止または中断することがあります。
    ① 本サービスを提供するための通信設備等の定期的な保守点検を行う場合または点検を緊急に行う場合
    ② 地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
    ③ コンピュータまたは通信回線等が事故により停止した場合
    ④ その他、運用上または技術上、当社が本サービスの提供の一時的な中断を必要と判断した場合
  2.  当社は、本サービスの提供の停止または中断により、利用者または第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。

第6条 ユーザーIDおよびパスワードの管理

  1.  利用者は、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
  2.  利用者は、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録している利用者自身による利用とみなします。
  3.  ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。

第7条 利用者情報 の変更

  1.  利用者は利用者情報に変更があった場合、すみやかに本サービス所定の方法により変更するものとします。この手続きがない場合、当社は利用者情報の変更がないものとして取り扱います。
  2.  利用者情報の変更がないために、当社またはペット関連サービス提供者からの通知、連絡その他サービス提供が遅延し、または不着、不履行であった場合、当社およびペット関連サービス提供者はその責任を負わないものとします。

第8条 譲渡禁止等

  1.  利用者は、本サービスを利用する権利を第三者へ貸借、譲渡、売買、名義変更し、または質権の設定その他の担保に供する等の行為ができないものとします。ただし、当社が事前に書面で承諾した場合はこの限りではありません

第9条 個人情報の取り扱い

  1.  当社は、利用者情報および利用者コンテンツ情報を、次の利用目的の範囲内で利用します。
    ① 本サービスを提供するため
    ② 本サービスに関するお知らせや利用者のお問い合わせ対応その他利用者との間で連絡をするため
    ③ キャンペーンやアンケートを実施するため
    ④ 過去に本利用規約に違反した方や不正・不当な目的でサービスを利用しようとされる方の利用を制限するため
    ⑤ 本サービスの改善、研究発表または広告・広報のための統計的情報を解析するため
  2.  当社は、利用者情報および利用者コンテンツ情報を、前一項の利用目的達成のため登録者が登録したペット関連サービス提供者に提供するものとし、利用者はかかる提供につきあらかじめ同意するものとします。
  3.  当社は、本利用規約に別段の定めがある場合を除き、利用者の同意を得ない限り、第三者に個人情報を提供しないものとします。但し、次のいずれかに該当する場合は、この限りではありません。
    ① 法令に基づく場合
    ② 人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
    ③ 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
    ④ 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき

  4.  前各号のほか、取得した個人情報は、当社が別途定める「プライバシーポリシー」に基づき、適切に取り扱うものとします。

第10条 利用環境の整備

  1.  利用者は、本サービスを利用するために必要な通信機器、ソフトウェアその他これらに付随して必要となる全ての機器および利用環境を、自己の費用と責任において準備し、利用可能な状態に置くものとします。また、本サービスのご利用にあたっては、自己の費用と責任において、利用者が任意に選択した電気通信サービスまたは電気通信回線を経由してインターネットに接続するものとします。
  2.  利用者は、関係官庁等が提供する情報を参考にして、自己の利用環境に応じ、コンピュータ・ウィルスの感染、不正アクセスおよび情報漏洩の防止等のため、セキュリティ環境を構築・維持するものとします。
  3.  当社は、利用者の機器および利用環境について一切関与せず、また一切の責任を負いません。

第11条 自己責任の原則

  1.  利用者は、利用者自身の責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為およびその結果についてその責任を負うものとします。
  2.  利用者は、本サービスのご利用に際し、他の利用者その他の第三者、ペット関連サービス提供者および当社に損害または不利益を与えた場合、自己の責任と費用においてこれを賠償、解決するものとします。

第12条 通知または連絡

  1.  利用者と当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時に利用者へ到達したものとみなします。

第13条 知的財産権等

  1.  利用者は、自ら著作権等の必要な知的財産権を有するか、または必要な権利者の許諾を得た文章、画像や映像等の情報に関してのみ、本サービスを利用し、投稿ないしアップロードすることができるものとします。
  2.  利用者が本サービスを利用して投稿ないしアップロードした文章、画像、映像等の著作権については、当該利用者その他既存の権利者に留保されるものとします。ただし、当社は、本サービスを利用して投稿ないしアップロードされた文章、画像、映像等について、本サービスの提供ならびに本サービスの周知宣伝等に必要な範囲において無償で利用できるものとします。なお、当社はかかる利用にあたり必要な範囲で改変することができるものとし、利用者は、この改変に関して、著作者人格権を行使しないものとします。

第14条 当社の知的財産権

  1.  利用者コンテンツ情報を除き、本サービスまたは本サービスにおいて表示される広告中 に含まれる一切のコンテンツおよび情報に関する著作権、商標権、その他の知的財産権（知的財産を受ける権利を含む）は全て当社または当社にその利用を許諾した権利者に帰属し、利用者は無断で複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可能かを含みます。）、伝送、配布、出版、営業使用等をしてはならないものとします。
  2.  本サービスまたは本サービスにおいて表示される広告中に含まれる一切のコンテンツおよび情報は、著作権法、商標法、意匠法等により保護されております。
  3.  本サービスおよび本サービスに関連して使用されているすべてのソフトウェアは、知的財産権に関する法令等により保護されている諸権利および営業秘密を含んでおります。

第15条 禁止事項

  利用者は、本サービスの利用にあたり、以下の行為をしてはなりません。
  1.  法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為。
  2.  公の秩序または善良の風俗を害するおそれのある行為。
  3.  当社または第三者の著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上または契約上の権利を侵害する行為。
  4.  過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を、投稿または送信する行為。
  5.  当社または第三者になりすます行為または意図的に虚偽の情報を流布させる行為。
  6.  当社が定める方法以外の方法で、本コンテンツの利用権を、現金、財物その他の経済上の利益と交換する行為。
  7.  本サービスが予定している利用目的と異なる目的で本サービスを利用する行為。
  8.  反社会的勢力に対する利益供与その他の協力行為
  9.  宗教活動または宗教団体への勧誘行為
  10. 他人の個人情報、登録情報、利用履歴情報などを、不正に収集、開示または提供する行為
  11. 本サービスのサーバやネットワークシステムに支障を与える行為、BOT、チートツール、その他の技術的手段を利用してサービスを不正に操作する行為、本サービスの不具合を意図的に利用する行為、当社に対し不当な問い合わせまたは要求をする行為、その他当社による本サービスの運営または他のお客様による本サービスの利用を妨害し、これらに支障を与える行為
  12. 上記1.から11. のいずれかに該当する行為を援助または助長する行為
  13. その他、当社が不適当と判断し、利用者に通知した行為

第16条 利用制限

  1.  当社は、利用者が以下のいずれかに該当する場合には、事前の通知なく、利用者に対して本サービスの全部もしくは一部の利用を制限しまたは利用者としての登録を抹消することができるものとします。
    ① 本規約のいずれかの条項に違反した場合
    ② 登録事項に虚偽の事実があることが判明した場合
    ③ 当社からの連絡に対し、一定期間返答がない場合
    ④ 本サービスについて、最終の利用から一定期間利用がない場合
    ⑤ その他、当社が本サービスの利用を適当でないと判断した場合
  2.  当社は、本条に基づき当社が行った行為により利用者に生じた損害について、一切の責任を負いません。

第17条 本サービスの変更・停止

  1.  当社は、利用者に通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによって利用者に生じた損害について一切の責任を負いません。

第18条 保証の否認および免責事項

  1.  当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
  2.  当社は、本サービスに起因して利用者に生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する当社と利用者との間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
  3.  前項ただし書に定める場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為により利用者に生じた損害のうち特別な事情から生じた損害（当社または利用者が損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、当社の過失（重過失を除きます。）による債務不履行または不法行為により利用者に生じた損害の賠償は、利用者から当該損害が発生した月に受領した利用料の額を上限とします。
  4.  当社は、本サービスに関して、利用者と他の利用者またはペット関連サービス提供者または第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。

第19条 準拠法

  1.  本規約の解釈にあたっては、日本法を準拠法とします。

第20条 裁判管轄

  1.  本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を第一審の専属的合意管轄とします。
          </pre>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            text-btn
            text="閉じる"
            :loading="isProcessLoading"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-bottom-sheet>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsBottomSheetTOS',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  components: {
    ComponentsLayoutHeading1,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    }
  }
}
</script>
