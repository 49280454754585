<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="パスワード変更"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/console/index')"
>
<v-row align="center" justify="center">

  <v-col cols=12>
    <span>ペトコネのウェブサイトログイン用アカウントを設定するメニューです</span>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection1>
        <v-row align="center" justify="center">
          <v-col cols=12>
            <ComponentsLayoutHeading3 text="アカウント" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12 v-if="isUndefined(contents)">
            <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
          </v-col>
          <v-col cols=12 v-else>
            <ComponentsItemsTextField
              name="account"
              :value.sync="contents.users_authentication.personal_account"
              :loading="isLoading || isProcessLoading"
              ref="account"
            ></ComponentsItemsTextField>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="パスワード" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12 v-if="isUndefined(contents)">
            <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
          </v-col>
          <v-col cols=12 v-else>
            <ComponentsItemsTextFieldTypePassword
              name="password"
              :value.sync="contents.users_authentication.personal_password"
              :loading="isLoading || isProcessLoading"
              autocomplete="current-password"
              ref="password"
            ></ComponentsItemsTextFieldTypePassword>
          </v-col>

          <v-col cols=12 >
            <ComponentsLayoutHeading3 text="パスワード(確認)" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12 v-if="isUndefined(contents)">
            <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
          </v-col>
          <v-col cols=12 v-else>
            <ComponentsItemsTextFieldTypePassword
              name="password"
              :value.sync="password"
              :loading="isLoading || isProcessLoading"
              ref="re_password"
            ></ComponentsItemsTextFieldTypePassword>
          </v-col>
        </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12>
    <ComponentsItemsButton
      block
      :loading="isLoading || isProcessLoading"
      text="更新する"
      @click="change()"
    >
    </ComponentsItemsButton>
  </v-col>

</v-row>
</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsTextFieldTypePassword from '@/components/items/TextFieldTypePassword'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleResetPasswordPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    password: '',
    error: false,
    contents: {}
  }),

  computed: {
    users: {
      get () {
        return this.$store.getters['modStorage/users']
      }
    }
  },

  mounted () {
    // this.contents = this.$store.getters['modStorage/users']
    this.contents = this.clone(this.users)
    this.isProcessLoading = false
  },

  methods: {
    isError: function () {
      return this.error
    },

    confirm: async function () {
      this.error = false
      const forms = [
        'account',
        'password',
        're_password'
      ]
      for (const name of forms) {
        if (this.$refs[name]) {
          await this.$refs[name].confirm()
          if (this.$refs[name].error) {
            this.error = true
          }
        }
      }
      if (this.error) {
        return false
      }
      if (this.contents.users_authentication.personal_password !== this.password) {
        this.$confirm({ title: 'Error', message: 'パスワードが一致していません', button: { yes: 'OK' } })
        this.error = true
        return false
      }

      if (this.isUndefined(this.users.users_authentication.personal_account)) {
        if (!this.isUndefined(this.contents.users_authentication.personal_account)) {
          if (await APILib.getOAuthSignInCheck(this.contents.users_authentication.personal_account)) {
            this.$confirm({ title: 'Error', message: '既に登録されているアカウントです', button: { yes: 'OK' } })
            this.error = true
            return false
          }
        } else {
          this.$confirm({ title: 'Error', message: 'アカウントを入力してください', button: { yes: 'OK' } })
          this.error = true
          return false
        }
      }

      return true
    },

    change: function () {
      (async () => {
        this.error = false
        await this.confirm()
        if (this.error) {
          return false
        }
        this.isProcessLoading = true
        await APILib.putUsers(this.contents)
        await this.$store.dispatch('modStorage/LoadUsers')
        this.$confirm({ title: 'Success', message: 'アカウント・パスワードを設定しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage2,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading3,
    ComponentsItemsTextField,
    ComponentsItemsTextFieldTypePassword,
    ComponentsItemsButton
  }
}
</script>
