<template>
<ComponentsLayoutPage2
  header
  footer
  :loading="isProcessLoading"
  header-title="認証番号の入力"
  header-left
  header-left-icon='mdi-chevron-left'
  header-left-text='戻る'
  @left-click="pageTransition('/forget-password/authentication')"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px', paddingTop: '30px' }">

    <v-col cols=12>
      <span>認証番号をご入力ください</span>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" class="mx-auto py-3">
          <v-col cols=12>
            <v-otp-input
              :length="6"
              :disabled="isProcessLoading"
              v-model="verifyCode"
              @finish="onFinish"
              :style="{ maxWidth: '300px' }"
              class="mx-auto"
            ></v-otp-input>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12 class="text-center">
      <!--
      <ComponentsItemsButton
        block
        text-btn
        :loading="isProcessLoading"
        text="認証番号を再送する"
        @click="ReSend()"
      >
      </ComponentsItemsButton>
      --->
      <ComponentsItemsButton
        block
        text-btn
        :loading="isProcessLoading"
        text="戻る"
        @click="pageTransition('/forget-password/authentication')"
      >
      </ComponentsItemsButton>
    </v-col>

  </v-row>
</ComponentsLayoutPage2>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsItemsButton from '@/components/items/Button'

export default {

  name: 'ForgetPasswordAuthenticationVerifyPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    verifyCode: ''
  }),

  computed: {
    requestCode: {
      get () {
        return this.$route.query.id
      }
    }
  },

  mounted () {
    this.isProcessLoading = false
  },

  methods: {
    onFinish: function () {
      (async () => {
        this.isProcessLoading = true
        await APILib.putOAuthPasswordReset(this.requestCode, this.verifyCode)
        this.pageTransition('/forget-password/reset?id=' + this.requestCode + '&code=' + this.verifyCode)
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: '認証番号をご確認ください', button: { yes: 'OK' } })
      })
    },
    ReSend: function () {
      (async () => {
        this.isProcessLoading = true
        // await API.postAuthPasswordReset(this.account)
        this.$confirm({ title: 'Confirm', message: '認証番号を再送しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage2,
    ComponentsLayoutSection1,
    ComponentsItemsButton
  }
}
</script>
