<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="利用規約"
  :header-left="(!isInLineClient)"
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/')"
>
  <v-row align="center" justify="center" class="mx-auto pt-5" style="max-width: 820px;">
    <v-col cols=12>
      <ComponentsFormsApplicationConfirm
        @click="actionNext()"
      ></ComponentsFormsApplicationConfirm>
    </v-col>
  </v-row>
</ComponentsLayoutPage2>

</template>

<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsFormsApplicationConfirm from '@/components/forms/ApplicationConfirm'

export default {
  name: 'SignUpConfirmPage',

  mixins: [MixinLib],

  mounted: function () {
  },

  methods: {
    actionNext: function () {
      (async () => {
        if (this.isInLineClient) {
          if (this.exists(this.$route.query, 'store')) {
            // 店舗のLINEアカウントMENU経由
            return this.goTransition('/stores/' + this.$route.query.store + '/sign-in', (this.exists(this.$route.query, 'page')) ? { page: this.$route.query.page } : {})
          } else {
            // 自動作成をして遷移（導線はないが一応用意）
            await APILib.postOAuthSignUp({ personals: {}, users_authentication: {}, users_attribute: {}, address_book: [] })
            return this.SignInLoading((this.isUndefined(this.$route.query.page)) ? '/console/index' : this.$route.query.page)
          }
        } else {
          // ウェブサイト経由
          this.pageTransition('/sign-up/register')
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },
  components: {
    ComponentsLayoutPage2,
    ComponentsFormsApplicationConfirm
  }
}
</script>
