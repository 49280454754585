<template>
<v-row align="center" justify="center" class="pb-2">
  <v-col cols=12 class="py-1 text-left" v-for="item in items" :key="item[itemValue]">
    <v-checkbox
      dense
      :value="item[itemValue]"
      :label="item[itemText]"
      class="mt-0 mr-0"
      :color="buttonColor"
      :input-value="value"
      :loading="loading"
      :hide-details="!error"
      :rules="validate"
      :error-messages="errors"
      :placeholder="placeholder"
      :disabled="(disabled == true)"
      @change="updateValue"
    >
      <template v-slot:label>
        <span :style="{ color: (isUndefined(textColor)) ? buttonColor : textColor }" class="ec-font-size12" v-text="item[itemText]"></span>
      </template>
    </v-checkbox>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import ValidationLib from '@/libs/ValidationLib'

export default {
  name: 'ComponentsItemsCheckBoxGroup',

  mixins: [MixinLib],

  data () {
    return {
      validate: [],
      placeholder: '',
      error: false,
      errors: ''
    }
  },

  computed: {
  },

  created: function () {
    this.validate = ValidationLib.rules(this.name)
    this.placeholder = ValidationLib.placeholder(this.name)
  },

  methods: {
    updateValue: function (e) {
      this.$emit('update:value', e)
    },

    isError: function () {
      return (this.errors.length > 0)
    },

    confirm: async function () {
      return new Promise((resolve, reject) => {
        try {
          this.errors = ''
          for (var y = 0; y < this.validate.length; y++) {
            const err = this.validate[y](this.value)
            if (err !== true) {
              this.errors = err
              break
            }
          }
          if (this.isError()) {
            this.error = true
            return resolve(false)
          }
          this.error = false
          return resolve(true)
        } catch (e) {
          return reject(e)
        }
      })
    }
  },

  props: {
    name: {
      type: String,
      required: true
    },
    textColor: {
      type: String,
      required: true,
      default: () => ''
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    itemText: {
      type: String,
      required: false,
      default: () => 'code'
    },
    itemValue: {
      type: String,
      required: false,
      default: () => 'name'
    },
    value: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: [String, Boolean],
      required: true
    },
    disabled: {
      type: [String, Boolean],
      required: false,
      default: () => false
    }
  }
}
</script>
