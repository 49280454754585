<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP1. 予約申請希望選択" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/reserve/1')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 ref="TOP">
      <ComponentsLayoutHeading1 text="STEP2. コース＆指名条件"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading2 text="ご予約希望のコースと担当者を選択してください。"></ComponentsLayoutHeading2>
    </v-col>

    <v-col cols=12 v-for="(item, index) in animals" :key="index">
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" class="pb-3">
          <v-col cols=12>
            <ComponentsViewFormsShortAnimalProfile
              :personals="item.personals"
            >
            </ComponentsViewFormsShortAnimalProfile>
          </v-col>
          <v-col cols=12>
            <v-divider></v-divider>
          </v-col>
          <v-col cols=12>
            <ComponentsLayoutSection2>
              <v-row align="center" justify="center">
                <v-col cols=4 >
                  <ComponentsLayoutHeading3 text="希望コース" class="pb-3"></ComponentsLayoutHeading3>
                </v-col>
                <v-col cols=5 class="text-truncate text-left">
                  <span v-text="getCategoriesName(commonRequest[index].courses)"></span>
                </v-col>
                <v-col cols=3 class="text-right">
                  <ComponentsItemsButton
                    outlined
                    small
                    text="選択"
                    :loading="isLoading || isProcessLoading"
                    @click="onCourseSelect(index)"
                  >
                  </ComponentsItemsButton>
                </v-col>
                <v-col cols=4 class="pb-5">
                  <ComponentsLayoutHeading3 text="指名" ></ComponentsLayoutHeading3>
                </v-col>
                <v-col cols=5 class="text-truncate text-left">
                  <span v-text="getGroomerName(commonRequest[index].assign)"></span>
                </v-col>
                <v-col cols=3 class="text-right">
                  <ComponentsItemsButton
                    outlined
                    small
                    text="選択"
                    :loading="isLoading || isProcessLoading"
                    @click="onTrimmerSelect(index)"
                  >
                  </ComponentsItemsButton>
                </v-col>
              </v-row>
            </ComponentsLayoutSection2>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12>
      <ComponentsItemsButton
        block
        :loading="isProcessLoading"
        text="次へ"
        @click="onNext()"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP3. ご予約希望日時の設定"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP4. お客様情報の確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP5. カルテ情報の確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP6. 申請内容の最終確認"></ComponentsLayoutHeading1>
    </v-col>
  </v-row>

  <!-- 担当者の指名 -->
  <ComponentsBottomSheetSelectTrimmer
    :model="model.trimmer"
    :values="edit.assign"
    @on-setting-click="onSetTrimmer"
    @close-click="model.trimmer = false"
    v-if="model.trimmer"
  >
  </ComponentsBottomSheetSelectTrimmer>

  <!-- コース選択 -->
  <ComponentsBottomSheetSelectCourse
    :model="model.course"
    :values="edit.course"
    @on-setting-click="commonRequest[edit.index].courses = $event, model.course = false"
    @close-click="model.course = false"
    v-if="model.course"
  >
  </ComponentsBottomSheetSelectCourse>
</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsViewFormsShortAnimalProfile from '@/components/view-forms/ShortAnimalProfile'

import ComponentsBottomSheetSelectTrimmer from '@/components/bottom-sheet/SelectTrimmer'
import ComponentsBottomSheetSelectCourse from '@/components/bottom-sheet/SelectCourse'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingReserveTrimmingMemberStep2Page',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    model: {
      trimmer: false,
      course: false
    },
    edit: {
      index: 0,
      assign: '',
      course: ''
    }
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    StorageGroomer: {
      get () {
        return this.$store.getters['modStorage/storesGroomer']
      }
    },
    StorageCategories: {
      get () {
        return this.$store.getters['modStorage/storesGroomingCategories']
      }
    },
    groomer: {
      get () {
        return this.StorageGroomer.map(v => { return { name: v.business_users.name, code: v.business_users_id } })
      }
    },
    categories: {
      get () {
        return this.StorageCategories.map(v => { return { name: v.item_categories.item_category_name, code: v.item_category_id } })
      }
    },
    commonRequest: {
      get () {
        return this.$store.getters['modTrimming/commonRequest']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changeRequest', v)
      }
    },
    animals: {
      get () {
        return this.$store.getters['modTrimming/animals']
      }
    }
  },

  created () {
    setTimeout(() => (
      this.$refs.TOP.scrollIntoView({ behavior: 'smooth' })
    ))
  },

  mounted () {
    this.onLoading()
  },

  methods: {
    onLoading: function () {
      this.isProcessLoading = false
    },
    getGroomerName (code) {
      const index = this.groomer.findIndex(v => v.code === code)
      return (index >= 0) ? this.groomer[index].name : 'おまかせ'
    },
    getCategoriesName (code) {
      const index = this.categories.findIndex(v => v.code === code)
      return (index >= 0) ? this.categories[index].name : '選択してください'
    },
    onTrimmerSelect: function (index) {
      this.edit.index = index
      this.edit.assign = this.commonRequest[index].assign
      this.model.trimmer = true
    },
    onCourseSelect: function (index) {
      this.edit.index = index
      this.edit.course = this.commonRequest[index].courses
      this.model.course = true
    },
    onSetTrimmer ($event) {
      if (this.commonRequest[this.edit.index].assign !== $event) {
        this.$store.dispatch('modTrimming/InitializeTimezone')
      }
      this.commonRequest[this.edit.index].assign = $event
      this.model.trimmer = false
    },
    onNext: function () {
      if (this.commonRequest.some((item) => (this.isUndefined(item.courses)))) {
        return this.$confirm({ title: 'Warning', message: 'コースを選択してください', button: { yes: 'OK' } })
      }
      this.pageTransition('/gr-rsv/' + this.storeID + '/reserve/3')
    }

  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutSection1,
    ComponentsLayoutSection2,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsLayoutHeading3,
    ComponentsViewFormsShortAnimalProfile,
    ComponentsBottomSheetSelectTrimmer,
    ComponentsBottomSheetSelectCourse,
    ComponentsItemsButton
  }
}
</script>
