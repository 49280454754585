<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="うちの子 新規登録"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/console/index')"
>
  <v-row align="center" justify="center">
    <v-col cols=12 class="pt-5">
      <ComponentsFormsProfileAnimal
        :loading="isLoading || isProcessLoading"
        :personals.sync="personals"
        :animals_attribute.sync="animals_attribute"
        ref="animals"
      ></ComponentsFormsProfileAnimal>
    </v-col>
    <v-col cols=12 class="pt-5">
      <ComponentsItemsButton
        block
        :loading="isLoading || isProcessLoading"
        text="登録する"
        @click="created()"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>

</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsFormsProfileAnimal from '@/components/forms/ProfileAnimal'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleCreateAnimalsPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    personals: {
      img_src: '',
      name: '',
      name_kana: '',
      animal: '犬',
      species: '',
      sex: '',
      birth_date: ''
    },
    animals_attribute: {
      blood: '',
      snack_status: '',
      allergy_status: '',
      sterilization_date: '',
      adoptaversary_date: '',
      death_date: '',
      license_status: '',
      license_area: '',
      license_id: '',
      rabies_area: '',
      rabies_id: '',
      microchip_status: '',
      microchip: '',
      doctor_status: '',
      doctor_name: '',
      doctor_tel: '',
      memo: '',
      sterilization_status: ''
    }
  }),

  computed: {
    animals: {
      get () {
        return this.$store.getters['modStorage/animals']
      }
    }
  },

  mounted () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },

    created () {
      (async () => {
        this.error = false
        if (this.$refs.animals) {
          await this.$refs.animals.confirm()
          if (this.$refs.animals.error) {
            this.error = true
          }
        }
        if (this.error) {
          return false
        }
        this.isProcessLoading = true

        const contents = {
          personals: this.personals,
          animals_attribute: this.animals_attribute
        }
        const resultsRow = await APILib.postAnimals(contents)
        this.animals.push(resultsRow)
        this.$confirm({ title: 'Success', message: '登録を行いました', button: { yes: 'OK' } })
        this.pageTransition('/console/index')
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage2,
    ComponentsFormsProfileAnimal,
    ComponentsItemsButton
  }
}
</script>
