<template>
<v-app :style="{ backgroundColor: backGroundColor }">

  <div id="scrollTop"></div>

  <router-view />

  <v-bottom-navigation :color="buttonColor" fixed app v-model="position" grow>
    <v-btn dense :value="link.text" v-for="link in links" :key="link.text" @click="pageTransition(link.route)" color="white" height="inherit">
      <span v-text="link.text"></span>
      <v-badge overlap color="red" :content="(link.badge >= 100) ? 99 : link.badge" v-if="link.badge > 0">
        <v-icon >{{ link.icon }}</v-icon>
      </v-badge>
      <v-icon v-else>
        {{ link.icon }}
      </v-icon>
    </v-btn>
  </v-bottom-navigation>

</v-app>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ConsolePage',

  mixins: [MixinLib],

  data: () => ({
    position: false,
    pageDataRefreshInterval: null,
    pageDataRefreshTime: 3600000 // 60分
  }),

  computed: {
    links: {
      get () {
        return [
          {
            icon: 'mdi-home-outline',
            route: '/console/index',
            text: 'ホーム',
            badge: 0
          },
          {
            icon: 'mdi-image-album',
            route: '/console/album',
            text: 'アルバム',
            badge: 0
          },
          /*
          {
            icon: 'mdi-card-account-details',
            route: '/console/members-card',
            text: '会員証',
            badge: 0
          },
          */
          {
            icon: 'mdi-store',
            route: '/console/shops',
            text: 'ショップ',
            badge: 0
          }
        ]
      }
    }
  },

  created () {
    this.pageDataRefresh()
    this.pageDataRefreshInterval = setInterval(this.pageDataRefresh, this.pageDataRefreshTime)
  },

  mounted () {
    this.init()
  },

  methods: {
    init: function () {
      const index = this.links.findIndex(v => this.$route.path.startsWith(v.route))
      if (index >= 0) {
        this.position = this.links[index].text
      }
    },

    pageDataRefresh: function () {
      (async () => {
        //
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '一部コンテンツの読み込みに失敗しました。', button: { yes: 'OK' } })
      })
    }
  },

  components: {
  }
}
</script>
