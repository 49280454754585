<template>
<v-row align="center" justify="center">
  <v-col cols=12 class="pt-5">
    <ComponentsLayoutHeading1 text="基本情報"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="名前" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsTextField
            name="name"
            :value.sync="personals.name1"
            :loading="loading"
            ref="name"
          ></ComponentsItemsTextField>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="名前（カナ）" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsTextField
            name="name_kana"
            :value.sync="personals.name_kana1"
            :loading="loading"
            ref="name_kana"
          ></ComponentsItemsTextField>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="動物" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsComboBox
            name="animal"
            :value.sync="personals.animal"
            :items="StorageAnimalType"
            :loading="loading"
            ref="animal"
          >
          </ComponentsItemsComboBox>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 :text="personals.animal + '種'" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsComboBox
            name="species"
            :value.sync="personals.species"
            :items="StorageSpecies"
            :loading="isLoadingSpecies || loading"
            ref="species"
          >
          </ComponentsItemsComboBox>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="性別" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsButtonToggle
            name="sex"
            :value.sync="personals.sex"
            :loading="loading"
            :items="StorageSexAnimal"
            item-text="name"
            item-value="code"
            ref="sex"
          ></ComponentsItemsButtonToggle>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="生年月日" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsDatePicker
            name="birth_date"
            :value.sync="personals.birth_date"
            :loading="loading"
            :start-date="'2020-01-01'"
            ref="birth_date"
          >
          </ComponentsItemsDatePicker>
        </v-col>

        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="迎えた日" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsDatePicker
            name="adoptaversary_date"
            :value.sync="personals.adoptaversary_date"
            :loading="loading"
            :start-date="'2020-01-01'"
            ref="adoptaversary_date"
          >
          </ComponentsItemsDatePicker>
        </v-col>

        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="アレルギーの有無" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsButtonToggle
            name="allergy_status"
            :value.sync="animals_attribute.allergy_status"
            :loading="loading"
            :items="allergy_status"
            item-text="name"
            item-value="code"
            ref="allergy_status"
          ></ComponentsItemsButtonToggle>
        </v-col>
        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="お預かり中に「おやつ」をあげて良いか" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsButtonToggle
            name="snack_status"
            :value.sync="animals_attribute.snack_status"
            :loading="loading"
            :items="snack_status"
            item-text="name"
            item-value="code"
            ref="snack_status"
          ></ComponentsItemsButtonToggle>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="避妊去勢"></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="避妊去勢" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsButtonToggle
            name="sterilization_status"
            :value.sync="animals_attribute.sterilization_status"
            :loading="loading"
            :items="sterilization_status"
            item-text="name"
            item-value="code"
            ref="sterilization_status"
          ></ComponentsItemsButtonToggle>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="避妊去勢日" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsDatePicker
            name="sterilization_date"
            :value.sync="personals.sterilization_date"
            :loading="loading"
            :start-date="'2020-01-01'"
            ref="sterilization_date"
          >
          </ComponentsItemsDatePicker>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="飼い犬の登録"></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="鑑札交付の手続き" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsButtonToggle
            name="license_status"
            :items="license_status"
            itemText="name"
            itemValue="code"
            :value.sync="animals_attribute.license_status"
            :loading="loading"
            ref="license_status"
          >
          </ComponentsItemsButtonToggle>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="鑑札番号" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=5>
          <ComponentsItemsTextField
            name="license_area"
            :value.sync="animals_attribute.license_area"
            :loading="loading"
            ref="license_area"
          ></ComponentsItemsTextField>
        </v-col>
        <v-col cols=7>
          <ComponentsItemsTextField
            name="license_id"
            :value.sync="animals_attribute.license_id"
            :loading="loading"
            ref="license_id"
          ></ComponentsItemsTextField>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="狂犬病注射済票" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=5>
          <ComponentsItemsTextField
            name="rabies_area"
            :value.sync="animals_attribute.rabies_area"
            :loading="loading"
            ref="rabies_area"
          ></ComponentsItemsTextField>
        </v-col>
        <v-col cols=7>
          <ComponentsItemsTextField
            name="rabies_id"
            :value.sync="animals_attribute.rabies_id"
            :loading="loading"
            ref="rabies_id"
          ></ComponentsItemsTextField>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="マイクロチップの装着" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsButtonToggle
            name="microchip_status"
            :items="microchip_status"
            itemText="name"
            itemValue="code"
            :value.sync="animals_attribute.microchip_status"
            :loading="loading"
            ref="microchip_status"
          >
          </ComponentsItemsButtonToggle>
        </v-col>
        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="マイクロチップID" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsTextField
            name="microchip"
            :value.sync="animals_attribute.microchip"
            :loading="loading"
            ref="microchip"
          ></ComponentsItemsTextField>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="動物病院"></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="動物病院の有無" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsButtonToggle
            name="doctor_status"
            :items="family_doctor_status"
            itemText="name"
            itemValue="code"
            :value.sync="animals_attribute.doctor_status"
            :loading="loading"
            ref="doctor_status"
          >
          </ComponentsItemsButtonToggle>
        </v-col>
        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="動物病院名" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsTextField
            name="doctor_name"
            :value.sync="animals_attribute.doctor_name"
            :loading="loading"
            ref="doctor_name"
          >
          </ComponentsItemsTextField>
        </v-col>
        <v-col cols=12 >
          <ComponentsLayoutHeading3 text="動物病院 連絡先" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 >
          <ComponentsItemsTextField
            name="doctor_tel"
            :value.sync="animals_attribute.doctor_tel"
            :loading="loading"
            ref="doctor_tel"
          >
          </ComponentsItemsTextField>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsLayoutSection1 from '@/components/layout/Section1'

import ComponentsItemsButtonToggle from '@/components/items/ButtonToggle'
// import ComponentsItemsTextFieldCustomType from '@/components/items/TextFieldCustomType'
import ComponentsItemsDatePicker from '@/components/items/DatePicker'
import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsComboBox from '@/components/items/ComboBox'

export default {
  name: 'ComponentsFormsProfileAnimal',

  mixins: [MixinLib],

  data: () => ({
    isLoadingSpecies: false,
    error: false
  }),

  watch: {
    'personals.animal' () {
      this.LoadingSpecies()
    }
  },

  computed: {
    sterilization_status: {
      get () {
        return this.getSterilizationStatus()
      }
    },
    license_status: {
      get () {
        return this.getLicenseStatus()
      }
    },
    microchip_status: {
      get () {
        return this.getMicrochipStatus()
      }
    },
    family_doctor_status: {
      get () {
        return this.getFamilyDoctorStatus()
      }
    },
    allergy_status: {
      get () {
        return this.getAllergyStatus()
      }
    },
    snack_status: {
      get () {
        return this.getSnackStatus()
      }
    }
  },

  created: function () {
  },

  mounted () {
    this.LoadingSpecies()
  },

  methods: {
    LoadingSpecies () {
      (async () => {
        this.isLoadingSpecies = true
        await this.$store.dispatch('modStorage/LoadSpecies', this.getValue(this.personals, 'animal'))
        this.isLoadingSpecies = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isLoadingSpecies = false
      })
      // this.pageTransition('/sign-in')
    },

    isError: function () {
      return this.error
    },

    confirm: async function () {
      (async () => {
        this.error = false
        const forms = [
          'name',
          'name_kana',
          'animal',
          'species',
          'sex',
          'blood',
          'birth_date',
          'snack_status',
          'allergy_status',
          'sterilization_status',
          'sterilization_date',
          'adoptaversary_date',
          'death_date',
          'license_status',
          'license_area',
          'license_id',
          'rabies_area',
          'rabies_id',
          'microchip_status',
          'microchip',
          'animal_code',
          'doctor_status',
          'doctor_name',
          'doctor_tel'
        ]
        for (const name of forms) {
          if (this.$refs[name]) {
            await this.$refs[name].confirm()
          }
          if (this.$refs[name] && this.$refs[name].error) {
            this.error = true
          }
        }
        if (this.error) {
          return false
        }
        return true
      })().catch((e) => {
        this.error = true
      })
    }
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading3,
    ComponentsLayoutSection1,
    ComponentsItemsButtonToggle,
    // ComponentsItemsTextFieldCustomType,
    ComponentsItemsDatePicker,
    ComponentsItemsTextField,
    ComponentsItemsComboBox
  },

  props: {
    personals: {
      type: Object,
      required: false,
      default: () => {
        return {
          img_src: '',
          name1: '',
          name2: '',
          name_kana1: '',
          name_kana2: '',
          animal: '犬',
          species: '',
          sex: '1',
          birth_date: ''
        }
      }
    },
    animals_attribute: {
      type: Object,
      required: false,
      default: () => {
        return {
          blood: '',
          snack_status: '1',
          allergy_status: '1',
          sterilization_status: '1',
          sterilization_date: '',
          adoptaversary_date: '',
          death_date: '',
          license_status: '1',
          license_area: '',
          license_id: '',
          rabies_area: '',
          rabies_id: '',
          microchip_status: '1',
          microchip: '',
          doctor_status: '1',
          doctor_name: '',
          doctor_tel: ''
        }
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
