<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="うちの子 非表示一覧"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/console/index')"
>

  <v-row align="center" justify="center">
    <v-col cols=12 class="pt-5" v-show="animals.length > 0">
      <v-list two-line flat nav class="rounded-lg">
        <template v-for="(item, index) in animals">

          <v-divider class="py-1" :key="'d-' + item.personal_id" v-if="index > 0"></v-divider>

          <v-list-item :key="item.personal_id" @click="() => false">
            <v-list-item-avatar>
              <v-img :src="item.personals.img_src" aspect-ratio="1" class="grey lighten-2"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.personals.name1"></v-list-item-title>
              <v-list-item-subtitle>
                <span v-text="item.personals.animal"></span>&nbsp;<span v-text="item.personals.species"></span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span v-text="getSexName(item.personals.sex)"></span>&nbsp;<span v-text="getAge(item.personals.birthday)"></span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-show="(!$vuetify.breakpoint.xs)" class="pr-5">
              <ComponentsItemsButton
                text="削 除"
                :loading="isLoading || isProcessLoading"
                @click="() => confirmRemove(index)"
              >
              </ComponentsItemsButton>
            </v-list-item-action>
            <v-list-item-action v-show="(!$vuetify.breakpoint.xs)">
              <ComponentsItemsButton
                outlined
                text="再表示"
                :loading="isLoading || isProcessLoading"
                @click="() => commitShow(index)"
              >
              </ComponentsItemsButton>
            </v-list-item-action>
            <v-list-item-action v-show="($vuetify.breakpoint.xs)">
              <ComponentsItemsButton
                icon-btn
                icon="mdi-cog"
                :loading="isLoading || isProcessLoading"
                @click="openSheet(index)"
              >
              </ComponentsItemsButton>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-col>
  </v-row>

  <v-bottom-sheet v-model="sheet" v-show="($vuetify.breakpoint.xs)">
    <v-list>
      <v-list-item @click="() => confirmRemove(editIndex)">
        <v-list-item-icon>
          <v-icon >mdi-delete</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <span >削除</span>
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="() => commitShow(editIndex)">
        <v-list-item-icon>
          <v-icon >mdi-eye</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <span >再表示</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-bottom-sheet>

</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleHideAnimalsPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    sheet: false,
    editIndex: 0
  }),

  computed: {
    animals: {
      get () {
        return this.$store.getters['modStorage/hideAnimals']
      }
    }
  },

  mounted () {
    this.isProcessLoading = false
  },

  methods: {
    getSexName: function (sex) {
      const index = this.StorageSexAnimal.findIndex(v => v.code === sex)
      return (index >= 0) ? this.StorageSexAnimal[index].name : '不明'
    },

    openSheet: function (index) {
      this.editIndex = index
      this.sheet = true
    },

    confirmRemove: function (index) {
      this.editIndex = index
      this.$confirm({ title: 'Confirm', message: this.animals[index].personals.name1 + 'ちゃんの情報について、削除後に元に戻す事は出来ません。本当に削除しても宜しいですか？', button: { yes: 'OK' }, callback: this.commitSRemove })
    },

    commitSRemove: function (ans) {
      (async () => {
        if (ans) {
          this.isProcessLoading = true
          const profile = this.clone(this.animals[this.editIndex])
          await APILib.deleteAnimals(this.animals[this.editIndex].personal_id)
          this.animals.splice(this.editIndex, 1)
          this.$confirm({ title: 'Confirm', message: profile.personals.name1 + 'ちゃんを削除しました', button: { yes: 'OK' } })
          setTimeout(() => {
            this.$store.dispatch('modStorage/LoadAnimals')
          }, 3000)
          this.sheet = false
          this.isProcessLoading = false
        }
      })().catch((e) => {
        this.isProcessLoading = false
        if (e.message === 'ReservationExists') {
          return this.$confirm({ title: 'Error', message: 'トリミングを予約中です。ご予約している店舗にキャンセルの連絡を行ってください。', button: { yes: 'OK' } })
        }
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    commitShow: function (index) {
      (async () => {
        this.isProcessLoading = true
        const profile = this.clone(this.animals[index])
        await APILib.putAnimalsScreen(this.animals[index].personal_id)
        this.animals.splice(index, 1)
        this.$confirm({ title: 'Confirm', message: profile.personals.name1 + 'ちゃんを表示状態に変更しました', button: { yes: 'OK' } })
        setTimeout(() => {
          this.$store.dispatch('modStorage/LoadAnimals')
        }, 3000)
        this.sheet = false
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage2,
    ComponentsItemsButton
  }
}
</script>
