import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueConfirmDialog from 'vue-confirm-dialog'
import VueScrollTo from 'vue-scrollto'

import 'vue-croppa/dist/vue-croppa.css'
import Croppa from 'vue-croppa'
import AuthLineLib from '@/libs/AuthLineLib'

Vue.config.productionTip = false

Vue.use(VueScrollTo)
Vue.use(VueConfirmDialog)
Vue.use(Croppa)

Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

/*
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
*/
async function main () {
  await AuthLineLib.init()
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}

main()
