<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="うちの子"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/console/index')"
>
  <v-row align="center" justify="center" class="pt-5">
    <v-col cols=3 class="text-left" v-show="showArrow">
      <ComponentsItemsButton
        icon-btn
        icon="mdi-chevron-left"
        :loading="isLoading || isProcessLoading"
        @click="prev()"
      >
      </ComponentsItemsButton>
    </v-col>
    <v-col :cols="(showArrow) ? 6 : 12" >
      <v-row align="center" justify="center">
        <v-col cols=12 class="text-center">
          <v-avatar :size="150">
            <v-img :src="(isUndefined(profile)) ? '' : profile.personals.img_src + '?width=150&height=150'" aspect-ratio="1" class="grey lighten-2"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols=12 class="py-1 text-center text-truncate" >
          <span v-text="(isUndefined(profile)) ? '' : profile.personals.name"></span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols=3 class="text-right" v-show="showArrow">
      <ComponentsItemsButton
        icon-btn
        icon="mdi-chevron-right"
        :loading="isLoading || isProcessLoading"
        @click="next()"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=12>
      <ComponentsViewFormsAnimalProfile
        :loading="isLoading || isProcessLoading"
        :personal-id="personalsID"
      >
      </ComponentsViewFormsAnimalProfile>
    </v-col>

    <v-col cols=6 class="text-center">
      <ComponentsItemsButton
        block
        icon="mdi-scale"
        text="体重"
        :loading="isLoading || isProcessLoading"
        @click="bottomSheets.weightHistory = true"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=6 class="text-center">
      <ComponentsItemsButton
        block
        icon="mdi-content-cut"
        text="サロン履歴"
        :loading="isLoading || isProcessLoading"
        @click="bottomSheets.trimmingHistory = true"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=6 class="text-center">
      <ComponentsItemsButton
        block
        text="写真の変更"
        :loading="isLoading || isProcessLoading"
        @click="() => openUploadImageLibrary()"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=6 class="text-center">
      <ComponentsItemsButton
        block
        text="非表示にする"
        :loading="isLoading || isProcessLoading"
        @click="confirmHide()"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=12>
      <ComponentsViewFormsAnimalAllergy
        :loading="isLoading || isProcessLoading"
        :personal-id="personalsID"
      >
      </ComponentsViewFormsAnimalAllergy>
    </v-col>

    <v-col cols=12>
      <ComponentsViewFormsTrimmingImage
        :images.sync="images"
        :loading="isLoading || isProcessLoading"
        profile-mode
        @update:images="(e) => actionCommitImages(e)"
      >
      </ComponentsViewFormsTrimmingImage>
    </v-col>

    <v-col cols=12>
      <ComponentsViewFormsInsurances
        :loading="isLoading || isProcessLoading"
        :personal-id="personalsID"
      >
      </ComponentsViewFormsInsurances>
    </v-col>

    <v-col cols=12>
      <ComponentsViewFormsAnimalVaccine
        :loading="isLoading || isProcessLoading"
        :personal-id="personalsID"
      >
      </ComponentsViewFormsAnimalVaccine>
    </v-col>

    <!-- BottomSheet --->
    <ComponentsBottomSheetTrimmingHistory
      :model="bottomSheets.trimmingHistory"
      :personal-id="personalsID"
      @close-click="bottomSheets.trimmingHistory = false"
      v-if="bottomSheets.trimmingHistory"
    >
    </ComponentsBottomSheetTrimmingHistory>

    <ComponentsBottomSheetWeightHistory
      :model="bottomSheets.weightHistory"
      :personal-id="personalsID"
      @close-click="bottomSheets.weightHistory = false"
      v-if="bottomSheets.weightHistory"
    >
    </ComponentsBottomSheetWeightHistory>

  </v-row>
  <v-dialog v-model="bottomSheet.selectLibrary" width="90%" max-width="420" v-if="bottomSheet.selectLibrary">
    <v-card>
      <v-card-text class="pt-5">
        <v-row align="center" justify="center" >
          <v-col cols=12 class="text-left ec-font-size14 py-1">
            <span>アップロードする画像を選択します</span>
          </v-col>
          <v-col cols=12 class="text-left ec-font-size10 py-1">
            <span>最大アップロードサイズ: 20MB</span>
          </v-col>
          <v-col cols=12 class="text-center py-1">
            <croppa
              v-model="imgSrc"
              :width="280"
              :height="280"
              placeholder="タップして画像を選択してください"
              :prevent-white-space="true"
              :disabled="false"
              :disable-click-to-choose="false"
              :disable-drag-and-drop="false"
              :disable-drag-to-move="false"
              :disable-scroll-to-zoom="false"
              :disable-pinch-to-zoom="false"
              :disable-rotation="false"
              initial-size="natural"
              initial-position="center"
              :show-remove-button="true"
              remove-button-color="black"
              :show-loading="true"
              :file-size-limit="20 * 1024 * 1024"
              accept=".jpeg,.png,.jpg"
              style="line-height: normal!important;"
              @file-type-mismatch="onFileTypeMismatch"
              @file-size-exceed="onFileSizeExceed"
            >
            </croppa>
          </v-col>
          <v-col cols=12 class="text-center py-1">
            <v-btn-toggle dense dark>
              <v-btn @click="imgSrc.zoomIn()">
                <v-icon>mdi-magnify-minus-outline</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.zoomOut()">
                <v-icon>mdi-magnify-plus-outline</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.rotate()">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.rotate(-1)">
                <v-icon>mdi-restore</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.flipX()">
                <v-icon>mdi-compare-horizontal</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.flipY()">
                <v-icon>mdi-compare-vertical</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols=12 class="text-left ec-font-size10 py-1">
            <span>アップロード後の画像を変更する場合は <v-icon small>mdi-close-circle</v-icon> アイコンで一度画像をクリアして下さい。</span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <ComponentsItemsButton
          text-btn
          text="閉じる"
          @click="bottomSheet.selectLibrary = false"
        >
        </ComponentsItemsButton>
        <v-spacer></v-spacer>
        <ComponentsItemsButton
          text-btn
          text="変更する"
          @click="changeUploadImageLibrary()"
        >
        </ComponentsItemsButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsItemsButton from '@/components/items/Button'

import ComponentsBottomSheetWeightHistory from '@/components/bottom-sheet/WeightHistory'
import ComponentsBottomSheetTrimmingHistory from '@/components/bottom-sheet/TrimmingHistory'

import ComponentsViewFormsAnimalProfile from '@/components/view-forms/AnimalProfile'
import ComponentsViewFormsAnimalAllergy from '@/components/view-forms/AnimalAllergy'
import ComponentsViewFormsInsurances from '@/components/view-forms/Insurances'
import ComponentsViewFormsAnimalVaccine from '@/components/view-forms/AnimalVaccine'
import ComponentsViewFormsTrimmingImage from '@/components/view-forms/TrimmingImage'

export default {
  name: 'ConsoleAnimalsContentsPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    imgSrcMaxLength: process.env.VUE_APP_CUT_IMG_MAX,
    images: [],
    contents: '',
    imgSrc: {},
    bottomSheet: {
      selectMenus: false,
      selectLibrary: false
    },
    bottomSheets: {
      insurancesHistory: false,
      weightHistory: false,
      vaccineHistory: false,
      animalProfile: false,
      trimmingHistory: false,
      hide: false
    }
  }),

  computed: {
    personalsID: {
      get () {
        return this.$route.params.PersonalsID
      }
    },
    profile: {
      get () {
        return this.animals.find(v => v.personal_id === this.personalsID) || {}
      }
    },
    showArrow: {
      get () {
        return (this.animals.length >= 1)
      }
    },
    animals: {
      get () {
        return this.$store.getters['modStorage/animals']
      }
    }
  },

  watch: {
    personalsID () {
      this.goLoading()
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goHome () {
      this.pageTransition('/console/index')
    },
    prev () {
      const index = this.animals.findIndex(v => v.personal_id === this.personalsID)
      if (index === 0) {
        this.pageTransition('/console/animals/' + this.animals[this.animals.length - 1].personal_id)
      } else {
        this.pageTransition('/console/animals/' + this.animals[index - 1].personal_id)
      }
    },
    next () {
      const index = this.animals.findIndex(v => v.personal_id === this.personalsID)
      if ((index + 1) >= this.animals.length) {
        this.pageTransition('/console/animals/' + this.animals[0].personal_id)
      } else {
        this.pageTransition('/console/animals/' + this.animals[index + 1].personal_id)
      }
    },
    confirmHide: function () {
      this.$confirm({ title: 'Confirm', message: '非表示にして本当に宜しいですか？ ※再表示したい場合には、HOME左上メニューの非表示一覧から再表示を行ってください', button: { yes: 'OK', no: 'CANCEL' }, callback: this.commitHide })
    },
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        this.images = []
        if (this.isUndefined(this.personalsID)) {
          return false
        }
        this.images = await APILib.getStoresAnimalsTrimmingCutImages(this.personalsID)
        /*
        this.list = [...Array(parseInt(this.imgSrcMaxLength))].map((cur, idx) => {
          return (this.isUndefined(imgSrc) === false && imgSrc.length > idx) ? imgSrc[idx].img_src : ''
        })
        */
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },
    commitHide: function (ans) {
      (async () => {
        if (ans) {
          this.isProcessLoading = true
          const index = this.animals.findIndex(item => item.personal_id === this.personalsID)
          await APILib.deleteAnimalsScreen(this.personalsID)
          this.animals.splice(index, 1)
          setTimeout(() => {
            this.$store.dispatch('modStorage/LoadAnimals')
          }, 3000)
          this.isProcessLoading = false
          this.pageTransition('/console/index')
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    openUploadImageLibrary: function () {
      if (this.exists(this.imgSrc, 'remove')) {
        this.imgSrc.remove()
      }
      this.bottomSheet.selectLibrary = true
    },
    actionCommitImages: function (images) {
      (async () => {
        console.log(images)
        this.images = await APILib.putStoresAnimalsTrimmingCutImages(this.personalsID, { personals_trimming_cut_image: images })
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    changeUploadImageLibrary: function () {
      this.imgSrc.generateBlob((blob) => this.uploadImageLibrary(blob), 'image/png')
      this.bottomSheet.selectMenus = false
      this.bottomSheet.selectLibrary = false
    },

    uploadImageLibrary: function (blob) {
      (async () => {
        this.contents = await APILib.getAnimalsContents(this.personalsID)
        const resultRows = await APILib.postMediaImage(blob, 'images.png')
        this.contents.personals.img_src = resultRows
        await APILib.putAnimals(this.personalsID, this.contents)
        await this.$store.dispatch('modStorage/LoadAnimals')
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    onFileTypeMismatch: function (file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },

    onFileSizeExceed: function (file) {
      alert('File size exceeds. Please choose a file smaller than 100kb.')
    }
  },

  components: {
    ComponentsLayoutPage2,
    ComponentsItemsButton,
    ComponentsBottomSheetTrimmingHistory,
    ComponentsBottomSheetWeightHistory,
    ComponentsViewFormsAnimalProfile,
    ComponentsViewFormsAnimalAllergy,
    ComponentsViewFormsInsurances,
    ComponentsViewFormsAnimalVaccine,
    ComponentsViewFormsTrimmingImage
  }
}
</script>
