<template>
<v-row align="center" justify="center">
  <v-col cols=12>
    <ComponentsLayoutHeading1 text="ワクチン接種情報"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12 v-for="(item, index) of vaccines" :key="item.components.code">
    <v-row align="center" justify="center">
      <v-col cols=12 v-if="isUndefined(item.vaccine_id)">
        <ComponentsLayoutSection2>
          <v-row align="center" justify="center">
            <v-col :cols=9>
              <v-row align="center" justify="center">
                <v-col cols=12 class="py-1 text-truncate">
                  <span v-text="item.components.name"></span>
                </v-col>
                <v-col cols=12 class="py-1">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols=12 class="py-0 ec-font-size10 text-truncate">
                  <span>接種日:</span>&nbsp;<span>-</span>
                </v-col>
                <v-col cols=12 class="py-0 ec-font-size10 text-truncate">
                  <span>期限日:</span>&nbsp;<span>-</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols=3 class="text-right">
              <ComponentsItemsButton
                small
                :loading="loading || isProcessLoading"
                icon-btn
                icon="mdi-pencil"
                @click="openEdit(index)"
              >
              </ComponentsItemsButton>
            </v-col>
          </v-row>
        </ComponentsLayoutSection2>
      </v-col>
      <v-col cols=12 v-else>
        <ComponentsLayoutSection2>
          <v-row align="center" justify="center">
            <v-col :cols="(item.components.requiredImage) ? 7 : 9" >
              <v-row align="center" justify="center">
                <v-col cols=12 class="py-1 text-truncate">
                  <span v-text="item.components.name"></span><v-chip class="ml-3 mb-1" label x-small color="primary" dense v-if="item.personals_vaccine.action_type === '2'">特</v-chip>
                </v-col>
                <v-col cols=12 class="py-1">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols=12 class="py-0 ec-font-size10 text-truncate" v-if="!isUndefined(item.personals_vaccine.action_date)">
                  <span>接種日:</span>&nbsp;<span v-text="formatDate(item.personals_vaccine.action_date, 'YYYY年MM月DD日')"></span>
                </v-col>
                <v-col cols=12 class="py-0 ec-font-size10 text-truncate" v-else>
                  <span>接種日:</span>&nbsp;<span>-</span>
                </v-col>
                <v-col cols=12 class="py-0 ec-font-size10 text-truncate" v-if="!isUndefined(item.personals_vaccine.expire_date)">
                  <span>期限日:</span>&nbsp;<span v-text="formatDate(item.personals_vaccine.expire_date, 'YYYY年MM月DD日')"></span>
                </v-col>
                <v-col cols=12 class="py-0 ec-font-size10 text-truncate" v-else>
                  <span>期限日:</span>&nbsp;<span>-</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols=2 class="text-center" v-if="(item.components.requiredImage)">
              <v-avatar tile>
                <v-img :loading="loading || isProcessLoading" :src="item.personals_vaccine.img_src" aspect-ratio="1" class="grey lighten-2"></v-img>
              </v-avatar>
            </v-col>
            <v-col cols=3 class="text-right">
              <ComponentsItemsButton
                small
                :loading="loading || isProcessLoading"
                icon-btn
                icon="mdi-pencil"
                @click="openEdit(index)"
              >
              </ComponentsItemsButton>
            </v-col>
          </v-row>
        </ComponentsLayoutSection2>
      </v-col>
    </v-row>
  </v-col>

  <v-bottom-sheet persistent scrollable v-model="vaccineModal" v-if="vaccineModal">
    <v-card flat >
      <v-card-title :color="primaryColor" height="60">
        <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
          <v-col cols=12>
            <ComponentsLayoutHeading1 text="ワクチン接種設定" border-hidden></ComponentsLayoutHeading1>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text :style="{ backgroundColor: backGroundColor }">
        <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
          <v-col cols=12>
            <ComponentsFormsAnimalVaccine
              :personals-vaccine="edit.personals_vaccine"
              :loading="loading || isProcessLoading"
              ref="vaccineForms"
            >
            </ComponentsFormsAnimalVaccine>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
          <v-col cols=12 class="d-flex">
            <ComponentsItemsButton
              text-btn
              text="閉じる"
              :loading="isLoading || isProcessLoading"
              @click="vaccineModal = false"
            >
            </ComponentsItemsButton>
            <v-spacer></v-spacer>
            <ComponentsItemsButton
              text-btn
              :loading="isLoading || isProcessLoading"
              text="更新する"
              @click="created()"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </v-card-actions>

    </v-card>
  </v-bottom-sheet>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsFormsAnimalVaccine from '@/components/forms/AnimalVaccine'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsAnimalVaccine',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    vaccineModal: false,
    editIndex: 0,
    vaccines: [],
    edit: {}
  }),

  watch: {
    personalId () {
      this.goLoading()
    }
  },

  computed: {
    vaccineImageRequire: {
      get () {
        const index = this.StorageVaccineTypes.findIndex(v => v.code === this.contents.personals_vaccine.vaccine_type_id)
        return (index >= 0) ? (this.StorageVaccineTypes[index].requiredImage) : false
      }
    },
    vaccineTypeName: {
      get () {
        const index = this.StorageVaccineTypes.findIndex(v => v.code === this.contents.personals_vaccine.vaccine_type_id)
        return (index >= 0) ? (this.StorageVaccineTypes[index].name) : '-'
      }
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.vaccines = []
        if (this.isUndefined(this.personalId)) {
          return false
        }
        this.isProcessLoading = true
        for (const vaccineTypes of this.StorageVaccineTypes) {
          const resultRows = await APILib.getAnimalsVaccine(this.personalId, vaccineTypes.code)
          if (this.isUndefined(resultRows) === false && resultRows.length > 0) {
            this.vaccines.push({
              ...resultRows[0],
              components: vaccineTypes
            })
          } else {
            this.vaccines.push({
              vaccine_id: '',
              components: vaccineTypes,
              personals_vaccine: {
                vaccine_type_id: vaccineTypes.code,
                action_date: '',
                expire_date: '',
                img_src: '',
                action_type: process.env.VUE_APP_PERSONALS_VACCINE_ACTION_TYPE
              }
            })
          }
        }
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    openEdit: function (index) {
      this.editIndex = index
      this.edit = this.clone(this.vaccines[index])
      this.vaccineModal = true
    },

    created: function () {
      (async () => {
        let error = false
        if (this.$refs.vaccineForms) {
          await this.$refs.vaccineForms.confirm()
          if (this.$refs.vaccineForms.error) {
            error = true
          }
        }
        if (error) {
          return false
        }
        this.isProcessLoading = true
        const resultsRow = await APILib.postAnimalsVaccine(this.personalId, this.edit)
        this.edit.vaccine_id = resultsRow.vaccine_id
        this.vaccines.splice(this.editIndex, 1, this.clone(this.edit))
        this.vaccineModal = false
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutSection2,
    ComponentsLayoutHeading1,
    ComponentsFormsAnimalVaccine,
    ComponentsItemsButton
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    personalId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
