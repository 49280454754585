<template>
<ComponentsLayoutPage2
  header
  footer
  :loading="isProcessLoading"
  header-title="パスワード再設定"
  header-left
  header-left-icon='mdi-chevron-left'
  header-left-text='戻る'
  @left-click="pageTransition('/forget-password/authentication')"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px', paddingTop: '30px' }">

    <v-col cols=12>
      <span>再設定するパスワードをご入力下さい</span>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" class="mx-auto py-3">
          <v-col cols=12>
            <ComponentsLayoutHeading3 text="パスワード" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextFieldTypePassword
              name="password"
              :value.sync="password"
              :loading="isProcessLoading"
              autocomplete="current-password"
              ref="password"
            ></ComponentsItemsTextFieldTypePassword>
          </v-col>

          <v-col cols=12 >
            <ComponentsLayoutHeading3 text="パスワード(確認)" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextFieldTypePassword
              name="password"
              :value.sync="re_password"
              :loading="isProcessLoading"
              ref="re_password"
            ></ComponentsItemsTextFieldTypePassword>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12 class="text-center">
      <ComponentsItemsButton
        block
        :loading="isProcessLoading"
        text="再設定してログイン画面に戻る"
        @click="ReSetPassword()"
      >
      </ComponentsItemsButton>
    </v-col>

  </v-row>
</ComponentsLayoutPage2>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsItemsTextFieldTypePassword from '@/components/items/TextFieldTypePassword'
import ComponentsItemsButton from '@/components/items/Button'

export default {

  name: 'ForgetPasswordResetPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    password: '',
    re_password: ''
  }),

  computed: {
    requestCode: {
      get () {
        return this.$route.query.id
      }
    },
    verifyCode: {
      get () {
        return this.$route.query.code
      }
    }
  },

  mounted () {
    this.isProcessLoading = false
  },

  methods: {

    confirm: async function () {
      if (this.isUndefined(this.users.users_authentication.personal_account)) {
        if (!this.isUndefined(this.contents.users_authentication.personal_account)) {
          if (await APILib.getOAuthSignInCheck(this.contents.users_authentication.personal_account)) {
            this.$confirm({ title: 'Error', message: '既に登録されているアカウントです', button: { yes: 'OK' } })
            this.error = true
            return false
          }
        } else {
          this.$confirm({ title: 'Error', message: 'アカウントを入力してください', button: { yes: 'OK' } })
          this.error = true
          return false
        }
      }

      return true
    },

    ReSetPassword: function () {
      (async () => {
        const error = false
        const forms = [
          'password',
          're_password'
        ]
        for (const name of forms) {
          if (this.$refs[name]) {
            await this.$refs[name].confirm()
            if (this.$refs[name].error) {
              this.error = true
            }
          }
        }
        if (error) {
          return false
        }
        if (this.password !== this.re_password) {
          this.$confirm({ title: 'Error', message: 'パスワードが一致していません', button: { yes: 'OK' } })
          this.error = true
          return false
        }
        this.isProcessLoading = true
        await APILib.postOAuthPasswordReset(this.requestCode, this.verifyCode, this.password)
        this.$confirm({ title: 'Success', message: 'パスワードを変更しました', button: { yes: 'OK' } })
        this.pageTransition('/login')
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage2,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading3,
    ComponentsItemsTextFieldTypePassword,
    ComponentsItemsButton
  }
}
</script>
