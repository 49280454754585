<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP1. 予約申請希望選択" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/reserve/1')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP2. コース＆指名条件" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/reserve/2')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP3. ご予約希望日時の設定" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/reserve/3')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsViewFormsTrimmingApplyCalendar
        :loading="isLoading || isProcessLoading"
        :trimming-timezone="timezone"
      >
      </ComponentsViewFormsTrimmingApplyCalendar>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP4. お客様情報の確認" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/reserve/4')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection1>
        <ComponentsViewFormsShortPersonalsProfile
          :personals="personals"
          :users_attribute="usersAttribute"
          :address_book="addressBook"
        >
        </ComponentsViewFormsShortPersonalsProfile>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP5. カルテ情報の確認" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/reserve/5')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 class="pb-5" v-for="(item, index) in animals" :key="index">
      <ComponentsViewFormsTrimmingApplyConditions
        :loading="isProcessLoading"
        :courses="commonRequest[index].courses"
        :assign="commonRequest[index].assign"
        :personals="item.personals"
        :contents="item"
      >
      </ComponentsViewFormsTrimmingApplyConditions>
    </v-col>

    <v-col cols=12 ref="TOP">
      <ComponentsLayoutHeading1 text="STEP6. 申請内容の最終確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsFormsTrimmingApplyConfirm
        :loading="isProcessLoading"
        :store-id="storeID"
        ref="applyConfirmForm"
      ></ComponentsFormsTrimmingApplyConfirm>
    </v-col>

    <v-col cols=12>
      <ComponentsItemsButton
        block
        :loading="isProcessLoading"
        text="入力した内容で予約の申請を行う"
        @click="onNext()"
      >
      </ComponentsItemsButton>
    </v-col>

  </v-row>

</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'
import AuthLineLib from '@/libs/AuthLineLib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'

import ComponentsViewFormsTrimmingApplyConditions from '@/components/view-forms/TrimmingApplyConditions'
import ComponentsViewFormsTrimmingApplyCalendar from '@/components/view-forms/TrimmingApplyCalendar'
import ComponentsViewFormsShortPersonalsProfile from '@/components/view-forms/ShortPersonalsProfile'

// import ComponentsFormsTrimmingApplyAdditional from '@/components/forms/TrimmingApplyAdditional'
import ComponentsFormsTrimmingApplyConfirm from '@/components/forms/TrimmingApplyConfirm'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingReserveTrimmingMemberStep6Page',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    UsersStores: {
      get () {
        return this.$store.getters['modStorage/usersStores']
      }
    },
    commonRequest: {
      get () {
        return this.$store.getters['modTrimming/commonRequest']
      }
    },
    timezone: {
      get () {
        return this.$store.getters['modTrimming/trimmingTimezone']
      }
    },
    fbnPersonals: {
      get () {
        return this.$store.getters['modTrimming/fbnPersonals']
      }
    },
    personals: {
      get () {
        return this.$store.getters['modTrimming/personals']
      }
    },
    usersAttribute: {
      get () {
        return this.$store.getters['modTrimming/usersAttribute']
      }
    },
    addressBook: {
      get () {
        return this.$store.getters['modTrimming/addressBook']
      }
    },
    animals: {
      get () {
        return this.$store.getters['modTrimming/animals']
      }
    },
    trimmingRequest: {
      get () {
        return this.$store.getters['modTrimming/trimmingRequest']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changeTrimmingRequest', v)
      }
    },
    usersAuthentication: {
      get () {
        return this.$store.getters['modTrimming/usersAuthentication']
      }
    },
    isCreateAppUser: {
      get () {
        if (AuthLineLib.isInClient()) {
          return false
        }
        if (this.isUndefined(this.UsersStores)) {
          return false
        }
        if (this.isUndefined(this.UsersStores.users_authentication)) {
          return false
        }
        return (this.isUndefined(this.UsersStores.users_authentication.personal_account))
      }
    }
  },

  created () {
    setTimeout(() => (
      this.$refs.TOP.scrollIntoView({ behavior: 'smooth' })
    ))
  },

  mounted () {
    this.onLoading()
  },

  methods: {
    onLoading: function () {
      this.isProcessLoading = false
    },
    onNext: function () {
      (async () => {
        // 事前チェック
        /*
        if (this.$refs.applyAdditionalForm) {
          await this.$refs.applyAdditionalForm.confirm()
          if (this.$refs.applyAdditionalForm.error) {
            this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
            return false
          }
        }
        */
        if (this.$refs.applyConfirmForm) {
          await this.$refs.applyConfirmForm.confirm()
          if (this.$refs.applyConfirmForm.error) {
            // this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
            return false
          }
        }
        this.isProcessLoading = true

        const contents = this.$store.getters['modTrimming/requestContents'](false, this.isCreateAppUser)
        // 予約登録
        await APILib.postStoresTrimmingKarte(this.storeID, this.UsersStores.fbn_personals.karte_id, contents)
        this.isProcessLoading = false
        // ペトコネアカウントの保存
        if (this.isCreateAppUser) {
          this.$store.dispatch('modSession/setAuthorization', [this.usersAuthentication.personal_account, this.usersAuthentication.personal_password])
        }
        // 完了画面に遷移
        return this.pageTransition('/gr-rsv/' + this.storeID + '/complete')
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '予約申請でエラーが発生しました。お手数ですがシステムにお問合せ下さい [' + e.message + ']', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsLayoutSection1,
    ComponentsViewFormsTrimmingApplyConditions,
    ComponentsViewFormsTrimmingApplyCalendar,
    ComponentsViewFormsShortPersonalsProfile,
    ComponentsFormsTrimmingApplyConfirm,
    ComponentsItemsButton
  }
}
</script>
