<template>
<v-bottom-sheet persistent scrollable v-model="model" v-if="model">
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutHeading1 text="ご予約内容" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }" v-if="!isUndefined(contents)">
        <v-col cols=12>
          <ComponentsLayoutSection1>
            <v-row align="center" justify="center">
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="予約店舗"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(stores)) ? '' : stores.store_name"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="パートナー名"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.personals.name1"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="予約日"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : formatDate(contents.trimming.trimming_date, 'YYYY年MM月DD日(ddd)')"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="受付時間"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : formatDate(contents.trimming.trimming_date + ' ' + contents.trimming.trimming_str_time, 'HH:mm') + '～'"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="指名"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12 v-for="item of contents.trimming_assign.filter(v => v.assign_status === '2')" :key="item.business_users_id">
                      <span v-text="item.name"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="コース"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12 v-for="item of contents.trimming_courses" :key="item.item_category_id">
                      <span v-text="item.item_category_name"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
            </v-row>
          </ComponentsLayoutSection1>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutSection1>
            <v-row align="center" justify="center">
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="お顔のカット"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.trimming_report.face_cut"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="お耳のカット"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.trimming_report.ear_cut"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="四肢のカット"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.trimming_report.legs_cut"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="からだのカット"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.trimming_report.body_cut"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="しっぽのカット"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.trimming_report.tail_cut"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="シャンプー"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.trimming_report.used_shampoo"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="コンディショナー"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.trimming_report.used_conditioner"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="スパメニュー"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.trimming_report.used_spa"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="オプション"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.trimming_report.used_options"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="クニリック"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.trimming_report.used_clinic"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="グルーマーからのメッセージ"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="center">
                    <v-col cols=12>
                      <span v-text="(isUndefined(contents)) ? '' : contents.trimming_report.note"></span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="共有写真"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutSection2>
                  <v-row align="center" justify="start">
                    <v-col cols=4 class="d-flex child-flex" v-for="(item, index) of contents.trimming_report_media" :key="index">
                      <v-img :src="item.img_src + '?width=240&height=240'" contain aspect-ratio="1" class="grey lighten-2" @click="onImageClick(index)">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection2>
              </v-col>
            </v-row>
          </ComponentsLayoutSection1>
        </v-col>

      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            text-btn
            text="閉じる"
            :loading="isProcessLoading"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>

  <div id="fullscreen" v-if="showFullscreen">
    <img :src="contents.trimming_report_media[currentImageIndex].img_src + '?width=1024&height=1024'">
    <ComponentsItemsButton
      outlined
      text="閉じる"
      :loading="isProcessLoading"
      @click="onImageClose()"
      :style="{ position: 'fixed', bottom: '20px', right: '20px' }"
    >
    </ComponentsItemsButton>
  </div>

</v-bottom-sheet>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsBottomSheetTrimmingHistorySheet',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    showFullscreen: false,
    currentImageIndex: 0,
    contents: {}
  }),

  watch: {
    model () {
      if (this.model) {
        this.goLoading()
      }
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.contents = {}
        if (this.isUndefined(this.personalId)) {
          return false
        }
        if (this.isUndefined(this.trimmingId)) {
          return false
        }
        this.isProcessLoading = true
        this.contents = await APILib.getAnimalsTrimmingHistoryContents(this.personalId, this.trimmingId)
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    onImageClick: function (index) {
      this.currentImageIndex = index
      this.showFullscreen = true
    },
    onImageClose: function () {
      this.showFullscreen = false
    }
  },

  beforeDestroy () {
    this.isProcessLoading = false
    this.showFullscreen = false
    this.currentImageIndex = 0
    this.contents = {}
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutSection2,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading3,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    },
    personalId: {
      type: String,
      require: true,
      default: () => ''
    },
    trimmingId: {
      type: String,
      require: true,
      default: () => ''
    },
    stores: {
      type: Object,
      require: true,
      default: () => {}
    }
  }
}
</script>
<style scope>
#fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}

#fullscreen img {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
</style>
