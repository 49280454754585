<template>
  <router-view />
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ForgetPasswordPage',

  mixins: [MixinLib],

  data: () => ({
  }),

  components: {
  }
}
</script>
