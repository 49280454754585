<template>
<v-bottom-sheet persistent scrollable v-model="model">
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutHeading1 :text="title" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <pre class="ec-font-size12" v-text="contents"></pre>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            text-btn
            text="同意する"
            :loading="isProcessLoading"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-bottom-sheet>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsBottomSheetDocs',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    contents: ''
  }),

  watch: {
    model () {
      if (this.model) {
        this.goLoading()
      }
    }
  },

  computed: {
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        this.contents = await APILib.getStoresDocuments(this.storeId, this.docId)
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },

  beforeDestroy () {
    this.isProcessLoading = false
    this.contents = ''
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    },
    title: {
      type: String,
      require: true,
      default: () => ''
    },
    docId: {
      type: String,
      require: true,
      default: () => ''
    },
    storeId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
