<template>
<v-btn
  :block="(block)"
  :disabled="disabled"
  :icon="iconBtn"
  :text="textBtn"
  :x-small="(xSmall !== false)"
  :small="(small !== false)"
  :outlined="outlined"
  :loading="loading"
  depressed
  :dark="(!outlined && !disabled)"
  :color="(isUndefined(color)) ? buttonColor : color"
  @click="$emit('click')"
>
  <v-icon v-if="(iconBtn)">{{ icon }}</v-icon>
  <v-icon left v-if="((!iconBtn) && icon !== '' && iconPosition === 'left')">{{ icon }}</v-icon>
  <span :class="(small !== false || xSmall !== false) ? 'ec-font-size10' : ''"  v-text="text" v-if="!iconBtn"></span>
  <v-icon right v-if="((!iconBtn) && icon !== '' && iconPosition === 'right')">{{ icon }}</v-icon>
</v-btn>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsItemsButton',

  mixins: [MixinLib],

  props: {
    color: {
      type: String,
      required: false,
      default: () => ''
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: () => false
    },
    small: {
      type: Boolean,
      required: false,
      default: () => false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    iconBtn: {
      type: Boolean,
      required: false,
      default: () => false
    },
    textBtn: {
      type: Boolean,
      required: false,
      default: () => false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: () => false
    },
    block: {
      type: Boolean,
      required: false,
      default: () => false
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    icon: {
      type: String,
      required: false,
      default: () => ''
    },
    iconPosition: {
      type: String,
      required: false,
      default: () => 'left'
    },
    text: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>
