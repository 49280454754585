import Amplify, { Auth, Hub } from 'aws-amplify'

const utilsAuthObj = {}

utilsAuthObj.Configure = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.VUE_APP_AUTH_ID_POOL_ID,
      region: process.env.VUE_APP_AUTH_REGION,
      identityPoolRegion: process.env.VUE_APP_AUTH_ID_POOL_REGION,
      userPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID,
      userPoolWebClientId: process.env.VUE_APP_AUTH_USER_POOL_CLIENT_ID,
      mandatorySignIn: false,
      cookieStorage: {
        domain: process.env.VUE_APP_DOMAIN,
        path: '/',
        expires: 365,
        // sameSite: "strict" | "lax",
        secure: true
      },
      // storage: MyStorage,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      // clientMetadata: { myCustomKey: 'myCustomValue' },
      oauth: {
        domain: process.env.VUE_APP_AUTH_DOMAIN,
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: (process.env.VUE_APP_DOMAIN === 'localhost') ? 'http://' + process.env.VUE_APP_DOMAIN + ':8080/' : 'https://' + process.env.VUE_APP_DOMAIN + '/',
        redirectSignOut: (process.env.VUE_APP_DOMAIN === 'localhost') ? 'http://' + process.env.VUE_APP_DOMAIN + ':8080/' : 'https://' + process.env.VUE_APP_DOMAIN + '/',
        responseType: 'code'
      }
    }
  })
}

utilsAuthObj.federatedSignIn = (provider) => {
  return Auth.federatedSignIn({ provider: provider })
}

utilsAuthObj.SignIn = (username, password) => {
  return Auth.signIn(username, password)
}

utilsAuthObj.SignOut = async () => {
  try {
    await Auth.signOut({ global: true })
  } catch (e) {
    console.log(e)
  }
  return true
}

utilsAuthObj.onListen = (SuccessCallBack, ErrorCallBack) => {
  return Hub.listen('auth', async (data) => {
    const { payload } = data
    return utilsAuthObj.onAuthEvent(SuccessCallBack, ErrorCallBack, payload)
  })
}

utilsAuthObj.onAuthEvent = (SuccessCallBack, ErrorCallBack, payload) => {
  if (payload.event === 'signIn') {
    return Auth.currentAuthenticatedUser().then(SuccessCallBack).catch(ErrorCallBack)
  } else {
    return false
  }
}
/*
utilsAuthObj.getSignInProvider = async () => {
  const profile = await utilsAuthObj.currentUser()
  // profile.attributes.identities
  return (profile.username.startsWith('line_')) ? process.env.VUE_APP_SIGN_IN_PROVIDER_LINE : ''
}

utilsAuthObj.currentUser = async () => {
  return await Auth.currentAuthenticatedUser()
}
*/
utilsAuthObj.getToken = async () => {
  const user = await Auth.currentAuthenticatedUser()
  return user.signInUserSession.idToken.jwtToken
}

utilsAuthObj.isLogin = async () => {
  return Auth.currentSession()
    .then((res) => {
      return true
    })
    .catch(() => {
      return false
    })
}

export default utilsAuthObj
