<template>
<v-row align="center" justify="center">

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="基本情報" border-hidden></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection1>
        <v-row align="center" justify="center">
          <v-col cols=6>
            <ComponentsLayoutHeading3 text="姓" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=6>
            <ComponentsLayoutHeading3 text="名" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=6>
            <ComponentsItemsTextField
              name="name1"
              :value.sync="personals.name1"
              :loading="loading"
              ref="name1"
            ></ComponentsItemsTextField>
          </v-col>
          <v-col cols=6>
            <ComponentsItemsTextField
              name="name2"
              :value.sync="personals.name2"
              :loading="loading"
              ref="name2"
            ></ComponentsItemsTextField>
          </v-col>

          <v-col cols=6>
            <ComponentsLayoutHeading3 text="姓（カナ）" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=6>
            <ComponentsLayoutHeading3 text="名（カナ）" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=6>
            <ComponentsItemsTextField
              name="name_kana1"
              :value.sync="personals.name_kana1"
              :loading="loading"
              ref="name_kana1"
            ></ComponentsItemsTextField>
          </v-col>
          <v-col cols=6>
            <ComponentsItemsTextField
              name="name_kana2"
              :value.sync="personals.name_kana2"
              :loading="loading"
              ref="name_kana2"
            ></ComponentsItemsTextField>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="生年月日" ></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsDatePicker
              name="birth_date_no_required"
              :value.sync="personals.birth_date"
              :loading="loading"
              :start-date="'1980-01-01'"
              ref="birth_date"
            >
            </ComponentsItemsDatePicker>
            <!--
            <ComponentsItemsTextFieldCustomType
              name="birth_date_no_required"
              type="date"
              :value.sync="personals.birth_date"
              :loading="loading"
              ref="birth_date"
            ></ComponentsItemsTextFieldCustomType>
            --->
          </v-col>

          <v-col cols=12 >
            <ComponentsLayoutHeading3 text="性別" ></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsButtonToggle
              name="sex"
              :value.sync="personals.sex"
              :loading="loading"
              :items="StorageSexPerson"
              item-text="name"
              item-value="code"
              ref="sex"
            ></ComponentsItemsButtonToggle>
          </v-col>

          <v-col cols=12 v-if="showMailAddress">
            <ComponentsLayoutHeading3 text="メールアドレス" ></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12 v-if="showMailAddress">
            <ComponentsItemsTextField
              name="mail_address"
              :value.sync="users_attribute.mail_address"
              :loading="loading"
              ref="mail_address"
            ></ComponentsItemsTextField>
          </v-col>
        </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="住所" border-hidden></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection1>
        <v-row align="center" justify="center">
          <v-col cols=12>
            <ComponentsLayoutHeading3 text="郵便番号" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <v-row align="start" justify="center">
              <v-col cols=8>
                <ComponentsItemsTextField
                  name="post_no"
                  :value.sync="users_attribute.post_no"
                  :loading="loading"
                  inputmode="numeric"
                  autocomplete="postal-code"
                  ref="post_no"
                ></ComponentsItemsTextField>
              </v-col>
              <v-col cols=4 class="text-right">
                <ComponentsItemsButton
                  text="検索"
                  :loading="loading"
                  @click="SetAddress"
                ></ComponentsItemsButton>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="都道府県" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsSelectField
              name="prefecture_id"
              :value.sync="users_attribute.prefecture_id"
              :items="StoragePrefecture"
              :loading="loading"
              itemText="name"
              itemValue="code"
              ref="prefecture_id"
            ></ComponentsItemsSelectField>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="住所" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="address"
              :value.sync="users_attribute.address"
              :loading="loading"
              ref="address"
            ></ComponentsItemsTextField>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="建物"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="building"
              :value.sync="users_attribute.building"
              :loading="loading"
              ref="building"
            ></ComponentsItemsTextField>
          </v-col>

        </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="連絡先" border-hidden></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection1>
        <v-row align="center" justify="center">
          <v-col cols=12 v-if="address_book.length >= 1">
            <ComponentsLayoutHeading3 text="連絡先電話番号1" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12 v-if="address_book.length >= 1">
            <ComponentsItemsTextField
              name="tel"
              inputmode="numeric"
              :value.sync="address_book[0].tel"
              :loading="loading"
              ref="tel1"
            ></ComponentsItemsTextField>
          </v-col>

          <v-col cols=12 class="py-1" v-if="address_book.length >= 2">
            <v-divider></v-divider>
          </v-col>

          <v-col cols=12 v-if="address_book.length >= 2">
            <ComponentsLayoutHeading3 text="連絡先電話番号2"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12 v-if="address_book.length >= 2">
            <ComponentsItemsTextField
              name="tel_sub"
              inputmode="numeric"
              :value.sync="address_book[1].tel"
              :loading="loading"
              ref="tel2"
            ></ComponentsItemsTextField>
          </v-col>

          <v-col cols=12 class="py-1" v-if="address_book.length >= 3">
            <v-divider></v-divider>
          </v-col>

          <v-col cols=12 v-if="address_book.length >= 3">
            <ComponentsLayoutHeading3 text="連絡先電話番号3"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12 v-if="address_book.length >= 3">
            <ComponentsItemsTextField
              name="tel_sub"
              inputmode="numeric"
              :value.sync="address_book[2].tel"
              :loading="loading"
              ref="tel3"
            ></ComponentsItemsTextField>
          </v-col>

        </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12 v-show="showMemberShip">
    <v-row align="center" justify="center">
      <v-col cols=12>
        <ComponentsLayoutHeading1 :text="StorageStoresMemberShip.membership_name + '登録'" border-hidden></ComponentsLayoutHeading1>
      </v-col>

      <v-col cols=12>
        <ComponentsLayoutHeading2 :text="'グルーミングサービスのご提供をするために' + StorageStoresMemberShip.membership_name + '会員へのご登録を必須とさせて頂いています。'"></ComponentsLayoutHeading2>
      </v-col>

      <v-col cols=12 v-if="!isUndefined(StorageStoresMemberShip.membership_description)">
        <ComponentsLayoutHeading2 :text="StorageStoresMemberShip.membership_description"></ComponentsLayoutHeading2>
      </v-col>

      <v-col cols=12>
        <ComponentsLayoutSection1>
          <v-row align="center" justify="center" class="py-3">

            <v-col cols=12 >
              <ComponentsLayoutHeading3 :text="UserTypeName" required></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=12 >
              <ComponentsItemsTextField
                name="members_account"
                custom-rules
                :rules="userTypeRule"
                :value.sync="fbn_personals.members_account"
                :loading="loading"
                ref="members_account"
              ></ComponentsItemsTextField>
            </v-col>

            <v-col cols=12 >
              <ComponentsLayoutHeading3 text="パスワード" required></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=12 >
              <ComponentsItemsTextFieldTypePassword
                name="members_password"
                custom-rules
                :rules="passwordRule"
                :value.sync="fbn_personals.members_password"
                :loading="loading"
                autocomplete="current-password"
                ref="members_password"
              ></ComponentsItemsTextFieldTypePassword>
            </v-col>

            <v-col cols=12 >
              <ComponentsLayoutHeading3 text="パスワード(確認)" required></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=12 >
              <ComponentsItemsTextFieldTypePassword
                name="password"
                custom-rules
                :rules="passwordRule"
                :value.sync="password"
                :loading="loading"
                ref="members_re_password"
              ></ComponentsItemsTextFieldTypePassword>
            </v-col>

            <v-col cols=12 v-show="showMailMagazine">
              <ComponentsLayoutHeading3 text="メールマガジンの配信"></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=12 v-show="showMailMagazine">
              <ComponentsItemsCheckBox
                name="mail_magazine_status"
                :value.sync="fbn_personals.mail_magazine_status"
                :loading="loading"
                text="メールマガジンを受け取る"
                ref="mail_magazine_status"
              >
              </ComponentsItemsCheckBox>
            </v-col>
            <v-col cols=12 class="pb-5" v-show="showMailMagazine">
              <span v-text="StorageStoresMemberShip.membership_melmag_description"></span>
            </v-col>

            <v-col cols=12 class="pb-5" v-show="showTerms || showPolicy">
              <ComponentsLayoutHeading3 :text="MemberShipPolicyTitle" required></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=12 class="pb-1" v-show="showTerms">
              <ComponentsItemsCheckBox
                name="agree"
                :value.sync="agree1"
                :loading="loading"
                text="利用規約に同意する"
                ref="agree"
                @change="actionOpenTOS()"
              >
              </ComponentsItemsCheckBox>
            </v-col>
            <v-col cols=12 class="pt-1" v-show="showPolicy">
              <ComponentsItemsCheckBox
                name="agree"
                :value.sync="agree2"
                :loading="loading"
                text="プライバシーポリシーに同意する"
                ref="agree"
                @change="actionOpenPolicy()"
              >
              </ComponentsItemsCheckBox>
            </v-col>
          </v-row>
        </ComponentsLayoutSection1>
      </v-col>
    </v-row>
  </v-col>

  <v-col cols=12 v-show="showAppUser">
    <v-row align="center" justify="center">
      <v-col cols=12>
        <ComponentsLayoutHeading1 text="ペトコネアカウント登録" border-hidden></ComponentsLayoutHeading1>
      </v-col>

      <v-col cols=12>
        <span>ペトコネは、愛犬の情報管理やトリミングサービスのご予約状況やグルーマーからのレポートを見ることが出来るサービスです。</span>
      </v-col>

      <v-col cols=12>
        <ComponentsLayoutSection1>
          <v-row align="center" justify="center" class="py-3">

            <v-col cols=12>
              <span>ペトコネウェブをご利用いただくためにペトコネ アカウントのご登録を必須とさせて頂いています。</span>
            </v-col>

            <v-col cols=12 >
              <ComponentsLayoutHeading3 text="アカウント" required></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=12 >
              <ComponentsItemsTextField
                name="personal_account"
                :value.sync="users_authentication.personal_account"
                :loading="loading"
                ref="personal_account"
              ></ComponentsItemsTextField>
            </v-col>

            <v-col cols=12 >
              <ComponentsLayoutHeading3 text="パスワード" required></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=12 >
              <span>1つ以上の数字、小文字（英字）、大文字（英字）が含まれる8文字以上の文字列をご入力ください。</span>
            </v-col>
            <v-col cols=12 >
              <ComponentsItemsTextFieldTypePassword
                name="personal_password"
                :value.sync="users_authentication.personal_password"
                :loading="loading"
                autocomplete="current-password"
                ref="personal_password"
              ></ComponentsItemsTextFieldTypePassword>
            </v-col>

            <v-col cols=12 >
              <ComponentsLayoutHeading3 text="パスワード(確認)" required></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=12 >
              <ComponentsItemsTextFieldTypePassword
                name="password"
                :value.sync="personal_password"
                :loading="loading"
                ref="personal_re_password"
              ></ComponentsItemsTextFieldTypePassword>
            </v-col>

            <v-col cols=12 class="pb-5">
              <ComponentsLayoutHeading3 text="利用規約に同意してアカウント登録する" required></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=12 class="pb-1">
              <ComponentsItemsCheckBox
                name="agree"
                :value.sync="agree3"
                :loading="loading"
                text="利用規約に同意する"
                ref="agree"
                @change="actionOpenAppsTOS()"
              >
              </ComponentsItemsCheckBox>
            </v-col>

          </v-row>
        </ComponentsLayoutSection1>
      </v-col>
    </v-row>
  </v-col>

  <ComponentsBottomSheetDocs
    :model="bottomSheet.member_ship_tos"
    :store-id="storeId"
    :title="StorageStoresMemberShip.membership_name + '会員利用規約'"
    :doc-id="docsMemberShipTosID"
    @close-click="bottomSheet.member_ship_tos = false"
    v-if="bottomSheet.member_ship_tos"
  >
  </ComponentsBottomSheetDocs>

  <ComponentsBottomSheetDocs
    :model="bottomSheet.member_ship_policy"
    :store-id="storeId"
    :title="StorageStoresMemberShip.membership_name + 'プライバシーポリシー'"
    :doc-id="docsMemberShipPolicyID"
    @close-click="bottomSheet.member_ship_policy = false"
    v-if="bottomSheet.member_ship_policy"
  >
  </ComponentsBottomSheetDocs>

  <ComponentsBottomSheetTOS
    :model="bottomSheet.apps_tos"
    @close-click="bottomSheet.apps_tos = false"
    v-if="bottomSheet.apps_tos"
  >
  </ComponentsBottomSheetTOS>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import SearchPostNo from 'yubinbango-core2'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsBottomSheetDocs from '@/components/bottom-sheet/Docs'
import ComponentsBottomSheetTOS from '@/components/bottom-sheet/TOS'

import ComponentsItemsButton from '@/components/items/Button'
import ComponentsItemsButtonToggle from '@/components/items/ButtonToggle'
import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsSelectField from '@/components/items/SelectField'
import ComponentsItemsTextFieldTypePassword from '@/components/items/TextFieldTypePassword'
import ComponentsItemsCheckBox from '@/components/items/CheckBox'
import ComponentsItemsDatePicker from '@/components/items/DatePicker'

export default {
  name: 'ComponentsFormsTrimmingProfilePersonals',

  mixins: [MixinLib],

  data: () => ({
    agree1: false,
    agree2: false,
    agree3: false,
    bottomSheet: {
      member_ship_tos: false,
      member_ship_policy: false,
      apps_tos: false
    },
    password: '',
    personal_password: '',
    error: false,
    rules: {
      required: v => !!v || '必須項目です',
      regexNumeric: v => (!/[0-9]/.test(v)) || '半角数字を1つ以上含めてください',
      regexAlphaUpper: v => (!/[A-Z]/.test(v)) || '半角英字（大文字）を1つ以上含めてください',
      regexAlphaLower: v => (!/[a-z]/.test(v)) || '半角英字（小文字）を1つ以上含めてください',
      regexSymbol: v => (!/[-_@]/.test(v)) || '記号(-_@)を1つ以上含めてください',
      telMatch: v => (/^[0-9]+$/.test(v)) || '半角数字のみ利用可能です',
      emailMatch: v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || '形式が正しくありません'
    }
  }),

  computed: {
    docsMemberShipTosID: {
      get () {
        return process.env.VUE_APP_DOCS_MEMBER_SHIP_TOS
      }
    },
    docsMemberShipPolicyID: {
      get () {
        return process.env.VUE_APP_DOCS_MEMBER_SHIP_POLICY
      }
    },
    UserTypeName: {
      get () {
        if (this.StorageStoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_MAIL) {
          return 'メールアドレス'
        }
        if (this.StorageStoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_TEL) {
          return '電話番号'
        }
        if (this.StorageStoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_USER) {
          return 'アカウント'
        }
        return ''
      }
    },
    userTypeRule: {
      get () {
        return this.$store.getters['modStorage/userTypeRule']
      }
    },
    passwordRule: {
      get () {
        return this.$store.getters['modStorage/passwordRule']
      }
    },
    showMailMagazine: {
      get () {
        return (this.StorageStoresMemberShip.membership_melmag_status === process.env.VUE_APP_MEMBERSHIP_MALMAG_STATUS)
      }
    },
    showTerms: {
      get () {
        return (this.StorageStoresMemberShip.membership_terms_status === process.env.VUE_APP_MEMBERSHIP_TERMS_STATUS)
      }
    },
    showPolicy: {
      get () {
        return (this.StorageStoresMemberShip.membership_policy_status === process.env.VUE_APP_MEMBERSHIP_POLICY_STATUS)
      }
    },
    MemberShipPolicyTitle: {
      get () {
        if (this.showTerms && this.showPolicy) {
          return '利用規約とプライバシーポリシーに同意して会員登録する'
        }
        if (this.showTerms) {
          return '利用規約に同意して会員登録する'
        }
        if (this.showPolicy) {
          return 'プライバシーポリシーに同意して会員登録する'
        }
        return ''
      }
    },
    showMailAddress: {
      get () {
        return !(this.showMemberShip && this.StorageStoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_MAIL)
      }
    }
    /*
    isCreateMemberShip: {
      get () {
        return (this.exists(this.fbn_personals, 'members_account')) ? (this.isUndefined(this.fbn_personals.members_account)) : true
      }
    },
    isCreateAppUser: {
      get () {
        // LINE経由は不要、登録済みは不要
        return (AuthLineLib.isInClient()) ? false : (this.exists(this.users_authentication, 'personal_account')) ? (this.isUndefined(this.users_authentication.personal_account)) : true
      }
    }
    */
  },

  methods: {
    actionOpenTOS: function () {
      if (this.agree1 === '1') {
        this.bottomSheet.member_ship_tos = true
      }
    },
    actionOpenPolicy: function () {
      if (this.agree2 === '1') {
        this.bottomSheet.member_ship_policy = true
      }
    },
    actionOpenAppsTOS: function () {
      if (this.agree3 === '1') {
        this.bottomSheet.apps_tos = true
      }
    },
    SetAddress: function () {
      if (this.isUndefined(this.users_attribute.post_no)) {
        return false
      }
      return new SearchPostNo.Core(this.users_attribute.post_no, (data) => {
        const index = this.StoragePrefecture.findIndex(row => row.name === data.region)
        this.users_attribute.prefecture_id = (index >= 0) ? this.StoragePrefecture[index].code : 0
        this.users_attribute.address = data.locality + data.street
      })
    },

    isError: function () {
      return this.error
    },

    confirm: async function () {
      this.error = false
      // メールアドレスのコピー
      if (!this.showMailAddress) {
        this.users_attribute.mail_address = this.fbn_personals.members_account
      }
      // 基本情報チェック
      const forms = [
        'name1',
        'name2',
        'name_kana1',
        'name_kana2',
        'birth_date',
        'sex',
        'tel1',
        'tel2',
        'tel3',
        'post_no',
        'prefecture_id',
        'address',
        'building',
        'mail_address'
        // 'customer_code',
        // 'apply_date',
        // 'withdraw_date',
        // 'members_code',
        // 'members_account',
        // 'members_password',
        // 'personal_account',
        // 'personal_password'
      ]
      for (const name of forms) {
        if (this.$refs[name]) {
          await this.$refs[name].confirm()
        }
        if (this.$refs[name] && this.$refs[name].error) {
          // console.log(name)
          // console.log(this.$refs[name].error)
          this.error = true
        }
      }
      if (this.error) {
        this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
        return false
      }

      // ペトコネアカウント登録チェック
      if (this.showAppUser) {
        const forms = [
          'personal_account',
          'personal_password',
          'personal_re_password'
        ]
        for (const name of forms) {
          if (this.$refs[name]) {
            await this.$refs[name].confirm()
          }
          if (this.$refs[name] && this.$refs[name].error) {
            this.error = true
          }
        }
        if (this.error) {
          this.$confirm({ title: 'Error', message: 'ペトコネアカウントの入力内容をご確認ください', button: { yes: 'OK' } })
          return false
        }
        if (this.users_authentication.personal_password !== this.personal_password) {
          this.$confirm({ title: 'Error', message: 'ペトコネアカウントのパスワードが一致していません', button: { yes: 'OK' } })
          this.error = true
          return false
        }
        if (!this.agree3) {
          this.$confirm({ title: 'Error', message: 'ペトコネアカウントの利用規約への同意は必須です', button: { yes: 'OK' } })
          this.error = true
          return false
        }
        const results = await APILib.getOAuthSignInCheck(this.users_authentication.personal_account)
        if (results) {
          this.$confirm({ title: 'Error', message: 'ご入力されたペトコネアカウントは、既にご登録済みです', button: { yes: 'OK' } })
          this.error = true
          return false
        }
      }

      // GDC会員登録チェック
      if (this.showMemberShip) {
        const forms = [
          'members_account',
          'members_password',
          'members_re_password'
        ]
        for (const name of forms) {
          if (this.$refs[name]) {
            await this.$refs[name].confirm()
          }
          if (this.$refs[name] && this.$refs[name].error) {
            this.error = true
          }
        }
        if (this.error) {
          this.$confirm({ title: 'Error', message: this.StorageStoresMemberShip.membership_name + '会員の入力内容をご確認ください。', button: { yes: 'OK' } })
          return false
        }
        if (this.fbn_personals.members_password !== this.password) {
          this.$confirm({ title: 'Error', message: this.StorageStoresMemberShip.membership_name + '会員のパスワードが一致していません', button: { yes: 'OK' } })
          this.error = true
          return false
        }
        if (this.showTerms && !this.agree1) {
          this.$confirm({ title: 'Error', message: this.StorageStoresMemberShip.membership_name + '会員の利用規約への同意は必須です', button: { yes: 'OK' } })
          this.error = true
          return false
        }
        if (this.showPolicy && !this.agree2) {
          this.$confirm({ title: 'Error', message: this.StorageStoresMemberShip.membership_name + '会員のプライバシーポリシーへの同意は必須です', button: { yes: 'OK' } })
          this.error = true
          return false
        }
        const status = await APILib.getStoresConfirmMembers(this.storeId, this.fbn_personals.members_account)
        if (!status) {
          this.$confirm({ title: 'Error', message: this.UserTypeName + 'は、' + this.StorageStoresMemberShip.membership_name + '会員として登録済みです。', button: { yes: 'OK' } })
          this.error = true
          return false
        }
      }
      return true
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsLayoutHeading3,
    ComponentsBottomSheetDocs,
    ComponentsBottomSheetTOS,
    ComponentsItemsButton,
    ComponentsItemsButtonToggle,
    // ComponentsItemsTextFieldCustomType,
    ComponentsItemsTextField,
    ComponentsItemsTextFieldTypePassword,
    // ComponentsItemsTextArea,
    ComponentsItemsCheckBox,
    ComponentsItemsDatePicker,
    ComponentsItemsSelectField
  },

  props: {
    storeId: {
      type: String,
      require: true,
      default: () => ''
    },
    personals: {
      type: Object,
      required: false,
      default: () => {
        return {
          img_src: '',
          name1: '',
          name2: '',
          name_kana1: '',
          name_kana2: '',
          sex: '9',
          birth_date: ''
        }
      }
    },
    users_attribute: {
      type: Object,
      required: false,
      default: () => {
        return {
          country: '+81',
          post_no: '',
          prefecture_id: '',
          address: '',
          building: '',
          mail_address: ''
        }
      }
    },
    address_book: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            tel: '',
            memo: ''
          },
          {
            tel: '',
            memo: ''
          },
          {
            tel: '',
            memo: ''
          }
        ]
      }
    },
    fbn_personals: {
      type: Object,
      required: false,
      default: () => {
        return {
          members_account: '',
          members_password: '',
          mail_magazine_status: ''
        }
      }
    },
    users_authentication: {
      type: Object,
      required: false,
      default: () => {
        return {
          personal_account: '',
          personal_password: ''
        }
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showAppUser: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showMemberShip: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
