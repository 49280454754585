<template>
<ComponentsLayoutSection2>
  <v-row align="center" justify="center" class="mx-auto py-5">
    <v-col cols=12>
      <ComponentsLayoutHeading3 text="アカウント" required></ComponentsLayoutHeading3>
    </v-col>
    <v-col cols=12>
      <ComponentsItemsTextField
        name="account"
        :value.sync="account"
        :loading="isProcessLoading"
        ref="account"
      ></ComponentsItemsTextField>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading3 text="パスワード" required></ComponentsLayoutHeading3>
    </v-col>
    <v-col cols=12>
      <ComponentsItemsTextFieldTypePassword
        name="password"
        :value.sync="password"
        :loading="isProcessLoading"
        autocomplete="current-password"
        ref="password"
      ></ComponentsItemsTextFieldTypePassword>
    </v-col>

    <v-col cols=12 class="pt-5 pb-1">
      <ComponentsItemsButton
        block
        :loading="isProcessLoading"
        text="ログイン"
        @click="goLogin()"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=12 v-if="(federatedSignIn)">
      <ComponentsItemsButton
        block
        :loading="isProcessLoading"
        color="green accent-4"
        text="LINEでログイン"
        @click="goLineLogin()"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>
</ComponentsLayoutSection2>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import AuthLib from '@/libs/AuthLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsTextFieldTypePassword from '@/components/items/TextFieldTypePassword'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsFormsSignIn',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    account: '',
    password: ''
  }),

  mounted () {
    this.account = this.$store.getters['modSession/account']
  },

  methods: {
    goLogin: function () {
      (async () => {
        if (this.$refs.account) {
          await this.$refs.account.confirm()
        }
        if (this.$refs.password) {
          await this.$refs.password.confirm()
        }
        if (this.$refs.account && this.$refs.account.error) {
          return false
        }
        if (this.$refs.password && this.$refs.password.error) {
          return false
        }
        this.isProcessLoading = true

        this.$store.dispatch('modSession/setAuthorization', [this.account, this.password])
        await AuthLib.SignIn(this.account, this.password)
      })().catch((e) => {
        this.actionOldLogin(this.account, this.password)
        /*
        this.isProcessLoading = false
        console.log(e)
        if (e.code === 'NotAuthorizedException') {
          return this.$confirm({ title: 'Error', message: 'メールアドレス、または、パスワードをご確認ください', button: { yes: 'OK' } })
        }
        this.$confirm({ title: 'Error', message: e, button: { yes: 'OK' } })
        */
      })
    },

    actionOldLogin: function (account, password) {
      (async () => {
        await APILib.postOAuthReset(account, password)
        await AuthLib.SignIn(account, password)
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        if (e.message === 'NotAuthorizedException') {
          return this.$confirm({ title: 'Error', message: 'メールアドレス、または、パスワードをご確認ください', button: { yes: 'OK' } })
        }
        this.$confirm({ title: 'Error', message: e, button: { yes: 'OK' } })
      })
    },

    goLineLogin: function () {
      (async () => {
        // AuthLineLib.login()
        // AuthLib.Configure()
        await AuthLib.federatedSignIn(process.env.VUE_APP_SIGN_IN_PROVIDER_LINE)
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutSection2,
    ComponentsLayoutHeading3,
    ComponentsItemsTextField,
    ComponentsItemsTextFieldTypePassword,
    ComponentsItemsButton
  },

  props: {
    federatedSignIn: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
