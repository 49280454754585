<template>
<v-row align="center" justify="center" class="pt-5" no-gutters>

  <v-col cols=12 class="pb-3">
    <ComponentsItemsCalendar
      :value="calendarDate"
      calendar-type="month"
      :loading="isProcessLoading"
      @update:value="(event) => calendarDate = event"
    >
    </ComponentsItemsCalendar>
  </v-col>

  <v-col cols=12>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center" :style="{ borderRight: '1px solid #eee' }"><span>日</span></th>
            <th class="text-center" :style="{ borderRight: '1px solid #eee' }"><span>体重<br />(kg)</span></th>
            <th class="text-center" :style="{ borderRight: '1px solid #eee' }"><span>増減<br />(kg)</span></th>
            <th class="text-center" :style="{ width: '60px' }"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" :key="item.weight_date">
            <td class="text-center ec-font-size10" :style="{ borderRight: '1px solid #eee' }">
              <span v-text="formatDate(item.weight_date,'DD (ddd)')"></span>
            </td>
            <td class="text-center" :style="{ borderRight: '1px solid #eee' }">
              <span class="ec-font-size12 font-weight-bold" :style="{ color: '#FF8300' }" v-text="item.weight"></span>
            </td>
            <td class="text-center ec-font-size12 font-weight-bold" :style="{ borderRight: '1px solid #eee' }">
              <span v-if="parseFloat(item.fluctuation) == 0">
                <span class="gray--text text--lighten-3" v-text="item.fluctuation"></span>
              </span>
              <span v-if="parseFloat(item.fluctuation) > 0">
                <span class="pink--text text--lighten-3" v-text="item.fluctuation"></span>
              </span>
              <span v-if="parseFloat(item.fluctuation) < 0">
                <span class="blue--text text--lighten-3" v-text="item.fluctuation"></span>
              </span>
            </td>
            <td class="text-center">
              <ComponentsItemsButton
                x-small
                :loading="isProcessLoading"
                icon-btn
                icon="mdi-pencil"
                @click="actionEditOpen(item)"
              >
              </ComponentsItemsButton>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-col>

  <v-dialog v-model="modal" hide-overlay transition="dialog-transition" max-width="320">
    <ComponentsItemsCalculator
      :value.sync="edit.weight"
      @set="actionCommit()"
      @close="modal = false"
      v-if="modal"
    >
    </ComponentsItemsCalculator>
  </v-dialog>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsItemsCalendar from '@/components/items/Calendar'
import ComponentsItemsCalculator from '@/components/items/Calculator'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsFormsWeightDays',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    modal: false,
    edit: {
      weight: ''
    }
  }),

  computed: {
    calendarDate: {
      get () {
        return this.$store.getters['modStorage/weightsDate']
      },
      set (v) {
        this.$store.dispatch('modStorage/LoadWeight', [this.personalId, v])
      }
    },
    list: {
      get () {
        return this.$store.getters['modStorage/weightsDaysList']
      }
    }
  },

  methods: {
    actionEditOpen: function (item) {
      this.edit = this.clone(item)
      this.modal = true
    },

    actionCommit: function () {
      (async () => {
        this.isProcessLoading = true
        const index = this.list.findIndex(v => v.weight_date === this.edit.weight_date)
        if (this.isUndefined(this.edit.weight) || this.edit.weight === 0) {
          await APILib.deleteAnimalsWeight(this.personalId, this.edit.weight_date)
          this.edit.weight = ''
          if (index >= 0) {
            this.list.splice(index, 1, this.edit)
          }
        } else if (this.list[index].weight !== '') {
          const contents = await APILib.putAnimalsWeight(this.personalId, this.edit.weight_date, { personals_weight: this.edit })
          if (index >= 0) {
            this.list.splice(index, 1, contents.personals_weight)
          }
        } else {
          const contents = await APILib.postAnimalsWeight(this.personalId, this.edit.weight_date, { personals_weight: this.edit })
          if (index >= 0) {
            this.list.splice(index, 1, contents.personals_weight)
          }
        }

        setTimeout(async () => {
          await this.$store.dispatch('modStorage/LoadWeight', [this.personalId, this.calendarDate])
          this.isProcessLoading = false
        }, 2000)
        this.modal = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  beforeDestroy () {
    this.isProcessLoading = false
    this.modal = false
    this.edit = {
      weight: ''
    }
  },

  components: {
    ComponentsItemsCalendar,
    ComponentsItemsCalculator,
    ComponentsItemsButton
  },

  props: {
    personalId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
