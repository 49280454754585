<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="プロフィール設定"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/console/index')"
>
  <v-row align="center" justify="center">

    <v-col cols=12 class="pt-5" v-show="!isUndefined(contents)">
      <ComponentsFormsProfileCustomer
        :loading="isLoading || isProcessLoading"
        :profile="contents"
        ref="customer"
      ></ComponentsFormsProfileCustomer>
    </v-col>

    <v-col cols=12>
      <ComponentsItemsButton
        block
        :loading="isLoading || isProcessLoading"
        text="更新する"
        @click="change()"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>

</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsFormsProfileCustomer from '@/components/forms/ProfileCustomer'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleUserProfilePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    contents: {}
  }),

  mounted () {
    this.contents = this.$store.getters['modStorage/users']
    this.isProcessLoading = false
  },

  methods: {
    change: function () {
      (async () => {
        this.error = false
        if (this.$refs.customer) {
          await this.$refs.customer.confirm()
          if (this.$refs.customer.error) {
            this.error = true
          }
        }

        if (this.error) {
          return false
        }
        this.isProcessLoading = true
        await APILib.putUsers(this.$refs.customer.profile)
        await this.$store.dispatch('modStorage/LoadUsers')
        this.$confirm({ title: 'Success', message: '登録内容の変更を行いました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage2,
    ComponentsFormsProfileCustomer,
    ComponentsItemsButton
  }
}
</script>
