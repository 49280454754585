<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP1. 予約申請希望選択" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/reserve/1')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP2. コース＆指名条件" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/reserve/2')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 class="pb-5" v-for="(item, index) in animals" :key="index">
      <ComponentsViewFormsTrimmingApplyConditions
        :loading="isProcessLoading"
        :courses="commonRequest[index].courses"
        :assign="commonRequest[index].assign"
        :personals="item.personals"
        :contents="item"
      >
      </ComponentsViewFormsTrimmingApplyConditions>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP3. ご予約希望日時の設定" icon="mdi-pencil" @click="pageTransition('/gr-rsv/' + storeID + '/reserve/3')"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsViewFormsTrimmingApplyCalendar
        :loading="isLoading || isProcessLoading"
        :trimming-timezone="timezone"
      >
      </ComponentsViewFormsTrimmingApplyCalendar>
    </v-col>

    <v-col cols=12 ref="TOP">
      <ComponentsLayoutHeading1 text="STEP4. お客様情報の確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsFormsTrimmingProfilePersonals
        :loading="isProcessLoading"
        :store-id="storeID"
        :personals.sync="personals"
        :users_attribute.sync="usersAttribute"
        :address_book.sync="addressBook"
        :fbn_personals.sync="fbnPersonals"
        :users_authentication.sync="usersAuthentication"
        :show-app-user="isCreateAppUser"
        ref="UserProfileForm"
      ></ComponentsFormsTrimmingProfilePersonals>
    </v-col>

    <v-col cols=12>
      <ComponentsItemsButton
        block
        :loading="isProcessLoading"
        text="次へ"
        @click="onNext()"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP5. カルテ情報の確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP6. 申請内容の最終確認"></ComponentsLayoutHeading1>
    </v-col>

  </v-row>

</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import AuthLineLib from '@/libs/AuthLineLib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsViewFormsTrimmingApplyConditions from '@/components/view-forms/TrimmingApplyConditions'
import ComponentsViewFormsTrimmingApplyCalendar from '@/components/view-forms/TrimmingApplyCalendar'
import ComponentsFormsTrimmingProfilePersonals from '@/components/forms/TrimmingProfilePersonals'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingReserveTrimmingMemberStep4Page',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    UsersStores: {
      get () {
        return this.$store.getters['modStorage/usersStores']
      }
    },
    commonRequest: {
      get () {
        return this.$store.getters['modTrimming/commonRequest']
      }
    },
    animals: {
      get () {
        return this.$store.getters['modTrimming/animals']
      }
    },
    timezone: {
      get () {
        return this.$store.getters['modTrimming/trimmingTimezone']
      }
    },
    fbnPersonals: {
      get () {
        return this.$store.getters['modTrimming/fbnPersonals']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changeFbnPersonals', v)
      }
    },
    personals: {
      get () {
        return this.$store.getters['modTrimming/personals']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changePersonals', v)
      }
    },
    usersAttribute: {
      get () {
        return this.$store.getters['modTrimming/usersAttribute']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changeUsersAttribute', v)
      }
    },
    addressBook: {
      get () {
        return this.$store.getters['modTrimming/addressBook']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changeAddressBook', v)
      }
    },
    usersAuthentication: {
      get () {
        return this.$store.getters['modTrimming/usersAuthentication']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changeUsersAuthentication', v)
      }
    },
    isCreateAppUser: {
      get () {
        if (AuthLineLib.isInClient()) {
          return false
        }
        if (this.isUndefined(this.UsersStores)) {
          return false
        }
        if (this.isUndefined(this.UsersStores.users_authentication)) {
          return false
        }
        return (this.isUndefined(this.UsersStores.users_authentication.personal_account))
      }
    }
  },

  created () {
    setTimeout(() => (
      this.$refs.TOP.scrollIntoView({ behavior: 'smooth' })
    ))
  },

  mounted () {
    this.onLoading()
  },

  methods: {
    onLoading: function () {
      this.isProcessLoading = false
    },
    onNext: function () {
      (async () => {
        if (this.$refs.UserProfileForm) {
          await this.$refs.UserProfileForm.confirm()
          if (this.$refs.UserProfileForm.error) {
            // this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
            return false
          }
        }
        // this.animals = this.trimmingRequest.filter((v, idx) => (this.trimmingCount > idx)).map(v => this.clone(this.format))
        this.pageTransition('/gr-rsv/' + this.storeID + '/reserve/5')
        return true
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '入力内容をご確認ください [' + e.message + ']', button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsViewFormsTrimmingApplyConditions,
    ComponentsViewFormsTrimmingApplyCalendar,
    ComponentsFormsTrimmingProfilePersonals,
    ComponentsItemsButton
  }
}
</script>
