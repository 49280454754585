import Moment from 'moment'
Moment.locale('ja', {
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
})

const UtilLib = {

  clone: function (obj) {
    return JSON.parse(JSON.stringify(obj))
  },

  isDebug: function () {
    return (parseInt(process.env.VUE_APP_DEBUG_MODE) !== 0)
  },

  // =======================================================================
  //
  // オブジェクト操作系
  //
  //
  exists: function (obj, key) {
    return (UtilLib.isObject(obj)) ? (Object.keys(obj).some(v => v === key)) : false
  },

  isObject: function (o) {
    return (o instanceof Object && !(o instanceof Array))
  },

  isArray: function (obj) {
    return (obj instanceof Array)
  },

  isEmpty: function (obj) {
    return UtilLib.isUndefined(obj) || (obj == null) || (obj === '')
  },

  isUndefined: function (obj) {
    return (!obj) ? (obj !== 0 && obj !== false) : ((typeof obj === 'object') ? Object.keys(obj).length === 0 : false)
  },

  isPartialMatch: function (str, match) {
    var regex = RegExp(match)
    return regex.test(str)
  },

  getValue: function (obj, s) {
    if (UtilLib.isObject(obj) === false) {
      return null
    }
    return (UtilLib.exists(obj, s)) ? obj[s] : null
  },

  // =======================================================================
  //
  // 日付操作系
  //
  //
  getStartWeekDate: function (dateTime, format, startWeek) {
    if (startWeek === 'sun') {
      return UtilLib.addDateTime(Moment(dateTime).startOf('isoWeek'), -1, 'days').format(format)
    }
    if (startWeek === 'mon') {
      return UtilLib.addDateTime(Moment(dateTime).startOf('isoWeek'), 0, 'days').format(format)
    }
    if (startWeek === 'sat') {
      return UtilLib.addDateTime(Moment(dateTime).startOf('isoWeek'), -2, 'days').format(format)
    }
    return UtilLib.addDateTime(Moment(dateTime).startOf('isoWeek'), -1, 'days').format(format)
  },

  getWeekDate: function (dateTime, format, weekType) {
    if (weekType === 'sat') {
      return UtilLib.addDateTime(Moment(dateTime).startOf('isoWeek'), 5, 'days').format(format)
    } else if (weekType === 'mon') {
      return UtilLib.addDateTime(Moment(dateTime).startOf('isoWeek'), 0, 'days').format(format)
    }
    return UtilLib.addDateTime(Moment(dateTime).startOf('isoWeek'), -2, 'days').format(format)
  },

  getDate: function (dateTime, format) {
    return (dateTime) ? Moment(dateTime).format(format) : Moment().format(format)
  },

  formatDate: function (dateTime, format) {
    return (dateTime) ? Moment(dateTime).format(format) : ''
  },

  checkDate: function (dateTime) {
    return Moment(dateTime).isValid()
  },

  addDateTime (dateTime, add = 1, addType = 'days') {
    return Moment(dateTime).add(add, addType)
  },

  getStartOfMonth (dateTime, dateFormat = 'YYYY-MM-DD') {
    return Moment(dateTime).startOf('month').format(dateFormat)
  },

  getDateOfWeek (dateTime) {
    return Moment(dateTime).day()
  },

  getEndOfMonth (dateTime, dateFormat = 'YYYY-MM-DD') {
    return Moment(dateTime).endOf('month').format(dateFormat)
  },

  diffDateTime: function (s, e, f = 'HH:mm:ss', rf = 'hours') {
    return Moment(e, f).diff(Moment(s, f), rf)
  },

  getWeekDay: function (date) {
    return Moment(date).weekday()
  },

  getAge: function (date) {
    if (UtilLib.isUndefined(date)) {
      return '-'
    }
    return Moment().diff(Moment(date), 'years')
  },

  getMonthDateArray (dateTime) {
    return Array(parseInt(this.getEndOfMonth(dateTime, 'DD'))).fill().map((v, idx) => Moment(dateTime).add(idx, 'days').format('YYYY-MM-DD'))
  },

  getHoursArray (start, end) {
    return Array(parseInt(UtilLib.diffDateTime('2000-01-01 ' + start, '2000-01-01 ' + end, 'YYYY-MM-DD HH:mm', 'hours'))).fill().map((v, idx) => {
      return Moment('2000-01-01 ' + start).add(idx, 'hours').format('HH:00')
    })
  },

  diffMonth (s, e) {
    const strDate = new Date(s)
    const endDate = new Date(e)
    const strMonth = strDate.getFullYear() * 12 + strDate.getMonth()
    const endMonth = endDate.getFullYear() * 12 + endDate.getMonth()
    return endMonth - strMonth
  },

  // =======================================================================
  //
  // その他システム依存
  //
  //
  getSterilizationStatus: function () {
    return [
      {
        code: '1',
        name: '手術済'
      },
      {
        code: '2',
        name: '未手術'
      }
      /*
      {
        code: '9',
        name: '未選択'
      }
      */
    ]
  },

  getLicenseStatus: function () {
    return [
      {
        code: '1',
        name: '交付済み'
      },
      {
        code: '2',
        name: '未交付'
      }
      /*
      {
        code: '9',
        name: '未選択'
      }
      */
    ]
  },

  getMicrochipStatus: function () {
    return [
      {
        code: '1',
        name: '装着済み'
      },
      {
        code: '2',
        name: '未装着'
      }
      /*
      {
        code: '9',
        name: '未選択'
      }
      */
    ]
  },

  getFamilyDoctorStatus: function () {
    return [
      {
        code: '1',
        name: 'あり'
      },
      {
        code: '2',
        name: 'なし'
      }
      /*
      {
        code: '9',
        name: '未選択'
      }
      */
    ]
  },

  getAllergyStatus: function () {
    return [
      {
        code: '1',
        name: 'あり'
      },
      {
        code: '2',
        name: 'なし'
      }
      /*
      {
        code: '9',
        name: '未選択'
      }
      */
    ]
  },

  getSnackStatus: function () {
    return [
      {
        code: '1',
        name: 'はい'
      },
      {
        code: '2',
        name: 'いいえ'
      }
      /*
      {
        code: '9',
        name: '未選択'
      }
      */
    ]
  },

  getSnackStatusTagName: function () {
    return [
      {
        code: '1',
        name: 'おやつ OK'
      },
      {
        code: '2',
        name: 'おやつ NG'
      }
    ]
  },

  getStatusSalesTax: function () {
    return [
      {
        code: '1',
        name: '税抜'
      },
      {
        code: '2',
        name: '税込'
      },
      {
        code: '3',
        name: '非課税'
      }
    ]
  },

  getStatusFractionDigits: function () {
    return [
      {
        code: '1',
        name: '円未満'
      },
      {
        code: '2',
        name: '10円未満'
      },
      {
        code: '3',
        name: '100円未満'
      }
    ]
  },

  getStatusFractionRound: function () {
    return [
      {
        code: '1',
        name: '切捨'
      },
      {
        code: '2',
        name: '四捨五入'
      },
      {
        code: '3',
        name: '切上'
      }
    ]
  },

  getCalendarDateType: function () {
    return [
      {
        code: 'day',
        name: '日'
      },
      {
        code: '4day',
        name: '4日'
      },
      {
        code: 'week',
        name: '週'
      }
    ]
  },

  getStartWeek: function () {
    return [
      {
        code: 'sun',
        name: '日曜'
      },
      {
        code: 'mon',
        name: '月曜'
      },
      {
        code: 'sat',
        name: '土曜'
      }
    ]
  },

  getInterval: function () {
    return [
      {
        code: '15',
        name: '15分'
      },
      {
        code: '30',
        name: '30分'
      },
      {
        code: '60',
        name: '1時間'
      }
    ]
  },

  getLanguage: function () {
    return [
      {
        code: 'ja-jp',
        name: '日本語'
      },
      {
        code: 'en-US',
        name: '英語'
      }
    ]
  }
}

export default UtilLib
