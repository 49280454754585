<template>
<v-row align="center" justify="center">
  <!--
  <v-col cols=12 class="text-right">
    <ComponentsItemsButton
      small
      :loading="loading"
      text="条件を変更する"
      @click="$emit('on-change-click')"
    >
    </ComponentsItemsButton>
  </v-col>
  --->

  <v-col cols=12>
    <ComponentsLayoutSection2>
      <v-row align="center" justify="center">
        <v-col cols=12 v-for="(item, index) of newerRequest" :key="index">
          <v-row align="center" justify="center">
            <v-col cols=12 class="py-1" v-if="index > 0">
              <v-divider></v-divider>
            </v-col>
            <v-col cols=4 >
              <ComponentsLayoutHeading3 text="希望コース" class="pb-3"></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=8 class="text-truncate text-left">
              <span v-text="getCategoriesName(item.courses)"></span>
            </v-col>
            <v-col cols=4 v-show="StoreNewAssignStatusEnabled">
              <ComponentsLayoutHeading3 text="指名" class="pb-3"></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=8 class="text-truncate text-left" v-show="StoreNewAssignStatusEnabled">
              <span v-text="getGroomerName(item.assign)"></span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </ComponentsLayoutSection2>
  </v-col>

</v-row>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection2 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

// import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsTrimmingNewerRequest',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    StoreNewAssignStatusEnabled: {
      get () {
        return (this.getValue(this.getValue(this.StorageStoreServiceContents, 'stores_service_web_reserve'), 'store_new_assign_status') === '1')
      }
    },
    StorageGroomer: {
      get () {
        return this.$store.getters['modStorage/storesGroomer']
      }
    },
    StorageCategories: {
      get () {
        return this.$store.getters['modStorage/storesGroomingCategories']
      }
    },
    groomer: {
      get () {
        return this.StorageGroomer.map(v => { return { name: v.business_users.name, code: v.business_users_id } })
      }
    },
    categories: {
      get () {
        return this.StorageCategories.map(v => { return { name: v.item_categories.item_category_name, code: v.item_category_id } })
      }
    }
  },

  methods: {
    getGroomerName: function (code) {
      const index = this.StorageGroomer.findIndex(v => v.business_users_id === code)
      return (index >= 0) ? this.StorageGroomer[index].business_users.name : 'おまかせ'
    },
    getCategoriesName: function (code) {
      const index = this.StorageCategories.findIndex(v => v.item_category_id === code)
      return (index >= 0) ? this.StorageCategories[index].item_categories.item_category_name : '選択してください'
    }
  },

  components: {
    ComponentsLayoutSection2,
    ComponentsLayoutHeading3
    // ComponentsItemsButton
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    newerRequest: {
      type: Array,
      require: false,
      default: () => []
    }
  }
}
</script>
