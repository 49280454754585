<template>
<v-row align="center" justify="center">
  <v-col cols=12 class="py-0 text-left text-truncate">
    <span class="ec-font-size10" v-text="getValue(personals, 'name_kana1')"></span>
  </v-col>
  <v-col cols=12 class="py-0 ec-font-size16 text-left text-truncate">
    <span class="font-weight-bold" v-text="getValue(personals, 'name1')"></span>
  </v-col>
  <v-col cols=12 class="pt-3 pb-0 text-left text-truncate">
    <span class="pr-3" v-text="getAnimalSexName(getValue(personals, 'sex'))"></span><span class="pr-3" v-text="getValue(personals, 'animal')"></span><span v-text="getValue(personals, 'species')"></span>
  </v-col>
  <v-col cols=12 class="py-0 text-left text-truncate">
    <span class="pr-3" v-text="formatDate(getValue(personals, 'birth_date'), 'YYYY/MM/DD')"></span><span v-text="getAge(getValue(personals, 'birth_date')) + '歳'"></span>
  </v-col>
</v-row>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsViewFormsShortAnimalProfile',

  mixins: [MixinLib],

  data: () => ({
  }),

  props: {
    personals: {
      type: Object,
      require: false,
      default: () => {
        return {
          img_src: '',
          name1: '',
          name2: '',
          name_kana1: '',
          name_kana2: '',
          animal: '犬',
          species: '',
          sex: '9',
          birth_date: ''
        }
      }
    }
  }
}
</script>
