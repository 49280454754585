<template>
<v-bottom-sheet persistent scrollable v-model="model" v-if="model">
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="pb-5">
          <ComponentsLayoutHeading1 text="ご予約希望日時の設定" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="start" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutHeading2 text="※混雑状況やご予約申請の申請順などにより、ご希望に添えない場合がございますのでご了承ください。"></ComponentsLayoutHeading2>
        </v-col>
        <v-col :cols="($vuetify.breakpoint.mdAndUp) ? 6 : 12">
          <v-row align="start">
            <v-col cols=12>
              <ComponentsLayoutHeading1 text="ご希望の日付を選択してください。"></ComponentsLayoutHeading1>
            </v-col>
            <v-col cols=12>
              <ComponentsItemsDatePicker
                name="trimming_date"
                :value.sync="edit.trimming_date"
                :loading="loading || isProcessLoading"
                pickerType="DATE"
                :allowed-dates="allowedDates"
                :min="minDate"
                :max="maxDate"
                :event-color="eventColor"
                :events="arrayEvents"
                no-title
                read-only
                @change="reloadCalendar"
              >
              </ComponentsItemsDatePicker>
              <!--
              <v-date-picker
                v-model="edit.trimming_date"
                locale="jp-ja"
                :color="buttonColor"
                :allowed-dates="allowedDates"
                :day-format="(date) => new Date(date).getDate()"
                :min="minDate"
                :max="maxDate"
                :event-color="eventColor"
                :events="arrayEvents"
                no-title
              ></v-date-picker>
              -->
            </v-col>
            <!--
            <v-col cols=12 class="text-left">
              <v-avatar color="red" size=8 class="pr-3"></v-avatar><span class="pr-3">残り僅か（直接ご連絡ください）</span>
            </v-col>
            <v-col cols=12 class="text-left">
              <v-avatar color="red" size=8 class="pr-3"></v-avatar><span class="pr-3">締め切り</span>
            </v-col>
            --->
          </v-row>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutHeading2 :text="formatDate(minDate,'YYYY年MM月DD日') + 'から' + formatDate(maxDate,'YYYY年MM月DD日') + 'のご予約の申請が可能です。'"></ComponentsLayoutHeading2>
        </v-col>
        <v-col :cols="($vuetify.breakpoint.mdAndUp) ? 6 : 12">
          <v-row align="start">
            <v-col cols=12>
              <ComponentsLayoutHeading1 text="ご希望のグルーミング開始時間を選択してください。"></ComponentsLayoutHeading1>
            </v-col>
            <v-col cols=12 v-for="(item, index) in selectTimes" :key="index">
              <ComponentsLayoutSection1
                :color="(edit.times === item.time_name) ? secondaryActiveColor : ''"
                on-click-action
                @click="edit.times = item.time_name"
              >
                <v-row no-gutters>
                  <v-col cols=2>
                    <v-icon class="pr-3" color="primary">mdi-circle-outline</v-icon>
                  </v-col>
                  <v-col cols=10 class="text-left">
                    <v-row no-gutters>
                      <v-col cols=12>
                        <span v-text="item.time_name"></span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </ComponentsLayoutSection1>
            </v-col>
            <v-col cols=12>
              <ComponentsLayoutSection1
                :color="(edit.times === TimesFreeText) ? secondaryActiveColor : ''"
                on-click-action
                @click="edit.times = TimesFreeText"
              >
                <v-row no-gutters>
                  <v-col cols=2>
                    <v-icon class="pr-3" color="primary">mdi-circle-outline</v-icon>
                  </v-col>
                  <v-col cols=10 class="text-left">
                    <v-row no-gutters>
                      <v-col cols=12>
                        <span>上記以外の時間帯を希望する</span>
                      </v-col>
                      <v-col cols=12 class="ec-font-size12 py-3">
                        <span>受付希望の時間帯をご入力ください</span>
                      </v-col>
                      <v-col cols=12>
                        <v-row justify="start">
                          <v-col cols=12 class="d-flex align-center">
                            <ComponentsItemsTextFieldTypeTime
                              name="trimming_str_time"
                              :value.sync="edit.trimming_str_time"
                              :loading="loading || isProcessLoading"
                            >
                            </ComponentsItemsTextFieldTypeTime>
                            <span class="px-3">～</span>
                            <ComponentsItemsTextFieldTypeTime
                              name="trimming_end_time"
                              :value.sync="edit.trimming_end_time"
                              :loading="loading || isProcessLoading"
                            >
                            </ComponentsItemsTextFieldTypeTime>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </ComponentsLayoutSection1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <ComponentsItemsButton
            text-btn
            text="閉じる"
            :loading="isProcessLoading"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            :loading="isProcessLoading"
            text="希望日時を設定する"
            @click="setDateTime()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>

</v-bottom-sheet>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'
import ComponentsLayoutSection1 from '@/components/layout/Section1'

import ComponentsItemsDatePicker from '@/components/items/DatePicker'
import ComponentsItemsTextFieldTypeTime from '@/components/items/TextFieldTypeTime'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsBottomSheetTrimmingCalendar',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    // startDays: 3,
    // rangeMonth: 2,
    arrayEvents: [],
    editTime: '',
    edit: {
      priority: 1,
      trimming_date: '',
      times: '',
      trimming_str_time: '',
      trimming_end_time: ''
    }
  }),

  computed: {
    commonRequest: {
      get () {
        return this.$store.getters['modTrimming/commonRequest']
      }
    },
    storeServiceTimes: {
      get () {
        return this.$store.getters['modStorage/storeServiceTimes']
      }
    },
    storeService: {
      get () {
        return this.$store.getters['modStorage/storeServiceContents']
      }
    },
    minDateSetting: {
      get () {
        if (this.newer) {
          if (this.storeService.stores_service_web_reserve.store_new_range_type === '1') {
            return this.formatDate(this.addDateTime([], this.storeService.stores_service_web_reserve.store_new_range_start, 'days'), 'YYYY-MM-DD')
          } else {
            // 月末の場合のみの特殊処理
            if (this.storeService.stores_service_web_reserve.store_new_range_next === '31') {
              if (this.formatDate([], 'DD') === this.getEndOfMonth([], 'DD')) {
                if (parseInt(this.storeService.stores_service_web_reserve.store_new_range_end) === 1) {
                  return this.formatDate(this.addDateTime([], 1 + parseInt(this.storeService.stores_service_web_reserve.store_new_range_start), 'months'), 'YYYY-MM') + '-01'
                }
              }
            }
            // 月初の場合のみの特殊処理
            // if (parseInt(this.storeService.stores_service_web_reserve.store_new_range_next) === 1) {
            //   return this.formatDate(this.addDateTime([], 0 + parseInt(this.storeService.stores_service_web_reserve.store_new_range_start), 'months'), 'YYYY-MM') + '-01'
            // }
            return (parseInt(this.formatDate([], 'DD')) > parseInt(this.storeService.stores_service_web_reserve.store_new_range_next))
              ? this.formatDate(this.addDateTime([], 1 + parseInt(this.storeService.stores_service_web_reserve.store_new_range_start), 'months'), 'YYYY-MM') + '-01'
              : this.formatDate(this.addDateTime([], 0 + parseInt(this.storeService.stores_service_web_reserve.store_new_range_start), 'months'), 'YYYY-MM') + '-01'
          }
        } else {
          if (this.storeService.stores_service_web_reserve.store_rep_range_type === '1') {
            return this.formatDate(this.addDateTime([], this.storeService.stores_service_web_reserve.store_rep_range_start, 'days'), 'YYYY-MM-DD')
          } else {
            // 月末の場合のみの特殊処理
            if (this.storeService.stores_service_web_reserve.store_rep_range_next === '31') {
              if (this.formatDate([], 'DD') === this.getEndOfMonth([], 'DD')) {
                if (parseInt(this.storeService.stores_service_web_reserve.store_rep_range_end) === 1) {
                  return this.formatDate(this.addDateTime([], 1 + parseInt(this.storeService.stores_service_web_reserve.store_rep_range_start), 'months'), 'YYYY-MM') + '-01'
                }
              }
            }
            // 月初の場合のみの特殊処理
            // if (parseInt(this.storeService.stores_service_web_reserve.store_rep_range_next) === 1) {
            //   return this.formatDate(this.addDateTime([], 0 + parseInt(this.storeService.stores_service_web_reserve.store_rep_range_start), 'months'), 'YYYY-MM') + '-01'
            // }
            return (parseInt(this.formatDate([], 'DD')) > parseInt(this.storeService.stores_service_web_reserve.store_rep_range_next))
              ? this.formatDate(this.addDateTime([], 1 + parseInt(this.storeService.stores_service_web_reserve.store_rep_range_start), 'months'), 'YYYY-MM') + '-01'
              : this.formatDate(this.addDateTime([], 0 + parseInt(this.storeService.stores_service_web_reserve.store_rep_range_start), 'months'), 'YYYY-MM') + '-01'
          }
        }
      }
    },
    minDate: {
      get () {
        return (this.formatDate(this.addDateTime([], 1, 'days'), 'YYYY-MM-DD') >= this.minDateSetting) ? this.formatDate(this.addDateTime([], 1, 'days'), 'YYYY-MM-DD') : this.minDateSetting
      }
    },
    maxDate: {
      get () {
        if (this.newer) {
          if (this.storeService.stores_service_web_reserve.store_new_range_type === '1') {
            return this.formatDate(this.addDateTime(this.minDate, this.storeService.stores_service_web_reserve.store_new_range_end, 'days'), 'YYYY-MM-DD')
          } else {
            return this.getEndOfMonth(this.addDateTime(this.minDate, this.storeService.stores_service_web_reserve.store_new_range_end - 1, 'months'), 'YYYY-MM-DD')
          }
        } else {
          if (this.storeService.stores_service_web_reserve.store_rep_range_type === '1') {
            return this.formatDate(this.addDateTime(this.minDate, this.storeService.stores_service_web_reserve.store_rep_range_end, 'days'), 'YYYY-MM-DD')
          } else {
            return this.getEndOfMonth(this.addDateTime(this.minDate, this.storeService.stores_service_web_reserve.store_rep_range_end - 1, 'months'), 'YYYY-MM-DD')
          }
        }
      }
    },
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    TimesFreeText: {
      get () {
        return process.env.VUE_APP_TRIMMING_TIMES_FREE_TEXT
      }
    },
    selectTimes: {
      get () {
        return this.$store.getters['modStorage/storeReserveTimes']
      }
    }
  },

  created () {
  },

  mounted () {
    this.goLoading()
  },

  methods: {
    async goLoading () {
      (async () => {
        this.isProcessLoading = true
        this.edit = this.clone(this.trimmingTimezone)
        if (this.isUndefined(this.edit.trimming_date)) {
          this.edit.trimming_date = this.minDate
        }
        await this.reloadCalendar()
        if (this.arrayEvents.some(item => item === this.edit.trimming_date) === false) {
          this.edit.trimming_date = (this.arrayEvents.length === 0) ? null : this.arrayEvents[0]
        }
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },
    async reloadCalendar () {
      this.isProcessLoading = true
      const strMonthDate = this.getStartOfMonth(this.edit.trimming_date, 'YYYY-MM-DD')
      const dMonth = this.diffMonth(strMonthDate, this.maxDate) + 1
      this.arrayEvents = Array(parseInt(dMonth)).fill().reduce((pre, cur, idx) => { return [...pre, ...this.getMonthDateArray(this.addDateTime(strMonthDate, idx, 'months'))] }, [])
      for (const item of this.commonRequest) {
        if (this.isUndefined(item.assign) === false) {
          const dates = await APILib.getStoresServiceReservableDates(this.storeID, item.assign, strMonthDate, dMonth)
          this.arrayEvents = dates.filter(v => this.arrayEvents.indexOf(v) >= 0)
        }
      }
      this.arrayEvents.sort()
      this.isProcessLoading = false
    },
    // 選択制御
    allowedDates: function (date) {
      const week = (this.isUndefined(date)) ? 1 : this.getDateOfWeek(date) + 1
      const $StoreTimes = this.storeServiceTimes.find(item => parseInt(item.open_weeks) === week)
      return ($StoreTimes.open_status === '1') && (this.arrayEvents.indexOf(date) >= 0)
    },
    //
    eventColor: function (date) {
      return (this.allowedDates(date)) ? '' : 'red'
      // return (this.arrayEvents.indexOf(date) >= 0) ? '' : 'red'
    },
    setDateTime: function () {
      if (this.isUndefined(this.edit.trimming_date)) {
        return this.$confirm({ title: 'Error', message: 'ご予約希望日を選択してください', button: { yes: 'OK' } })
      }
      if (this.isUndefined(this.edit.times)) {
        return this.$confirm({ title: 'Error', message: 'ご予約の受付時間帯を選択してください', button: { yes: 'OK' } })
      }
      if (this.edit.times === this.TimesFreeText) {
        if (this.isUndefined(this.edit.trimming_str_time)) {
          return this.$confirm({ title: 'Error', message: '受付希望の開始時間を入力してください', button: { yes: 'OK' } })
        }
        if (this.checkDate(this.edit.trimming_date + ' ' + this.edit.trimming_str_time) === false) {
          return this.$confirm({ title: 'Error', message: '受付希望の開始時間が時間として正しくありません', button: { yes: 'OK' } })
        }
        if (this.isUndefined(this.edit.trimming_end_time)) {
          return this.$confirm({ title: 'Error', message: '受付希望の終了時間を入力してください', button: { yes: 'OK' } })
        }
        if (this.checkDate(this.edit.trimming_date + ' ' + this.edit.trimming_end_time) === false) {
          return this.$confirm({ title: 'Error', message: '受付希望の終了時間が時間として正しくありません', button: { yes: 'OK' } })
        }
        const strTime = this.formatDate(this.edit.trimming_date + ' ' + this.edit.trimming_str_time, 'HH:mm')
        const endTime = this.formatDate(this.edit.trimming_date + ' ' + this.edit.trimming_end_time, 'HH:mm')
        if (strTime >= endTime) {
          return this.$confirm({ title: 'Error', message: '受付希望の終了時間は、開始時間以降の時間を入力して下さい', button: { yes: 'OK' } })
        }
      } else {
        const index = this.selectTimes.findIndex(item => item.time_name === this.edit.times)
        if (index >= 0) {
          this.edit.trimming_str_time = this.clone(this.selectTimes[index].str_time)
          this.edit.trimming_end_time = this.clone(this.selectTimes[index].end_time)
        } else {
          return this.$confirm({ title: 'Error', message: '不正な操作を検知しました', button: { yes: 'OK' } })
        }
      }
      this.$emit('on-commit-click', this.edit)
      /// this.$emit('next-click', this.edit.dates, this.edit.times.map(v => this.ItemTimes[v].code))
    }
  },

  beforeDestroy () {
    this.isProcessLoading = false
    this.arrayEvents = []
    this.editTime = ''
    this.edit = {
      priority: 1,
      trimming_date: '',
      times: '',
      trimming_str_time: '',
      trimming_end_time: ''
    }
  },

  components: {
    // ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsLayoutSection1,
    // ComponentsLayoutHeading3,
    ComponentsItemsDatePicker,
    ComponentsItemsTextFieldTypeTime,
    // ComponentsItemsRadioGroup,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    newer: {
      type: Boolean,
      require: false,
      default: () => false
    },
    trimmingTimezone: {
      type: Object,
      require: false,
      default: () => {
        return {
          priority: 1,
          trimming_date: '',
          times: '',
          trimming_str_time: '',
          trimming_end_time: ''
        }
      }
    }
  }
}
</script>
