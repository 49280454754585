<template>
<v-app :style="{ backgroundColor: backGroundColor }">

  <div id="scrollTop"></div>

  <v-app-bar fixed flat app :color="(isUndefined(StorageStoreContents)) ? primaryColor : StorageStoreContents.stores_profile.store_color" height="60" v-show="header" class="mx-auto" max-width=840>
    <v-row align="center" justify="center">
      <v-col cols=3 class="text-left">
        <ComponentsItemsButton
          small
          text-btn
          :icon="headerLeftIcon"
          :loading="loading"
          :text="headerLeftText"
          @click="$emit('left-click')"
          v-show="headerLeft"
        >
        </ComponentsItemsButton>
      </v-col>
      <v-col cols=6 >
        <v-img
          :src="(isUndefined(StorageStoreContents)) ? '' : StorageStoreContents.stores_profile.store_logo_src"
          :loading="loading"
          class="mx-auto pt-1"
          contain
          width="120"
          height="auto"
        >
        </v-img>
      </v-col>
      <v-col cols=3 class="text-right pa-0">
        <ComponentsItemsTextIconButton
          icon="mdi-help-circle"
          :loading="loading"
          text="HELP"
          color="grey darken-2"
          @click="modal = true"
        >
        </ComponentsItemsTextIconButton>
      </v-col>
    </v-row>
  </v-app-bar>

  <v-main>
    <v-container fluid class="ec-font-size12" style="max-width: 840px;">
      <v-row align="center" justify="center" class="mx-auto">
        <v-col cols=12 class="px-0">
          <v-img
            :src="(isUndefined(StorageStoreContents)) ? '' : StorageStoreContents.stores_profile.store_img_src"
            :loading="loading"
            class="mx-auto pt-1 align-end grey lighten-2"
            width="100%"
            height="auto"
            contain
          >
            <span class="white--text ec-font-size12 pl-1 pb-1" v-text="(isUndefined(StorageStoreContents)) ? '' : StorageStoreContents.stores.store_name"></span>
          </v-img>
        </v-col>
        <v-col cols=12 class="px-0 pt-5">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </v-main>

  <v-footer absolute app :color="primaryColor" padless height="40" v-show="footer">
    <v-row no-gutters>
      <v-col cols="12" class="py-2 text-center">
        <span class="'mx-auto ec-font-size10" >copyright (c) ColorZoo.All rights reserved..</span>
      </v-col>
    </v-row>
  </v-footer>

  <ComponentsBottomSheetFAQ
    :model="modal"
    @close-click="modal = false"
  >
  </ComponentsBottomSheetFAQ>

</v-app>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsBottomSheetFAQ from '@/components/bottom-sheet/FAQ'

import ComponentsItemsButton from '@/components/items/Button'
import ComponentsItemsTextIconButton from '@/components/items/TextIconButton'

export default {
  name: 'ComponentsLayoutPage3',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    modal: false
  }),

  computed: {
    profile: {
      get () {
        return this.getValue('profile', this.StorageShopProfile) || {}
      }
    },
    customize: {
      get () {
        return this.getValue('customize', this.StorageShopProfile) || {}
      }
    }
  },

  components: {
    ComponentsBottomSheetFAQ,
    ComponentsItemsButton,
    ComponentsItemsTextIconButton
  },

  props: {
    header: { type: Boolean, required: false, default: () => false },
    footer: { type: Boolean, required: false, default: () => false },
    headerTitle: { type: String, require: false, default: () => '' },
    headerLeft: { type: Boolean, required: false, default: () => false },
    headerLeftIcon: { type: String, require: false, default: () => '' },
    headerLeftText: { type: String, require: false, default: () => '' },
    loading: { type: Boolean, required: false, default: () => false }
  }
}
</script>
