<template>
<v-row align="center" justify="center" class="pt-5">
  <v-col cols=12 v-show="vaccineImageRequire">
    <ComponentsLayoutHeading3 text="証明画像" ></ComponentsLayoutHeading3>
  </v-col>
  <v-col cols=12 v-show="vaccineImageRequire">
    <ComponentsItemsImageUpload
      :src.sync="personalsVaccine.img_src"
      :loading="loading"
    >
    </ComponentsItemsImageUpload>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading3 text="接種日/投薬日/猶予開始日" ></ComponentsLayoutHeading3>
  </v-col>
  <v-col cols=12>
    <ComponentsItemsDatePicker
      name="action_date"
      :value.sync="personalsVaccine.action_date"
      :loading="loading"
      ref="action_date"
    >
    </ComponentsItemsDatePicker>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading3 text="接種/投薬/猶予 期限日" required></ComponentsLayoutHeading3>
  </v-col>
  <v-col cols=12>
    <ComponentsItemsDatePicker
      name="expire_date"
      :value.sync="personalsVaccine.expire_date"
      :loading="loading"
      ref="expire_date"
    >
    </ComponentsItemsDatePicker>
  </v-col>

  <v-col cols=12 v-show="vaccineImageRequire">
    <ComponentsItemsCheckBox
      name="action_type "
      :value.sync="personalsVaccine.action_type"
      text="猶予証明書/抗体検査証明書"
      true-value="2"
      false-value="1"
      ref="action_type "
    ></ComponentsItemsCheckBox>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsItemsImageUpload from '@/components/items/ImageUpload'
import ComponentsItemsCheckBox from '@/components/items/CheckBox'
import ComponentsItemsDatePicker from '@/components/items/DatePicker'

export default {
  name: 'ComponentsFormsAnimalVaccine',

  mixins: [MixinLib],

  data: () => ({
    error: false
  }),

  computed: {
    vaccineImageRequire: {
      get () {
        const index = this.StorageVaccineTypes.findIndex(v => v.code === this.personalsVaccine.vaccine_type_id)
        return (index >= 0) ? (this.StorageVaccineTypes[index].requiredImage) : false
      }
    }
  },

  methods: {
    isError: function () {
      return this.error
    },

    confirm: async function () {
      this.error = false
      const forms = [
        'action_date',
        'expire_date'
      ]
      for (const name of forms) {
        if (this.$refs[name]) {
          await this.$refs[name].confirm()
        }
        if (this.$refs[name] && this.$refs[name].error) {
          this.error = true
        }
      }

      if (this.error) {
        return false
      }
      return true
    }
  },

  components: {
    ComponentsLayoutHeading3,
    ComponentsItemsImageUpload,
    ComponentsItemsDatePicker,
    ComponentsItemsCheckBox
  },

  props: {
    personalsVaccine: {
      type: Object,
      required: false,
      default: () => {
        return {
          vaccine_type_id: '',
          action_date: '',
          expire_date: '',
          img_src: '',
          action_type: '1'
        }
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
