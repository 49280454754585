<template>
<v-row align="center" justify="center" class="pt-5">

  <v-col cols=12>
    <ComponentsLayoutHeading3 text="証明画像" required></ComponentsLayoutHeading3>
  </v-col>
  <v-col cols=12>
    <ComponentsItemsImageUpload
      :src.sync="personalsInsurances.img_src"
      :loading="loading"
    >
    </ComponentsItemsImageUpload>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading3 text="発行日" ></ComponentsLayoutHeading3>
  </v-col>
  <v-col cols=12>
    <ComponentsItemsDatePicker
      name="action_date"
      :value.sync="personalsInsurances.action_date"
      :loading="loading"
      ref="action_date"
    >
    </ComponentsItemsDatePicker>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading3 text="期限日" required></ComponentsLayoutHeading3>
  </v-col>
  <v-col cols=12>
    <ComponentsItemsDatePicker
      name="expire_date"
      :value.sync="personalsInsurances.expire_date"
      :loading="loading"
      ref="expire_date"
    >
    </ComponentsItemsDatePicker>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

// import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsItemsImageUpload from '@/components/items/ImageUpload'

import ComponentsItemsDatePicker from '@/components/items/DatePicker'

export default {
  name: 'ComponentsFormsInsurances',

  mixins: [MixinLib],

  data: () => ({
    error: false
  }),

  methods: {
    isError: function () {
      return this.error
    },

    confirm: async function () {
      this.error = false
      const forms = [
        'action_date',
        'expire_date'
      ]
      for (const name of forms) {
        if (this.$refs[name]) {
          await this.$refs[name].confirm()
        }
        if (this.$refs[name] && this.$refs[name].error) {
          this.error = true
        }
      }

      if (this.error) {
        return false
      }
      if (this.isUndefined(this.personalsInsurances.img_src)) {
        this.$confirm({ title: 'Error', message: '証明写真のアップロードを行ってください', button: { yes: 'OK' } })
        this.error = true
        return false
      }
      return true
    }
  },

  components: {
    // ComponentsLayoutHeading1,
    ComponentsLayoutHeading3,
    ComponentsItemsImageUpload,
    ComponentsItemsDatePicker
  },

  props: {
    personalsInsurances: {
      type: Object,
      required: false,
      default: () => {
        return {
          action_date: '',
          expire_date: '',
          img_src: ''
        }
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
