import UtilLib from '@/libs/UtilLib'

const state = {
  windowSize: {
    width: 0,
    height: 0
  },
  isInitialLoading: false,
  isLoading: true,
  isDataLoading: false,
  page_background_color: 'rgba(var(--b3f,244,247,252),1)',
  page_title: '',
  common_header_title: '',
  common_header_left: '',
  common_header_right: '',
  backend: {
  }
}

const getters = {
  isInitialLoading: (state) => {
    return state.isInitialLoading
  },
  isLoading: (state) => {
    return state.isLoading
  },
  isDataLoading: (state) => {
    return state.isDataLoading
  },
  windowWidthSize: (state) => {
    return state.windowSize.width
  },
  windowHeightSize: (state) => {
    return state.windowSize.height
  },
  pageBackgroundColor: (state) => {
    return state.page_background_color
  },
  pageTitle: (state) => {
    return state.page_title
  },
  CommonHeaderTitle: (state) => {
    return state.common_header_title
  },
  CommonHeaderLeft: (state) => {
    return state.common_header_left
  },
  CommonHeaderRight: (state) => {
    return state.common_header_right
  }
}

const mutations = {
  initialize (state) {
    state = {
      windowSize: {
        width: 0,
        height: 0
      },
      page_background_color: '',
      page_title: '',
      common_header_title: '',
      common_header_left: '',
      common_header_right: '',
      backend: {
      }
    }
  },
  setWindowWidthSize (state, size) {
    state.windowSize.width = size
  },
  setWindowHeightSize (state, size) {
    state.windowSize.height = size
  },
  setPageContents (state, contents) {
    state.page_background_color = (UtilLib.getValue('page_background_color', contents)) || 'rgba(var(--b3f,244,247,252),1)'
    state.page_title = (UtilLib.getValue('page_title', contents))
    state.common_header_title = (UtilLib.getValue('common_header_title', contents))
    state.common_header_left = (UtilLib.getValue('common_header_left', contents))
    state.common_header_right = (UtilLib.getValue('common_header_right', contents))
  }
}

const actions = {
  isInitialLoading ({ commit, dispatch, state }, contents) {
    state.isInitialLoading = contents
  },
  isLoading ({ commit, dispatch, state }, contents) {
    state.isLoading = contents
  },
  isDataLoading ({ commit, dispatch, state }, contents) {
    state.isDataLoading = contents
  },
  reset ({ commit, dispatch, state }) {
    commit('initialize')
  },
  setWindowWidthSize ({ commit, dispatch, state }, size) {
    commit('setWindowWidthSize', size)
  },
  setWindowHeightSize ({ commit, dispatch, state }, size) {
    commit('setWindowHeightSize', size)
  },
  setPageContents ({ commit, dispatch, state }, contents) {
    commit('setPageContents', contents)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
