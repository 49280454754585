<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">
    <v-col cols=12>
      <ComponentsLayoutHeading1 text="ご登録情報の更新が完了致しました"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" class="py-3">
          <v-col cols=12>
            <span>問診票のご入力ありがとうございました。</span>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12 class="text-center">
      <ComponentsItemsButton
        block
        :loading="isLoading || isProcessLoading"
        text="HOMEへ"
        @click="goHome()"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>

</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
// import AuthLib from '@/libs/AuthLib'
import AuthLineLib from '@/libs/AuthLineLib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingProfileCompletePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  mounted () {
    this.isProcessLoading = false
  },

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    StorageStoreContents: {
      get () {
        return this.$store.getters['modStorage/storeContents']
      }
    },
    StoreLineAccount: {
      get () {
        const index = this.StorageStoreContents.stores_outbound_links.findIndex(v => v.social_media_site === 'LINE')
        return (index >= 0) ? this.StorageStoreContents.stores_outbound_links[index].social_media_link : ''
      }
    }
  },

  methods: {
    goHome: function () {
      (async () => {
        if (AuthLineLib.isInClient()) {
          // LINE経由はそのまま遷移
          return this.SignInLoading('/console/index', true)
        } else {
          /*
          const status = await AuthLib.isLogin()
          if (!status) {
            return await AuthLib.SignIn(this.$store.getters['modSession/account'], this.$store.getters['modSession/password'])
          } else {
            return this.pageTransition('/console/index')
          }
          */
          this.pageTransition('/')
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.pageTransition('/')
      })
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsItemsButton
  }
}
</script>
