<template>
<component
  v-bind:is="currentTabComponent"
  header
  footer
  header-title="Sample"
  header-left
  header-left-icon='mdi-chevron-left'
  header-left-text='戻る'
  header-right
  header-right-icon='mdi-chevron-right'
  header-right-text='進む'
>
  <v-row align="center" justify="center" class="mx-auto">
    <v-col cols=12>
      <ComponentsItemsSelectField
        name="sample"
        :value.sync="currentTabComponent"
        :items="tabComponent"
        itemText="name"
        itemValue="code"
        ref="sample"
      ></ComponentsItemsSelectField>
    </v-col>
    <v-col cols=12>
      <ComponentsLayoutHeading1 text="Heading1 フォント"></ComponentsLayoutHeading1>
    </v-col>
    <v-col cols=12>
      <span>Page フォント</span>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading2 text="Heading2 フォント"></ComponentsLayoutHeading2>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center">
          <v-col cols=12>
            <ComponentsLayoutHeading3  help="Sample" text="Section1 Heading3 フォント" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <span>Section1 フォント</span>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="sample"
              :value.sync="text"
              ref="sample"
            ></ComponentsItemsTextField>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsButtonToggle
              name="sample"
              :value.sync="toggle"
              :items="[ { name: 'Sample1', code: '1' }, { name: 'Sample2', code: '2' } ]"
              item-text="name"
              item-value="code"
              ref="sample"
            ></ComponentsItemsButtonToggle>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsCheckBox
              name="sample"
              :value.sync="checkbox"
              text="Sample"
              ref="sample"
            >
            </ComponentsItemsCheckBox>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsSelectField
              name="sample"
              :value.sync="selected"
              :items="[ { name: 'Sample1', code: '1' }, { name: 'Sample2', code: '2' } ]"
              itemText="name"
              itemValue="code"
              ref="sample"
            ></ComponentsItemsSelectField>
          </v-col>
          <v-col cols=4 class="text-center">
            <ComponentsItemsButton
              small
              block
              icon="mdi-close"
              text="Sample"
            >
            </ComponentsItemsButton>
          </v-col>
          <v-col cols=4 class="text-center">
            <ComponentsItemsButton
              small
              block
              text-btn
              text="Sample"
            >
            </ComponentsItemsButton>
          </v-col>
          <v-col cols=4 class="text-center">
            <ComponentsItemsButton
              small
              text-btn
              icon-position="right"
              icon="mdi-chevron-right"
            >
            </ComponentsItemsButton>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsSimpleSoleList
              avatar
              two-line
              text="Title"
              text2="SubTitle"
            ></ComponentsItemsSimpleSoleList>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsSwitch
              name="sample"
              :value.sync="switched"
            ></ComponentsItemsSwitch>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>
    <v-col cols=12>
      <ComponentsLayoutSection2>
        <v-row align="center" justify="center">
          <v-col cols=12>
            <ComponentsLayoutHeading3  help="Sample" text="Section2 Heading3 フォント" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <span>Section2 フォント</span>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="sample"
              :value.sync="text"
              ref="sample"
            ></ComponentsItemsTextField>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsButtonToggle
              name="sample"
              :value.sync="toggle"
              :items="[ { name: 'Sample1', code: '1' }, { name: 'Sample2', code: '2' } ]"
              item-text="name"
              item-value="code"
              ref="sample"
            ></ComponentsItemsButtonToggle>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsCheckBox
              name="sample"
              :value.sync="checkbox"
              text="Sample"
              ref="sample"
            >
            </ComponentsItemsCheckBox>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsSelectField
              name="sample"
              :value.sync="selected"
              :items="[ { name: 'Sample1', code: '1' }, { name: 'Sample2', code: '2' } ]"
              itemText="name"
              itemValue="code"
              ref="sample"
            ></ComponentsItemsSelectField>
          </v-col>
          <v-col cols=4 class="text-center">
            <ComponentsItemsButton
              small
              block
              icon="mdi-close"
              text="Sample"
            >
            </ComponentsItemsButton>
          </v-col>
          <v-col cols=4 class="text-center">
            <ComponentsItemsButton
              small
              block
              text-btn
              text="Sample"
            >
            </ComponentsItemsButton>
          </v-col>
          <v-col cols=4 class="text-center">
            <ComponentsItemsButton
              small
              text-btn
              icon-position="right"
              icon="mdi-chevron-right"
            >
            </ComponentsItemsButton>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsSimpleSoleList
              avatar
              two-line
              text="Title"
              text2="SubTitle"
            ></ComponentsItemsSimpleSoleList>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsSwitch
              name="sample"
              :value.sync="switched"
            ></ComponentsItemsSwitch>
          </v-col>
        </v-row>
      </ComponentsLayoutSection2>
    </v-col>
    <v-col cols=4>
      <ComponentsItemsProgressCircular x-small></ComponentsItemsProgressCircular>
    </v-col>
    <v-col cols=4>
      <ComponentsItemsProgressCircular small></ComponentsItemsProgressCircular>
    </v-col>
    <v-col cols=4>
      <ComponentsItemsProgressCircular></ComponentsItemsProgressCircular>
    </v-col>
  </v-row>

</component>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsButtonToggle from '@/components/items/ButtonToggle'
import ComponentsItemsCheckBox from '@/components/items/CheckBox'
import ComponentsItemsSelectField from '@/components/items/SelectField'
import ComponentsItemsButton from '@/components/items/Button'
import ComponentsItemsProgressCircular from '@/components/items/ProgressCircular'
import ComponentsItemsSimpleSoleList from '@/components/items/SimpleSoleList'
import ComponentsItemsSwitch from '@/components/items/Switch'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

export default {
  name: 'LoginPage',

  mixins: [MixinLib],

  data: () => ({
    text: 'Design Sample',
    switched: '1',
    toggle: '1',
    checkbox: '1',
    selected: '',
    currentTabComponent: 'ComponentsLayoutPage1',
    tabComponent: [
      { name: 'LayoutPage1', code: 'ComponentsLayoutPage1' },
      { name: 'LayoutPage2', code: 'ComponentsLayoutPage2' },
      { name: 'LayoutPage3', code: 'ComponentsLayoutPage3' }
    ]
  }),

  mounted: function () {
    if (process.env.VUE_APP_MODE) {
      this.pageTransition('/')
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutPage2,
    ComponentsLayoutPage3,
    ComponentsLayoutSection1,
    ComponentsLayoutSection2,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsLayoutHeading3,
    ComponentsItemsTextField,
    ComponentsItemsButtonToggle,
    ComponentsItemsCheckBox,
    ComponentsItemsSelectField,
    ComponentsItemsButton,
    ComponentsItemsProgressCircular,
    ComponentsItemsSimpleSoleList,
    ComponentsItemsSwitch
  }
}
</script>
