<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">
    <v-col cols=12>
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" v-if="isEnabledNewContact">
          <v-col cols=12 class="pt-5">
            <ComponentsLayoutHeading3 text="ご予約をご希望のお客様へ"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <span>ご予約をご希望のお客様は、大変お手数ですが、店舗に直接ご連絡ください。</span>
          </v-col>
          <v-col cols=12 class="text-center">
            <a :href="'tel:' + (isUndefined(StorageStoreContents)) ? '' : StorageStoreContents.stores_profile.store_tel" style="text-decoration: none;">
              <v-btn block :color="buttonColor" class="white--text" style="height: 55px;">
                <v-row align="center" justify="center">
                  <v-col cols=3>
                    <v-icon>mdi-phone</v-icon>
                  </v-col>
                  <v-col cols=9>
                    <v-row align="center" justify="center">
                      <v-col cols=12 class="pt-1 pb-0 px-0 ma-0 text-left" style="font-size: 1.1rem;">
                        <span v-text="(isUndefined(StorageStoreContents)) ? '' : StorageStoreContents.stores_profile.store_tel"></span>
                      </v-col>
                      <v-col cols=12 class="pt-1 pb-0 px-0 ma-0 text-left caption">
                        <span>代表番号</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-btn>
            </a>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" v-else>
          <v-col cols=12 class="pt-5">
            <ComponentsLayoutHeading3 text="ご予約をご希望のお客様へ"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <span>ご予約をご希望のお客様は、大変お手数ですが、店舗に直接ご連絡ください。</span>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>
  </v-row>
</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

export default {
  name: 'Stores404Page',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    isEnabledNewContact: {
      get () {
        return (this.isUndefined(this.StorageStoreServiceContents)) ? false : (this.StorageStoreServiceContents.stores_service_web_reserve.store_new_contact_status === '1')
      }
    }
  },

  mounted () {
    this.isProcessLoading = false
  },
  methods: {
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading3
  }
}
</script>
