<template>
<v-bottom-sheet persistent scrollable v-model="model" max-width=840>
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsLayoutHeading1 text="プライバシーポリシー" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="center" justify="center" class="pt-5">
        <v-col cols=12>
          <pre class="ec-font-size12">
株式会社カラーズ（以下、「当社」といいます）は、当社が提供するサービス（以下、「サービス」といいます。）におけるプライバシー情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。

第1条（プライバシー情報）

  プライバシー情報のうち「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報および個人識別符号が含まれる情報を指します。
  プライバシー情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、ご利用いただいたサービスやご購入いただいた商品、ご覧になったページや広告の履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのIPアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。

第2条（プライバシー情報の収集方法）

  当社は法令に基づき、適正かつ公正な手段により以下の場合において、ユーザーの個人情報を取得いたします。
  ・ユーザーが当社の商品やWEBサイト、カタログ、 及び サービスをご利用頂いたとき
  ・ユーザーが当社からのアンケートにご協力頂いたときや当社キャンペーンなどに応募されたとき
  ・ユーザーからのお問い合わせ、ご相談に当社が対応するとき、など
  また、ユーザーと当社の提携先（当社の商品・サービスの販売勧奨委託先である会社及び個人事業主、フランチャイズ店舗、情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などとの間でなされたユーザーの個人情報を含む取引記録や、決済に関する情報を提携先などから収集することがあります。

  当社は、ユーザーが利用したサービスやソフトウエア、購入した商品、閲覧したページや広告の履歴、検索した検索キーワード、利用日時、利用方法、利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含みます）、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などの履歴情報および特性情報を、お客様が当社や提携先のサービスを利用しまたはページを閲覧する際に収集します。

  上記以外の場合で個人情報を取得する場合は個別にお知らせします。

第3条（個人情報を収集・利用する目的）

  当社が個人情報を収集・利用する目的は、以下のとおりです。

  1.ユーザーに自分の登録情報の閲覧や修正、利用状況の閲覧を行っていただくために、氏名、住所、連絡先、支払方法などの登録情報、利用されたサービスや購入された商品、およびそれらの代金などに関する情報を表示する目的
  2.ユーザーにお知らせや連絡（商品やサービスのご提案やご案内を含みます。）をするためにメールアドレスを利用する場合やユーザーに商品を送付したり必要に応じて連絡したりするため、氏名や住所などの連絡先情報を利用する目的
  3.ユーザーにカウンセリング等サービスの提供及び商品のご案内をするために商品購入履歴、サービス利用状況、連絡先情報などを利用する目的
  4.ユーザーの本人確認を行うために、氏名、生年月日、住所、電話番号、クレジットカード番号、配達証明付き郵便の到達結果などの情報を利用する目的
  5.ユーザーに代金を請求するために、購入された商品名や数量、利用されたサービスの種類や期間、回数、請求金額、氏名、住所、銀行口座番号やクレジットカード番号などの支払に関する情報などを利用する目的
  6.ユーザーが簡便にデータを入力できるようにするために、当社に登録されている情報を入力画面に表示させたり、ユーザーのご指示に基づいて他のサービスなど（提携先が提供するものも含みます）に転送したりする目的
  7.代金の支払を遅滞したり第三者に損害を発生させたりするなど、サービスの利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの利用をお断りするために、氏名や住所など個人を特定するための情報を利用する目的
  8.ユーザーからのお問い合わせに対応するために、お問い合わせ内容や代金の請求に関する情報など、当社がユーザーに対してサービスを提供するにあたって必要となる情報や、ユーザーのサービス利用状況、連絡先情報などを利用する目的
  9.上記の利用目的に付随する目的

第4条（個人情報の第三者提供）

  当社は、プライバシー情報のうち、個人情報については、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、次に掲げる必要があり第三者に提供する場合はこの限りではありません。

  （1）当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合。なお、個人情報の取扱いの全部または一部を委託する委託先には、当社の商品・サービスの販売勧奨委託先である会社及び個人事業主、フランチャイズ店舗等、提携先が含まれます。
  （2）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
  （3）人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
  （4）公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
  （5）合併その他の事由による事業の承継に伴って個人情報が提供される場合
  （6）その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合

第5条（個人情報の開示）

  当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、１件あたり1,000円（税抜）の手数料ならびにかかった費用の実費を申し受けます。

  （1）本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
  （2）当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
  （3）その他法令に違反することとなる場合
  前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。

第6条（個人情報の訂正、追加および削除）

  ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除を請求することができます。
  当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正、追加または削除を行い、これをユーザーに通知します。また、前項の請求に応じない旨の決定をしたときは、ユーザーに対し遅滞なくその旨を通知します。

第7条（個人情報の利用停止等）

  当社は、本人から、個人情報が利用目的の範囲を超えて取り扱われているという理由、違法または不当な行為を助長する等の不適正な方法により利用されているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合、もしくは第三者提供の制限に違反して第三者に提供されているという理由により第三者提供の停止を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等または第三者提供の停止を行い、その旨本人に通知します。ただし、個人情報の利用停止等または第三者提供の停止に多額の費用を有する場合その他利用停止等または第三者提供の停止を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。

第8条（共同利用）

  当社は、以下の通り、個人情報を共同利用させていただいております。

  （1）共同利用する個人情報の項目
  サービスの利用登録をしたユーザーの情報（氏名、生年月日、住所、電話番号、メールアドレス、取引記録）
  （2）共同利用者の範囲
  株式会社カラーズ、株式会社カラーズ・エデュケーション、株式会社カラーズ・プロダクツ、株式会社アール・ツー、株式会社ベックジャパン
  （3）共同利用者の利用目的
  ・当社グループが提供する商品およびサービスの企画・開発のため
  ・当社グループの商品およびサービスに関するご提案やご案内のため
  （4）個人情報の管理について責任を有する者の名称
  株式会社カラーズ

第9条（組織的・人的・物理的・技術的安全管理措置の整備）

  当社は取り扱う個人情報の漏えい、滅失又は毀損の防止その他個人情報の安全管理のために、個人情報の取扱規程を策定し必要かつ適切な措置を講じております。

  （1）組織的安全管理措置
  個人情報の取扱いに関する責任者を設置するとともに、個人情報を取り扱う従業者及び当該従業者が取り扱う個人情報の範囲を明確化し、法や取扱規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備しています。
  また、個人情報の取扱状況について自己点検を実施するとともに、他部署や外部の者による監査を実施しています。
  （2）人的安全管理措置
  個人情報の取扱いに関する留意事項について、従業者に研修を実施しています。
  また、個人情報についての秘密保持に関する事項を就業規則に規定しています。
  （3）物理的安全管理措置
  個人情報を取り扱う区域において、従業者の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者による個人情報の閲覧を防止する措置を実施しています。
  （4）技術的安全管理措置
  アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定しています。

第10条（プライバシーポリシーの変更）

  本ポリシーの内容は、ユーザーに通知することなく、変更することができるものとします。
  当社が別途定める場合を除いて、変更後のプライバシーポリシーは、当社ウェブサイトに掲載したときから効力を生じるものとします。

第11条（その他）
  1. Cookieについて
    当社ウェブサイトをご利用する過程で、サイト運営のためにお客さまの「Cookie情報」を取得する場合があります。「Cookie情報」とは、お客さまが当社のウェブサイトを再度訪問されたときなどに、より便利に閲覧していただくための情報です。当社ウェブサイトのカートは、利便性を考慮してCookieを使用しておりますが、データはお客さまのコンピュータに保存され、弊社のサーバー側では記録しておりません。 お客さまがご使用になるインターネット閲覧ソフト（ブラウザ）の設定を変更することにより、Cookieを拒否するように設定することもできますが、その場合、当社ウェブサイトを十分に活用できなくなる可能性があります。 これにより当社ウェブサイト、またはウェブサイトにおけるサービスを正常にご利用いただけない場合がありますので、あらかじめご了承ください。
  2. オンライン広告について
    Google、Yahoo!を含む第三者配信事業者によりインターネット上のさまざまなサイトに当社の広告が掲載されています。Google、Yahoo!を含む第三者配信事業者はCookieを使用して、当社ウェブサイトへの過去のアクセス情報に基づいて広告を配信します。 ユーザーはGoogle広告のオプトアウトページ もしくはYahoo!広告のオプトアウトページにアクセスして、Google、Yahoo!によるCookieの使用を無効にできます （または、Network Advertising Initiative のオプトアウトページにアクセスして、第三者配信事業者による Cookie の使用を無効にできます）。
  3. Googleアナリティクスの利用について
    当社では、サービスの企画や改善のためにGoogle者の提供するアクセス解析サービス・Googleアナリティクスを利用しています。 Googleアナリティクスでデータが収集、処理されるしくみについてはGoogle社が提供するこちらのページからご確認いただけます。 Googleのサービスを使用するサイトやアプリから収集した情報のGoogleによる使用また、Googleアナリティクスによるデータ収集を無効にしたい場合は、 Google社によるオプトアウトアドオンのダウンロードページで「Google Analyticsオプトアウトアドオン」をダウンロードおよびインストールし、ブラウザのアドオン設定を変更することで実施できます。
  4. セキュリティ対策・SSLについて
    当SSLとは、情報を暗号化することで、データの盗聴や改ざんを防止して送受信する機能のことです。 当社ウェブサイトでは、会員登録やアンケート回答の際など個人情報が送受信されるすべてのページにおいて、 SSL による暗号化通信を使用し、お客さまの個人情報が外部の第三者に通信傍受できないよう対策を行っています。

2022年4月1日改訂

株式会社カラーズ
〒657-0036
神戸市灘区桜口町4-1-1
ウェルブ六甲道4番街1番館402

MAIL：help@green-dog.com
          </pre>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <ComponentsItemsButton
        text-btn
        text="閉じる"
        :loading="isProcessLoading"
        @click="$emit('close-click')"
      >
      </ComponentsItemsButton>
    </v-card-actions>
  </v-card>
</v-bottom-sheet>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsBottomSheetPrivacyPolicy',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  components: {
    ComponentsLayoutHeading1,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    }
  }
}
</script>
