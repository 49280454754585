<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="パスワード再発行"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/')"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px', paddingTop: '30px' }">

    <v-col cols=12>
      <span>アカウントに紐づくメールアドレス宛にパスワード再発行用の認証番号を記載したメールをお送り致します</span>
    </v-col>

    <v-col cols=12>
      <span>ご登録のペトコネアカウントをご入力ください</span>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" class="mx-auto py-3">
          <v-col cols=12>
            <ComponentsLayoutHeading3 text="アカウント" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="account"
              :value.sync="account"
              :loading="isProcessLoading"
              ref="account"
            ></ComponentsItemsTextField>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12 class="text-center">
      <ComponentsItemsButton
        block
        text="認証番号の取得"
        @click="Authentication()"
      >
      </ComponentsItemsButton>
    </v-col>

  </v-row>
</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsLayoutSection1 from '@/components/layout/Section1'

import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ForgetPasswordAuthenticationPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    account: '',
    requestCode: ''
  }),

  mounted: function () {
    if (this.isInLineClient) {
      this.pageTransition('/console/index')
    }
  },

  methods: {
    Authentication: function () {
      (async () => {
        if (this.$refs.account) {
          await this.$refs.account.confirm()
        }
        if (this.$refs.account && this.$refs.account.error) {
          return false
        }
        this.isProcessLoading = true
        this.requestCode = await APILib.getOAuthPasswordReset(this.account)
        this.$confirm({ title: 'Confirm', message: '本人確認のための認証番号を送信しました', button: { yes: 'OK' }, callback: this.onNext })
        // this.pageTransition('/console/index')
        // this.pageTransition('/forget-password/authentication-verify')
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '入力内容をご確認ください', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },
    onNext: function () {
      this.pageTransition('/forget-password/authentication-verify?id=' + this.requestCode)
    }
  },
  components: {
    ComponentsLayoutPage2,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading3,
    ComponentsItemsTextField,
    ComponentsItemsButton
  }
}
</script>
