<template>
<v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
  <v-col cols=12
    v-for="item in items"
    :key="item.title"
  >
    <v-card flat class="rounded-lg" @click="() => false">
      <v-card-text :style="{ backgroundColor: 'rgba(2, 136, 209, .1)', color: 'rgba(2, 136, 209, 1)' }">
        <v-row align="start" justify="center" class="font-weight-bold" no-gutters>
          <v-col cols=2 class="text-left ec-font-size20">
            <span>Q</span>
          </v-col>
          <v-col cols=10 class="text-left ec-font-size12">
            <span v-text="item.title"></span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text :style="{ backgroundColor: 'rgba(255, 248, 225, 1)' }">
        <v-row align="start" justify="center" class="font-weight-bold" no-gutters>
          <v-col cols=2 class="text-left ec-font-size20" :style="{ color: 'rgba(255, 143, 0, 1)' }">
            <span>A</span>
          </v-col>
          <v-col cols=10 class="text-left ec-font-size12">
            <span v-html="item.answer"></span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
  <v-col cols=12>
    <v-card outlined flat class="rounded-lg font-weight-bold" :color="buttonColor" @click="goSupport()">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols=2>
          </v-col>
          <v-col cols=8 class="text-center white--text">
            <span>ペトコネ（LINE/WEB共通）<br />に関するお問い合わせ</span>
          </v-col>
          <v-col cols=2 class="text-center">
            <ComponentsItemsButton
              icon-btn
              color="white"
              icon="mdi-chevron-right"
              :loading="isProcessLoading"
              @click="() => false"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
  <v-col cols=12>
    <v-card outlined flat class="rounded-lg font-weight-bold" color="green lighten-2" @click="() => false">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols=12 class="text-center white--text">
            <span>店舗サービスに関するお問い合わせは<br />お手数ですが店舗までご連絡ください</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsFAQ',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    items: [
      {
        answer: '大変お手数ですが、<a href="https://www.green-dog.com/shop/guide/contents/36" target="_blank">GREEN DOG 通販 お問い合わせ窓口</a>でご確認下さい。',
        title: 'GREENDOG会員のアカウント忘れました'
      },
      {
        answer: '<a href="https://www.green-dog.com/shop/mypage/" target="_blank">GREEN DOG 通販マイページ</a>のパスワードを忘れた方は、こちらから再発行を行ってください。',
        title: 'GREEN DOG会員のパスワードを忘れました。'
      },
      {
        answer: '大変お手数ですが、該当店舗までお電話でのお問合せをお願いします。',
        title: '仮予約申請しましたが、店舗から返信がありません。'
      },
      {
        answer: 'LINE版をご利用の方は、INEのメニュー > ペトコネアプリHOME > 予約一覧 をご確認ください。<br />ウェブ版をご利用の方は、<a href="https://www.petcone-japan.com/" target="_blank">ペトコネウェブ版</a>にアクセスしていただき、ペトコネアカウントでログイン後、HOME > 予約一覧 をご確認ください。',
        title: 'いつ予約をとったのか確認したいのですが、どこから確認できますか？'
      },
      {
        answer: 'はい。ご登録情報は連携しています。',
        title: 'GREEN DOGのマイページとペトコネの登録情報は紐づいていますか？'
      },
      {
        answer: 'LINE版をご利用の方は、LINEのメニュー > ペトコネアプリHOME > 左上メニュー > プロフィール設定 から変更をお願いします。<br />ウェブ版をご利用の方は、<a href="https://www.petcone-japan.com/" target="_blank">ペトコネウェブ版</a>にアクセスしていただき、ペトコネアカウントでログイン後、HOME > 左上メニュー > プロフィール設定 から変更下さい<br />GREEN DOGアカウントを変更したい方は、<a href="https://www.green-dog.com/shop/mypage/" target="_blank">GREEN DOG 通販マイページ</a>より変更をお願いします。',
        title: '会員情報の変更をしたいのですが、どうしたらいいでしょうか？'
      },
      {
        answer: 'LINE版をご利用の方は、LINEのメニュー > グルーミングの予約を申請する から申請が行えます。<br />詳細は、<a href="https://www.green-dog.com/store/" target="_blank">GREEN DOG実店舗のご案内</a> より該当店舗のグルーミングのご予約手順変更についてを参照ください。',
        title: '仮予約の手順がわかりません。'
      },
      {
        answer: '大変お手数ですが、該当店舗までお電話でのお問合せをお願いします。',
        title: 'グルーミングのキャンセルをしたいのですが、どうすればいいですか？'
      },
      {
        answer: '大変お手数ですが、該当店舗までお電話でのお問合せをお願いします。',
        title: 'グルーミングの予約日時変更をしたいのですが、どうすればいいですか？'
      },
      {
        answer: '仮予約申請で病院の予約を同時に取ることは出来ません。大変お手数ですが、該当店舗までお電話でのお問合せをお願いします。',
        title: 'グルーミングの日に病院の予約も取りたいですが、仮予約申請で予約はとれますか？'
      },
      {
        answer: '<a href="https://www.green-dog.com/shop/mypage/login" target="_blank">GREEN DOG 通販 ログイン</a>の 左メニュー > 会員サービス > GREEN DOG CLUB退会 より退会を行ってください。',
        title: '退会したいのですがどうすればいいですか？'
      },
      {
        answer: 'LINE版をご利用の方は、LINEのメニュー > ペトコネアプリHOME にある⊕アイコンから追加登録が行えます。<br />ウェブ版をご利用の方は、<a href="https://www.petcone-japan.com/" target="_blank">ペトコネウェブ版</a>にアクセスしていただき、ペトコネアカウントでログイン後、HOME にある⊕アイコンから追加登録が行えます。',
        title: 'パートナー（愛犬）の追加登録をしたい。'
      },
      {
        answer: '大変お手数ですが、該当店舗までお電話でのお問合せをお願いします。',
        title: '仮予約申請を取り消したいのですがどうすればいいですか？'
      },
      {
        answer: '大変お手数ですが、該当店舗までお電話でのお問合せをお願いします。',
        title: '仮予約申請を申請し直したいのですが、どうすればいいですか？'
      },
      {
        answer: 'LINE版をご利用の方は、LINEのメニュー > ペトコネアプリHOME にある愛犬をタップしていただき、ワクチン接種情報で更新が行えます。<br />ウェブ版をご利用の方は、<a href="https://www.petcone-japan.com/" target="_blank">ペトコネウェブ版</a>にアクセスしていただき、ペトコネアカウントでログイン後、HOME にある愛犬をタップしていただき、ワクチン接種情報で更新が行えます。',
        title: '狂犬病、混合ワクチンの証明証の画像を更新したいのですがどうすればいいですか？'
      },
      {
        answer: '証明書の画像をアップロードしていただき、猶予期限をご入力後、猶予証明書/抗体検査証明書にチェックをいれてください。',
        title: '狂犬病、混合ワクチン猶予の場合はどうすればいいですか？'
      },
      {
        answer: 'LINE版をご利用の方は、GREEN DOG CLUBアカウント。ウェブ版をご利用の方は、ペトコネアカウントでログインして頂ければ、今までと同じようにご利用可能です。',
        title: '携帯を買い替えたのですが、以前のデータは連携されますか？'
      },
      {
        answer: '予約申請時の「ご予約に関するご質問やご要望」欄にご希望をご入力ください。',
        title: '予約内容をトリミングコースかシャンプーコースどちらか相談してから予約を取りたい場合は仮予約申請時にどうすればいいですか？'
      },
      {
        answer: 'LINE版をご利用の方は、LINEのメニュー > ペトコネアプリHOME > 左上メニュー > プロフィール設定 から変更をお願いします。<br />ウェブ版をご利用の方は、<a href="https://www.petcone-japan.com/" target="_blank">ペトコネウェブ版</a>にアクセスしていただき、ペトコネアカウントでログイン後、HOME > 左上メニュー > プロフィール設定 から変更下さい<br />GREEN DOGアカウントを変更したい方は、<a href="https://www.green-dog.com/shop/mypage/" target="_blank">GREEN DOG 通販マイページ</a>より変更をお願いします。',
        title: '住所、電話番号変更したいのですが、LINEからでも変更できますか？'
      },
      {
        answer: '予約申請時の「ご予約に関するご質問やご要望」欄にご希望をご入力ください。',
        title: 'トリミングの日に商品の取り置きをしたいのですが仮予約申請時に依頼することはできますか？'
      },
      {
        answer: '大変お手数ですが、該当店舗までお電話でのお問合せをお願いします。',
        title: '予約時間に間に合わない可能性がある場合はどうすればいいですか？'
      },
      {
        answer: '各店舗のLINEお友達登録していただき、それぞれの店舗で仮予約申請ができます。',
        title: '複数店舗で仮予約申請はできますか？'
      }
    ]
  }),

  computed: {
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true

        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    goSupport: function () {
      this.goTransition(process.env.VUE_APP_SUPPORT_LINE)
    }
  },

  components: {
    ComponentsItemsButton
  },

  props: {
  }
}
</script>
