<template>
<ComponentsLayoutPage1
  header
  footer
>
  <v-row align="center" justify="center" class="pt-5 mx-auto" :style="($vuetify.breakpoint.smAndDown) ? {} : { maxWidth: '420px' }">
    <!--
    <v-col cols=12>
      <ComponentsFormsSignIn
        federated-sign-in
      >
      </ComponentsFormsSignIn>
    </v-col>
    --->
    <v-col cols=12>
      <ComponentsFormsSignIn
      >
      </ComponentsFormsSignIn>
    </v-col>

    <v-col cols=12>
      <ComponentsItemsButton
        block
        text-btn
        text="パスワードをお忘れの方はこちら"
        @click="pageTransition('/forget-password/authentication')"
      >
      </ComponentsItemsButton>
    </v-col>

    <!--
    <v-col cols=12 class="ec-font-size12 py-1 text-center">
      <span>アカウントをお持ちでないですか？</span>
    </v-col>

    <v-col cols=12>
      <ComponentsItemsButton
        block
        text-btn
        color="error"
        text="アカウントを作成"
        @click="pageTransition('/sign-up/confirm')"
      >
      </ComponentsItemsButton>
    </v-col>
    --->
  </v-row>
</ComponentsLayoutPage1>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'

import ComponentsItemsButton from '@/components/items/Button'
import ComponentsFormsSignIn from '@/components/forms/SignIn'

export default {
  name: 'LoginPage',

  mixins: [MixinLib],

  data: () => ({
  }),

  /*
  created: function () {
    (async () => {
      if (this.isInitialLoading === false) {
        this.isInitialLoading = true
        const uri = (this.routeURI.endsWith('/')) ? this.routeURI.slice(0, -1) : this.routeURI
        await this.SignInLoading(uri)
      }
    })().catch((e) => {
      this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
    })
  },
  */

  mounted: function () {
    if (this.isInLineClient) {
      this.pageTransition('/console/index')
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsItemsButton,
    ComponentsFormsSignIn
  }
}
</script>
