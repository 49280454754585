<template>
<v-row align="center" justify="center">
  <v-col cols=12>
    <ComponentsLayoutHeading1 text="ご希望のカットイメージ" :border-hidden="borderHidden"></ComponentsLayoutHeading1>
  </v-col>
  <v-col cols=12>
    <ComponentsLayoutHeading2 text="ご希望のカットイメージ写真があればご登録ください"></ComponentsLayoutHeading2>
  </v-col>

  <v-col cols=12>
    <component v-bind:is="(profileMode) ? components.section2 : components.section1">
      <v-row align="center" justify="center" class="pb-3">
        <v-col cols=12 class="text-right">
          <ComponentsItemsButton
            :loading="loading || isProcessLoading"
            small
            icon="mdi-plus"
            text="追加"
            @click="bottomSheet.libraryImages = true"
          >
          </ComponentsItemsButton>
        </v-col>
        <v-col cols=12 class="text-left">
          <v-row align="start" justify="start">
            <v-col cols=3 v-for="(item, idx) of images" :key="idx" class="d-flex child-flex pa-1">
              <v-img :src="item.img_src" aspect-ratio="1" class="grey lighten-2">
                <v-icon color="white" @click="actionRemoveImages(item.img_src)">mdi-close</v-icon>
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </component>
    <!--
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center" class="pb-3">
        <v-col cols=12 class="pb-5 text-right">
          <ComponentsItemsButton
            :loading="loading || isProcessLoading"
            small
            icon="mdi-plus"
            text="追加"
            @click="bottomSheet.libraryImages = true"
          >
          </ComponentsItemsButton>
        </v-col>
        <v-col cols=12 class="text-left">
          <v-row align="start" justify="start">
            <v-col cols=3 v-for="(item, idx) of images" :key="idx" class="d-flex child-flex pa-1">
              <v-img :src="item.img_src" aspect-ratio="1" class="grey lighten-2"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
    --->
  </v-col>

  <!-- フォトライブラリから選択 -->
  <ComponentsBottomSheetUploadImages
    :model="bottomSheet.libraryImages"
    :loading="loading || isProcessLoading"
    @close-click="bottomSheet.libraryImages = false"
    @commit-click="(e) => actionAddImages(e)"
    v-if="bottomSheet.libraryImages"
  >
  </ComponentsBottomSheetUploadImages>

  <!-- 画像加工 -->
  <!--
  <ComponentsBottomSheetEditImages
    :model="bottomSheet.editImage"
    :loading="loading || isProcessLoading"
    :list="imgSrc"
    :index="edit.imgIndex"
    @close-click="bottomSheet.editImage = false"
    @commit-click="(e) => actionChangeImage(e)"
  >
  </ComponentsBottomSheetEditImages>
  --->
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutSection2 from '@/components/layout/Section2'

import ComponentsItemsButton from '@/components/items/Button'

import ComponentsBottomSheetUploadImages from '@/components/bottom-sheet/UploadImages'
// import ComponentsBottomSheetEditImages from '@/components/bottom-sheet/EditImages'

export default {
  name: 'ComponentsViewFormsTrimmingImage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    components: {
      section1: ComponentsLayoutSection1,
      section2: ComponentsLayoutSection2
    },
    accordion: false,
    bottomSheet: {
      editImage: false,
      libraryImages: false
    },
    edit: {
      imgIndex: 0,
      imgSrc: ''
    },
    // imgSrcMaxLength: process.env.VUE_APP_KARTE_IMG_MAX,
    imgSrc: [],
    imageFiles: [],
    imageConfig: {
      imagePath: process.env.VUE_APP_IMAGE_ENDPOINT + process.env.VUE_APP_IMAGE_FACE_PATH,
      // fileMaxSize: 3145728,
      fileMaxSize: 20971520,
      fileMaxCount: 1,
      fileExtensions: ['jpg', 'jpeg', 'png']
    },
    rules: [
      value => !value[0] || value[0].size < 20971520 || '20MB以下の画像を選択して下さい '
    ],
    list: []
  }),

  mounted: function () {
  },

  methods: {
    // ------------------------------------------
    // 画像の設定
    // ------------------------------------------
    actionAddImages: function (resultRows) {
      (async () => {
        this.isProcessLoading = true
        const list = (this.isUndefined(this.images)) ? [] : this.clone(this.images)
        for (const item of resultRows) {
          list.push({ img_src: item })
        }
        this.$emit('update:images', list)
        this.bottomSheet.editImage = false
        this.bottomSheet.libraryImages = false
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },

    actionRemoveImages: function (imgSrc) {
      const index = this.images.findIndex(item => item.img_src === imgSrc)
      if (index >= 0) {
        this.images.splice(index, 1)
      }
    }
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsLayoutSection1,
    ComponentsItemsButton,
    ComponentsBottomSheetUploadImages
    // ComponentsBottomSheetEditImages
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    profileMode: {
      type: Boolean,
      required: false,
      default: () => false
    },
    borderHidden: {
      type: Boolean,
      required: false,
      default: () => false
    },
    images: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>
