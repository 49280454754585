<template>
<v-row align="center" justify="center">

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="基本情報"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection1>
        <v-row align="center" justify="center">
          <v-col cols=6>
            <ComponentsLayoutHeading3 text="姓" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=6>
            <ComponentsLayoutHeading3 text="名" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=6>
            <ComponentsItemsTextField
              name="name1"
              :value.sync="profile.personals.name1"
              :loading="loading"
              ref="name1"
            ></ComponentsItemsTextField>
          </v-col>
          <v-col cols=6>
            <ComponentsItemsTextField
              name="name2"
              :value.sync="profile.personals.name2"
              :loading="loading"
              ref="name2"
            ></ComponentsItemsTextField>
          </v-col>

          <v-col cols=6>
            <ComponentsLayoutHeading3 text="姓（カナ）" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=6>
            <ComponentsLayoutHeading3 text="名（カナ）" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=6>
            <ComponentsItemsTextField
              name="name_kana1"
              :value.sync="profile.personals.name_kana1"
              :loading="loading"
              ref="name_kana1"
            ></ComponentsItemsTextField>
          </v-col>
          <v-col cols=6>
            <ComponentsItemsTextField
              name="name_kana2"
              :value.sync="profile.personals.name_kana2"
              :loading="loading"
              ref="name_kana2"
            ></ComponentsItemsTextField>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="生年月日" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsDatePicker
              name="birth_date"
              :value.sync="profile.personals.birth_date"
              :loading="loading"
              :start-date="'2020-01-01'"
              ref="birth_date"
            >
            </ComponentsItemsDatePicker>
          </v-col>

          <v-col cols=12 >
            <ComponentsLayoutHeading3 text="性別" ></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsButtonToggle
              name="sex"
              :value.sync="profile.personals.sex"
              :loading="loading"
              :items="StorageSexPerson"
              item-text="name"
              item-value="code"
              ref="sex"
            ></ComponentsItemsButtonToggle>
          </v-col>

        </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="住所"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection1>
        <v-row align="center" justify="center">
          <v-col cols=12>
            <ComponentsLayoutHeading3 text="郵便番号" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <v-row align="start" justify="center">
              <v-col cols=8>
                <ComponentsItemsTextField
                  name="post_no_no_required"
                  :value.sync="profile.users_attribute.post_no"
                  inputmode="numeric"
                  autocomplete="postal-code"
                  :loading="loading"
                  ref="post_no"
                ></ComponentsItemsTextField>
              </v-col>
              <v-col cols=4 class="text-right">
                <ComponentsItemsButton
                  text="検索"
                  :loading="loading"
                  @click="SetAddress"
                ></ComponentsItemsButton>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="都道府県" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsSelectField
              name="prefecture_id_no_required"
              :value.sync="profile.users_attribute.prefecture_id"
              :items="StoragePrefecture"
              :loading="loading"
              itemText="name"
              itemValue="code"
              ref="prefecture_id"
            ></ComponentsItemsSelectField>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="住所" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="address_no_required"
              :value.sync="profile.users_attribute.address"
              :loading="loading"
              ref="address"
            ></ComponentsItemsTextField>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="建物"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="building"
              :value.sync="profile.users_attribute.building"
              :loading="loading"
              ref="building"
            ></ComponentsItemsTextField>
          </v-col>

        </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="連絡先"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection1>
        <v-row align="center" justify="center">
          <v-col cols=12>
            <ComponentsLayoutHeading3 text="連絡先電話番号1" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="tel"
              inputmode="numeric"
              :value.sync="profile.address_book[0].tel"
              :loading="loading"
              ref="tel1"
            ></ComponentsItemsTextField>
          </v-col>
          <!--
          <v-col cols=12>
            <ComponentsItemsTextArea
              name="tel_memo"
              :value.sync="profile.address_book[0].memo"
              rows=2
              :loading="loading"
              ref="tel1_memo"
            ></ComponentsItemsTextArea>
          </v-col>
          --->

          <v-col cols=12 class="py-1">
            <v-divider></v-divider>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="連絡先電話番号2"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="tel_sub"
              inputmode="numeric"
              :value.sync="profile.address_book[1].tel"
              :loading="loading"
              ref="tel2"
            ></ComponentsItemsTextField>
          </v-col>

          <!--
          <v-col cols=12>
            <ComponentsItemsTextArea
              name="tel_memo"
              :value.sync="profile.address_book[1].memo"
              rows=2
              :loading="loading"
              ref="tel2_memo"
            ></ComponentsItemsTextArea>
          </v-col>
          --->

          <v-col cols=12 class="py-1">
            <v-divider></v-divider>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="連絡先電話番号3"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="tel_sub"
              inputmode="numeric"
              :value.sync="profile.address_book[2].tel"
              :loading="loading"
              ref="tel3"
            ></ComponentsItemsTextField>
          </v-col>

          <!--
          <v-col cols=12>
            <ComponentsItemsTextArea
              name="tel_memo"
              :value.sync="profile.address_book[2].memo"
              rows=2
              :loading="loading"
              ref="tel3_memo"
            ></ComponentsItemsTextArea>
          </v-col>
          --->

        </v-row>
    </ComponentsLayoutSection1>
  </v-col>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import SearchPostNo from 'yubinbango-core2'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsLayoutSection1 from '@/components/layout/Section1'

import ComponentsItemsButton from '@/components/items/Button'
import ComponentsItemsButtonToggle from '@/components/items/ButtonToggle'
// import ComponentsItemsTextFieldCustomType from '@/components/items/TextFieldCustomType'
import ComponentsItemsDatePicker from '@/components/items/DatePicker'
import ComponentsItemsTextField from '@/components/items/TextField'
// import ComponentsItemsTextArea from '@/components/items/TextArea'
import ComponentsItemsSelectField from '@/components/items/SelectField'

export default {
  name: 'ComponentsFormsProfileCustomer',

  mixins: [MixinLib],

  data: () => ({
    error: false
  }),

  methods: {
    SetAddress: function () {
      if (this.isUndefined(this.profile.users_attribute.post_no)) {
        return false
      }
      return new SearchPostNo.Core(this.profile.users_attribute.post_no, (data) => {
        const index = this.StoragePrefecture.findIndex(row => row.name === data.region)
        this.profile.users_attribute.prefecture_id = (index >= 0) ? this.StoragePrefecture[index].code : 0
        this.profile.users_attribute.address = data.locality + data.street
      })
    },

    isError: function () {
      return this.error
    },

    confirm: async function () {
      this.error = false
      const forms = [
        'name1',
        'name2',
        'name_kana1',
        'name_kana2',
        'birth_date',
        'sex',
        'tel1',
        'tel2',
        'tel3',
        'post_no',
        'prefecture_id',
        'address',
        'building',
        'customer_code',
        'apply_date',
        'withdraw_date',
        'members_code'
      ]
      for (const name of forms) {
        if (this.$refs[name]) {
          await this.$refs[name].confirm()
        }
        if (this.$refs[name] && this.$refs[name].error) {
          this.error = true
        }
      }

      if (this.error) {
        return false
      }
      return true
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading3,
    ComponentsItemsButton,
    ComponentsItemsButtonToggle,
    // ComponentsItemsTextFieldCustomType,
    ComponentsItemsDatePicker,
    ComponentsItemsTextField,
    // ComponentsItemsTextArea,
    ComponentsItemsSelectField
  },

  props: {
    profile: {
      type: Object,
      required: false,
      default: () => {
        return {
          personals: {
            img_src: '',
            name1: '',
            name2: '',
            name_kana1: '',
            name_kana2: '',
            sex: '9',
            birth_date: ''
          },
          users_attribute: {
            country: '+81',
            post_no: '',
            prefecture_id: '',
            address: '',
            building: '',
            mail_address: ''
          },
          address_book: [
            {
              tel: '',
              memo: ''
            },
            {
              tel: '',
              memo: ''
            },
            {
              tel: '',
              memo: ''
            }
          ]
        }
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
