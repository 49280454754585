// import UtilLib from '@/libs/UtilLib'
// import APILib from '@/libs/APILib'
import UtilLib from '@/libs/UtilLib'

const state = {
  vaccineTypes: [],
  selectHeadcount: [
    { name: '1頭', code: '1' },
    { name: '2頭', code: '2' },
    { name: '3頭', code: '3' }
  ],
  common_request: [],
  //
  trimming_timezone: [],
  //
  trimming_request: {
    time_to_contact: '',
    ask_note: ''
  },
  trimming: {
    ask_note: '',
    trimming_date: '',
    trimming_str_time: '',
    trimming_end_time: ''
  },
  //
  personals: {
    img_src: '',
    name: '',
    name_kana: '',
    sex: '9',
    birth_date: ''
  },
  users_attribute: {
    country: '+81',
    post_no: '',
    prefecture_id: '',
    address: '',
    building: '',
    mail_address: ''
  },
  address_book: [],
  fbn_personals: {},
  users_authentication: {},
  //
  animals: []
}

const getters = {
  selectHeadcount: (state) => {
    return state.selectHeadcount
  },
  headcount: (state) => {
    // Integer から String にするために末尾に '' を付与
    return state.common_request.length + ''
  },
  commonRequest: (state) => {
    return state.common_request || []
  },
  trimming: (state) => {
    return state.trimming || {}
  },
  trimmingRequest: (state) => {
    return state.trimming_request || {}
  },
  trimmingTimezone: (state) => {
    return state.trimming_timezone || []
  },
  fbnPersonals: (state) => {
    return state.fbn_personals || {}
  },
  personals: (state) => {
    return state.personals || {}
  },
  usersAttribute: (state) => {
    return state.users_attribute || {}
  },
  addressBook: (state) => {
    return state.address_book || []
  },
  usersAuthentication: (state) => {
    return state.users_authentication || {}
  },
  animals: (state) => {
    return state.animals || []
  },
  requestContents: (state) => (addMemberShips = false, addAuthentication = false) => {
    const results = {
      personals: state.personals,
      users_attribute: state.users_attribute,
      address_book: state.address_book,
      trimming_request: state.trimming_request,
      trimming_request_timezone: state.trimming_timezone.filter(item => !UtilLib.isUndefined(item.trimming_date)),
      contents: state.animals.map(item => {
        const timezone = state.trimming_timezone.filter(item => !UtilLib.isUndefined(item.trimming_date))
        item.trimming = state.trimming
        if (timezone.length > 0) {
          item.trimming.trimming_date = timezone[0].trimming_date
          item.trimming.trimming_str_time = timezone[0].trimming_str_time
          item.trimming.trimming_end_time = timezone[0].trimming_end_time
        }
        return item
      })
    }
    if (addMemberShips) {
      results.fbn_personals = state.fbn_personals
    }
    if (addAuthentication) {
      results.users_authentication = state.users_authentication
    }
    return results
  }
}

const mutations = {
  Initialize (state, vaccineTypes) {
    state.vaccineTypes = vaccineTypes
    state.common_request = []
    state.trimming_timezone = []
    state.trimming_timezone.push({ priority: 1, trimming_date: '', times: '', trimming_str_time: '', trimming_end_time: '' })
    state.trimming_timezone.push({ priority: 2, trimming_date: '', times: '', trimming_str_time: '', trimming_end_time: '' })
    state.trimming_timezone.push({ priority: 3, trimming_date: '', times: '', trimming_str_time: '', trimming_end_time: '' })
    state.trimming = {
      ask_note: '',
      trimming_date: '',
      trimming_str_time: '',
      trimming_end_time: ''
    }
    state.trimming_request = {
      time_to_contact: '',
      ask_note: ''
    }
    state.personals = {
      img_src: '',
      name: '',
      name_kana: '',
      sex: '9',
      birth_date: ''
    }
    state.users_attribute = {
      country: '+81',
      post_no: '',
      prefecture_id: '',
      address: '',
      building: '',
      mail_address: ''
    }
    state.address_book = []
    state.address_book.push({ tel: '', memo: '' })
    state.address_book.push({ tel: '', memo: '' })
    state.address_book.push({ tel: '', memo: '' })
    state.fbn_personals = {
      members_account: '',
      members_password: '',
      mail_magazine_status: ''
    }
    state.users_authentication = {
      personal_account: '',
      personal_password: ''
    }
    state.animals = []
  },
  InitializeTimezone (state) {
    state.trimming_timezone = []
    state.trimming_timezone.push({ priority: 1, trimming_date: '', times: '', trimming_str_time: '', trimming_end_time: '' })
    state.trimming_timezone.push({ priority: 2, trimming_date: '', times: '', trimming_str_time: '', trimming_end_time: '' })
    state.trimming_timezone.push({ priority: 3, trimming_date: '', times: '', trimming_str_time: '', trimming_end_time: '' })
  },
  setupRequest (state, count) {
    if (state.common_request.length === parseInt(count)) {
      return
    }
    if (state.common_request.length > parseInt(count)) {
      state.common_request = state.common_request.filter((item, index) => (parseInt(count) > index))
    } else {
      state.common_request = [...Array(parseInt(count))].map((item, index) => {
        return (state.common_request.length > index)
          ? state.common_request[index]
          : {
            size: '1',
            courses: '',
            assign: ''
          }
      })
    }
  },
  // 新規経由の愛犬選択
  setupAnimals (state, count) {
    if (state.animals.length === parseInt(count)) {
      return
    }
    if (state.animals.length > parseInt(count)) {
      state.animals = state.animals.filter((item, index) => (parseInt(count) > index))
    } else {
      state.animals = [...Array(parseInt(count))].map((item, index) => {
        return (state.animals.length > index)
          ? state.animals[index]
          : {
            trimming: {
              ask_note: '',
              trimming_date: '',
              trimming_str_time: '',
              trimming_end_time: ''
            },
            trimming_assign: [],
            trimming_task: [],
            trimming_courses: [],
            trimming_menus: [],
            personals: {
              img_src: '',
              name: '',
              name_kana: '',
              animal: '犬',
              species: '',
              sex: '9',
              birth_date: ''
            },
            animals_attribute: {
              blood: '',
              snack_status: '2',
              allergy_status: '2',
              sterilization_status: '2',
              sterilization_date: '',
              adoptaversary_date: '',
              death_date: '',
              license_status: '2',
              license_area: '',
              license_id: '',
              rabies_area: '',
              rabies_id: '',
              microchip_status: '2',
              microchip: '',
              doctor_status: '2',
              doctor_name: '',
              doctor_tel: '',
              memo: ''
            },
            personals_vaccine: state.vaccineTypes.map(v => {
              return {
                vaccine_id: '',
                personals_vaccine: {
                  vaccine_type_id: v.code,
                  action_date: '',
                  expire_date: '',
                  img_src: '',
                  action_type: process.env.VUE_APP_PERSONALS_VACCINE_ACTION_TYPE
                }
              }
            }),
            personals_allergies: [],
            personals_trimming_cut_image: [],
            personals_interview_sheet: {}
          }
      })
    }
  },
  // リピーター経由の愛犬選択
  setSelectAnimals (state, [item, actionType]) {
    if (UtilLib.isUndefined(UtilLib.getValue(UtilLib.getValue(item, 'fbn_personals'), 'karte_id'))) {
      return
    }
    if (state.animals.some(v => v.karte_id === item.fbn_personals.karte_id)) {
      if (actionType === 'remove') {
        state.animals.splice(state.animals.findIndex(v => v.karte_id === item.fbn_personals.karte_id), 1)
      }
      return
    }
    if (actionType !== 'add') {
      return
    }

    state.animals.push({
      karte_id: item.fbn_personals.karte_id,
      trimming: {
        ask_note: '',
        trimming_date: '',
        trimming_str_time: '',
        trimming_end_time: ''
      },
      trimming_assign: [],
      trimming_task: [],
      trimming_courses: [],
      trimming_menus: [],
      personals: item.personals,
      animals_attribute: item.animals_attribute,
      personals_vaccine: item.personals_vaccine.map((v) => {
        const vaccineTypesIndex = state.vaccineTypes.findIndex(types => types.code === v.personals_vaccine.vaccine_type_id)
        const requiredImage = (vaccineTypesIndex >= 0) ? (state.vaccineTypes[vaccineTypesIndex].requiredImage) : false
        if (requiredImage && UtilLib.isUndefined(v.personals_vaccine.action_type)) {
          return {
            vaccine_id: v.personals_vaccine.vaccine_id,
            personals_vaccine: {
              vaccine_type_id: v.personals_vaccine.vaccine_type_id,
              action_date: v.personals_vaccine.action_date,
              expire_date: v.personals_vaccine.expire_date,
              img_src: v.personals_vaccine.img_src,
              action_type: process.env.VUE_APP_PERSONALS_VACCINE_ACTION_TYPE
            }
          }
        } else {
          return {
            vaccine_id: v.personals_vaccine.vaccine_id,
            personals_vaccine: {
              vaccine_type_id: v.personals_vaccine.vaccine_type_id,
              action_date: v.personals_vaccine.action_date,
              expire_date: v.personals_vaccine.expire_date,
              img_src: v.personals_vaccine.img_src,
              action_type: v.personals_vaccine.action_type
            }
          }
        }
      }),
      personals_allergies: item.personals_allergies,
      personals_trimming_cut_image: item.personals_trimming_cut_image,
      personals_interview_sheet: item.personals_interview_sheet
    })
  }
}

const actions = {
  Initialize ({ commit, dispatch, state }, vaccineTypes) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('Initialize', vaccineTypes)
        resolve(true)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  InitializeTimezone ({ commit, dispatch, state }) {
    commit('InitializeTimezone')
  },
  setupRequest ({ commit, dispatch, state }, count) {
    commit('setupRequest', count)
    commit('setupAnimals', count)
  },
  setSelectAnimals ({ commit, dispatch, state }, [item, actionType = 'add']) {
    commit('setSelectAnimals', [item, actionType])
    commit('setupRequest', state.animals.length)
  },
  setupRequestAnimals ({ commit, dispatch, state }, count) {
    state.animals.map((item, index) => {
      //
      item.trimming_courses = []
      item.trimming_courses.push({
        item_category_id: state.common_request[index].courses
      })
      //
      item.trimming_assign = []
      item.trimming_task = []
      if (UtilLib.isUndefined(state.common_request[index].assign) === false) {
        item.trimming_assign.push({
          business_users_id: state.common_request[index].assign,
          assign_str_time: state.trimming_timezone[0].trimming_str_time,
          assign_end_time: state.trimming_timezone[0].trimming_end_time,
          assign_status: process.env.VUE_APP_TRIMMING_ASSIGN_STATUS
        })
        item.trimming_task.push({
          business_users_id: state.common_request[index].assign,
          item_category_id: state.common_request[index].courses,
          estimate: '0'
        })
      }
      return item
    })
  },
  changeRequest ({ commit, dispatch, state }, contents) {
    state.common_request = contents
  },
  changeTrimmingTimezone ({ commit, dispatch, state }, contents) {
    state.trimming_timezone = contents
  },
  changeTrimming ({ commit, dispatch, state }, contents) {
    state.trimming = contents
  },
  changeTrimmingRequest ({ commit, dispatch, state }, contents) {
    state.trimming_request = contents
  },
  changeFbnPersonals ({ commit, dispatch, state }, contents) {
    state.fbn_personals = contents
  },
  changePersonals ({ commit, dispatch, state }, contents) {
    state.personals = contents
  },
  changeUsersAttribute ({ commit, dispatch, state }, contents) {
    state.users_attribute = contents
  },
  changeAddressBook ({ commit, dispatch, state }, contents) {
    state.address_book = contents
  },
  changeUsersAuthentication ({ commit, dispatch, state }, contents) {
    state.users_authentication = contents
  },
  changeAnimals ({ commit, dispatch, state }, contents) {
    state.animals = contents
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
