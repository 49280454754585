<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="HELP"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/console/index')"
>
  <v-row align="center" justify="center">
    <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
      <v-col cols=12>
        <ComponentsViewFormsFAQ
        >
        </ComponentsViewFormsFAQ>
      </v-col>
    </v-row>
  </v-row>
</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsViewFormsFAQ from '@/components/view-forms/FAQ'

export default {
  name: 'HelpPage',

  mixins: [MixinLib],

  data: () => ({
  }),

  components: {
    ComponentsLayoutPage2,
    ComponentsViewFormsFAQ
  }
}
</script>
