import VueCookies from 'vue-cookies'
import UtilLib from '@/libs/UtilLib'
// import APILib from '@/libs/APILib'

const state = {
  authorization: '',
  provider: '',
  MemberShip: []
}

const getters = {
  account: (state) => {
    if (UtilLib.isUndefined(state.authorization)) {
      const authorization = VueCookies.get('authorization')
      if (UtilLib.isUndefined(authorization)) {
        return ''
      }
      state.authorization = authorization
    }
    const b64Authorization = Buffer.from(state.authorization, 'base64').toString()
    const authorization = b64Authorization.split(':')
    return (authorization.length === 2) ? authorization[0] : ''
  },
  password: (state) => {
    if (UtilLib.isUndefined(state.authorization)) {
      const authorization = VueCookies.get('authorization')
      if (UtilLib.isUndefined(authorization)) {
        return ''
      }
      state.authorization = authorization
    }
    const b64Authorization = Buffer.from(state.authorization, 'base64').toString()
    const authorization = b64Authorization.split(':')
    return (authorization.length === 2) ? authorization[1] : ''
  },
  provider: (state) => {
    return state.provider
  },
  MemberShip: (state) => {
    return (UtilLib.isUndefined(state.MemberShip)) ? {} : state.MemberShip
  }
}

const mutations = {
  setAuthorization (state, [account, password]) {
    state.authorization = Buffer.from(account + ':' + password).toString('base64')
    VueCookies.config(60 * 60 * 24 * 365, '')
    VueCookies.set('authorization', state.authorization)
  },
  setProvider (state, contents) {
    state.provider = contents
  },
  MemberShip (state, contents) {
    state.MemberShip = contents
  }
}

const actions = {
  setAuthorization ({ commit, dispatch, state }, [account, password]) {
    commit('setAuthorization', [account, password])
  },
  setProvider ({ commit, dispatch, state }, contents) {
    commit('setProvider', contents)
  },
  /*
  LoadMemberShip ({ commit, dispatch, state }, [storeID, query]) {
    return new Promise((resolve, reject) => {
      (async () => {
        const contents = await APILib.getStoresPersonals(storeID, query)
        commit('MemberShip', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  */
  SetMemberShip ({ commit, dispatch, state }, contents) {
    commit('MemberShip', contents)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
