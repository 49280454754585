<template>
<v-radio-group
  :value="value"
  :loading="loading"
  :rules="validate"
  :error-messages="errors"
  :hide-details="!error"
  :disabled="(disabled == true)"
  @change="updateValue"
  class="mt-0"
>
  <v-radio
    v-for="item in items"
    :key="item[itemValue]"
    :label="item[itemText]"
    :value="item[itemValue]"
    :color="buttonColor"
    class="py-1"
  >
    <template v-slot:label>
      <span :style="{ color: (isUndefined(textColor)) ? buttonColor : textColor }" class="ec-font-size12" v-text="item[itemText]"></span>
    </template>
  </v-radio>
</v-radio-group>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import ValidationLib from '@/libs/ValidationLib'

export default {
  name: 'ComponentsItemsRadioGroup',

  mixins: [MixinLib],

  data () {
    return {
      validate: [],
      placeholder: '',
      error: false,
      errors: ''
    }
  },

  computed: {
  },

  created: function () {
    this.validate = ValidationLib.rules(this.name)
    this.placeholder = ValidationLib.placeholder(this.name)
  },

  methods: {
    updateValue: function (e) {
      this.$emit('update:value', e)
    },

    isError: function () {
      return (this.errors.length > 0)
    },

    confirm: async function () {
      return new Promise((resolve, reject) => {
        try {
          this.errors = ''
          for (var y = 0; y < this.validate.length; y++) {
            const err = this.validate[y](this.value)
            if (err !== true) {
              this.errors = err
              break
            }
          }
          if (this.isError()) {
            this.error = true
            return resolve(false)
          }
          this.error = false
          return resolve(true)
        } catch (e) {
          return reject(e)
        }
      })
    }
  },

  props: {
    name: {
      type: String,
      required: true
    },
    textColor: {
      type: String,
      required: true,
      default: () => ''
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    itemText: {
      type: String,
      required: false,
      default: () => 'name'
    },
    itemValue: {
      type: String,
      required: false,
      default: () => 'code'
    },
    value: {
      type: String,
      required: true,
      default: () => ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
