<template>
<v-row align="center" justify="center" :style="{ color: primaryFontColor }">
  <v-col :cols="(required || icon) ? 9 : 12" class="pb-1 text-left font-weight-bold ec-font-size14 ">
    <span v-text="text"></span>
  </v-col>
  <v-col cols=3 class="pb-1 text-right" v-if="(required)">
    <ComponentsItemsRequireLabel></ComponentsItemsRequireLabel>
  </v-col>
  <v-col cols=3 class="pb-1 text-right" v-if="(icon)">
    <ComponentsItemsButton
      small
      icon-btn
      :icon="icon"
      @click="$emit('click')"
    >
    </ComponentsItemsButton>
  </v-col>
  <v-col cols=12 class="pt-0 pb-3" v-show="!borderHidden">
    <v-divider></v-divider>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import ComponentsItemsRequireLabel from '@/components/items/RequireLabel'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsLayoutHeading1',

  mixins: [MixinLib],

  components: {
    ComponentsItemsRequireLabel,
    ComponentsItemsButton
  },

  props: {
    text: {
      type: String,
      required: false,
      default: () => ''
    },
    required: {
      type: Boolean,
      required: false,
      default: () => false
    },
    icon: {
      type: String,
      required: false,
      default: () => ''
    },
    borderHidden: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
