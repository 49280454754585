<template>
<v-app :style="{ backgroundColor: backGroundColor }">

  <div id="scrollTop"></div>

  <v-app-bar fixed flat app :color="primaryColor" height="60" v-show="header" class="mx-auto" max-width=840>
    <v-row align="center" white="center">
      <v-col cols=3 class="text-left">
        <ComponentsItemsButton
          small
          text-btn
          :icon="headerLeftIcon"
          :loading="loading"
          :text="headerLeftText"
          @click="$emit('left-click')"
          v-show="headerLeft"
        >
        </ComponentsItemsButton>
      </v-col>
      <v-col cols=6 class="text-center ec-font-size12 font-weight-bold">
        <span  v-text="headerTitle"></span>
      </v-col>
      <v-col cols=3 class="text-right">
        <ComponentsItemsButton
          small
          text-btn
          icon-position="right"
          :icon="headerRightIcon"
          :loading="loading"
          :text="headerRightText"
          @click="$emit('right-click')"
          v-show="headerRight"
        >
        </ComponentsItemsButton>
      </v-col>
    </v-row>
  </v-app-bar>

  <v-main>
    <v-container fluid class="ec-font-size12" style="max-width: 840px;" :style="{ backgroundColor: (isUndefined(backColor)) ? backGroundColor : backColor }">
      <slot></slot>
    </v-container>
  </v-main>

  <v-footer absolute app :color="primaryColor" padless height="40" v-show="footer">
    <v-row no-gutters>
      <v-col cols="12" class="py-2 text-center">
        <span class="'mx-auto ec-font-size10" >copyright (c) ColorZoo.All rights reserved..</span>
      </v-col>
    </v-row>
  </v-footer>
</v-app>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsLayoutPage2',

  mixins: [MixinLib],

  components: {
    ComponentsItemsButton
  },

  props: {
    header: {
      type: Boolean,
      required: false,
      default: () => false
    },
    footer: {
      type: Boolean,
      required: false,
      default: () => false
    },
    backColor: {
      type: String,
      required: false,
      default: () => ''
    },
    headerTitle: {
      type: String,
      require: false,
      default: () => ''
    },
    headerLeft: {
      type: Boolean,
      required: false,
      default: () => false
    },
    headerLeftIcon: {
      type: String,
      require: false,
      default: () => ''
    },
    headerLeftText: {
      type: String,
      require: false,
      default: () => ''
    },
    headerRight: {
      type: Boolean,
      required: false,
      default: () => false
    },
    headerRightIcon: {
      type: String,
      require: false,
      default: () => ''
    },
    headerRightText: {
      type: String,
      require: false,
      default: () => ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
