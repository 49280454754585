<template>
<v-row align="center" justify="center">
  <v-col cols=12>
    <ComponentsLayoutSection2>
      <v-row align="center" justify="center">
        <v-col cols=12>
          <v-row align="center" justify="center" v-for="(item, index) of timezone" :key="index">
            <v-col cols=6>
              <ComponentsLayoutHeading3 :text="'第' + (index + 1) + '希望'"></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols=6 class="text-right">
              <ComponentsItemsButton
                small
                :loading="loading || isProcessLoading"
                text="設定"
                @click="openSetApplyDateTimes(index)"
              >
              </ComponentsItemsButton>
            </v-col>
            <v-col cols=12 class="pl-5 text-left">
              <v-row align="center" justify="center">
                <v-col cols=4>
                  <ComponentsLayoutHeading3 text="希望日：" class="pb-3"></ComponentsLayoutHeading3>
                </v-col>
                <v-col cols=8>
                  <span v-if="!isUndefined(item.trimming_str_time)">
                    <span v-text="formatDate(item.trimming_date, 'YYYY年MM月DD日 (ddd)')"></span>
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols=12 class="pl-5 text-left">
              <v-row align="center" justify="center">
                <v-col cols=4>
                  <ComponentsLayoutHeading3 text="希望時間：" class="pb-3"></ComponentsLayoutHeading3>
                </v-col>
                <v-col cols=8>
                  <span v-if="!isUndefined(item.trimming_str_time)">
                    <span v-text="onGetTimezone(item)"></span>
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols=12 class="py-1" v-if="index < timezone.length - 1">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </ComponentsLayoutSection2>
  </v-col>

  <!-- カレンダー -->
  <ComponentsBottomSheetTrimmingCalendar
    :model="bottomSheet.setDateTimeSheet"
    :trimming-timezone="timezone[edit.index]"
    @close-click="bottomSheet.setDateTimeSheet = false"
    @on-commit-click="returnSetApplyDateTimes"
    :newer="newer"
    v-if="bottomSheet.setDateTimeSheet"
  >
  </ComponentsBottomSheetTrimmingCalendar>

</v-row>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsLayoutSection2 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsBottomSheetTrimmingCalendar from '@/components/bottom-sheet/TrimmingCalendar'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsFormsTrimmingApplyCalendar',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    bottomSheet: {
      setDateTimeSheet: false
    },
    edit: {
      index: 0
    }
  }),

  computed: {
    timezone: {
      get () {
        return this.trimmingTimezone
      },
      set (v) {
        this.$emit('update:trimmingTimezone', v)
      }
    }
  },

  created () {
  },

  mounted () {
    this.isProcessLoading = false
  },

  methods: {
    openSetApplyDateTimes: function (index) {
      this.bottomSheet.setDateTimeSheet = true
      this.edit.index = index
    },
    returnSetApplyDateTimes: function (event) {
      this.timezone[this.edit.index] = this.clone(event)
      this.bottomSheet.setDateTimeSheet = false
    },
    onGetTimezone: function (item) {
      if (this.isUndefined(item.times)) {
        return item.trimming_str_time + '-' + item.trimming_end_time
      }
      return (item.times === process.env.VUE_APP_TRIMMING_TIMES_FREE_TEXT) ? item.times + ' [' + item.trimming_str_time + '-' + item.trimming_end_time + ']' : item.times
      // return (this.isUndefined(item.times)) ? item.trimming_str_time + '-' + item.trimming_end_time : item.times
    }
  },

  components: {
    ComponentsLayoutSection2,
    ComponentsLayoutHeading3,
    ComponentsBottomSheetTrimmingCalendar,
    ComponentsItemsButton
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    newer: {
      type: Boolean,
      require: false,
      default: () => false
    },
    trimmingTimezone: {
      type: Array,
      require: false,
      default: () => {
        return [
          {
            priority: 1,
            trimming_date: '',
            times: '',
            trimming_str_time: '',
            trimming_end_time: ''
          },
          {
            priority: 1,
            trimming_date: '',
            times: '',
            trimming_str_time: '',
            trimming_end_time: ''
          },
          {
            priority: 1,
            trimming_date: '',
            times: '',
            trimming_str_time: '',
            trimming_end_time: ''
          }
        ]
      }
    }
  }
}
</script>
