<template>
<v-row align="center" justify="center" class="mx-auto" v-if="loading || isProcessLoading">
  <v-col cols=12 class="py-5">
    <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
  </v-col>
</v-row>
<v-row align="center" justify="center" v-else>
  <v-col cols=12>
    <v-row align="center" justify="center" v-for="(item, index) in SchemaData" :key="index">
      <v-col cols=12 class="text-left">
        <ComponentsLayoutHeading3 :text="item.label" class="pb-3"></ComponentsLayoutHeading3>
      </v-col>
      <v-col cols=12 v-if="(item.type === 'select' && item.multiple === true)">
        <ComponentsItemsCheckBoxGroup
          :name="item.name"
          :items="item.items"
          itemText="value"
          itemValue="value"
          :text-color="tertiaryFontColor"
          :value.sync="ValuesData[item.name]"
          :loading="loading || isProcessLoading"
          @update:value="(e) => { ValuesData[item.name] = e, $emit('update:values', ValuesData) }"
        >
        </ComponentsItemsCheckBoxGroup>
      </v-col>
      <v-col cols=12 v-if="(item.type === 'select' && item.multiple === false)">
        <ComponentsItemsRadioGroup
          :name="item.name"
          :items="item.items"
          itemText="value"
          itemValue="value"
          :text-color="tertiaryFontColor"
          :value.sync="ValuesData[item.name]"
          :loading="loading || isProcessLoading"
          @update:value="(e) => { ValuesData[item.name] = e, $emit('update:values', ValuesData) }"
        >
        </ComponentsItemsRadioGroup>
      </v-col>
      <v-col cols=12 v-if="(item.type === 'text')">
        <ComponentsItemsTextFieldCustomType
          :name="item.name"
          :value.sync="ValuesData[item.name]"
          :type="item.type"
          :loading="loading || isProcessLoading"
          @update:value="(e) => { ValuesData[item.name] = e, $emit('update:values', ValuesData) }"
        >
        </ComponentsItemsTextFieldCustomType>
      </v-col>
    </v-row>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsItemsRadioGroup from '@/components/items/RadioGroup'
import ComponentsItemsCheckBoxGroup from '@/components/items/CheckBoxGroup'
import ComponentsItemsTextFieldCustomType from '@/components/items/TextFieldCustomType'

export default {
  name: 'ComponentsItemsInterviewSheet',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  watch: {
    schema () {
      this.init()
    },
    values () {
      console.log('watch')
      // this.$emit('update:values', this.ValuesData)
    }
  },

  computed: {
    SchemaData: {
      get () {
        return this.schema
      },
      set (v) {
        this.$emit('update:schema', v)
      }
    },
    ValuesData: {
      get () {
        return this.schema.reduce((prev, curr) => {
          if (curr.type === 'select') {
            if (curr.multiple) {
              prev[curr.name] = (this.isObject(this.values) && this.exists(this.values, curr.name)) ? this.values[curr.name] : []
            } else {
              prev[curr.name] = (this.isObject(this.values) && this.exists(this.values, curr.name)) ? this.values[curr.name] : ''
            }
          } else {
            prev[curr.name] = (this.isObject(this.values) && this.exists(this.values, curr.name)) ? this.values[curr.name] : ''
          }
          return prev
        }, {})
      },
      set (v) {
        this.$emit('update:values', v)
      }
    }
  },

  mounted () {
    // this.init()
  },

  methods: {
    /*
    updateValue: function (e) {
      this.$emit('update:values', e)
    },

    init () {
      this.isProcessLoading = true
      this.updateValue(this.schema.reduce((prev, curr) => {
        if (curr.type === 'select') {
          if (curr.multiple) {
            prev[curr.name] = (this.exists(this.values, curr.name)) ? this.values[curr.name] : []
          } else {
            prev[curr.name] = (this.exists(this.values, curr.name)) ? this.values[curr.name] : ''
          }
        } else {
          prev[curr.name] = (this.exists(this.values, curr.name)) ? this.values[curr.name] : ''
        }
        return prev
      }, {}))
      this.isProcessLoading = false
    }
    */
  },

  components: {
    ComponentsLayoutHeading3,
    ComponentsItemsRadioGroup,
    ComponentsItemsCheckBoxGroup,
    ComponentsItemsTextFieldCustomType
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    schema: {
      type: Array,
      required: true,
      default: () => []
    },
    values: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
}
</script>
