<template>
  <router-view v-if="!isProcessLoading" />
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'StoresCommonPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    users: {
      get () {
        return this.$store.getters['modStorage/users']
      }
    },
    usersStores: {
      get () {
        return this.$store.getters['modStorage/usersStores']
      }
    },
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    StorageCategories: {
      get () {
        return this.$store.getters['modStorage/storesGroomingCategories']
      }
    }
  },

  mounted: function () {
    if (this.isInLineClient === false) {
      return this.pageTransition('/')
    }
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        // マスター情報の読み込み（バックエンド）
        this.$store.dispatch('modStorage/LoadMaster')
        // 店舗情報の読み込み
        await this.$store.dispatch('modStorage/LoadStoresContents', this.storeID)
        //
        await this.$store.dispatch('modStorage/LoadUsers').catch((e) => false)
        //
        await this.$store.dispatch('modStorage/LoadUsersStores', this.storeID).catch((e) => false)
        //
        this.isProcessLoading = false
        // カルテ登録済み: リピーター
        if (this.isMember()) {
          return this.SignUpTransition()
        }
        // アプリ登録あり & カルテ登録無し: 新規
        if (this.isRegister()) {
          // 自動連携後、画面遷移
          return this.SignUpTransition()
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    isRegister: function () {
      if (this.isUndefined(this.users)) {
        return false
      }
      return true
    },
    isMember: function () {
      if (this.isUndefined(this.usersStores)) {
        return false
      }
      return true
    },
    SignUpTransition: function () {
      if (this.exists(this.$route.query, 'page')) {
        return this.goTransition(this.$route.query.page)
      } else {
        return this.goTransition('/console/index')
      }
    }
  },

  components: {
    // ComponentsLayoutPage2,
    // ComponentsItemsProgressCircular
  }
}
</script>
