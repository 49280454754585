<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP1. 予約申請希望選択"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP2. コース＆指名条件"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP3. ご予約希望日時の設定"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP4. お客様情報の確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP5. カルテ情報の確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP6. 申請内容の最終確認"></ComponentsLayoutHeading1>
    </v-col>

  </v-row>
</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

export default {
  name: 'StoresTrimmingReserveReservePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    StorageCategories: {
      get () {
        return this.$store.getters['modStorage/storesGroomingCategories'].filter(v => v.item_categories.item_category_attr1 === process.env.VUE_APP_PERSONALS_ITEM_CATEGORY_ATTR1)
      }
    },
    Users: {
      get () {
        return this.$store.getters['modStorage/users']
      }
    },
    UsersStores: {
      get () {
        return this.$store.getters['modStorage/usersStores']
      }
    }
  },

  created () {
  },

  mounted () {
    this.goLoading()
  },

  methods: {
    // -------------------------------------------
    //
    // -------------------------------------------
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        this.$store.dispatch('modTrimming/Initialize', this.StorageVaccineTypes)
        if (this.isUndefined(this.StorageCategories)) {
          return this.pageTransition('/gr-rsv/' + this.storeID)
        }
        await this.$store.dispatch('modTrimming/changeFbnPersonals', this.clone(this.UsersStores.fbn_personals))
        await this.$store.dispatch('modTrimming/changePersonals', this.clone(this.UsersStores.personals))
        await this.$store.dispatch('modTrimming/changeUsersAttribute', this.clone(this.UsersStores.users_attribute))
        await this.$store.dispatch('modTrimming/changeAddressBook', this.clone(this.UsersStores.address_book))
        await this.$store.dispatch('modTrimming/changeUsersAuthentication', this.clone(this.UsersStores.users_authentication))

        this.pageTransition('/gr-rsv/' + this.storeID + '/reserve/1')
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '想定外のシステム異常が発生しました。お手数ですがシステムにお問合せ下さい [' + e.message + ']', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1
  }
}
</script>
