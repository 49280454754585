<template>
<ComponentsLayoutPage1
  header
>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'

export default {
  name: 'ConsoleMembersCardPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  methods: {
  },

  components: {
    ComponentsLayoutPage1
  }
}
</script>
