<template>
<v-bottom-sheet persistent scrollable v-model="model" v-if="model">
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutHeading1 text="よくあるご質問(FAQ)" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <ComponentsViewFormsFAQ
      >
      </ComponentsViewFormsFAQ>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            text-btn
            text="閉じる"
            :loading="isProcessLoading"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-bottom-sheet>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsItemsButton from '@/components/items/Button'
import ComponentsViewFormsFAQ from '@/components/view-forms/FAQ'

export default {
  name: 'ComponentsBottomSheetFAQ',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    items: [
      {
        answer: 'ここに回答内容を記載',
        title: 'GREENDOG会員のアカウント忘れました'
      },
      {
        answer: '<a href="https://www.green-dog.com/shop/mypage/" target="_blank">GREEN DOG 通販マイページ</a>のパスワードを忘れた方は、こちらから再発行を行ってください。',
        title: 'GREEN DOG会員のパスワードを忘れました。'
      },
      {
        answer: '大変お手数ですが、該当店舗までお電話でお問合せ頂けますでしょうか？',
        title: '仮予約申請しましたが、店舗から返信がありません。'
      },
      {
        answer: 'LINE版をご利用の方は、LINEの下部メニュー HOMEメニューの予約一覧 をご確認ください。<br />ウェブ版をご利用の方は、<a href="https://www.petcone-japan.com/" target="_blank">ペトコネウェブ版</a>にアクセスしていただき、ペトコネアカウントでログイン後、HOMEメニューの予約一覧 をご確認ください。',
        title: 'いつ予約をとったのか確認したいのですが、どこから確認できますか？'
      },
      {
        answer: 'はい。ご登録情報は連携しています。',
        title: 'GREEN DOGのマイページとペトコネの登録情報は紐づいていますか？'
      },
      {
        answer: 'LINE版をご利用の方は、LINEの下部メニュー HOMEメニューの左上メニューにあるプロフィール設定 から変更下さい。<br />ウェブ版をご利用の方は、<a href="https://www.petcone-japan.com/" target="_blank">ペトコネウェブ版</a>にアクセスしていただき、ペトコネアカウントでログイン後、HOMEメニューの左上メニューにあるプロフィール設定 から変更下さい<br />GREEN DOGアカウントを変更したい方は、<a href="https://www.green-dog.com/shop/mypage/" target="_blank">GREEN DOG 通販マイページ</a>より変更をお願いいたします。',
        title: '会員情報の変更をしたいのですが、どうしたらいいでしょうか？'
      },
      {
        answer: 'ここに回答内容を記載',
        title: '仮予約の手順がわかりません。'
      },
      {
        answer: '大変お手数ですが、該当店舗までお電話でお問合せ頂けますでしょうか？',
        title: 'グルーミングのキャンセルをしたいのですが、どうすればいいですか？'
      },
      {
        answer: '大変お手数ですが、該当店舗までお電話でお問合せ頂けますでしょうか？',
        title: 'グルーミングの予約日時変更をしたいのですが、どうすればいいですか？'
      },
      {
        answer: '仮予約申請で病院の予約を同時に取ることは出来ません。大変お手数ですが、該当店舗までお電話でお問合せ頂けますでしょうか？',
        title: 'グルーミングの日に病院の予約も取りたいですが、仮予約申請で予約はとれますか？'
      },
      {
        answer: 'ここに回答内容を記載',
        title: '退会したいのですがどうすればいいですか？'
      },
      {
        answer: 'ここに回答内容を記載',
        title: 'パートナー（愛犬）の追加登録をしたい。'
      },
      {
        answer: '大変お手数ですが、該当店舗までお電話でお問合せ頂けますでしょうか？',
        title: '仮予約申請を取り消したいのですがどうすればいいですか？'
      },
      {
        answer: '大変お手数ですが、該当店舗までお電話でお問合せ頂けますでしょうか？',
        title: '仮予約申請を申請し直したいのですが、どうすればいいですか？'
      },
      {
        answer: 'ここに回答内容を記載',
        title: '狂犬病、混合ワクチンの証明証の画像を更新したいのですがどうすればいいですか？'
      },
      {
        answer: 'ここに回答内容を記載',
        title: '狂犬病、混合ワクチン猶予の場合はどうすればいいですか？'
      },
      {
        answer: 'ここに回答内容を記載',
        title: '携帯を買い替えたのですが、以前のデータは連携されますか？'
      },
      {
        answer: 'ここに回答内容を記載',
        title: '予約内容をトリミングコースかシャンプーコースどちらか相談してから予約を取りたい場合は仮予約申請時にどうすればいいですか？'
      },
      {
        answer: 'ここに回答内容を記載',
        title: '住所、電話番号変更したいのですが、LINEからでも変更できますか？'
      },
      {
        answer: 'ここに回答内容を記載',
        title: 'トリミングの日に商品の取り置きをしたいのですが仮予約申請時に依頼することはできますか？'
      },
      {
        answer: 'ここに回答内容を記載',
        title: '予約時間に間に合わない可能性がある場合はどうすればいいですか？'
      },
      {
        answer: 'ここに回答内容を記載',
        title: '複数店舗で仮予約申請はできますか？'
      }
    ]
  }),

  watch: {
    model () {
      if (this.model) {
        this.goLoading()
      }
    }
  },

  computed: {
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true

        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    goSupport: function () {
      this.goTransition(process.env.VUE_APP_SUPPORT_LINE)
    }
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsViewFormsFAQ,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    }
  }
}
</script>
