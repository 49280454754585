<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">
    <v-col cols=12>
      <ComponentsLayoutHeading1 :text="(isUndefined(StorageStoresMemberShip)) ? '' : StorageStoresMemberShip.membership_name + ' 会員証'"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 v-if="isUndefined(membersCode)">
      <ComponentsLayoutSection2>
        <v-row align="center" justify="center" class="mx-auto py-3">
          <v-col cols=12>
            <span>非会員</span>
          </v-col>
        </v-row>
      </ComponentsLayoutSection2>
    </v-col>
    <v-col cols=12 v-else>
      <ComponentsLayoutSection2>
        <v-row align="center" justify="center" class="mx-auto py-3">
          <v-col cols=12>
            <VueBarcode :value="membersCode" format="code128" class="text-center" height="90" fontSize="16" textMargin="5" background="transparent"></VueBarcode>
          </v-col>
        </v-row>
      </ComponentsLayoutSection2>
    </v-col>
  </v-row>
</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import VueBarcode from 'vue-barcode'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsLayoutSection2 from '@/components/layout/Section1'

export default {
  name: 'StoresSignInIndexPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    usersStores: {
      get () {
        return this.$store.getters['modStorage/usersStores']
      }
    },
    membersCode: {
      get () {
        return (this.isUndefined(this.usersStores)) ? '' : this.usersStores.fbn_personals.members_code
      }
    }
  },

  mounted () {
    // LINE経由以外はTOPに転送
    if (this.isInLineClient === false) {
      this.pageTransition('/')
    }
    this.isProcessLoading = false
  },

  methods: {
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsLayoutSection2,
    VueBarcode
  }
}
</script>
