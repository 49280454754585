<template>
<!--- 担当者の指名 ---->
<v-bottom-sheet persistent scrollable v-model="model" v-if="model">
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutHeading1 text="担当者の指名" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutSection1 :color="(isUndefined(selected)) ? secondaryActiveColor : ''" on-click-action @click="selected = ''">
            <v-row align="center" justify="center">
              <v-col cols=8 class="ec-font-size14 font-weight-bold">
                <span>おまかせ</span>
              </v-col>
              <v-col cols=4 class="text-right">
                <ComponentsItemsButton
                  :outlined="(!isUndefined(selected))"
                  :loading="isLoading"
                  small
                  text="選択する"
                >
                </ComponentsItemsButton>
              </v-col>
            </v-row>
          </ComponentsLayoutSection1>
        </v-col>
        <v-col cols=12 v-for="item in StorageGroomer" :key="item.business_users_id">
          <ComponentsViewFormsGroomerProfile
            :value="selected"
            :loading="isLoading"
            :business-users-id="item.business_users_id"
            :business-users="item.business_users"
            :stores-service-users="item.stores_service_users"
            @click="selected = item.business_users_id"
          ></ComponentsViewFormsGroomerProfile>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <ComponentsItemsButton
            text-btn
            text="閉じる"
            :loading="isProcessLoading"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            text="設定"
            :loading="isProcessLoading"
            @click="$emit('on-setting-click', selected)"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-bottom-sheet>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsViewFormsGroomerProfile from '@/components/view-forms/GroomerProfile'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsBottomSheetSelectTrimmer',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    selectedWorker: null
  }),

  watch: {
  },

  computed: {
    StorageGroomer: {
      get () {
        return this.$store.getters['modStorage/storesGroomer']
      }
    },
    selected: {
      get () {
        return (this.selectedWorker === null) ? this.values : this.selectedWorker
      },
      set (v) {
        this.selectedWorker = v
      }
    }
  },

  mounted () {
    this.selectedWorker = null
  },

  methods: {
  },

  beforeDestroy () {
    this.isProcessLoading = false
    this.selectedWorker = null
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsViewFormsGroomerProfile,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    },
    values: {
      type: String,
      require: false,
      default: () => ''
    }
  }
}
</script>
