<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">
    <v-col cols=12>
      <ComponentsLayoutHeading1 :text="(isUndefined(StorageStoresMemberShip)) ? '' : StorageStoresMemberShip.membership_name + ' アカウントをお持ちの方'"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection2>
        <v-row align="center" justify="center" class="mx-auto py-3">
          <v-col cols=12>
            <ComponentsLayoutHeading3 :text="UserTypeName" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextField
              name="account"
              custom-rules
              :rules="userTypeRule"
              :value.sync="account"
              :loading="isProcessLoading"
              ref="account"
            ></ComponentsItemsTextField>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutHeading3 text="パスワード" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsTextFieldTypePassword
              name="password"
              custom-rules
              :rules="passwordRule"
              :value.sync="password"
              :loading="isProcessLoading"
              autocomplete="current-password"
              ref="password"
            ></ComponentsItemsTextFieldTypePassword>
          </v-col>
          <v-col cols=12>
            <ComponentsItemsButton
              block
              :loading="isProcessLoading"
              text="ログインして登録情報の入力に進む"
              @click="goLogin()"
            >
            </ComponentsItemsButton>
          </v-col>
          <v-col cols=12 class="pt-5 pb-1">
            <span>パスワードを忘れた方は、<a href="https://www.inugohan-madoguchi.com/password/forget" target="_blank">こちら</a></span>
          </v-col>
          <v-col cols=12 class="py-1">
            <span>店舗で会員登録し、パスワードが未設定の方は<a href="https://www.green-dog.com/shop/member" target="_blank">こちら</a></span>
          </v-col>
        </v-row>
      </ComponentsLayoutSection2>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="アカウントをお持ちでない方"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection2>
        <v-row align="center" justify="center" class="mx-auto py-3">
          <v-col cols=12>
            <ComponentsItemsButton
              block
              :loading="isProcessLoading"
              text="登録情報の入力に進む"
              @click="goNext()"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </ComponentsLayoutSection2>
    </v-col>
  </v-row>
</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsLayoutSection2 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsTextFieldTypePassword from '@/components/items/TextFieldTypePassword'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingProfileMemberShipPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    account: '',
    password: ''
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    userTypeRule: {
      get () {
        return this.$store.getters['modStorage/userTypeRule']
      }
    },
    passwordRule: {
      get () {
        return this.$store.getters['modStorage/passwordRule']
      }
    },
    UserTypeName: {
      get () {
        if (this.StorageStoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_MAIL) {
          return 'メールアドレス'
        }
        if (this.StorageStoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_TEL) {
          return '電話番号'
        }
        if (this.StorageStoresMemberShip.membership_username_type === process.env.VUE_APP_MEMBERSHIP_USERNAME_TYPE_USER) {
          return 'アカウント'
        }
        return ''
      }
    }
  },

  mounted () {
    this.isProcessLoading = false
  },
  methods: {
    goLogin: function () {
      (async () => {
        if (this.$refs.account) {
          await this.$refs.account.confirm()
        }
        if (this.$refs.password) {
          await this.$refs.password.confirm()
        }
        if (this.$refs.account && this.$refs.account.error) {
          return false
        }
        if (this.$refs.password && this.$refs.password.error) {
          return false
        }
        this.isProcessLoading = true
        // LINE-IDをリンクさせて転送
        // await APILib.getStoresPersonals(this.storeID, { authorization: Buffer.from(this.account + ':' + this.password) })
        // return this.SignInLoading('/gr-shs/' + this.storeID, true)
        // 認証チェック
        // await this.$store.dispatch('modSession/LoadMemberShip', [this.storeID, { authorization: Buffer.from(this.account + ':' + this.password).toString('base64') }])
        //
        // return this.pageTransition('/gr-shs/' + this.storeID + '/sheets')
        const profiles = await APILib.getStoresPersonals(this.storeID, { authorization: Buffer.from(this.account + ':' + this.password).toString('base64') })
        this.$store.dispatch('modStorage/SetUsers', profiles)
        this.$store.dispatch('modStorage/SetUsersStores', profiles)
        this.$store.dispatch('modSession/SetMemberShip', this.$store.getters['modStorage/usersStores'])
        return this.pageTransition('/gr-shs/' + this.storeID + '/sheets')
      })().catch((e) => {
        this.isProcessLoading = false
        if (e.message === 'NotAuthorizedException') {
          return this.$confirm({ title: 'Error', message: 'メールアドレス、または、パスワードをご確認ください', button: { yes: 'OK' } })
        }
        this.$confirm({ title: 'Error[1]', message: e, button: { yes: 'OK' } })
      })
    },
    goNext: function () {
      return this.pageTransition('/gr-shs/' + this.storeID + '/authentication')
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsLayoutSection2,
    ComponentsLayoutHeading3,
    ComponentsItemsTextField,
    ComponentsItemsTextFieldTypePassword,
    ComponentsItemsButton
  }
}
</script>
