<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="予約一覧"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/console/index')"
>
<v-row align="center" justify="center">
  <v-col cols=12 class="px-0">
    <v-list three-line class="transparent">
      <template v-for="(item, index) in reserves">
        <v-divider :key="'t-' + index" v-if="index === 0"></v-divider>

        <v-list-item three-line :key="item.trimming_id" class="white" @click="openContents(item)">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              <span v-text="getDate(item.trimming.trimming_date + ' ' + item.trimming.trimming_str_time, 'YYYY年MM月DD日(ddd) HH:mm')"></span><span>&nbsp;～</span>
            </v-list-item-title>
            <v-list-item-subtitle style="font-size: 0.7rem!important;">
              <span v-text="item.store.store_name"></span>
            </v-list-item-subtitle>
            <v-list-item-subtitle style="font-size: 0.7rem!important;">
              <span v-text="item.personals.name1"></span>&nbsp;<span>ちゃん</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action style="align-self: center;">
            <ComponentsItemsButton
              small
              :loading="isLoading || isProcessLoading"
              icon-btn
              icon="mdi-chevron-right"
              icon-position="right"
            >
            </ComponentsItemsButton>
          </v-list-item-action>
        </v-list-item>

        <v-divider :key="'b-' + index"></v-divider>
      </template>
    </v-list>
  </v-col>

  <ComponentsBottomSheetTrimmingReserveSheet
    :model="bottomSheet"
    :personal-id="edit.personalID"
    :trimming-id="edit.trimmingID"
    :stores="edit.store"
    @close-click="bottomSheet = false"
    v-if="bottomSheet"
  >
  </ComponentsBottomSheetTrimmingReserveSheet>

</v-row>
</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'

import ComponentsItemsButton from '@/components/items/Button'

import ComponentsBottomSheetTrimmingReserveSheet from '@/components/bottom-sheet/TrimmingReserveSheet'

export default {
  name: 'ConsoleReservePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    bottomSheet: false,
    edit: {
      personalID: '',
      trimmingID: '',
      store: {}
    }
  }),

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        /// this.list = await APILib.getUsersTrimmingOrder()
        await this.$store.dispatch('modStorage/LoadReserve')
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    openContents: function (item) {
      this.edit.personalID = item.personal_id
      this.edit.trimmingID = item.trimming_id
      this.edit.store = item.store
      this.bottomSheet = true
    }
  },

  components: {
    ComponentsLayoutPage2,
    ComponentsItemsButton,
    ComponentsBottomSheetTrimmingReserveSheet
  }
}
</script>
