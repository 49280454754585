<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
  header-left
  header-left-icon='mdi-chevron-left'
  header-left-text='戻る'
  @left-click="pageTransition('/gp/' + storeID + '/authentication')"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">
    <v-col cols=12>
      <ComponentsLayoutHeading1 text="(本人確認) 本人確認コードの入力"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection2>
        <v-row align="center" justify="center" class="mx-auto py-3">
          <v-col cols=12>
            <ComponentsLayoutHeading3 text="本人確認コードをご入力ください" required></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=12>
            <v-otp-input
              :length="6"
              :disabled="isProcessLoading"
              v-model="verifyCode"
              @finish="onFinish"
            ></v-otp-input>
          </v-col>

          <v-col cols=12 class="text-center">
            <ComponentsItemsButton
              block
              text-btn
              :loading="isProcessLoading"
              text="認証コードを再送する"
              @click="pageTransition('/gr-shs/' + this.storeID + '/authentication')"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </ComponentsLayoutSection2>
    </v-col>

  </v-row>
</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'
// import Twilio from '@/libs/Twilio'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsLayoutSection2 from '@/components/layout/Section1'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingProfileAuthenticationVerifyPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    verifyCode: ''
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    channel: {
      get () {
        return this.$store.getters['modVerify/channel']
      }
    },
    tel: {
      get () {
        return this.$store.getters['modVerify/tel']
      }
    }
  },

  mounted () {
    this.isProcessLoading = false
  },
  methods: {
    onFinish: function () {
      (async () => {
        this.isProcessLoading = true
        // console.log('onFinish')
        await APILib.postStoresVerificationChecks(this.storeID, this.tel, this.verifyCode)

        const profiles = await APILib.getStoresPersonals(this.storeID, { tel: this.tel })
        this.$store.dispatch('modStorage/SetUsers', profiles)
        this.$store.dispatch('modStorage/SetUsersStores', profiles)
        this.$store.dispatch('modSession/SetMemberShip', this.$store.getters['modStorage/usersStores'])
        this.isProcessLoading = false
        return this.pageTransition('/gr-shs/' + this.storeID + '/sheets')
      })().catch((e) => {
        this.isProcessLoading = false
        if (e.message === 'AuthenticationException') {
          return this.$confirm({ title: 'Error', message: '認証コードが間違っています', button: { yes: 'OK' } })
        }
        this.$confirm({ title: 'Error', message: e, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsLayoutSection2,
    ComponentsLayoutHeading3,
    ComponentsItemsButton
  }
}
</script>
