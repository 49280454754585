<template>
<v-chip x-small color="red" text-color="white" disabled label><span>必須</span></v-chip>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsItemsRequireLabel',

  mixins: [MixinLib],

  props: {
  }
}
</script>
