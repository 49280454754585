<template>
<v-row align="center" justify="center" class="pt-5" no-gutters>

  <v-col cols=12 class="pb-3">
    <ComponentsLayoutSection2>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols=3 class="text-center">
          <ComponentsItemsButton
            :loading="isProcessLoading"
            icon-btn
            icon="mdi-arrow-left-drop-circle"
            @click="changeMonth(-1)"
          >
          </ComponentsItemsButton>
        </v-col>
        <v-col cols=6 class="text-center">
          <span :style="{ color: buttonColor }" v-text="formatDate(calendarDate,'YYYY年')"></span>
        </v-col>
        <v-col cols=3 class="text-center">
          <ComponentsItemsButton
            :loading="isProcessLoading"
            icon-btn
            icon="mdi-arrow-right-drop-circle"
            @click="changeMonth(1)"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </ComponentsLayoutSection2>
  </v-col>

  <v-col cols=12>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center" :style="{ borderRight: '1px solid #eee' }"><span>日</span></th>
            <th class="text-center" :style="{ borderRight: '1px solid #eee' }"><span>体重<br />(kg)</span></th>
            <th class="text-center" :style="{ borderRight: '1px solid #eee' }"><span>前年<br />(kg)</span></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" :key="item.weight_date">
            <td class="text-center ec-font-size10" :style="{ borderRight: '1px solid #eee' }">
              <span v-text="formatDate(item.weight_month,'MM月')"></span>
            </td>
            <td class="text-center" :style="{ borderRight: '1px solid #eee' }">
              <span class="ec-font-size12 font-weight-bold" :style="{ color: '#FF8300' }" v-text="item.weight" v-if="getDate([],'YYYY-MM') >= formatDate(item.weight_month,'YYYY-MM')"></span>
            </td>
            <td class="text-center" :style="{ borderRight: '1px solid #eee' }">
              <span class="ec-font-size12 font-weight-bold primary--text" v-text="item.last_weight" v-if="getDate([],'YYYY-MM') >= formatDate(addDateTime(item.weight_month, -1, 'years'),'YYYY-MM')"></span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-col>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsFormsWeightMonths',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    calendarDate: {
      get () {
        return this.$store.getters['modStorage/weightsDate']
      },
      set (v) {
        this.$store.dispatch('modStorage/LoadWeight', [this.personalId, v])
      }
    },
    list: {
      get () {
        return this.$store.getters['modStorage/weights']
      }
    }
  },

  methods: {
    changeMonth: function (add) {
      this.calendarDate = this.formatDate(this.addDateTime(this.calendarDate, add, 'years'), 'YYYY-MM-DD')
    }
  },

  beforeDestroy () {
    this.isProcessLoading = false
  },

  components: {
    ComponentsLayoutSection2,
    ComponentsItemsButton
  },

  props: {
    personalId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
