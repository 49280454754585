<template>
<v-row align="center" justify="center">
  <v-col cols=12>
    <ComponentsLayoutHeading1 text="アレルギー情報"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection2>
      <v-row align="center" justify="center">
        <v-col cols=9 v-if="allergies.length === 0">
          <span>アレルギー情報は未設定です</span>
        </v-col>
        <v-col cols=9 v-else>
          <v-chip small label class="ma-1 font-weight-bold" v-for="item in allergies" :key="item">
            <span v-text="item"></span>
          </v-chip>
        </v-col>
        <v-col cols=3 class="text-right">
          <ComponentsItemsButton
            :loading="loading || isProcessLoading"
            icon-btn
            icon="mdi-pencil"
            @click="editMode()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </ComponentsLayoutSection2>
  </v-col>

  <v-bottom-sheet persistent scrollable v-model="allergyModal" v-if="allergyModal">
    <v-card flat>
      <v-card-title :color="primaryColor" height="60">
        <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
          <v-col cols=12>
            <ComponentsLayoutHeading1 text="アレルギー設定" border-hidden></ComponentsLayoutHeading1>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text :style="{ backgroundColor: backGroundColor }">
        <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
          <v-col cols=8>
            <ComponentsItemsTextField
              name="allergies"
              :value.sync="tags"
              ref="allergies"
            ></ComponentsItemsTextField>
          </v-col>
          <v-col cols=4>
            <ComponentsItemsButton
              :loading="isLoading || isProcessLoading"
              text="追加"
              @click="addAllergies()"
            >
            </ComponentsItemsButton>
          </v-col>

          <v-col cols=12>
            <ComponentsLayoutSection2>
              <v-row align="center" justify="center">
                <v-col cols=12>
                  <ComponentsLayoutHeading2 text="アレルギー設定状況"></ComponentsLayoutHeading2>
                </v-col>
                <v-col cols=12 class="py-1">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols=12 v-if="edit.length === 0">
                  <span>未設定</span>
                </v-col>
                <v-col cols=12 v-else>
                  <v-row align="center" justify="center">
                    <v-col cols=12 class="py-2" v-for="(item, index) in edit" :key="item">
                      <v-chip small label close @click:close="removeAllergies(index)">
                        <span v-text="item"></span>
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </ComponentsLayoutSection2>
          </v-col>

        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
          <v-col cols=12 class="d-flex">
            <ComponentsItemsButton
              text-btn
              :loading="isLoading || isProcessLoading"
              text="閉じる"
              @click="allergyModal = false"
            >
            </ComponentsItemsButton>
            <v-spacer></v-spacer>
            <ComponentsItemsButton
              text-btn
              :loading="isLoading || isProcessLoading"
              text="反映する"
              @click="created()"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </v-card-actions>

    </v-card>
  </v-bottom-sheet>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'

import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsAnimalAllergy',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    allergyModal: false,
    allergies: [],
    tags: '',
    edit: []
  }),

  watch: {
    personalId () {
      this.goLoading()
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    setContents: function (contents) {
      this.allergies = []
      if (this.isUndefined(contents)) {
        return
      }
      if (this.exists(contents, 'personals_allergies') === false) {
        return
      }
      this.allergies = this.clone(contents.personals_allergies)
    },

    goLoading: function () {
      (async () => {
        this.edit = []
        if (this.isUndefined(this.personalId)) {
          return false
        }
        this.isProcessLoading = true
        this.setContents(await APILib.getAnimalsAllergy(this.personalId))
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    editMode: function () {
      this.edit = this.clone(this.allergies)
      this.allergyModal = true
    },

    addAllergies: function () {
      if (this.isUndefined(this.tags)) {
        this.tags = ''
        return false
      }
      if (this.allergies.some(v => v === this.tags)) {
        this.tags = ''
        return false
      }
      if (this.tags.length > 20) {
        return this.$confirm({ title: 'Error', message: '20文字以内に収めてください', button: { yes: 'OK' } })
      }
      this.edit.push(this.clone(this.tags))
      this.tags = ''
    },

    removeAllergies: function (index) {
      this.edit.splice(index, 1)
    },

    created: function () {
      (async () => {
        this.isProcessLoading = true
        this.setContents(await APILib.putAnimalsAllergy(this.personalId, { personals_allergies: this.edit }))
        this.allergyModal = false
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutSection2,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsItemsTextField,
    ComponentsItemsButton
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    personalId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
