<template>
<v-row align="center" justify="center" :style="{ color: secondaryFontColor }">
  <!-- <v-col :cols="(required) ? 9 : 12" class="text-left ec-font-size12 font-weight-bold"> --->
  <v-col :cols="(required) ? 9 : 12" class="text-left ec-font-size12">
    <span v-text="text"></span>
  </v-col>
  <v-col cols=3 class="text-right" v-if="(required)">
    <ComponentsItemsRequireLabel></ComponentsItemsRequireLabel>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import ComponentsItemsRequireLabel from '@/components/items/RequireLabel'

export default {
  name: 'ComponentsLayoutHeading2',

  mixins: [MixinLib],

  components: {
    ComponentsItemsRequireLabel
  },

  props: {
    text: { type: String, required: false, default: () => '' },
    required: { type: [Boolean, String], required: false, default: () => false }
  }
}
</script>
