<template>
<v-text-field
  :prepend-inner-icon="prependInnerIcon"
  :append-icon="appendIcon"
  :value="value"
  :loading="loading"
  dense
  outlined
  :rounded="rounded"
  :filled="filled"
  class="ec-font-size16"
  :hide-details="!error"
  :rules="validate"
  :error-messages="errors"
  :placeholder="placeholder"
  :disabled="(disabled)"
  background-color="rgba(var(--b3f,250,250,250),1)"
  :autocomplete="autocomplete"
  :inputmode="inputmode"
  @input="updateValue"
  @click:prepend-inner="$emit('click:prepend-inner')"
  @click:append="$emit('click:append')"
></v-text-field>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import ValidationLib from '@/libs/ValidationLib'

export default {
  name: 'ComponentsItemsTextField',

  mixins: [MixinLib],

  data () {
    return {
      // validate: [],
      placeholder: '',
      error: false,
      errors: ''
    }
  },

  computed: {
    validate: {
      get () {
        return (this.customRules) ? this.rules : ValidationLib.rules(this.name)
      }
    }
  },

  created: function () {
    // this.validate = (this.customRules) ? this.rules : ValidationLib.rules(this.name)
    this.placeholder = ValidationLib.placeholder(this.name)
  },

  methods: {
    updateValue: function (e) {
      this.$emit('update:value', e)
    },

    isError: function () {
      return (this.errors !== '')
    },

    confirm: function () {
      return new Promise((resolve, reject) => {
        try {
          this.errors = ''
          for (var y = 0; y < this.validate.length; y++) {
            const err = this.validate[y](this.value)
            if (err !== true) {
              this.errors = err
              break
            }
          }
          if (this.isError()) {
            this.error = true
            return resolve(false)
          }
          this.error = false
          return resolve(true)
        } catch (e) {
          return reject(e)
        }
      })
    }
  },

  props: {
    appendIcon: {
      type: String,
      required: false,
      default: () => ''
    },
    prependInnerIcon: {
      type: String,
      required: false,
      default: () => ''
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true,
      default: () => ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    rounded: {
      type: Boolean,
      required: false,
      default: () => false
    },
    filled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    autocomplete: {
      type: String,
      required: false,
      default: () => ''
    },
    customRules: {
      type: Boolean,
      required: false,
      default: () => false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    inputmode: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>
