<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="サインアウト"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/console/index')"
>
<v-row align="center" justify="center">

  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12 class="py-5">
          <ComponentsItemsButton
            block
            :loading="isLoading || isProcessLoading"
            text="サインアウトする"
            @click="confirmSignOut()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

</v-row>
</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'
import AuthLineLib from '@/libs/AuthLineLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleSignOutPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  mounted: function () {
    this.isProcessLoading = false
  },

  methods: {
    confirmSignOut: function () {
      this.$confirm({ title: 'Confirm', message: 'サインアウトします', button: { yes: 'OK', no: 'Cancel' }, callback: this.commitSignOut })
    },

    commitSignOut: function (evt) {
      (async () => {
        if (evt) {
          if (AuthLineLib.isInClient()) {
            await APILib.postUsersSignOut()
            window.open('about:_blank', '_self').close()
          } else {
            this.SignOut(true)
          }
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutPage2,
    ComponentsItemsButton
  }
}
</script>
