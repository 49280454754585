<template>
<ComponentsLayoutPage1
  header
>
  <v-row align="center" justify="center">
    <v-col cols=12 class="text-center">
      <v-slide-group v-model="target" mandatory :show-arrows="!$vuetify.breakpoint.xs">
        <v-slide-item v-for="(item, index) in animals" :key="index" v-slot="{ active, toggle }">
          <v-card class="text-center" flat color="transparent" @click="toggle">
            <v-row align="center" justify="center" class="mx-auto" :style="($vuetify.breakpoint.xs) ? 'max-width:100px;' : 'max-width:150px;'" @click="() => false">
              <v-col cols=12 class="pb-1 text-center">
                <v-avatar :size="($vuetify.breakpoint.xs) ? 75 : 110" :color="(active) ? '#FF8300' : ''">
                  <v-img :src="item.personals.img_src + '?width=120&height=120'" aspect-ratio="1" class="grey lighten-2"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols=12 class="text-center text-omitted pt-1" :style="(active) ? { color: '#FF8300' } : { color: 'rgba(0,0,0,.87)' }">
                <span v-text="item.personals.name1"></span>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-col>
    <v-col cols=12 class="px-1">
      <v-sheet class="overflow-y-auto" color="white" :max-height="contentsHeight">
        <v-row class="pa-0 ma-0">
          <v-col cols=3 class="d-flex child-flex pa-1" v-for="(item, index) in list" :key="index">
            <v-img :src="item.img_src + '?width=200&height=200'" contain aspect-ratio="1" class="grey lighten-2" @click="onImageClick(index)">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>

  <div id="fullscreen" v-if="showFullscreen">
    <img :src="list[currentImageIndex].img_src + '?width=1024&height=1024'">
    <ComponentsItemsButton
      outlined
      text="閉じる"
      :loading="isProcessLoading"
      @click="onImageClose()"
      :style="{ position: 'fixed', bottom: '20px', right: '20px' }"
    >
    </ComponentsItemsButton>
  </div>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleAlbumPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    showFullscreen: false,
    currentImageIndex: 0,
    target: '',
    list: []
  }),

  watch: {
    target () {
      this.goLoading()
    }
  },

  computed: {
    contentsHeight: {
      get () {
        return this.windowHeightSize - 270
      }
    },
    animals: {
      get () {
        return this.$store.getters['modStorage/animals']
      }
    }
  },

  mounted () {
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        this.list = await APILib.getStoresAnimalsMedia(this.animals[this.target].personal_id)
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    onImageClick: function (index) {
      this.currentImageIndex = index
      this.showFullscreen = true
    },
    onImageClose: function () {
      this.showFullscreen = false
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsItemsButton
  }
}
</script>
<style scope>
#fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 10000;
}

#fullscreen img {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
</style>
