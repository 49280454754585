<template>
<v-row align="center" justify="center">
  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center" class="py-3">
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="サービス提供に関する同意事項" required></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12 class="py-3">
          <span>トリミング・グルーミングサービスに関する注意事項、利用規約を確認・同意のうえ、トリミング・グルーミングの予約申請を行います。</span>
        </v-col>
        <v-col cols=12 class="pt-2 pb-1">
          <ComponentsItemsCheckBox
            name="agree"
            :loading="isLoading || isProcessLoading"
            :value.sync="agree1"
            text="サービス提供に関する注意事項に同意する"
            @change="actionOpenServiceWarning()"
          ></ComponentsItemsCheckBox>
        </v-col>
        <v-col cols=12 class="pt-1 pb-5">
          <ComponentsItemsCheckBox
            name="agree"
            :loading="isLoading || isProcessLoading"
            :value.sync="agree2"
            text="サービス利用規約に同意する"
            @change="actionOpenServiceTOS()"
          ></ComponentsItemsCheckBox>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <ComponentsBottomSheetDocs
    :model="bottomSheet.service_warning"
    :store-id="storeId"
    title="トリミング・グルーミングサービス提供に関する注意事項"
    :doc-id="docsServiceWarningID"
    @close-click="bottomSheet.service_warning = false"
    v-if="bottomSheet.service_warning"
  >
  </ComponentsBottomSheetDocs>

  <ComponentsBottomSheetDocs
    :model="bottomSheet.service_tos"
    :store-id="storeId"
    title="トリミング・グルーミングサービス利用規約"
    :doc-id="docsServiceTosID"
    @close-click="bottomSheet.service_tos = false"
    v-if="bottomSheet.service_tos"
  >
  </ComponentsBottomSheetDocs>

</v-row>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsBottomSheetDocs from '@/components/bottom-sheet/Docs'

import ComponentsItemsCheckBox from '@/components/items/CheckBox'

export default {
  name: 'ComponentsFormsTrimmingApplyConfirm',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    agree1: false,
    agree2: false,
    bottomSheet: {
      service_warning: false,
      service_tos: false
    },
    error: false
  }),

  computed: {
    docsServiceWarningID: {
      get () {
        return process.env.VUE_APP_DOCS_TRIMMING_SERVICES_WARNING
      }
    },
    docsServiceTosID: {
      get () {
        return process.env.VUE_APP_DOCS_TRIMMING_SERVICES_TOS
      }
    }
  },

  created () {
  },

  mounted () {
    this.isProcessLoading = false
  },

  methods: {
    actionOpenServiceWarning: function () {
      if (this.agree1 === '1') {
        this.bottomSheet.service_warning = true
      }
    },

    actionOpenServiceTOS: function () {
      if (this.agree2 === '1') {
        this.bottomSheet.service_tos = true
      }
    },

    isError: function () {
      return this.error
    },

    confirm: async function () {
      this.error = false
      if (this.agree1 !== '1' || this.agree2 !== '1') {
        this.$confirm({ title: 'Error', message: 'サービス提供に関する同意事項への同意は必須です', button: { yes: 'OK' } })
        this.error = true
        return false
      }
      return true
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading3,
    ComponentsBottomSheetDocs,
    ComponentsItemsCheckBox
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    storeId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
