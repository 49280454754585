
const ValidationLib = {

  rules: function (key) {
    const rules = {
      required: v => !!v || '必須項目です',
      min1: v => v.length >= 1 || '1文字以上にしてください',
      min4: v => v.length >= 4 || '4文字以上にしてください',
      min6: v => v.length >= 6 || '6文字以上にしてください',
      min8: v => v.length >= 8 || '8文字以上にしてください',
      max8: v => v.length <= 8 || '8文字以下にしてください',
      max20: v => v.length <= 20 || '20文字以下にしてください',
      max30: v => v.length <= 30 || '30文字以下にしてください',
      max32: v => v.length <= 32 || '32文字以下にしてください',
      max50: v => v.length <= 50 || '50文字以下にしてください',
      max100: v => v.length <= 100 || '100文字以下にしてください',
      max300: v => v.length <= 300 || '300文字以下にしてください',
      max1000: v => v.length <= 1000 || '1000文字以下にしてください',
      max2000: v => v.length <= 2000 || '2000文字以下にしてください',
      regexAccount: v => {
        if (!/^[0-9a-zA-Z\\_\\.-]*$/.test(v)) {
          return '半角英数字と一部記号（.-_）のみ利用可能です'
        }
        return true
      },
      regexPassword: v => {
        if (!/[a-z]/.test(v)) {
          return '半角の大文字/小文字/数字をそれぞれ1つ以上含めてください。'
        }
        if (!/[A-Z]/.test(v)) {
          return '半角の大文字/小文字/数字をそれぞれ1つ以上含めてください。'
        }
        if (!/[0-9]/.test(v)) {
          return '半角の大文字/小文字/数字をそれぞれ1つ以上含めてください。'
        }
        return true
      },
      emailMatch: v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || '形式が正しくありません',
      numAlpha: v => /^[0-9a-zA-Z.\-_]*$/.test(v) || '半角英数字と一部記号（.-_）のみ利用可能です',
      numeric: v => (v.length === 0 || /^[0-9]+$/.test(v)) || '半角数字のみ利用可能です',
      urlMatch: v => (v.length === 0 || /^https?:\/\//.test(v)) || '形式が正しくありません',
      timeMatch: v => /^([01][0-9]|2[0-3]):[0-5][0-9]$/.test(v) || '形式が正しくありません',
      kanaMatch: v => /^[ァ-ンヴー\x20\u3000]*$/.test(v) || '形式が正しくありません',
      colorMatch: v => /^#[0-9A-F]{6}$/.test(v) || '形式が正しくありません',
      selection: v => v !== '0' || '必須項目です'
    }

    const validation = {
      name: [rules.required, rules.min1, rules.max30],
      name1: [rules.required, rules.min1, rules.max30],
      name2: [rules.required, rules.min1, rules.max30],
      name_kana: [rules.required, rules.kanaMatch, rules.max30],
      name_kana1: [rules.required, rules.kanaMatch, rules.max30],
      name_kana2: [rules.required, rules.kanaMatch, rules.max30],
      animals_name: [rules.required, rules.min1, rules.max30],
      animals_name_kana: [rules.required, rules.kanaMatch, rules.max30],
      account: [rules.required, rules.min6, rules.max32, rules.regexAccount],
      personal_account: [rules.required, rules.min6, rules.max32, rules.regexAccount],
      password: [rules.required, rules.min8, rules.max32, rules.regexPassword],
      personal_password: [rules.required, rules.min8, rules.max32, rules.regexPassword],
      mailAddress: [rules.required, rules.min8, rules.max100, rules.emailMatch],
      company_name: [rules.required, rules.min1, rules.max100],
      company_kana: [rules.max100],
      post_no: [rules.required, rules.min1, rules.max8],
      address: [rules.required, rules.min1, rules.max100],
      building: [rules.max100],
      tel: [rules.required, rules.min1, rules.max20, rules.numeric],
      tel_sub: [rules.max20, rules.numeric],
      domain_name: [rules.required, rules.min1, rules.max32],
      prefecture_id: [rules.required, rules.selection],
      company_id: [],
      shop_name: [rules.required, rules.min1, rules.max32],
      shop_site: [rules.urlMatch, rules.max100],
      shop_description: [rules.max1000],
      str_time: [rules.required, rules.timeMatch],
      end_time: [rules.required, rules.timeMatch],
      introduction: [rules.max1000],
      status: [rules.required, rules.numeric],
      request_url: [rules.max100, rules.urlMatch],
      status_membership: [rules.numeric],
      membership_name: [rules.max32],
      membership_description: [rules.max1000],
      password_rule: [rules.max32],
      status_mail_magazine: [rules.numeric],
      mail_magazine_description: [rules.max1000],
      status_terms: [rules.numeric],
      membership: [rules.max1000],
      status_policy: [rules.numeric],
      policy: [rules.max1000],
      open_status: [rules.required, rules.numeric],
      color: [rules.required, rules.colorMatch],
      logo_src: [],
      img_src: [],
      open_weeks: [rules.required, rules.numeric],
      services_id: [rules.required, rules.numeric],
      status_tel: [rules.required, rules.numeric],
      status_open_times: [rules.required, rules.numeric],
      product_category_name: [rules.required, rules.max32],
      status_course: [rules.required, rules.numeric],
      animal: [rules.required],
      species: [rules.required],
      birth_date: [rules.required],
      action_date: [rules.required],
      expire_date: [rules.required],
      vaccine_image: [rules.required],
      members_account: [rules.required, rules.max300],
      members_password: [rules.required, rules.min4, rules.max32, rules.regexPassword],
      communication_memo: [rules.max2000]
    }

    const key1 = (key === 'rePassword') ? 'password' : key
    return (validation[key1]) ? validation[key1] : []
  },

  placeholder: function (key) {
    const placeholder = {
      name: '例）ペトコネ 太郎',
      name1: 'ぺとこね',
      name2: '太郎',
      name_kana: '例）ペトコネ タロウ',
      name_kana1: 'ペトコネ',
      name_kana2: 'タロウ',
      animals_name: '例）わんこ',
      animals_name_kana: '例）ワンコ',
      account: 'アカウントを入力',
      password: 'パスワードを入力',
      rePassword: '再入力',
      mailAddress: 'メールアドレスを入力',
      company_name: '例）株式会社カラーズ',
      company_kana: '例）カブシキガイシャカラーズ',
      post_no: '例）6570036',
      address: '例）神戸市灘区桜口町4-1-1',
      building: '例）ウェルブ六甲道4番街1番館402',
      tel: '例）0788581283',
      tel_sub: '例）0788581283',
      domain_name: '例）GREEN DOG',
      prefecture_id: '選択してください',
      company_id: '選択してください',
      shop_name: '例）GREEN DOG SQUARE',
      shop_site: '例）https://www.green-dog.com/store/gds/',
      shop_description: '例）天井高く明るいアットホームな店内には、いつも笑顔があふれています。オーナーさまとパートナーが共に健康で幸せな生活を送れるように・・・　そんな願いを込めて、【GREEN DOG SQUARE】では商品、サービスをご提供しております。お店の奥には、フリースペースがあり、憩いのひと時をお過ごしいただけます。',
      str_time: '10:00',
      end_time: '19:00',
      introduction: '例）トリミングOK、トリミングNG',
      status: '',
      request_url: '',
      status_membership: '',
      membership_name: '例）GREEN DOG',
      membership_description: '',
      account_rule: '',
      password_rule: '',
      status_mail_magazine: '',
      mail_magazine_description: '',
      status_terms: '',
      membership: '',
      status_policy: '',
      policy: '',
      open_status: '',
      color: '',
      logo_src: '',
      img_src: '',
      open_weeks: '',
      services_id: '',
      status_tel: '',
      status_open_times: '',
      product_category_name: '例）トリミングコース',
      status_course: '',
      animal: '',
      species: '',
      birth_date: '',
      expire_date: '',
      action_date: '',
      members_account: 'アカウントを入力',
      members_password: 'パスワードを入力',
      personal_account: 'アカウントを入力',
      personal_password: 'パスワードを入力',
      trimming_str_time: '10:00',
      trimming_end_time: '18:00',
      time_to_contact: '例）１８時以降',
      ask_note: '例）希望日が埋まっている場合には、土日祝日の同一時間帯を希望。または、部分ケアやオプションをご希望の方は内容の記入をお願いします。'
    }
    return (placeholder[key]) ? placeholder[key] : ''
  }

}

export default ValidationLib
