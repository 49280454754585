<template>
<ComponentsLayoutSection2>
  <v-row align="center" justify="center">
    <v-col cols=12>
      <v-row align="center" justify="center">
        <v-col cols=4 class="py-1" v-show="false">
          <ComponentsLayoutHeading3 text="体格" class="pb-3"></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=8 class="py-1 text-left" v-show="false">
          <span v-text="getSizeName(contents.size)"></span>
        </v-col>
        <v-col cols=4 class="py-1">
          <ComponentsLayoutHeading3 text="希望コース" class="pb-3"></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=8 class="py-1 text-truncate text-left">
          <span v-text="getCategoriesName(contents.courses)"></span>
        </v-col>
        <v-col cols=4 class="py-1">
          <ComponentsLayoutHeading3 text="指名" class="pb-3"></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=8 class="py-1 text-truncate text-left">
          <span v-text="getGroomerName(contents.assign)"></span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</ComponentsLayoutSection2>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection2 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

export default {
  name: 'ComponentsViewFormsTrimmingApplyConditionsNew',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    StorageGroomer: {
      get () {
        return this.$store.getters['modStorage/storesGroomer']
      }
    },
    StorageCategories: {
      get () {
        return this.$store.getters['modStorage/storesGroomingCategories']
      }
    },
    animalSize: {
      get () {
        return [{ name: '小型犬', code: '1' }, { name: '中型犬', code: '2' }, { name: '大型犬', code: '3' }]
      }
    }
  },

  created () {
  },

  mounted () {
    this.isProcessLoading = false
  },

  methods: {
    getSizeName: function (code) {
      const index = this.animalSize.findIndex(v => v.code === code)
      return (index >= 0) ? this.animalSize[index].name : '不明'
    },
    getGroomerName: function (code) {
      const index = this.StorageGroomer.findIndex(v => v.business_users_id === code)
      return (index >= 0) ? this.StorageGroomer[index].business_users.name : 'おまかせ'
    },
    getCategoriesName: function (code) {
      const index = this.StorageCategories.findIndex(v => v.item_category_id === code)
      return (index >= 0) ? this.StorageCategories[index].item_categories.item_category_name : '選択してください'
    }
  },

  components: {
    ComponentsLayoutSection2,
    ComponentsLayoutHeading3
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    contents: {
      type: Object,
      require: true,
      default: () => {
        return {
          size: '1',
          courses: '',
          assign: ''
        }
      }
    }
  }
}
</script>
