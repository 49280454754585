<template>
<ComponentsLayoutPage1
  header
  footer
>
  <v-row align="center" justify="center">
    <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
      <v-col cols=12 class="pt-5">
        <ComponentsLayoutHeading1 text="よくあるご質問(FAQ)" border-hidden></ComponentsLayoutHeading1>
      </v-col>
      <v-col cols=12>
        <ComponentsViewFormsFAQ
        >
        </ComponentsViewFormsFAQ>
      </v-col>
    </v-row>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsViewFormsFAQ from '@/components/view-forms/FAQ'

export default {
  name: 'FAQPage',

  mixins: [MixinLib],

  data: () => ({
  }),

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutHeading1,
    ComponentsViewFormsFAQ
  }
}
</script>
