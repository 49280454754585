<template>
<v-card rounded flat class="rounded-lg" :color="(isUndefined(color)) ? primaryColor : color">
  <v-card-text class="ec-font-size12" :style="{ color: tertiaryFontColor }">
    <slot></slot>
  </v-card-text>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsLayoutSection',

  mixins: [MixinLib],

  props: {
    color: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>
