<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">

    <v-col cols=12 ref="TOP">
      <ComponentsLayoutHeading1 text="STEP1. 予約申請希望条件"></ComponentsLayoutHeading1>
    </v-col>
    <!--
    <v-col cols=12>
      <ComponentsLayoutHeading2 text="犬以外の予約をご希望の場合は、直接お電話下さい。"></ComponentsLayoutHeading2>
    </v-col>
    --->

    <v-col cols=12>
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center">
          <v-col cols=4 class="pb-5">
            <ComponentsLayoutHeading3 text="ご予約希望頭数" ></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=8 class="text-right">
            <ComponentsItemsButtonToggle
              name="animalsCount"
              :value.sync="headcount"
              :items="selectHeadcount"
              item-text="name"
              item-value="code"
              ref="animalsCount"
            ></ComponentsItemsButtonToggle>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" class="pb-3" v-for="(item, index) in commonRequest" :key="index">
          <v-col cols=12 class="py-1" v-if="index > 0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols=4 >
            <ComponentsLayoutHeading3 text="希望コース" class="pb-3"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=5 class="text-truncate text-left">
            <span v-text="getCategoriesName(item.courses)"></span>
          </v-col>
          <v-col cols=3 class="text-right">
            <ComponentsItemsButton
              outlined
              small
              text="選択"
              :loading="isLoading || isProcessLoading"
              @click="onCourseSelect(index)"
            >
            </ComponentsItemsButton>
          </v-col>
          <v-col cols=4 class="pb-5">
            <ComponentsLayoutHeading3 text="指名" ></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=5 class="text-truncate text-left">
            <span v-text="getGroomerName(item.assign)"></span>
          </v-col>
          <v-col cols=3 class="text-right">
            <ComponentsItemsButton
              outlined
              small
              text="選択"
              :loading="isLoading || isProcessLoading"
              @click="onTrimmerSelect(index)"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12>
      <ComponentsItemsButton
        block
        :loading="isProcessLoading"
        text="次へ"
        @click="onNext()"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=12 ref="Step2">
      <ComponentsLayoutHeading1 text="STEP2. ご予約希望日時の設定"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 ref="Step3">
      <ComponentsLayoutHeading1 text="STEP3. お客様情報の入力"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 ref="Step4">
      <ComponentsLayoutHeading1 text="STEP4. カルテ情報の入力"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12 ref="Step5">
      <ComponentsLayoutHeading1 text="STEP5. 申請内容の最終確認"></ComponentsLayoutHeading1>
    </v-col>

  </v-row>

  <!-- 担当者の指名 -->
  <ComponentsBottomSheetSelectTrimmer
    :model="model.trimmer"
    :values="edit.assign"
    @on-setting-click="onSetTrimmer"
    @close-click="model.trimmer = false"
    v-if="model.trimmer"
  >
  </ComponentsBottomSheetSelectTrimmer>

  <!-- コース選択 -->
  <ComponentsBottomSheetSelectCourse
    :model="model.course"
    :values="edit.course"
    @on-setting-click="commonRequest[edit.index].courses = $event, model.course = false"
    @close-click="model.course = false"
    v-if="model.course"
  >
  </ComponentsBottomSheetSelectCourse>
</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsBottomSheetSelectTrimmer from '@/components/bottom-sheet/SelectTrimmer'
import ComponentsBottomSheetSelectCourse from '@/components/bottom-sheet/SelectCourse'

import ComponentsItemsButtonToggle from '@/components/items/ButtonToggle'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingReserveTrimmingNewerStep1Page',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    model: {
      trimmer: false,
      course: false
    },
    edit: {
      index: 0,
      assign: '',
      course: ''
    }
  }),

  /*
  watch: {
    nowDate () {
      this.setCalendar()
    }
  },
  */

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    StorageGroomer: {
      get () {
        return this.$store.getters['modStorage/storesGroomer']
      }
    },
    StorageCategories: {
      get () {
        return this.$store.getters['modStorage/storesGroomingCategories']
      }
    },
    groomer: {
      get () {
        return this.StorageGroomer.map(v => { return { name: v.business_users.name, code: v.business_users_id } })
      }
    },
    categories: {
      get () {
        return this.StorageCategories.map(v => { return { name: v.item_categories.item_category_name, code: v.item_category_id } })
      }
    },
    selectHeadcount: {
      get () {
        return this.$store.getters['modTrimming/selectHeadcount']
      }
    },
    headcount: {
      get () {
        return this.$store.getters['modTrimming/headcount']
      },
      set (v) {
        this.$store.dispatch('modTrimming/setupRequest', v)
      }
    },
    commonRequest: {
      get () {
        return this.$store.getters['modTrimming/commonRequest']
      },
      set (v) {
        this.$store.dispatch('modTrimming/changeRequest', v)
      }
    }
  },

  created () {
    setTimeout(() => (
      this.$refs.TOP.scrollIntoView({ behavior: 'smooth' })
    ))
  },

  mounted () {
    this.onLoading()
  },

  methods: {
    onLoading: function () {
      this.isProcessLoading = false
    },
    getGroomerName (code) {
      const index = this.groomer.findIndex(v => v.code === code)
      return (index >= 0) ? this.groomer[index].name : 'おまかせ'
    },
    getCategoriesName (code) {
      const index = this.categories.findIndex(v => v.code === code)
      return (index >= 0) ? this.categories[index].name : '選択してください'
    },
    onTrimmerSelect: function (index) {
      this.edit.index = index
      this.edit.assign = this.commonRequest[index].assign
      this.model.trimmer = true
    },
    onCourseSelect: function (index) {
      this.edit.index = index
      this.edit.course = this.commonRequest[index].courses
      this.model.course = true
    },
    onSetTrimmer ($event) {
      if (this.commonRequest[this.edit.index].assign !== $event) {
        this.$store.dispatch('modTrimming/InitializeTimezone')
      }
      this.commonRequest[this.edit.index].assign = $event
      this.model.trimmer = false
    },
    onNext: function () {
      if (this.commonRequest.some((item) => (this.isUndefined(item.courses)))) {
        return this.$confirm({ title: 'Warning', message: 'コースを選択してください', button: { yes: 'OK' } })
      }
      this.pageTransition('/gr-rsv/' + this.storeID + '/new-reserve/2')
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading3,
    ComponentsBottomSheetSelectTrimmer,
    ComponentsBottomSheetSelectCourse,
    ComponentsItemsButtonToggle,
    ComponentsItemsButton
  }
}
</script>
