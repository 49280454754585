<template>
<ComponentsLayoutPage2
  header
  footer
  header-title="アカウント登録"
  header-left
  header-left-icon="mdi-chevron-left"
  header-left-text="戻る"
  @left-click="pageTransition('/')"
>
  <v-row align="center" justify="center" class="mx-auto pt-5" style="max-width: 820px;">
    <v-col cols=12>
      <ComponentsFormsUserAccount
        :loading="isLoading || isProcessLoading"
        ref="UserAccount"
      ></ComponentsFormsUserAccount>
    </v-col>
    <v-col cols=12>
      <ComponentsItemsButton
        block
        :loading="isLoading || isProcessLoading"
        text="アカウントを登録する"
        @click="created()"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>
</ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'
import AuthLib from '@/libs/AuthLib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsFormsUserAccount from '@/components/forms/UserAccount'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'SignUpRegisterPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  mounted () {
    this.isProcessLoading = false
  },

  methods: {
    created: function () {
      (async () => {
        if (this.$refs.UserAccount) {
          await this.$refs.UserAccount.confirm()
        }
        let error = false
        if (this.$refs.UserAccount && this.$refs.UserAccount.error) {
          error = true
        }
        if (error) {
          return false
        }
        this.isProcessLoading = true
        if (await APILib.getOAuthSignInCheck(this.$refs.UserAccount.users_authentication.personal_account)) {
          this.$confirm({ title: 'Error', message: '既に登録されているアカウントです', button: { yes: 'OK' } })
          this.isProcessLoading = false
          return false
        }
        await APILib.postOAuthSignUp({ personals: this.$refs.UserAccount.personals, users_authentication: this.$refs.UserAccount.users_authentication, users_attribute: this.$refs.UserAccount.users_attribute })
        await AuthLib.SignIn(this.$refs.UserAccount.users_authentication.personal_account, this.$refs.UserAccount.users_authentication.personal_password)
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage2,
    ComponentsFormsUserAccount,
    ComponentsItemsButton
  }
}
</script>
