<template>
<v-row align="center" justify="start">
  <v-col cols=12 :style="{ maxWidth: '200px' }">
    <v-card @click="upload()">
      <v-card-text class="pa-1">
        <v-row align="center" justify="center" >
          <v-col cols=12 class="text-center">
            <v-img :src="src" aspect-ratio="1" :class="(errors) ? 'error' : 'grey lighten-2'">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-col cols=12 class="py-1">
                    <v-icon large>mdi-cloud-upload</v-icon>
                    <br />
                    <span class="ec-font-size10">画像を選択してアップロード</span>
                  </v-col>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
  <v-col cols=12 class="py-1" v-show="!hideCaption">
    <span class="ec-font-size10">※アップロード可能なファイル形式は、JPEG/PNGのみです</span>
  </v-col>

  <v-dialog v-model="modal" width="90%" max-width="420">
    <v-card>
      <v-card-text class="pt-5">
        <v-row align="center" justify="center" >
          <v-col cols=12 class="text-left ec-font-size10 py-1">
            <span>最大アップロードサイズ: 20MB</span>
          </v-col>
          <v-col cols=12 class="text-center py-1">
            <croppa
              v-model="imgSrc"
              :width="280"
              :height="280"
              placeholder="タップして画像を選択してください"
              :prevent-white-space="true"
              :disabled="false"
              :disable-click-to-choose="false"
              :disable-drag-and-drop="false"
              :disable-drag-to-move="false"
              :disable-scroll-to-zoom="false"
              :disable-pinch-to-zoom="false"
              :disable-rotation="false"
              initial-size="natural"
              initial-position="center"
              :show-remove-button="true"
              remove-button-color="black"
              :show-loading="true"
              :file-size-limit="20 * 1024 * 1024"
              :initial-image="src"
              accept=".jpeg,.png,.jpg"
              style="line-height: normal!important;"
              @file-type-mismatch="onFileTypeMismatch"
              @file-size-exceed="onFileSizeExceed"
            >
            </croppa>
          </v-col>
          <v-col cols=12 class="text-center py-1">
            <v-btn-toggle dense dark>
              <v-btn @click="imgSrc.zoomIn()">
                <v-icon>mdi-magnify-minus-outline</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.zoomOut()">
                <v-icon>mdi-magnify-plus-outline</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.rotate()">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.rotate(-1)">
                <v-icon>mdi-restore</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.flipX()">
                <v-icon>mdi-compare-horizontal</v-icon>
              </v-btn>
              <v-btn @click="imgSrc.flipY()">
                <v-icon>mdi-compare-vertical</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols=12 class="text-left ec-font-size10 py-1">
            <span>上記の画像を変更する場合は <v-icon small>mdi-close-circle</v-icon> アイコンで一度画像をクリアして下さい。</span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <ComponentsItemsButton
          :loading="isLoading || isProcessLoading"
          text-btn
          class="ec-font-size12"
          text="閉じる"
          @click="modal = false"
        >
        </ComponentsItemsButton>
        <v-spacer></v-spacer>
        <ComponentsItemsButton
          :loading="isLoading || isProcessLoading"
          text-btn
          class="ec-font-size12"
          text="設定する"
          @click="change()"
        >
        </ComponentsItemsButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsItemsImageUpload',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    errors: false,
    modal: false,
    imgSrc: {},
    dataUrl: ''
  }),

  methods: {
    updateValue: function (e) {
      this.$emit('update:src', e)
    },

    upload: function () {
      this.dataUrl = ''
      this.modal = true
    },

    change: function () {
      this.imgSrc.generateBlob((blob) => this.uploadFile(blob), 'image/jpeg')
    },

    uploadFile: function (blob) {
      (async () => {
        const resultRow = await APILib.postMediaImage(blob, 'image.jpeg')
        this.updateValue(resultRow)
        this.modal = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    onFileTypeMismatch (file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },

    onFileSizeExceed (file) {
      alert('File size exceeds. Please choose a file smaller than 100kb.')
    },

    isError: function () {
      return (this.errors === true)
    },

    confirm: function () {
      return new Promise((resolve, reject) => {
        try {
          this.errors = false
          if (this.required) {
            if (this.isUndefined(this.src)) {
              this.errors = true
              return resolve(false)
            }
          }
          this.errors = false
          return resolve(true)
        } catch (e) {
          return reject(e)
        }
      })
    }
  },

  beforeDestroy () {
    this.isProcessLoading = false
    this.errors = false
    this.modal = false
    this.imgSrc = {}
    this.dataUrl = ''
  },

  components: {
    ComponentsItemsButton
  },

  props: {
    src: {
      type: String,
      required: false,
      default: () => ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hideCaption: {
      type: [Boolean, String],
      required: false,
      default: () => false
    },
    required: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
