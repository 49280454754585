<template>
<ComponentsLayoutSection1 :color="(value === businessUsersId) ? secondaryActiveColor : ''" on-click-action @click="onClick()">
  <v-row align="center" justify="center">
    <v-col cols=12 class="text-center">
      <v-avatar size=60 v-if="(isUndefined(businessUsers.img_src))">
        <v-img :src="businessUsers.img_src" aspect-ratio="1" class="grey lighten-2">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-col cols=12 class="py-1">
                <v-icon large>mdi-account</v-icon>
              </v-col>
            </v-row>
          </template>
        </v-img>
      </v-avatar>
      <v-avatar size=60 v-else>
        <v-icon large>mdi-account</v-icon>
      </v-avatar>
    </v-col>
    <v-col cols=8>
      <v-row align="center" justify="center">
        <v-col cols=12 class="ec-font-size14 font-weight-bold py-0">
          <span v-text="businessUsers.name"></span>
        </v-col>
        <v-col cols=12 class="pt-0 pb-1">
          指名料: &yen;&nbsp;<span v-text="parseInt(storesServiceUsers.assign_price).toLocaleString()"></span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols=4 class="text-right">
      <ComponentsItemsButton
        :outlined="!(value === businessUsersId)"
        :loading="loading"
        small
        :text="(value === businessUsersId) ? '選択中' : '指名する'"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>
  <v-row align="center" justify="center" v-if="!isUndefined(businessUsers.introduction)">
    <v-col cols=12 class="py-1">
      <v-divider></v-divider>
    </v-col>
    <v-col cols=12>
      <span v-text="businessUsers.introduction"></span>
    </v-col>
  </v-row>
</ComponentsLayoutSection1>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsGroomerProfile',

  mixins: [MixinLib],

  components: {
    ComponentsLayoutSection1,
    ComponentsItemsButton
  },

  methods: {
    onClick () {
      this.$emit('click')
    }
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    value: {
      type: String,
      required: false,
      default: () => ''
    },
    businessUsersId: {
      type: String,
      required: false,
      default: () => ''
    },
    businessUsers: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: '',
          name_kana: '',
          img_src: '',
          introduction: ''
        }
      }
    },
    storesServiceUsers: {
      type: Object,
      required: false,
      default: () => {
        return {
          assign_price: '0',
          assign_status: '2'
        }
      }
    }
  }

}
</script>
