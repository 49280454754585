import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 420,
      sm: 600,
      md: 960,
      lg: 1200
    }
  },
  theme: {
    themes: {
      light: {
        ImportantColor: '#D81B60',
        BaseColorLighten3: '#B2DFDB',
        BaseColorLighten2: '#80CBC4',
        BaseColorLighten1: '#4DB6AC',
        BaseColorDarken1: '#26A69A',
        BaseColorDarken2: '#00897B',
        BaseColorDarken3: '#00796B',
        BaseColorDarken4: '#00695C'
      }
    }
  }
})
