<template>
<v-bottom-sheet persistent scrollable v-model="model" v-if="model">
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="pb-5">
          <ComponentsLayoutHeading1 :text="contents.stores.store_name" border-hidden v-if="!isUndefined(contents)"></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }" v-if="!isUndefined(contents)">
        <v-col cols=12 class="pb-0">
          <span v-text="contents.stores_profile.store_description"></span>
        </v-col>

        <v-col cols=12>
          <v-img :src="contents.stores_profile.store_img_src + '?width=800&height=200'" class="grey lighten-2" height="200px"></v-img>
        </v-col>

        <v-col cols=12>
          <v-row align="center" justify="start">
            <v-col cols=3 class="pa-1" v-for="item in services" :key="item.service_type">
              <v-card flat :color="(item.stores_service.status === '1') ? 'blue darken-1 text-center justify-center' : 'grey lighten-2 text-center justify-center'" :style="{ height: '50px' }">
                <v-row align-content="center" class="fill-height ma-0">
                  <v-col align="center">
                    <span
                      class="white--text"
                      :style="($vuetify.breakpoint.xs) ? 'font-size:.6rem!important;' : ''"
                      v-text="item.service_name"
                    >
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols=12 class="py-1">
          <v-divider></v-divider>
        </v-col>

        <v-col cols=4 class="pa-1" v-for="(item, index) in contents.stores_outbound_links.filter(v => !isUndefined(v.social_media_link))" :key="index">
          <v-card flat class="text-center justify-center" :color="buttonColor" :style="{ height: '50px' }" @click="pageTransition(item.social_media_link)">
            <v-row align-content="center" class="fill-height ma-0">
              <v-col align="center">
                <span
                  class="white--text"
                  :style="($vuetify.breakpoint.xs) ? 'font-size:.6rem!important;' : ''"
                  v-text="item.social_media_site"
                >
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols=12 class="pt-5">
          <ComponentsLayoutHeading2 text="営業時間"></ComponentsLayoutHeading2>
        </v-col>

        <v-col cols=12>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center" width="20%">
                    <span>曜日</span>
                  </th>
                  <th class="text-center" width="80%">
                    <span>営業時間</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, weekIndex) of weeks" :key="item.code">
                  <td class="text-center ec-font-size12">
                    <span v-text="item.name"></span>
                  </td>
                  <td class="text-center ec-font-size12" v-if="times[weekIndex].length === 0">
                    <span>定休日</span>
                  </td>
                  <td class="text-center ec-font-size12" v-else>
                    <span v-text="times[weekIndex].map(v => formatDate('2000-01-01 ' + v.open_str_time, 'HH:mm') + '-' + formatDate('2000-01-01 ' + v.open_end_time, 'HH:mm')).join('、')"></span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading2 text="住所"></ComponentsLayoutHeading2>
        </v-col>

        <v-col cols=12>
          <span v-text="'〒' + contents.stores_profile.store_post_no"></span><br />
          <span v-text="getPrefectureName(contents.stores_profile.store_prefecture_id)"></span><span v-text="contents.stores_profile.store_address"></span><br />
          <span v-text="contents.stores_profile.store_building"></span>
        </v-col>
        <v-col cols=12>
          <div ref="map" style="height:300px; width:90%;"></div>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading2 text="電話番号"></ComponentsLayoutHeading2>
        </v-col>

        <v-col cols=12>
          <a :href="'tel:' + contents.stores_profile.store_tel" style="text-decoration: none;">
            <v-btn block class="primary UnderText" style="height: 65px;">
              <v-row align="center" justify="center" style="min-width: 200px;">
                <v-col cols=3>
                  <v-icon>mdi-phone</v-icon>
                </v-col>
                <v-col cols=9>
                  <v-row align="center" justify="center">
                    <v-col cols=12 class="pa-0 ma-0 text-left" style="font-size: 1.1rem;">
                      <span v-text="contents.stores_profile.store_tel"></span>
                    </v-col>
                    <v-col cols=12 class="pa-0 ma-0 text-left caption">
                      <span>代表番号</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-btn>
          </a>
        </v-col>

      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            text-btn
            text="閉じる"
            :loading="isProcessLoading"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
          <!--
          <ComponentsItemsButton
            small
            text="トリミングのご予約"
            :loading="isProcessLoading"
            @click="pageTransition('/gr-rsv/' + storeId + '/')"
            v-if="reserveCheck()"
          >
          </ComponentsItemsButton>
          --->
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-bottom-sheet>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

// import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsBottomSheetStore',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    contents: {},
    map: '',
    googleMaps: {
      lat: '',
      lng: '',
      isShow: false
    }
  }),

  watch: {
    model () {
      if (this.model) {
        this.goLoading()
      }
    }
  },

  computed: {
    weeks: {
      get () {
        return [
          { code: '1', name: '日' },
          { code: '2', name: '月' },
          { code: '3', name: '火' },
          { code: '4', name: '水' },
          { code: '5', name: '木' },
          { code: '6', name: '金' },
          { code: '7', name: '土' }
        ]
      }
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.contents = {}
        if (this.isUndefined(this.storeId)) {
          return false
        }
        this.isProcessLoading = true
        this.contents = await APILib.getStores(this.storeId)

        this.times = this.weeks.map(week => this.contents.stores_times.filter(time => time.open_weeks === week.code && time.open_status === '1'))

        this.services = []
        for (const item of this.StorageStoreServicesType) {
          const services = await APILib.getStoresService(this.storeId, item.code)
          this.services.push({ ...services, service_type: item.code, service_name: item.name })
        }

        const address = this.getPrefectureName(this.contents.stores_profile.store_prefecture_id) + this.contents.stores_profile.address + this.contents.stores_profile.building + ' ' + this.contents.stores.store_name
        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ address: address, region: 'jp' }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            this.googleMaps.lat = results[0].geometry.location.lat()
            this.googleMaps.lng = results[0].geometry.location.lng()
            this.googleMaps.isShow = true
            this.viewMap()
          } else {
            this.$confirm({ title: 'Warning', message: 'Mapの読み込みに失敗しました', button: { yes: 'OK' } })
          }
        })

        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    getPrefectureName: function (code) {
      const index = this.StoragePrefecture.findIndex(v => v.code === code)
      return (index >= 0) ? this.StoragePrefecture[index].name : ''
    },

    reserveCheck: function () {
      if (this.isUndefined(this.services)) {
        return false
      }
      const trimming = this.services.find(v => v.service_type === process.env.VUE_APP_TRIMMING_SERVICE_ID)
      if (trimming.stores_service_web_reserve.status === process.env.VUE_APP_WEB_RESERVE_STATUS && trimming.stores_service.status === process.env.VUE_APP_STORES_SERVICE_STATUS) {
        return true
      }
      return false
    },

    viewMap: function () {
      if (!this.googleMaps.isShow) {
        return null
      }
      this.map = new window.google.maps.Map(this.$refs.map, {
        center: { lat: this.googleMaps.lat, lng: this.googleMaps.lng },
        zoom: 16,
        disableDefaultUI: true,
        zoomControl: true
      })
      window.google.maps.Marker({
        position: {
          lat: this.googleMaps.lat,
          lng: this.googleMaps.lng
        },
        map: this.map
      })
    }
  },

  beforeDestroy () {
    this.isProcessLoading = false
    this.contents = {}
    this.map = ''
    this.googleMaps = {
      lat: '',
      lng: '',
      isShow: false
    }
  },

  components: {
    // ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    },
    storeId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
