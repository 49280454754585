<template>
<!--- 担当者の指名 ---->
<v-bottom-sheet persistent scrollable v-model="model" v-if="model">
  <v-card flat >
    <v-card-title :color="primaryColor">
      <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12>
          <ComponentsLayoutHeading1 text="希望コースの選択" border-hidden></ComponentsLayoutHeading1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="{ backgroundColor: backGroundColor }">
      <v-row align="start" justify="start" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
        <v-col cols=12 md=6 v-for="item in salesCourse" :key="item.item_category_id">
          <ComponentsViewFormsCoursesProfile
            :value="selected"
            :loading="isLoading"
            :contents="item"
            @click="selected = item.item_category_id"
          ></ComponentsViewFormsCoursesProfile>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
        <v-col cols=12 class="d-flex">
          <ComponentsItemsButton
            text-btn
            text="閉じる"
            :loading="isProcessLoading"
            @click="$emit('close-click')"
          >
          </ComponentsItemsButton>
          <v-spacer></v-spacer>
          <ComponentsItemsButton
            text="設定"
            :loading="isProcessLoading"
            @click="$emit('on-setting-click', selected)"
            :disabled="(isUndefined(selected))"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-bottom-sheet>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsViewFormsCoursesProfile from '@/components/view-forms/CoursesProfile'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsBottomSheetSelectCourse',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    selectedCourse: null
  }),

  watch: {
  },

  computed: {
    activeStatus: {
      get () {
        return process.env.VUE_APP_PERSONALS_ITEM_CATEGORY_ATTR1
      }
    },
    StorageCategories: {
      get () {
        return this.$store.getters['modStorage/storesGroomingCategories']
      }
    },
    salesCourse: {
      get () {
        return this.StorageCategories
          .filter(v => v.stores_item_categories.sales_status === process.env.VUE_APP_STORES_ITEM_CATEGORIES_SALES_STATUS_ENABLED)
          .filter(v => v.item_categories.item_category_attr1 === this.activeStatus)
      }
    },
    selected: {
      get () {
        return (this.selectedCourse === null) ? this.values : this.selectedCourse
      },
      set (v) {
        this.selectedCourse = v
      }
    }
  },

  mounted () {
    this.selectedCourse = null
  },

  methods: {
  },

  beforeDestroy () {
    this.isProcessLoading = false
    this.selectedCourse = null
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsViewFormsCoursesProfile,
    ComponentsItemsButton
  },

  props: {
    model: {
      type: Boolean,
      require: true,
      default: () => false
    },
    values: {
      type: String,
      require: false,
      default: () => ''
    }
  }
}
</script>
