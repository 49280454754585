<template>
<v-row align="center" justify="center">
  <v-col cols=12>
    <ComponentsLayoutHeading1 text="本アプリのご利用規約への同意"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12 class="text-left">
    <ComponentsLayoutHeading2 text="ペトコネアプリに登録している会員情報・愛犬情報は 『お客様がご利用になっている店舗に提供することを承諾し、Petconeサービスの利用規約および同サービスにかかるプライバシーポリシーを確認・同意のうえ、Petconeサービスを利用します。』"></ComponentsLayoutHeading2>
  </v-col>

  <v-col cols=12 class="text-left py-1">
    <ComponentsItemsCheckBox
      name="agree"
      :loading="isLoading || isProcessLoading"
      :value.sync="agree1"
      :true-value=true
      :false-value=false
      text="利用規約に同意する"
      @change="actionOpenTOS()"
    ></ComponentsItemsCheckBox>
  </v-col>

  <v-col cols=12 class="text-left pt-1 pb-5">
    <ComponentsItemsCheckBox
      name="agree"
      :loading="isLoading || isProcessLoading"
      :value.sync="agree2"
      :true-value=true
      :false-value=false
      text="プライバシーポリシーに同意する"
      @change="actionOpenPolicy()"
    ></ComponentsItemsCheckBox>
  </v-col>

  <v-col cols=12>
    <ComponentsItemsButton
      block
      :loading="isLoading || isProcessLoading"
      :disabled="!agree1 || !agree2"
      text="利用規約に同意して次へ"
      @click="$emit('click')"
    >
    </ComponentsItemsButton>
  </v-col>

  <ComponentsBottomSheetTOS
    :model="bottomSheet.tos"
    @close-click="bottomSheet.tos = false"
  >
  </ComponentsBottomSheetTOS>

  <ComponentsBottomSheetPrivacyPolicy
    :model="bottomSheet.policy"
    @close-click="bottomSheet.policy = false"
  >
  </ComponentsBottomSheetPrivacyPolicy>

</v-row>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'
import ComponentsItemsButton from '@/components/items/Button'
import ComponentsItemsCheckBox from '@/components/items/CheckBox'

import ComponentsBottomSheetTOS from '@/components/bottom-sheet/TOS'
import ComponentsBottomSheetPrivacyPolicy from '@/components/bottom-sheet/PrivacyPolicy'

export default {
  name: 'ComponentsFormsApplicationConfirm',

  mixins: [MixinLib],

  data: () => ({
    agree1: false,
    agree2: false,
    bottomSheet: {
      tos: false,
      policy: false
    },
    isProcessLoading: false
  }),

  methods: {
    actionOpenTOS: function () {
      if (this.agree1) {
        this.bottomSheet.tos = true
      }
    },
    actionOpenPolicy: function () {
      if (this.agree2) {
        this.bottomSheet.policy = true
      }
    }
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsItemsButton,
    ComponentsItemsCheckBox,
    ComponentsBottomSheetTOS,
    ComponentsBottomSheetPrivacyPolicy
  }

}
</script>
