<template>
<ComponentsLayoutPage3
  header
  footer
  :loading="isProcessLoading"
>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '600px' }">

    <v-col cols=12 ref="TOP">
      <ComponentsLayoutHeading1 text="STEP1. 予約申請希望選択"></ComponentsLayoutHeading1>
    </v-col>
    <!--
    <v-col cols=12 v-show="step === 1">
      <ComponentsLayoutHeading2 text="犬以外の予約をご希望の場合は、直接お電話下さい。"></ComponentsLayoutHeading2>
    </v-col>
    --->

    <v-col cols=12>
      <ComponentsLayoutHeading2 text="ご予約希望の愛犬を選択してください（複数選択可）"></ComponentsLayoutHeading2>
    </v-col>

    <v-col cols=12 class="text-right">
      <ComponentsItemsButton
        :loading="isLoading"
        small
        text="新しく愛犬を登録する"
        @click="modal = true"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=12>
      <v-list nav dense two-line class="rounded-lg">
        <template v-for="(item, index) in animals">
          <v-divider :key="'d' + index" v-if="index !== 0" class="pb-1"></v-divider>

          <v-list-item
            @click="(selectedAnimals.some(v => v.karte_id === item.fbn_personals.karte_id)) ? onSelectAnimal(item, 'remove') : onSelectAnimal(item, 'add')"
            color="primary"
            :input-value="(selectedAnimals.some(v => v.karte_id === item.fbn_personals.karte_id))"
            :key="index"
          >
          <!-- <v-list-item :key="index" @click="setAnimals(item)"> -->
            <v-list-item-content>
              <v-list-item-title class="text-left text-omitted">
                <span class="font-weight-bold" v-text="item.personals.name1"></span>
              </v-list-item-title>
              <v-list-item-subtitle class="text-left ec-font-size10 text-omitted">
                <span v-text="item.personals.species"></span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-left ec-font-size10 text-omitted">
                <span v-text="getAnimalSexName(item.personals.sex)"></span>&nbsp;<span v-text="getAge(item.personals.birth_date) + '歳'" v-if="!isUndefined(item.personals.birth_date)"></span>
              </v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
        </template>
      </v-list>
    </v-col>

    <v-col cols=12>
      <ComponentsItemsButton
        block
        :loading="isProcessLoading"
        text="次へ"
        @click="onNext()"
        :disabled="(selectedAnimals.length === 0)"
      >
      </ComponentsItemsButton>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP2. コース＆指名条件"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP3. ご予約希望日時の設定"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP4. お客様情報の確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP5. カルテ情報の確認"></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutHeading1 text="STEP6. 申請内容の最終確認"></ComponentsLayoutHeading1>
    </v-col>
  </v-row>

  <!-- 動物登録 -->
  <v-bottom-sheet persistent scrollable v-model="modal" v-if="modal">
    <v-card flat >
      <v-card-title :color="primaryColor">
        <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '840px' }">
          <v-col cols=12>
            <ComponentsLayoutHeading1 text="愛犬情報の追加" border-hidden></ComponentsLayoutHeading1>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text :style="{ backgroundColor: backGroundColor }">
        <v-row align="center" justify="center" class="pt-5 mx-auto" :style="{ maxWidth: '840px' }">
          <v-col cols=12 class="pt-5">
            <ComponentsFormsProfileAnimal
              :loading="isLoading || isProcessLoading"
              ref="animals"
            ></ComponentsFormsProfileAnimal>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-row class="mx-auto" no-gutters :style="{ maxWidth: '840px' }">
          <v-col cols=12 class="d-flex">
            <ComponentsItemsButton
              text-btn
              text="閉じる"
              :loading="isProcessLoading"
              @click="modal = false"
            >
            </ComponentsItemsButton>
            <v-spacer></v-spacer>
            <ComponentsItemsButton
              text="登録する"
              :loading="isProcessLoading"
              @click="addAction()"
            >
            </ComponentsItemsButton>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</ComponentsLayoutPage3>
</template>

<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage3 from '@/components/layout/Page3'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'

import ComponentsFormsProfileAnimal from '@/components/forms/ProfileAnimal'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'StoresTrimmingReserveTrimmingMemberStep1Page',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    modal: false
  }),

  computed: {
    storeID: {
      get () {
        return this.$route.params.shopID
      }
    },
    UsersStores: {
      get () {
        return this.$store.getters['modStorage/usersStores']
      }
    },
    SessionMemberShip: {
      get () {
        return this.$store.getters['modSession/MemberShip']
      }
    },
    animals: {
      get () {
        return ((this.SessionMemberShip.animals) && this.SessionMemberShip.animals.length > 0)
          ? this.SessionMemberShip.animals.filter(item => item.animals_attribute.death_status !== '2')
          : []
      }
    },
    selectedAnimals: {
      get () {
        return this.$store.getters['modTrimming/animals']
      }
    }
  },

  created () {
    setTimeout(() => (
      this.$refs.TOP.scrollIntoView({ behavior: 'smooth' })
    ))
  },

  mounted () {
    this.onLoading()
  },

  methods: {
    onLoading: function () {
      this.isProcessLoading = false
    },
    onSelectAnimal: function (item, type = 'add') {
      this.$store.dispatch('modTrimming/setSelectAnimals', [item, type])
    },
    addAction: function () {
      (async () => {
        let error = false
        if (this.$refs.animals) {
          await this.$refs.animals.confirm()
          if (this.$refs.animals.error) {
            error = true
          }
        }
        if (error) {
          return false
        }
        this.isProcessLoading = true
        const resultsRow = await APILib.postStoresAnimals(
          this.storeID,
          this.UsersStores.fbn_personals.karte_id,
          {
            personals: this.$refs.animals.personals,
            animals_attribute: this.$refs.animals.animals_attribute
          }
        )
        this.SessionMemberShip.animals.push(resultsRow)
        this.onSelectAnimal(resultsRow, 'add')
        this.modal = false
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },
    onNext: function () {
      if (this.selectedAnimals.length === 0) {
        return this.$confirm({ title: 'Warning', message: '愛犬を選択してください', button: { yes: 'OK' } })
      }
      this.pageTransition('/gr-rsv/' + this.storeID + '/reserve/2')
    }
  },

  components: {
    ComponentsLayoutPage3,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsFormsProfileAnimal,
    ComponentsItemsButton
  }
}
</script>
