import Vue from 'vue'
import Vuex from 'vuex'

import modPage from '@/store/vuex/pages.js'
import modSession from '@/store/vuex/session.js'
import modStorage from '@/store/vuex/storage.js'
import modVerify from '@/store/vuex/verify.js'
import modTrimming from '@/store/vuex/trimming.js'

// import createMultiTabState from 'vuex-multi-tab-state'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modSession: {
      authorization: '',
      provider: '',
      MemberShip: []
    },
    modPage: {
      windowSize: {
        width: 0,
        height: 0
      },
      isInitialLoading: false,
      isLoading: true,
      isDataLoading: false,
      page_background_color: 'rgba(var(--b3f,244,247,252),1)',
      page_title: '',
      common_header_title: '',
      common_header_left: '',
      common_header_right: '',
      backend: {
      }
    },
    modStorage: {
      weeks: {
        1: '月',
        2: '火',
        3: '水',
        4: '木',
        5: '金',
        6: '土',
        7: '日'
      },
      rules: {
        required: v => !!v || '必須項目です',
        regexNumeric: v => (/[0-9]/.test(v)) || '半角数字を1つ以上含めてください',
        regexAlphaUpper: v => (/[A-Z]/.test(v)) || '半角英字（大文字）を1つ以上含めてください',
        regexAlphaLower: v => (/[a-z]/.test(v)) || '半角英字（小文字）を1つ以上含めてください',
        regexSymbol: v => (/[-_@]/.test(v)) || '記号(-_@)を1つ以上含めてください',
        telMatch: v => (/^[0-9]+$/.test(v)) || '半角数字のみ利用可能です',
        emailMatch: v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || '形式が正しくありません'
      },
      Users: {},
      UsersStores: [],
      UsersAnimals: [],
      UsersAnimalsHide: [],
      // --------------------------------------------------------
      //
      // --------------------------------------------------------
      Reserve: [],
      WeightsDate: new Date().toISOString().substr(0, 10),
      WeightsYears: [],
      WeightsMonths: [],
      // --------------------------------------------------------
      //
      // --------------------------------------------------------
      StoreContents: {},
      StoreServiceContents: {},
      StoresGroomer: [],
      StoresGroomingCategories: [],
      StoresMemberShip: {},
      UserTypeRule: [],
      PasswordRule: [],
      StoresInterviewSheet: [],
      // --------------------------------------------------------
      //
      // --------------------------------------------------------
      AnimalType: [],
      Species: [],
      SexPerson: [],
      SexAnimal: [],
      Country: [],
      Allergy: [],
      VaccineType: [],
      Prefecture: [],
      StoreServices: []
    },
    modVerify: {
      channel: '',
      tel: ''
    },
    modTrimming: {
      vaccineTypes: [],
      selectHeadcount: [
        { name: '1頭', code: '1' },
        { name: '2頭', code: '2' },
        { name: '3頭', code: '3' }
      ],
      common_request: [],
      //
      trimming_timezone: [],
      //
      trimming_request: {
        time_to_contact: '',
        ask_note: ''
      },
      trimming: {
        ask_note: '',
        trimming_date: '',
        trimming_str_time: '',
        trimming_end_time: ''
      },
      //
      personals: {
        img_src: '',
        name: '',
        name_kana: '',
        sex: '9',
        birth_date: ''
      },
      users_attribute: {
        country: '+81',
        post_no: '',
        prefecture_id: '',
        address: '',
        building: '',
        mail_address: ''
      },
      address_book: [],
      fbn_personals: {},
      users_authentication: {},
      //
      animals: []
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    modPage,
    modSession,
    modStorage,
    modVerify,
    modTrimming
  }
  // plugins: [
  //   createMultiTabState({ statesPaths: ['modSession.accessToken'] })
  // ]
})
