<template>
<ComponentsLayoutSection1>
  <v-row align="center" justify="center" class="py-3">

    <v-col cols=12 >
      <ComponentsLayoutHeading3 text="アカウント" required></ComponentsLayoutHeading3>
    </v-col>
    <v-col cols=12 >
      <ComponentsItemsTextField
        name="account"
        :value.sync="users_authentication.personal_account"
        :loading="loading"
        ref="account"
      ></ComponentsItemsTextField>
    </v-col>

    <v-col cols=12 >
      <ComponentsLayoutHeading3 text="メールアドレス" required></ComponentsLayoutHeading3>
    </v-col>
    <v-col cols=12 >
      <ComponentsItemsTextField
        name="mailAddress"
        :value.sync="users_attribute.mail_address"
        :loading="loading"
        ref="mailAddress"
      ></ComponentsItemsTextField>
    </v-col>

    <v-col cols=12 >
      <ComponentsLayoutHeading3 text="パスワード" required></ComponentsLayoutHeading3>
    </v-col>
    <v-col cols=12 >
      <ComponentsItemsTextFieldTypePassword
        name="password"
        :value.sync="users_authentication.personal_password"
        :loading="loading"
        autocomplete="current-password"
        ref="password"
      ></ComponentsItemsTextFieldTypePassword>
    </v-col>

    <v-col cols=12 >
      <ComponentsLayoutHeading3 text="パスワード(確認)" required></ComponentsLayoutHeading3>
    </v-col>
    <v-col cols=12 >
      <ComponentsItemsTextFieldTypePassword
        name="password"
        :value.sync="password"
        :loading="loading"
        ref="re_password"
      ></ComponentsItemsTextFieldTypePassword>
    </v-col>

    <!--
    <v-col cols=12 >
      <ComponentsLayoutHeading3 text="メールアドレス" required></ComponentsLayoutHeading3>
    </v-col>
    <v-col cols=12 >
      <ComponentsItemsTextField
        name="mail_address"
        :value.sync="additional.mail_address"
        :loading="loading"
        ref="mail_address"
      ></ComponentsItemsTextField>
    </v-col>
    --->

  </v-row>
</ComponentsLayoutSection1>
</template>

<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsTextFieldTypePassword from '@/components/items/TextFieldTypePassword'

export default {
  name: 'ComponentsFormsUserAccount',

  mixins: [MixinLib],

  data: () => ({
    password: '',
    error: false
  }),

  methods: {
    isError: function () {
      return this.error
    },

    confirm: async function () {
      this.error = false
      const forms = [
        'account',
        'password',
        're_password',
        'mail_address'
      ]
      for (const name of forms) {
        if (this.$refs[name]) {
          await this.$refs[name].confirm()
          if (this.$refs[name].error) {
            this.error = true
          }
        }
      }
      if (this.error) {
        return false
      }

      if (this.users_authentication.personal_password !== this.password) {
        this.$confirm({ title: 'Error', message: 'パスワードが一致していません', button: { yes: 'OK' } })
        this.error = true
        return false
      }

      return true
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsItemsTextField,
    ComponentsItemsTextFieldTypePassword,
    ComponentsLayoutHeading3
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    personals: {
      type: Object,
      required: false,
      default: () => {
        return {
          img_src: '',
          name: '',
          name_kana: '',
          sex: '',
          birth_date: ''
        }
      }
    },
    users_authentication: {
      type: Object,
      required: false,
      default: () => {
        return {
          personal_account: '',
          personal_password: ''
          // mail_address: ''
        }
      }
    },
    users_attribute: {
      type: Object,
      required: false,
      default: () => {
        return {
          country: '',
          post_no: '',
          prefecture_id: '',
          address: '',
          building: '',
          mail_address: ''

        }
      }
    }
  }

}
</script>
